import { useCallback, useState } from 'react';
import useAuth from './useAuth';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const useFetchWithMsal = ({ scopes }) => {
  const { acquireToken } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const execute = useCallback(
    async (method, endpoint, payload = null, additionalHeaders = null) => {
      try {
        setIsLoading(true);
        const accessToken = await acquireToken(scopes);

        const headers = {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`, // If for some odd reason the Additional Headers contain an Authorization key, this will overwrite it
          ...additionalHeaders,
        };

        let requestBody;
        if (payload === null) {
          requestBody = null;
        } else {
          requestBody =
            headers['Content-Type'] === 'application/json'
              ? JSON.stringify(payload)
              : payload;
        }

        const response = await fetch(endpoint, {
          headers,
          method,
          body: requestBody,
        });

        if (!response.ok) {
          // throw new Error('An error ocurred while getting the response'); // FIXME: This will be a catch all error message. You should handle this better. If we don't handle the error in the app, it will crash
          // TODO: Before throwing errors from the hook we should make sure that all the App is handling errors, or it will crash
        }

        if (response.status === 204) return null; // No content (DELETE, PUT, PATCH, etc.

        const responseData = await response.json();
        return responseData;
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [acquireToken, scopes],
  );

  return { error, isLoading, execute };
};

export default useFetchWithMsal;
