import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TabbedMenu from 'components/TabbedMenu';
import useUser from 'hooks/useUser';
import { protectedResources } from 'authConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import ConsoleLogger from '../../utils/logger';
import { startLatencyMetric, startSuccessMetric } from '../../utils/metrics';

const PAGE_SIZE = 10;
const logger = ConsoleLogger.getInstance();

function AdminDashboard() {
  const location = useLocation();
  const [totalUpcomingEvents, setTotalUpcomingEvents] = useState(0);
  const [currentUpcomingEventsPage, setUpcomingEventsCurrentPage] = useState(1);
  const [upcomingEventsPageTotal, setUpcomingEventsPageTotal] = useState(0);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [totalUpcomingTickets, setTotalUpcomingTickets] = useState(0);
  const [currentUpcomingTicketsPage, setUpcomingTicketsCurrentPage] =
    useState(1);
  const [upcomingTicketsPageTotal, setUpcomingTicketsPageTotal] = useState(0);
  const [upcomingTickets, setUpcomingTickets] = useState([]);

  const [currentOnHoldTicketsPage, setOnHoldTicketsCurrentPage] = useState(1);
  const [totalOnHoldTickets, setTotalOnHoldTickets] = useState(0);
  const [onHoldTicketsPageTotal, setOnHoldTicketsPageTotal] = useState(0);
  const [onHoldTickets, setOnHoldTickets] = useState([]);

  const [currentArchivedTicketsPage, setArchivedTicketsCurrentPage] =
    useState(1);
  const [totalArchivedTickets, setTotalArchivedTickets] = useState(0);
  const [archivedTicketsPageTotal, setArchivedTicketsPageTotal] = useState(0);
  const [archivedTickets, setArchivedTickets] = useState([]);

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.tickets.scopes.access_as_user,
  });
  const {
    profile: { firstName },
    organizationId,
  } = useUser();

  const adminDashboardMenu = [
    {
      path: 'upcoming-events',
      label: 'Upcoming Events',
      badge: totalUpcomingEvents,
    },
    {
      path: 'upcoming-tickets',
      label: 'Upcoming Tickets',
      badge: totalUpcomingTickets,
    },
    {
      path: 'on-hold-tickets',
      label: 'On Hold Tickets',
      badge: totalOnHoldTickets,
    },
    {
      path: 'archived-tickets',
      label: 'Archived Tickets',
      badge: totalArchivedTickets,
    },
  ];

  const fetchUpcomingTickets = useCallback(async () => {
    logger.info('Fetching upcoming tickets');
    const latency = startLatencyMetric('fetchUpcomingTickets_latency');
    const success = startSuccessMetric('fetchUpcomingTickets_success');

    const payload = {
      organizationId,
      skip: (currentUpcomingTicketsPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    };

    const res = await execute(
      'POST',
      `${protectedResources.tickets.endpoint}/upcoming`,
      payload,
    );

    latency.record();
    success.record(true);

    setTotalUpcomingTickets(res.count);
    setUpcomingTicketsPageTotal(
      Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
    );
    setUpcomingTickets(res?.tickets);
  }, [execute, organizationId, currentUpcomingTicketsPage]);

  const fetchUpcomingEvents = useCallback(async () => {
    const payload = {
      organizationId,
      skip: (currentUpcomingEventsPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    };

    const res = await execute(
      'POST',
      `${protectedResources.events.endpoint}/upcoming`,
      payload,
    );

    setTotalUpcomingEvents(res.count);
    setUpcomingEventsPageTotal(
      Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
    );
    setUpcomingEvents(res?.events);
  }, [execute, organizationId, currentUpcomingEventsPage]);

  const fetchOnHoldTickets = useCallback(async () => {
    const payload = {
      organizationId,
      skip: (currentOnHoldTicketsPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    };

    const res = await execute(
      'POST',
      `${protectedResources.tickets.endpoint}/hold`,
      payload,
    );

    setTotalOnHoldTickets(res?.count);
    setOnHoldTicketsPageTotal(Math.ceil((res.count * 100) / (PAGE_SIZE * 100)));

    setOnHoldTickets(res?.tickets);
  }, [organizationId, currentOnHoldTicketsPage, execute]);

  const fetchArchivedTickets = useCallback(async () => {
    const payload = {
      organizationId,
      skip: (currentArchivedTicketsPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    };

    const res = await execute(
      'POST',
      `${protectedResources.tickets.endpoint}/archived`,
      payload,
    );

    setTotalArchivedTickets(res?.count);
    setArchivedTicketsPageTotal(
      Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
    );

    setArchivedTickets(res?.tickets);
  }, [organizationId, currentArchivedTicketsPage, execute]);

  useEffect(() => {
    // Handle out of bounds condition when hiding tickets
    const availablePages = Math.ceil((onHoldTickets * 100) / (PAGE_SIZE * 100));
    if (
      currentOnHoldTicketsPage !== 1 &&
      currentOnHoldTicketsPage > availablePages
    ) {
      setOnHoldTicketsCurrentPage((page) => page - 1);
    }
  }, [onHoldTickets, currentOnHoldTicketsPage]);

  useEffect(() => {
    fetchOnHoldTickets();
    fetchUpcomingTickets();
    fetchUpcomingEvents();
    fetchArchivedTickets();
  }, [
    fetchUpcomingTickets,
    fetchUpcomingEvents,
    fetchOnHoldTickets,
    fetchArchivedTickets,
    location,
  ]);

  return (
    <div
      id="AdminDashboard"
      className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
    >
      <div
        id="AdminDashboardHeader"
        className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
      >
        <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Admin Dashboard</h1>
            <p className="text-gray mb-0">Welcome, {firstName}!</p>
            <div className="mt-6">
              <TabbedMenu items={adminDashboardMenu} />
            </div>
          </div>
        </div>
      </div>

      <div
        id="AdminDashboardContentWrapper"
        className="gap-6 d-flex align-items-start px-lg-8 px-0 flex-column w-100 mt-8"
      >
        <Outlet
          context={{
            // Upcoming events state
            totalUpcomingEvents,
            currentUpcomingEventsPage,
            setUpcomingEventsCurrentPage,
            upcomingEventsPageTotal,
            upcomingEvents,
            // Upcoming tickets state
            totalUpcomingTickets,
            currentUpcomingTicketsPage,
            setUpcomingTicketsCurrentPage,
            upcomingTicketsPageTotal,
            upcomingTickets,
            // On-hold tickets state
            totalOnHoldTickets,
            currentOnHoldTicketsPage,
            setOnHoldTicketsCurrentPage,
            onHoldTicketsPageTotal,
            onHoldTickets,
            fetchOnHoldTickets,

            // Archived tickets state
            totalArchivedTickets,
            currentArchivedTicketsPage,
            setArchivedTicketsCurrentPage,
            archivedTicketsPageTotal,
            archivedTickets,
            fetchArchivedTickets,

            // Generic load state
            isLoading,
          }}
        />
      </div>
    </div>
  );
}

export default AdminDashboard;
