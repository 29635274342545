/* eslint-disable indent */
import React from 'react';
import styles from './HeroImage.module.scss';

/**
 * HeroImage component displays an image with a specified background color and style.
 * @param {Object} props - Component props.
 * @param {'pattern' | 'gradient'} [props.altBackground='pattern'] - Alternative background type: 'pattern' or 'gradient'.
 * @param {string} [props.altBackgroundColor='yellow'] - Alternative background color.
 * @param {string} props.imgSrc - Image source URL.
 * @param {Object} [props.style={}] - Additional CSS styles for the component.
 * @param {string} [props.className=''] - Additional CSS classes for the component.
 * @returns {JSX.Element} - Rendered component.
 */
function HeroImage({
  altBackground = 'pattern',
  altBackgroundColor = 'yellow',
  imgSrc,
  style = {},
  className = '',
}) {
  const backgroundImageStyle = imgSrc
    ? {
        backgroundImage: `url(${imgSrc})`,
        backgroundPosition: 'center', // centers the image
        backgroundRepeat: 'no-repeat', // prevents the image from repeating
        backgroundSize: 'cover', // scales the image to be as large as possible
      }
    : {};

  return (
    <div
      className={`${styles['hero-image']} ${
        imgSrc ? '' : styles[`bg-${altBackgroundColor}-${altBackground}`]
      } ${className}`}
      style={{
        ...backgroundImageStyle,
        ...style,
      }}
    />
  );
}

export default HeroImage;
