import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { protectedResources } from 'authConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import useUser from 'hooks/useUser';
import ConsoleLogger from 'utils/logger';
import cn from 'utils/classNames';
import usePageTracking from 'hooks/usePageTracking';
import NoTicket from './NoTicket';
import ClaimedTicketRoll from './ClaimedTicketRoll';
import styles from './Tickets.module.scss';

const GAMES_PER_PAGE = 9;
const GAMES_PER_PAGE_DASHBOARD = 3;

function ClaimedTickets({ dashboard }) {
  const [games, setGames] = useState({ events: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [eventsTotal, setEventsTotal] = useState(0);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });
  const { organizationUserId } = useUser();
  usePageTracking(!dashboard ? 'claimed-tickets' : false);
  const navigate = useNavigate();

  const getEvents = useCallback(async () => {
    try {
      const ticketPayload = {
        ParticipantId: organizationUserId.toUpperCase(),
        Skip: (currentPage - 1) * GAMES_PER_PAGE,
        Take: dashboard ? GAMES_PER_PAGE_DASHBOARD : GAMES_PER_PAGE,
      };
      const res = await execute(
        'POST',
        `${protectedResources.tickets.endpoint}/claimed-tickets`,
        ticketPayload,
      );

      setGames({
        events: res?.events,
        count: res?.count || 0,
      });

      setEventsTotal(res.ticketsCount);
      setPageTotal(Math.ceil((res?.count || 0) / GAMES_PER_PAGE));
    } catch (err) {
      ConsoleLogger.error(err);
      setGames({ events: [], count: 0 });
    }
  }, [currentPage, dashboard, execute, organizationUserId]);

  useEffect(() => {
    getEvents();
  }, [getEvents, currentPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleConfirm = async (ticketId) => {
    try {
      setIsActionLoading(true);
      const payload = {
        TicketIds: [ticketId],
      };
      await execute(
        'POST',
        `${protectedResources.tickets.endpoint}/confirm`,
        payload,
      );
    } catch (err) {
      ConsoleLogger.error(err);
    } finally {
      setIsActionLoading(false);
      getEvents();
    }
  };

  const handleRelease = async (ticketId) => {
    try {
      setIsActionLoading(true);
      const payload = {
        TicketIds: [ticketId],
      };
      await execute(
        'POST',
        `${protectedResources.tickets.endpoint}/release`,
        payload,
      );
    } catch (err) {
      ConsoleLogger.error(err);
    } finally {
      setIsActionLoading(false);
      getEvents();
    }
  };

  return (
    <div
      id="TicketsWrapper"
      className={cn([styles.ticketsWrapper, 'd-flex flex-wrap gap-6'])}
      aria-live="polite"
      aria-atomic="true"
    >
      {dashboard && (
        <div className="d-flex w-100 justify-content-between">
          <h2 className="fs-2 fw-semibold d-flex align-items-center">
            Claimed Tickets
          </h2>
          <Button
            variant="tertiary-gray"
            onClick={() => navigate('/tickets/claimed')}
          >
            View All Claimed Tickets
            <span className="badge rounded-pill bg-black">{eventsTotal}</span>
          </Button>
        </div>
      )}

      <div className="d-flex w-100 flex-wrap gap-6">
        {games?.events?.length > 0 && currentPage <= pageTotal ? (
          games.events.map((e) => (
            <ClaimedTicketRoll
              key={e.eventId}
              events={e}
              handleConfirm={handleConfirm}
              handleRelease={handleRelease}
              isLoading={isActionLoading}
            />
          ))
        ) : (
          <NoTicket text="You currently have no claimed tickets. Browse unclaimed tickets below and claim your spot!" />
        )}
        {!dashboard && (
          <div
            id="TableOuterFooter"
            className="pt-3 pb-4 px-4 px-lg-6 d-flex gap-4 w-100 justify-content-between align-items-center"
          >
            <div className={styles.paginationText}>
              Page {currentPage} of {pageTotal}
            </div>
            <div className="d-flex">
              <Button
                variant="tertiary"
                size="sm"
                disabled={currentPage === 1}
                className="me-3"
                onClick={handlePrevPage}
              >
                Previous
              </Button>
              <Button
                variant="tertiary"
                disabled={currentPage === pageTotal}
                size="sm"
                onClick={handleNextPage}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClaimedTickets;
