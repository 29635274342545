import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import LotteriesFiltered from './LotteriesFiltered';

function ActiveLotteries({ dashboard }) {
  const ACTIVE = 'Active';
  usePageTracking(!dashboard ? 'active-lotteries' : false);
  return (
    <LotteriesFiltered lotteryStatusFilter={ACTIVE} dashboard={dashboard} />
  );
}

export default ActiveLotteries;
