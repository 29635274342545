import React from 'react';
import { Button } from 'react-bootstrap';
import Trash from 'assets/icons/trash.svg';

function LotteryRulesParticipantsActions({ id, onParticipantDelete }) {
  return (
    <div className="d-flex align-items-center gap-1">
      <Button
        variant="link"
        className="p-0"
        onClick={() => onParticipantDelete(id)}
      >
        <Trash className="me-1" />
      </Button>
    </div>
  );
}

export default LotteryRulesParticipantsActions;
