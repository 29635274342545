/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import UploadCloud from 'assets/icons/upload-cloud.svg';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import Trash from 'assets/icons/trash.svg';
import Users from 'assets/icons/users.svg';
import UserPlus from 'assets/icons/user-plus.svg';
import Refresh from 'assets/icons/refresh.svg';
import styles from './LotterySteps.module.scss';
import makeParticipantRow from './ParticipantRowFormatter';

const defaultTableColumns = {
  id: 'Id',
  name: { label: 'Name', sortBy: 'FirstName' },
  jobTitle: { label: 'Job Title', sortBy: 'JobTitle' },
  company: { label: 'Company', sortBy: 'CompanyName' },
  tags: { label: 'Tags', sortBy: 'Tags' },
};

const freeForAllTableColumns = {
  ...defaultTableColumns,
  actions: 'Actions',
};

const priorityGroupTableColumns = {
  ...defaultTableColumns,
  priorityGroup: { label: 'Priority Group', sortBy: 'PriorityGroup' },
  actions: 'Actions',
};

function LotteryRulesParticipants({
  usePriorityGroups,
  priorityGroups,
  onPriorityGroupSelect,
  onParticipantDelete,
  participants,
  isParticipantsLoading,
  sortBy,
  setSortBy,
  handleReset,
  handleShowInvitationModal,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [participantRows, setParticipantRows] = useState([]);

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setPageTotal(Math.ceil((participants?.length || 0) / 10));
    setTotalParticipants(participants?.length || 0);
  }, [participants]);

  useEffect(() => {
    // Paginate the participants array, should get 10 values
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const paginatedParticipants = participants?.slice(startIndex, endIndex);

    // Map the participants to the table rows
    const currentPageParticipantRows = paginatedParticipants?.map(
      (participant) =>
        makeParticipantRow({
          avatar: participant?.ProfileImageUrl,
          company: participant?.CompanyName,
          email: participant?.Email,
          firstName: participant?.FirstName,
          id: participant?.OrganizationUserId,
          jobTitle: participant?.JobTitle,
          lastName: participant?.LastName,
          priorityGroup: priorityGroups?.find(
            (group) => group.groupIndex === participant?.priorityGroupIndex,
          ),
          tags: participant?.Tags,
          priorityGroups,
          handlePriorityGroupSelect: (priorityGroupIndex, id) => {
            onPriorityGroupSelect(priorityGroupIndex, id);
            setHasChanges(true);
          },
          handleParticipantDelete: (id) => {
            onParticipantDelete(id);
            setHasChanges(true);
          },
        }),
    );

    setParticipantRows(currentPageParticipantRows);
  }, [
    currentPage,
    participants,
    priorityGroups,
    onPriorityGroupSelect,
    onParticipantDelete,
  ]);

  function deleteSelectedParticipants() {
    onParticipantDelete(...selectedParticipants);
    setSelectedParticipants([]);
    setHasChanges(true);
  }

  function setPriorityGroupToSelectedParticipants(priorityGroupIndex) {
    onPriorityGroupSelect(priorityGroupIndex, ...selectedParticipants);
    setHasChanges(true);
    setSelectedParticipants([]);
  }

  function resetParticipants() {
    handleReset();
    setHasChanges(false);
  }

  return (
    <TableFrame>
      <TableHeader
        title="Lottery Participants"
        totalItems={totalParticipants}
        description="Add and edit participant settings."
      >
        <div className="d-flex align-items-center gap-2">
          {selectedParticipants.length > 0 && (
            <>
              <Button
                variant="tertiary"
                onClick={() => deleteSelectedParticipants()}
              >
                <Trash />
                Remove
              </Button>

              {usePriorityGroups && (
                <Dropdown>
                  <Dropdown.Toggle
                    className={`${styles['dropdown-toggle']}`}
                    variant="secondary"
                  >
                    <Users />
                    Change Priority Group
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    role="menu"
                    aria-label="Set Priority Group to Selected Users"
                    as="ul"
                  >
                    {priorityGroups?.map((priorityGroup, index) => (
                      <Dropdown.Item
                        key={priorityGroup.groupIndex}
                        className=""
                        as="li"
                      >
                        <Button
                          className="me-3 w-100"
                          variant="text"
                          onClick={() =>
                            setPriorityGroupToSelectedParticipants(index)
                          }
                        >
                          <PriorityGroupBadge
                            priorityGroup={priorityGroup}
                            showTooltip={false}
                          />
                        </Button>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          )}

          {hasChanges && (
            <Button variant="tertiary" onClick={() => resetParticipants()}>
              <Refresh />
              Reset participants
            </Button>
          )}

          <Button
            className="d-none d-lg-block text-nowrap py-2 "
            variant="outline-primary"
            onClick={handleShowInvitationModal}
          >
            <UserPlus className="me-3" />
            Add Participants
          </Button>
        </div>
      </TableHeader>
      <TableBody
        columns={
          usePriorityGroups ? priorityGroupTableColumns : freeForAllTableColumns
        }
        sortBy={sortBy}
        handleSortBy={setSortBy}
        rows={participantRows}
        isLoading={isParticipantsLoading}
        setSelected={setSelectedParticipants}
        selected={selectedParticipants}
        showSelectAll={false}
        noRowsMessage={
          isParticipantsLoading
            ? 'Loading Participants...'
            : 'No Participants Found'
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        NoRowsCTA={() =>
          !isParticipantsLoading && (
            <Button
              as={NavLink}
              id="UploadParticipantsDesktop"
              variant="outline-primary"
              to="/manage-participants/all-participants"
            >
              <UploadCloud className="me-2" />
              Go to Participants Management
            </Button>
          )
        }
      />
      <TableFooter
        currentPage={currentPage}
        pageTotal={pageTotal}
        setCurrentPage={setCurrentPage}
      />
    </TableFrame>
  );
}

export default LotteryRulesParticipants;
