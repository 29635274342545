import React from 'react';
import Button from 'react-bootstrap/Button';

/**
 * `ActionButtons` renders a pair of buttons typically used for form actions, such as 'Cancel' and 'Save'.
 * This component is designed to be used in forms where you wish to provide clear user actions.
 *
 * @component
 * @param {Object} props - The properties for the ActionButtons component.
 * @param {boolean} props.isLoading - Indicates if action operation is in progress (e.g., form submission).
 * @param {Function} props.handleCancel - Function to call when the 'Cancel' button is clicked.
 * @param {string} [props.cancelLabel='Cancel'] - Label for the 'Cancel' button.
 * @param {Function} props.handleAction - Function to call when the 'Save' button is clicked.
 * @param {string} [props.actionLabel='Save'] - Label for the 'Submit' action button.
 *
 * @example
 * <ActionButtons
 *   isLoading={formIsSubmitting}
 *   handleCancel={() => resetForm()}
 *   cancelLabel="Abort"
 *   handleAction={() => submitForm()}
 *   actionLabel="Submit"
 * />
 *
 * @returns {JSX.Element} A `div` containing two `Button` components.
 */
export default function ActionButtons({
  isLoading,
  handleCancel,
  cancelLabel,
  handleAction,
  actionLabel,
}) {
  return (
    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center gap-3 flex">
      <Button variant="tertiary" onClick={() => handleCancel()}>
        {cancelLabel || 'Cancel'}
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={isLoading}
        onClick={() => handleAction()}
      >
        {actionLabel || 'Save'}
      </Button>
    </div>
  );
}
