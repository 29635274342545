import { ORGANIZATION_ADMIN } from 'constants';
import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  DETAILS_STEP,
  LotteryDetailsFields,
  SCHEDULE_STEP,
} from 'constants/addLottery';
import NoData from 'components/NoData';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import ConsoleLogger from 'utils/logger';
import classNames from 'utils/classNames';
import useUser from 'hooks/useUser';

import Ticket from 'assets/icons/ticket.svg';

import LotteryCard from './LotteryCard';
import styles from './AllLotteries.module.scss';
import { useConfirm } from '../../confirm/ConfirmProvider';

let PAGE_TOTAL = 6;
const LOTTERY_DRAFT = 'lotteryDraft';

function AllLotteries({ lotteryStatusFilter, dashboard }) {
  PAGE_TOTAL = dashboard ? 3 : 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentLotteries, setCurrentLotteries] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState();
  const { requestConfirm } = useConfirm();

  const { organizationId, organizationUserRole } = useUser();

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.lotteries.scopes.access_as_user,
  });
  const navigate = useNavigate();

  /**
   * Get drafted lottery
   * @returns {Object} serialized drafted lottery
   */
  const getDraftedLottery = () => {
    const serializedDraftLottery = localStorage.getItem(LOTTERY_DRAFT);

    if (serializedDraftLottery === null) {
      return null;
    }

    const lotteryState = JSON.parse(serializedDraftLottery);
    const draftLottery = {
      id: 'DRAFT',
      status: 'Draft',
    };

    if (lotteryState[DETAILS_STEP]) {
      draftLottery.isDraft = true;

      const startDateTime = new Date(
        `${lotteryState[DETAILS_STEP][LotteryDetailsFields.START_DATE_FIELD]} ${
          lotteryState[DETAILS_STEP][LotteryDetailsFields.START_TIME_FIELD]
        }`,
      );

      const endDateTime = new Date(
        `${lotteryState[DETAILS_STEP][LotteryDetailsFields.END_DATE_FIELD]} ${
          lotteryState[DETAILS_STEP][LotteryDetailsFields.END_TIME_FIELD]
        }`,
      );

      draftLottery.startDateTime = startDateTime;
      draftLottery.endDateTime = endDateTime;
      draftLottery.title =
        lotteryState[DETAILS_STEP][LotteryDetailsFields.LOTTERY_NAME_FIELD];
      draftLottery.logo =
        lotteryState[DETAILS_STEP][LotteryDetailsFields.EVENT_LOGO_FIELD];
      draftLottery.coverPhoto =
        lotteryState[DETAILS_STEP][
          LotteryDetailsFields.EVENT_COVER_PHOTO_FIELD
        ];
      draftLottery.description =
        lotteryState[DETAILS_STEP][LotteryDetailsFields.DESCRIPTION_FIELD];
      draftLottery.venue =
        lotteryState[DETAILS_STEP][LotteryDetailsFields.VENUE_NAME_FIELD];
    }

    if (lotteryState[SCHEDULE_STEP]) {
      const tickets = lotteryState[SCHEDULE_STEP].eventSchedule?.map(
        (scheduleRow) => ({
          id: null,
          eventId: scheduleRow.name,
          isClaimed: false,
          isConfirmed: false,
        }),
      );

      draftLottery.tickets = tickets;

      // Get Unique Events
      draftLottery.eventIds = Array.from(
        new Set(
          lotteryState[SCHEDULE_STEP].eventSchedule.map(
            (scheduleRow) =>
              scheduleRow.name + scheduleRow.eventDate + scheduleRow.time,
          ),
        ),
      );
    } else {
      draftLottery.tickets = [];
      draftLottery.eventIds = [];
    }

    return draftLottery;
  };

  const getLotteries = useCallback(async () => {
    let draftedLottery = null;
    // Only get a drafted lottery if the user is an org admin
    if (organizationUserRole === ORGANIZATION_ADMIN && !lotteryStatusFilter) {
      draftedLottery = getDraftedLottery();
    }

    // When there's a drafted lottery, first page should have the first element to be the drafted lottery
    // So we are just pulling PAGE_TOTAL - 1 lotteries from the backend. Then, on the next pages, we pull PAGE_TOTAL lotteries
    let skip = (currentPage - 1) * PAGE_TOTAL;
    let take = PAGE_TOTAL;

    // For first page we don't skip, but we bring the PAGE_TOTAL - 1 lotteries
    if (draftedLottery && currentPage === 1) {
      skip = 0;
      take = PAGE_TOTAL - 1;
    }
    // For the next pages, we skip the first drafted lottery and bring PAGE_TOTAL lotteries
    else if (draftedLottery && currentPage > 1) {
      skip = (currentPage - 1) * PAGE_TOTAL - 1;
      take = PAGE_TOTAL;
    }

    setIsLoading(true);
    try {
      const payload = {
        organizationId,
        skip,
        take,
        lotteryStatusFilter: lotteryStatusFilter || '',
      };

      const res = await execute(
        'POST',
        `${protectedResources.lotteries.endpoint}/getLotteries`,
        payload,
      );

      if (res) {
        setCurrentLotteries(
          draftedLottery && currentPage === 1
            ? [draftedLottery, ...res.lotteries]
            : res.lotteries,
        );
        setTotalPages(Math.ceil(res.count / PAGE_TOTAL));
        setCount(res.count);
      }
    } catch (error) {
      setHasError(true);
      ConsoleLogger.error('Error fetching lotteries', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    execute,
    lotteryStatusFilter,
    organizationId,
    organizationUserRole,
  ]);

  useEffect(() => {
    getLotteries();
  }, [getLotteries]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleLotteryDelete = useCallback(
    async (lotteryId) => {
      try {
        await execute(
          'DELETE',
          `${protectedResources.lotteries.endpoint}/${lotteryId}`,
        );
        await getLotteries();
      } catch (error) {
        ConsoleLogger.error('Error deleting lottery', error);
      }
    },
    [execute, getLotteries],
  );

  const confirmLotteryDeletion = useCallback(
    async (lotteryId) => {
      requestConfirm({
        callback: () => handleLotteryDelete(lotteryId),
        headerText: 'Are you sure?',
        subHeaderText: 'Lottery will be deleted. This action cannot be undone.',
      });
    },
    [handleLotteryDelete, requestConfirm],
  );

  const archiveLottery = useCallback(
    async (lotteryId) => {
      try {
        await execute(
          'PATCH',
          `${protectedResources.lotteries.endpoint}/${lotteryId}/archive`,
        );
        await getLotteries();
      } catch (error) {
        ConsoleLogger.error('Error archiving lottery', error);
      }
    },
    [execute, getLotteries],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (hasError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div
      className={classNames([
        styles['lotteries-wrapper'],
        'd-flex flex-wrap gap-6',
      ])}
    >
      {dashboard && (
        <div className="d-flex w-100 justify-content-between">
          <h2 className="fs-2 fw-semibold d-flex align-items-center">
            Season Lotteries
          </h2>
          <Button
            variant="tertiary-gray"
            onClick={() => navigate('/season-lotteries/all-lotteries')}
          >
            View All Season Lotteries
            <span className="badge rounded-pill bg-black">{count}</span>
          </Button>
        </div>
      )}
      {currentLotteries?.length > 0 ? (
        currentLotteries.map((lottery) => (
          <LotteryCard
            key={lottery.id}
            lottery={lottery}
            isDraft={lottery.isDraft ?? false}
            userRole={organizationUserRole}
            onLotteryDeletion={confirmLotteryDeletion}
            onLotteryArchive={archiveLottery}
          />
        ))
      ) : (
        <NoData title="No Lotteries Found">
          {organizationUserRole === ORGANIZATION_ADMIN ? (
            <NavLink className="btn btn-primary btn-lg mt-4" to="/add-lottery">
              <Ticket className="me-1" />
              Create A New Lottery!
            </NavLink>
          ) : (
            'Your Admin should be creating your first lottery soon!'
          )}
        </NoData>
      )}
      <div
        id="TableOuterFooter"
        className="pt-3 pb-4 px-4 px-lg-6 d-flex gap-4 w-100 justify-content-between align-items-center"
      >
        {!dashboard &&
          (totalPages < 1 || (
            <>
              <div className="text-gray-dark fs-4 fw-medium lh-sm">
                Page {currentPage} of {totalPages}
              </div>
              <div className="d-flex">
                <Button
                  variant="tertiary"
                  size="sm"
                  disabled={currentPage === 1}
                  className="me-3"
                  onClick={handlePrevPage}
                >
                  Previous
                </Button>
                <Button
                  variant="tertiary"
                  disabled={currentPage === totalPages}
                  size="sm"
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              </div>
            </>
          ))}
      </div>
    </div>
  );
}

export default AllLotteries;
