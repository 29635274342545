import React from 'react';
import styles from '../AccountSettings.module.scss';
import ConsoleLogger from '../../../utils/logger';

function Integrations() {
  ConsoleLogger.log('Integrations', styles);
  return <div>Integrations</div>;
}

export default Integrations;
