import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import LotteriesFiltered from './LotteriesFiltered';

function ArchivedLotteries({ dashboard }) {
  const ARCHIVED = 'Archived';
  usePageTracking(!dashboard ? 'archived-lotteries' : false);
  return (
    <LotteriesFiltered lotteryStatusFilter={ARCHIVED} dashboard={dashboard} />
  );
}

export default ArchivedLotteries;
