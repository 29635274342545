import React from 'react';
import useUser from 'hooks/useUser';
import ClaimedTickets from 'pages/tickets/ClaimedTickets';
import UnclaimedTickets from 'pages/tickets/UnclaimedTickets';
import ActiveLotteries from 'pages/season-lotteries/ActiveLotteries';
import usePageTracking from 'hooks/usePageTracking';

function ParticipantDashboard() {
  const {
    profile: { firstName },
  } = useUser();

  usePageTracking('participant-dashboard');

  return (
    <div
      id="ParticipantDashboard"
      className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
    >
      <div
        id="ParticipantDashboardHeader"
        className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
      >
        <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Participant Dashboard</h1>
            <p className="text-gray mb-0">Welcome, {firstName}!</p>
          </div>
        </div>
      </div>

      <div
        id="ParticipantDashboardContentWrapper"
        className="gap-6 d-flex align-items-start px-lg-8 px-0 flex-column w-100 mt-8"
      >
        <ClaimedTickets dashboard />
        <div className="hr-divider my-6" />
        <UnclaimedTickets dashboard />
        <div className="hr-divider my-6" />
        <ActiveLotteries dashboard />
      </div>
    </div>
  );
}

export default ParticipantDashboard;
