import React from 'react';

function TypographyGuide() {
  return (
    <div>
      <h4 className="fw-normal">Rubik</h4>
      <h1 className="fw-normal">Rubik</h1>

      <p className="mt-3 h3 fw-normal">
        ABCDEFGHIJKLMNOPQRSTUVWXYZ
        <br />
        abcdefghijklmnopqrstuvwxyz
        <br />
        0123456789 !@#$%^&*()
      </p>

      <div className="container-fluid m-0 p-0 mt-4">
        {/* Display 2xl demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display 2xl</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 72px / 4.5 rem | Line height: 90x / 5.624 rem |
              Tracking -2%
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-4 h1 fw-bold">
                Display 2xl <br />
                Bold
              </div>
              <div className="col-xxl-4 h1 fw-bolder">
                Display 2xl <br />
                Extrabold
              </div>
              <div className="col-xxl-4 h1 fw-boldest">
                Display 2xl <br />
                Black
              </div>
            </div>
          </div>
        </div>

        {/* Display xl demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display xl</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 60px / 3.75rem | Line height: 72px / 4.5rem | Tracking:
              -2%
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-4 h2 fw-bold">
                Display xl <br />
                Bold
              </div>
              <div className="col-xxl-4 h2 fw-bolder">
                Display xl <br />
                Extrabold
              </div>
              <div className="col-xxl-4 h2 fw-boldest">
                Display xl <br />
                Black
              </div>
            </div>
          </div>
        </div>

        {/* Display lg demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display lg</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 48px / 3rem | Line height: 60px / 3.75rem | Tracking:
              -2%
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 h3 fw-normal">
                Display lg <br />
                Regular
              </div>
              <div className="col-xxl-3 h3 fw-medium">
                Display lg <br />
                Medium
              </div>
              <div className="col-xxl-3 h3 fw-semibold">
                Display lg <br />
                Semibold
              </div>

              <div className="col-xxl-3 h3 fw-bold">
                Display lg <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Display md demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display md</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 36px / 2.25rem | Line height: 44px / 2.75rem |
              Tracking: -2%
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 h4 fw-normal">
                Display md <br />
                Regular
              </div>
              <div className="col-xxl-3 h4 fw-medium">
                Display md <br />
                Medium
              </div>
              <div className="col-xxl-3 h4 fw-semibold">
                Display md <br />
                Semibold
              </div>

              <div className="col-xxl-3 h4 fw-bold">
                Display md <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Display sm demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display sm</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 30px / 1.875rem | Line height: 38px / 2.375rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 h5 fw-normal">
                Display sm <br />
                Regular
              </div>
              <div className="col-xxl-3 h5 fw-medium">
                Display sm <br />
                Medium
              </div>
              <div className="col-xxl-3 h5 fw-semibold">
                Display sm <br />
                Semibold
              </div>

              <div className="col-xxl-3 h5 fw-bold">
                Display sm <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Display xs demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Display xs</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 24px / 1.5rem | Line height: 32px / 2rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 h6 fw-normal">
                Display xs <br />
                Regular
              </div>
              <div className="col-xxl-3 h6 fw-medium">
                Display xs <br />
                Medium
              </div>
              <div className="col-xxl-3 h6 fw-semibold">
                Display xs <br />
                Semibold
              </div>

              <div className="col-xxl-3 h6 fw-bold">
                Display xs <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Text xl demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Text xl</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 20px / 1.25rem | Line height: 30px / 1.875rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 fs-1 fw-normal">
                Text xl <br />
                Regular
              </div>
              <div className="col-xxl-3 fs-1 fw-medium">
                Text xl <br />
                Medium
              </div>
              <div className="col-xxl-3 fs-1 fw-semibold">
                Text xl <br />
                Semibold
              </div>

              <div className="col-xxl-3 fs-1 fw-bold">
                Text xl <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Text lg demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Text lg</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 18px / 1.125rem | Line height: 28px / 1.75rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 fs-2 fw-normal">
                Text lg <br />
                Regular
              </div>
              <div className="col-xxl-3 fs-2 fw-medium">
                Text lg <br />
                Medium
              </div>
              <div className="col-xxl-3 fs-2 fw-semibold">
                Text lg <br />
                Semibold
              </div>

              <div className="col-xxl-3 fs-2 fw-bold">
                Text lg <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Text md demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Text md</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 16px / 1rem | Line height: 24px / 1.5rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 fs-3 fw-normal">
                Text md <br />
                Regular
              </div>
              <div className="col-xxl-3 fs-3 fw-medium">
                Text md <br />
                Medium
              </div>
              <div className="col-xxl-3 fs-3 fw-semibold">
                Text md <br />
                Semibold
              </div>

              <div className="col-xxl-3 fs-3 fw-bold">
                Text md <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Text sm demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Text sm</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 14px / 0.875rem | Line height: 20px / 1.25rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 fs-4 fw-normal">
                Text small <br />
                Regular
              </div>
              <div className="col-xxl-3 fs-4 fw-medium">
                Text small <br />
                Medium
              </div>
              <div className="col-xxl-3 fs-4 fw-semibold">
                Text small <br />
                Semibold
              </div>

              <div className="col-xxl-3 fs-4 fw-bold">
                Text small <br />
                Bold
              </div>
            </div>
          </div>
        </div>

        {/* Text xs demo */}
        <div className="row my-5">
          <div className="col-xxl-6">
            <span className="fs-3 fw-medium">Text xs</span>
          </div>

          <div className="col-xxl-6">
            <span className="fs-3 fw-medium" style={{ float: 'right' }}>
              Font size: 12px / 0.75rem | Line height: 18px / 1.125rem
            </span>
          </div>
          <hr />
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-xxl-3 fs-5 fw-normal">
                Text xs <br />
                Regular
              </div>
              <div className="col-xxl-3 fs-5 fw-medium">
                Text xs <br />
                Medium
              </div>
              <div className="col-xxl-3 fs-5 fw-semibold">
                Text xs <br />
                Semibold
              </div>

              <div className="col-xxl-3 fs-5 fw-bold">
                Text xs <br />
                Bold
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypographyGuide;
