import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ConsoleLogger from 'utils/logger';

import styles from './Dialog.module.scss';

/**
 * Dialog is a functional React component that renders a modal dialog with an icon,
 * heading, subheading, and an optional call-to-action (CTA) button.
 *
 * @param {Object} props - The properties passed to the Dialog component.
 * @param {React.ComponentType} props.Icon - A functional React component used for rendering the icon displayed in the dialog.
 * @param {string} props.heading - The heading text of the dialog.
 * @param {boolean} props.show - Indicates whether the modal is visible.
 * @param {Function} props.handleClose - A callback function that is called when the modal should be hidden.
 * @param {string} [props.subHeading] - The subheading text of the dialog.
 * @param {Function} [props.onExited] - A callback function that is called when the modal has finished exiting.
 * @param {boolean} [props.isLoading=false] - Indicates whether the CTA button is in a loading state.
 * @param {Function} [props.ctaHandler] - The handler function for clicking the CTA button.
 * @param {string} [props.ctaLabel] - The label for the CTA button.
 * @returns {React.ReactElement} - A Modal React element with structured content and interaction patterns
 */
function Dialog({
  Icon,
  heading,
  show,
  handleClose,
  subHeading,
  onExited,
  isLoading,
  ctaHandler,
  ctaLabel,
}) {
  if (typeof Icon !== 'function') {
    ConsoleLogger.error('Prop "icon" must be a functional component.');
  }

  const handleDismiss = () => {
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleDismiss}
      backdrop
      keyboard
      centered
      onExited={() => {
        if (onExited) {
          onExited();
        }
      }}
    >
      <Modal.Header className="align-items-start pb-5" closeButton>
        <div className="d-flex flex-column align-items-start">
          {Icon && (
            <div
              className={`${styles['dialog-icon']} bg-primary-lighter d-flex align-items-center justify-content-center p-3 rounded-circle mb-4`}
            >
              <Icon className="text-primary-darker" />
            </div>
          )}
          <h2 className="fs-2 fw-semibold w-100">{heading}</h2>
          {subHeading && <p className="text-gray mb-0 fs-4">{subHeading}</p>}
        </div>
      </Modal.Header>
      <Modal.Footer className="m-0">
        {ctaLabel && ctaHandler && (
          <Button
            variant="primary"
            size="lg"
            className="w-100 justify-content-center"
            onClick={ctaHandler}
            disabled={isLoading}
          >
            {ctaLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default Dialog;
