import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import ActionDots from 'assets/icons/action-dots.svg';
import RocketIcon from 'assets/icons/rocket.svg';
import Trash from 'assets/icons/trash.svg';
import Archive from 'assets/icons/archive.svg';
import Edit from 'assets/icons/edit.svg';
import styles from './SeasonLotteries.module.scss';
import { COMPLETE, UPCOMING, ARCHIVED } from '../../constants';

function LotteryActions({
  id,
  lotteryStatus,
  onLotteryDeletion,
  onLotteryArchive,
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className={`${styles['dropdown-toggle']} py-0`}
        id={`action-button-${id}`}
        variant="link"
      >
        <span className="visually-hidden">Additional Actions</span>
        <ActionDots aria-hidden />
      </Dropdown.Toggle>
      <Dropdown.Menu role="menu" aria-label="Actions" as="ul">
        <Dropdown.Item className="" as="li">
          <Link
            id={`view-lottery-${id}`}
            className="btn btn-text me-3 w-100"
            to={`/season-lottery-detail/${id}`}
          >
            <RocketIcon className="me-1" />
            View lottery details
          </Link>
        </Dropdown.Item>
        <Dropdown.Item className="" as="li">
          <Link
            id={`view-lottery-${id}`}
            className="btn btn-text me-3 w-100"
            to={`/add-lottery?duplicate=${id}`}
          >
            <Edit className="me-1" />
            Duplicate lottery
          </Link>
        </Dropdown.Item>

        {lotteryStatus === COMPLETE && lotteryStatus !== ARCHIVED && (
          <Dropdown.Item className="" as="li">
            <Button
              id={`archive-lottery-${id}`}
              className="me-3 w-100"
              variant="text"
              onClick={() => onLotteryArchive(id)}
            >
              <Archive className="me-1" />
              Archive Lottery
            </Button>
          </Dropdown.Item>
        )}

        {lotteryStatus === UPCOMING && (
          <Dropdown.Item className="" as="li">
            <Button
              id={`delete-lottery-${id}`}
              className="me-3 w-100"
              variant="text"
              onClick={() => onLotteryDeletion(id)}
            >
              <Trash className="me-1" />
              Delete Lottery
            </Button>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LotteryActions;
