import SeasonLotteries from './SeasonLotteries';
import AllLotteries from './AllLotteries';
import ActiveLotteries from './ActiveLotteries';
import UpcomingLotteries from './UpcomingLotteries';
import CompleteLotteries from './CompleteLotteries';
import ArchivedLotteries from './ArchivedLotteries';

export {
  AllLotteries,
  ActiveLotteries,
  UpcomingLotteries,
  CompleteLotteries,
  ArchivedLotteries,
};

export default SeasonLotteries;
