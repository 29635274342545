import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Collapse } from 'react-bootstrap';
import { protectedResources } from 'authConfig';
import TabbedMenu from 'components/TabbedMenu';
import useUser from 'hooks/useUser';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import ConsoleLogger from 'utils/logger';

const TicketsMenu = [
  {
    path: 'claimed',
    label: 'Claimed',
  },
  {
    path: 'unclaimed',
    label: 'Unclaimed',
  },
];

function Tickets() {
  const [activeLotteries, setActiveLotteries] = useState([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { organizationUserId } = useUser();

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  const getParticipantQuotas = useCallback(async () => {
    try {
      const res = await execute(
        'GET',
        `${protectedResources.tickets.endpoint}/quota/${organizationUserId}`,
      );

      if (res?.length) {
        const currentDate = new Date();
        const filteredLotteries = res.filter(
          (lottery) => new Date(lottery.endDate) >= currentDate,
        );
        setActiveLotteries(filteredLotteries);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      ConsoleLogger.error(err);
      setActiveLotteries([]);
    }
  }, [execute, organizationUserId]);

  useEffect(() => {
    getParticipantQuotas();
    setIsCollapseOpen(false);
  }, [getParticipantQuotas]);

  if (isLoading) {
    // TODO: this loading state sucks, needs improvement
    return ` `;
  }

  return (
    <div className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12">
      <section className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100">
        <div>
          <h2 className="h5">Tickets</h2>
          <p className="text-gray">
            Your claimed tickets and unclaimed tickets in active lotteries.
          </p>
          <h3 className="fs-2 fw-semibold">
            {activeLotteries?.length
              ? `You Have ${activeLotteries.length} Active Lotteries`
              : `You have no active lotteries`}
          </h3>
          <Button
            type="button"
            variant="tertiary-gray"
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          >
            {isCollapseOpen ? 'Hide Lottery Rules' : 'View Lottery Rules'}
          </Button>
          {activeLotteries && activeLotteries.length > 0 && (
            <Collapse in={isCollapseOpen}>
              <div>
                <ul className="text-gray fs-4 mt-4">
                  {activeLotteries?.map((l) => (
                    <li key={l.lotteryId} className="mt-2">
                      On Lottery{' '}
                      <span className="fw-bold">{l.lotteryTitle}</span> you are
                      allowed to{' '}
                      <span className="fw-bold">
                        claim tickets for {l.priorityGroupEventLimit} event(s)
                      </span>{' '}
                      and to{' '}
                      <span className="fw-bold">
                        claim {l.priorityGroupEventTicketLimit} tickets per
                        event
                      </span>
                    </li>
                  ))}
                </ul>
                <p className="text-gray fs-4">
                  Please contact your Admin if you have any questions about
                  claiming tickets
                </p>
              </div>
            </Collapse>
          )}
          <div className="mt-6 mb-4">
            <TabbedMenu items={TicketsMenu} />
          </div>
        </div>
        <div className="w-100">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default Tickets;
