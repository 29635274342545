import React from 'react';
import styles from './ReportCard.module.scss';

/**
 * @param {Object} props - The properties that define the ReportCard component.
 * @param {React.ReactNode} props.icon - The icon to be displayed in the ReportCard.
 * @param {string} props.title - The title of the ReportCard.
 * @param {string|number} props.value - The value to be displayed in the ReportCard.
 */
function ReportCard({ icon, title, value }) {
  return (
    <div
      className={`${styles.reportCard} d-flex flex-column p-6 justify-content-start gap-5 shadow-sm border border-gray-lighter rounded-3 bg-white`}
    >
      <div>{icon}</div>
      <div className="position-relative rounded-2 overflow-hidden fw-medium">
        {title}
      </div>
      <div className="h5 fw-bold">{value}</div>
    </div>
  );
}

export default ReportCard;
