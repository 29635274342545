import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ButtonGuide from './ButtonGuide';
import TypographyGuide from './TypographyGuide';
import ColorGuide from './ColorGuide';
import AvatarsGuide from './AvatarGuide';
import HeroGuide from './HeroGuide';
import FormGuide from './FormGuide';
import SidebarGuide from './SidebarGuide';
import GridGuide from './GridGuide';

function StyleGuide() {
  return (
    <div
      style={{
        padding: '2rem',
      }}
    >
      <h3 className="mb-3">AllSeater Style Guide</h3>
      <p>
        This document is intended to serve as a guide of the different UI
        Components that can be used and have been developed for the application.
        For reference look at the code implementation of each example component.
      </p>

      <hr className="my-5" />

      <Tabs defaultActiveKey="typographies">
        <Tab eventKey="typographies" title="Typography">
          <h3 className="text-primary my-4">Typography</h3>
          <TypographyGuide />
        </Tab>

        <Tab eventKey="colors" title="Color Guide">
          <h3 className="text-primary my-4">Color Guide</h3>
          <ColorGuide />
        </Tab>

        <Tab eventKey="buttons" title="Buttons">
          <h3 className="text-primary my-4">Buttons</h3>
          <ButtonGuide />
        </Tab>

        <Tab eventKey="avatars" title="Avatars">
          <h3 className="text-primary my-4">Avatars</h3>
          <AvatarsGuide />
        </Tab>

        <Tab eventKey="hero-images" title="Hero Images">
          <h3 className="text-primary my-4">Hero Images</h3>
          <HeroGuide />
        </Tab>

        <Tab eventKey="forms" title="Forms">
          <h3 className="text-primary my-4">Forms</h3>
          <FormGuide />
        </Tab>

        <Tab eventKey="grid" title="Grid">
          <h3 className="text-primary my-4">Grid</h3>
          <GridGuide />
        </Tab>

        <Tab eventKey="sidebars" title="Sidebars">
          <h3 className="text-primary my-4">Sidebars</h3>
          <SidebarGuide />
        </Tab>
      </Tabs>
    </div>
  );
}

export default StyleGuide;
