import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { format } from 'date-fns';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';
import Avatar from 'components/Avatar';
import Close from 'assets/icons/x-close.svg';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import localDateTime from 'utils/localTime';
import usePageTracking from 'hooks/usePageTracking';

const tableColumns = {
  id: 'Id',
  event: 'Event',
  eventDate: 'Event Date',
  eventTime: 'Event Time',
  seatDetails: 'Seat Details',
  ticketStatus: 'Ticket Status',
};

function makeOnHoldTicketRow({
  ticketId,
  lotteryLogo,
  eventName,
  eventDateTime,
  section,
  row,
  seat,
  handleHideTicket,
}) {
  const sectionDetail = section && `Section ${section}`;
  const rowDetail = row && `Row ${row}`;
  const seatDetail = seat && `Seat ${seat}`;
  // Construct a string with only valid details
  const seatDetails = [sectionDetail, rowDetail, seatDetail]
    .filter((s) => s)
    .join(', ');

  const eventDatetimeObj = localDateTime(eventDateTime);
  const eventDate = format(eventDatetimeObj, 'MM/dd/yyyy');
  const eventTime = format(eventDatetimeObj, 'hh:mm a');

  return {
    id: ticketId,
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    eventDate,
    eventTime,
    seatDetails: () => seatDetails,
    ticketStatus: (
      <div className="d-flex gap-3">
        <span className="badge user-tag rounded-pill">On Hold</span>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              <div className="d-flex gap-2 flex-column px-3 py-2">
                <span id={`${ticketId}-remove`}>
                  Remove this on hold ticket from displaying.
                </span>
              </div>
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-text"
            onClick={() => handleHideTicket(ticketId)}
            aria-describedby={`${ticketId}-remove`}
          >
            <Close className="text-danger" />
          </button>
        </OverlayTrigger>
      </div>
    ),
  };
}

function OnHoldTickets() {
  const {
    totalOnHoldTickets,
    currentOnHoldTicketsPage,
    setOnHoldTicketsCurrentPage,
    onHoldTicketsPageTotal,
    onHoldTickets,
    fetchOnHoldTickets,
    isLoading,
  } = useOutletContext();
  usePageTracking('onhold-tickets');

  const { execute, isLoading: isLoadingHideTicket } = useFetchWithMsal({
    scopes: protectedResources.tickets.scopes.access_as_user,
  });

  const handleHideTicket = async (ticketId) => {
    if (isLoadingHideTicket) {
      return;
    }
    await execute('POST', `${protectedResources.tickets.endpoint}/hide`, {
      ticketids: [ticketId],
    });
    // refresh the view
    fetchOnHoldTickets();
  };

  let ticketRows = [];
  if (onHoldTickets?.length) {
    ticketRows = onHoldTickets.map((ticket) =>
      makeOnHoldTicketRow({ ...ticket, handleHideTicket }),
    );
  }

  return (
    <TableFrame>
      <TableHeader
        title="On Hold Tickets"
        totalItems={totalOnHoldTickets}
        description="These are the tickets that have gone On Hold."
      />
      <TableBody
        columns={tableColumns}
        rows={ticketRows}
        isLoading={isLoading}
        noRowsMessage={
          isLoading ? 'Loading On Hold Tickets...' : 'No On Hold Tickets Found'
        }
      />
      <TableFooter
        currentPage={currentOnHoldTicketsPage}
        pageTotal={onHoldTicketsPageTotal}
        setCurrentPage={setOnHoldTicketsCurrentPage}
      />
    </TableFrame>
  );
}

export default OnHoldTickets;
