import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APPLICATION_INSIGHTS_INSTRUMENTATION_KEY } from 'constants';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true, // Automatically track route changes
  },
});

if (APPLICATION_INSIGHTS_INSTRUMENTATION_KEY === 'development') {
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Optionally track the initial page view
}

export default appInsights;
