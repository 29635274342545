import { useCallback, useContext } from 'react';
import * as msal from '@azure/msal-browser';
import ConsoleLogger from 'utils/logger';
import AuthenticationContext from '../AuthenticationContext'; // Ensure this points to where your context is defined

const logger = ConsoleLogger.getInstance();

const useAuth = () => {
  const { msalInstance } = useContext(AuthenticationContext);

  const acquireToken = useCallback(
    async (scopes) => {
      try {
        const account = msalInstance.getAllAccounts()[0];
        const response = await msalInstance.acquireTokenSilent({
          scopes,
          account,
        });
        return response.accessToken;
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // Fallback to interactive method if silent token acquisition fails
          msalInstance.acquireTokenRedirect({
            scopes,
            account: msalInstance.getActiveAccount(),
          });
        } else {
          logger.error(error);
        }

        throw error;
      }
    },
    [msalInstance],
  );

  return { acquireToken };
};

export default useAuth;
