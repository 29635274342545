import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';
import Avatar from 'components/Avatar';
import localDateTime from 'utils/localTime';
import usePageTracking from 'hooks/usePageTracking';

const tableColumns = {
  id: 'Id',
  event: 'Event',
  eventDate: 'Event Date',
  eventTime: 'Event Time',
  seatDetails: 'Seat Details',
  ticketStatus: 'Ticket Status',
};

function makeArchivedTicketRow({
  ticketId,
  lotteryLogo,
  eventName,
  eventDateTime,
  section,
  row,
  seat,
}) {
  const sectionDetail = section && `Section ${section}`;
  const rowDetail = row && `Row ${row}`;
  const seatDetail = seat && `Seat ${seat}`;
  // Construct a string with only valid details
  const seatDetails = [sectionDetail, rowDetail, seatDetail]
    .filter((s) => s)
    .join(', ');

  const eventDatetimeObj = localDateTime(eventDateTime);
  const eventDate = format(eventDatetimeObj, 'MM/dd/yyyy');
  const eventTime = format(eventDatetimeObj, 'hh:mm a');

  return {
    id: ticketId,
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    eventDate,
    eventTime,
    seatDetails: () => seatDetails,
    ticketStatus: (
      <div className="d-flex gap-3">
        <span className="badge user-tag rounded-pill">Archived</span>
      </div>
    ),
  };
}

function ArchivedTickets() {
  const {
    totalArchivedTickets,
    currentArchivedTicketsPage,
    setArchivedTicketsCurrentPage,
    archivedTicketsPageTotal,
    archivedTickets,
    isLoading,
  } = useOutletContext();
  usePageTracking('archived-tickets');

  let ticketRows = [];
  if (archivedTickets?.length) {
    ticketRows = archivedTickets.map((ticket) =>
      makeArchivedTicketRow({ ...ticket }),
    );
  }

  return (
    <TableFrame>
      <TableHeader
        title="Archived Tickets"
        totalItems={totalArchivedTickets}
        description="These are the tickets that have gone from on hold to archived."
      />
      <TableBody
        columns={tableColumns}
        rows={ticketRows}
        isLoading={isLoading}
        noRowsMessage={
          isLoading
            ? 'Loading Archived Tickets...'
            : 'No Archived Tickets Found'
        }
      />
      <TableFooter
        currentPage={currentArchivedTicketsPage}
        pageTotal={archivedTicketsPageTotal}
        setCurrentPage={setArchivedTicketsCurrentPage}
      />
    </TableFrame>
  );
}

export default ArchivedTickets;
