// Disabling for now while we get the links for those specific documents
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={`${styles.app_footer_wrapper} px-lg-8 px-4 `}>
      <div className={styles.divider} />
      <div className={`${styles.app_footer_content}`}>
        <div
          className="d-flex align-items-center justify-content-center w-100 justify-content-lg-start"
          style={{ gap: '1rem' }}
        >
          <a
            className="text-secondary text-decoration-underline"
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=a6677ba5-7b8f-44cd-9894-003eee2b2691"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            className="text-secondary text-decoration-underline"
            href="/privacy"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
