import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConsoleLogger from '../utils/logger';

const ConfirmContext = createContext();
const logger = ConsoleLogger.getInstance();

/**
 * Use this hook to request a confirmation before performing an action.
 * @returns {Object} An object with a requestConfirm function.
 */
export const useConfirm = () => useContext(ConfirmContext);

export function ConfirmProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [header, setHeader] = useState('');
  const [subHeader, setSubHeader] = useState('');
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [rememberMeKey, setRememberMeKey] = useState(undefined);

  const requestConfirm = useCallback(
    /**
     * Requests a confirmation before executing the provided callback.
     * @param {Object} confirmProps
     * @param {Function} confirmProps.callback - The function to be executed after confirmation.
     * @param {string} confirmProps.headerText - The header text for the confirmation modal.
     * @param {string} confirmProps.subHeaderText - The subheader text for the confirmation modal.
     * @param {string} confirmProps.[rememberKeyText] - The storage key for saving the user's preference.
     */
    ({ callback, headerText, subHeaderText, rememberKeyText = undefined }) => {
      if (!callback) {
        logger.error('callback is required to request a confirmation');
        return;
      }
      if (rememberKeyText) {
        setRememberMeKey(rememberKeyText);
        setDontShowAgain(false);
        const shouldSkipConfirmation =
          sessionStorage.getItem(rememberKeyText) === 'true';
        if (shouldSkipConfirmation) {
          callback();
          return;
        }
      }

      setHeader(headerText);
      setSubHeader(subHeaderText);
      setOnConfirm(() => () => {
        callback();
      });
      setIsVisible(true);
    },
    [],
  );

  const handleCheckbox = (e) => setDontShowAgain(e.target.checked);

  const handleConfirm = useCallback(() => {
    if (rememberMeKey && dontShowAgain) {
      sessionStorage.setItem(rememberMeKey, dontShowAgain);
    }
    onConfirm();
    setIsVisible(false);
  }, [dontShowAgain, onConfirm, rememberMeKey]);

  const handleCancel = useCallback(() => {
    setIsVisible(false);
  }, []);

  // Memorize the value object to prevent unnecessary re-renders
  /**
   * The value object to be provided to the context provider.
   * @type {Object}
   * @property {Function} requestConfirm - The function to request a confirmation.
   */
  const value = useMemo(
    () => ({
      requestConfirm,
    }),
    [requestConfirm],
  );

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      {isVisible && (
        <Modal
          show={isVisible}
          onHide={handleCancel}
          backdrop
          keyboard
          centered
        >
          <Modal.Header className="align-items-start pb-5" closeButton>
            <div className="d-flex flex-column align-items-start">
              <h2 className="fs-2 fw-semibold w-100">{header}</h2>
              <p className="text-gray mb-0 fs-4">{subHeader}</p>
            </div>
          </Modal.Header>
          {rememberMeKey && (
            <Modal.Body>
              <Form.Check
                type="checkbox"
                label="Don't show this message again"
                checked={dontShowAgain}
                onChange={handleCheckbox}
              />
            </Modal.Body>
          )}
          <Modal.Footer className="m-0">
            <Button
              variant="primary"
              size="lg"
              className="w-100 justify-content-center"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </ConfirmContext.Provider>
  );
}
