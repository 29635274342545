import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import LotteriesFiltered from './LotteriesFiltered';

function UpcomingLotteries() {
  const UPCOMING = 'Upcoming';
  usePageTracking('upcoming-lotteries');
  return <LotteriesFiltered lotteryStatusFilter={UPCOMING} />;
}

export default UpcomingLotteries;
