import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import Dialog from 'components/Dialog';

import ActionDots from 'assets/icons/action-dots.svg';
import Trash from 'assets/icons/trash.svg';
import Edit from 'assets/icons/edit.svg';
import ViewParticipant from 'assets/icons/view-participant.svg';
import styles from './ParticipantActions.module.scss';
import ParticipantViewEditSidebar from '../ParticipantViewEditSidebar';

/**
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.firstName
 * @param {string} props.lastName
 * @param {string} props.email
 * @param {Function} props.setShouldFetch
 * @param {boolean} [props.activeParticipant=false]
 */

function ParticipantActions({
  id,
  firstName,
  lastName,
  email,
  setShouldFetch,
  activeParticipant = false,
}) {
  const [showDeleteParticipantDialog, setShowDeleteParticipantDialog] =
    useState(false);

  const [showParticipantViewEditSidebar, setShowParticipantViewEditSidebar] =
    useState({
      show: false,
      mode: 'VIEW',
    });

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  async function deleteParticipant() {
    execute('DELETE', `${protectedResources.participants.endpoint}/${id}`);
    setShouldFetch(true);
    // TODO: Add toast message notification for user being deleted.
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className={styles['dropdown-toggle']}
          id={`action-button-${id}`}
          variant="link"
        >
          <span className="visually-hidden">Additional Actions</span>
          <ActionDots aria-hidden />
        </Dropdown.Toggle>

        <Dropdown.Menu role="menu" aria-label="Actions" as="ul">
          {activeParticipant && (
            <>
              <Dropdown.Item className="" as="li">
                <Button
                  id={`view-participant-${id}`}
                  className="me-3 w-100"
                  variant="text"
                  onClick={() =>
                    setShowParticipantViewEditSidebar({
                      show: true,
                      mode: 'VIEW',
                    })
                  }
                >
                  <ViewParticipant className="me-1" />
                  View participant details
                </Button>
              </Dropdown.Item>
              <Dropdown.Item className="" as="li">
                <Button
                  id={`edit-participant-${id}`}
                  className="me-3 w-100"
                  variant="text"
                  onClick={() =>
                    setShowParticipantViewEditSidebar({
                      show: true,
                      mode: 'EDIT',
                    })
                  }
                >
                  <Edit className="me-1" />
                  Edit participant details
                </Button>
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item className="" as="li">
            <Button
              id={`delete-participant-${id}`}
              className="me-3 w-100"
              variant="text"
              onClick={() => setShowDeleteParticipantDialog(true)}
            >
              <Trash className="me-1" />
              Delete participant
            </Button>
            <Dialog
              show={showDeleteParticipantDialog}
              Icon={Trash}
              heading={
                activeParticipant
                  ? `Are you sure you want to delete ${firstName} ${lastName}?`
                  : "Are you sure you want to delete this invited participant? They haven't created an account yet."
              }
              subHeading="This user will be permanently deleted"
              ctaHandler={() => deleteParticipant()}
              ctaLabel={`Delete User ${email}`}
              handleClose={setShowDeleteParticipantDialog}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ParticipantViewEditSidebar
        show={showParticipantViewEditSidebar.show}
        initialMode={showParticipantViewEditSidebar.mode}
        participantId={id}
        setShouldFetch={setShouldFetch}
        onClose={() =>
          setShowParticipantViewEditSidebar({ show: false, mode: 'VIEW' })
        }
      />
    </>
  );
}

export default ParticipantActions;
