import React, { useState, useEffect, useCallback } from 'react';
import ActionButtons from 'components/ActionButtons';
import { useNavigate } from 'react-router-dom';
import FieldRow, { FieldRowLeft, FieldRowRight } from 'components/FieldRow';
import Button from 'react-bootstrap/Button';
import EditIcon from 'assets/icons/edit.svg';
import Marker from 'assets/icons/marker-tiny.svg';
import LinkExternal from 'assets/icons/link-external-tiny.svg';
import Check from 'assets/icons/check-small.svg';
import Ticket from 'assets/icons/ticket-tiny.svg';
import cn from 'utils/classNames';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';

import {
  DETAILS_STEP,
  EVENT_TYPE_OPTIONS,
  SCHEDULE_STEP,
  LotteryDetailsFields,
  LotteryScheduleFields,
  UNCLAIMED_TICKET_OPTIONS,
  REVIEW_TICKETS_FORM_TYPE,
} from 'constants/addLottery';

import Avatar from 'components/Avatar';
import HeroImage from 'components/HeroImage/HeroImage';
import { format, parseISO } from 'date-fns';

import styles from './LotterySteps.module.scss';
import LotteryEventTicketsSidebar from './LotteryEventTicketsSidebar';

const {
  LOTTERY_NAME_FIELD,
  START_DATE_FIELD,
  START_TIME_FIELD,
  END_DATE_FIELD,
  END_TIME_FIELD,
  DESCRIPTION_FIELD,
  EVENT_TYPE_FIELD,
  VENUE_NAME_FIELD,
  VENUE_LINK_FIELD,
  UNCLAIMED_TICKETS_FIELD,
  EVENT_LOGO_FIELD,
  EVENT_COVER_PHOTO_FIELD,
  SECTION,
  ROW,
  SEAT,
  EVENT_TICKETS,
  EVENT_TICKET_ID,
  IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE,
} = LotteryDetailsFields;

const { EVENT_ID, EVENT_NAME, EVENT_DATE, TIME, ADDITIONAL_DETAILS } =
  LotteryScheduleFields;

const CONFIRM_DETAILS = 'confirm-details';
const CONFIRM_EVENT_SCHEDULE = 'confirm-event-schedule';
const EDIT_LABEL_CLASSES =
  'fs-4 d-flex align-items-center justify-content-between w-100 text-secondary-emphasis fw-semibold m-0';

// using the following date fields contruct the following string "Monday March 1, 2023 9:00AM PST to Friday March 5, 2023"
const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
};

const ITEMS_PER_PAGE = 10;
const DECREMENT_VALUE = 1;

// Handles the pagination of lottery schedules
const handlePaginateItems = (currentPage, paginateTarget = []) => {
  // Return an empty array if no current schedules page
  if (!currentPage) {
    return [];
  }
  const startIndex = (currentPage - DECREMENT_VALUE) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  return paginateTarget?.slice(startIndex, endIndex) || [];
};

/**
 *
 * @param {Object} props
 * @param {Function} props.handleReviewTickets handler for review tickets parent state
 * @returns
 */
function LotteryReviewTickets({
  handleReviewTickets,
  handleEditStep,
  unpublishedLottery,
}) {
  const navigate = useNavigate();
  const [eventToEdit, setEventToEdit] = useState(null);

  // Lottery Details fields
  const unclaimedOption = UNCLAIMED_TICKET_OPTIONS.find(
    (option) =>
      option.value ===
      unpublishedLottery[DETAILS_STEP][UNCLAIMED_TICKETS_FIELD],
  );

  const UnclaimedOptionIcon = unclaimedOption?.Icon;

  const lotteryName = unpublishedLottery[DETAILS_STEP][LOTTERY_NAME_FIELD];
  const lotteryDescription =
    unpublishedLottery[DETAILS_STEP][DESCRIPTION_FIELD];

  const venueName = unpublishedLottery[DETAILS_STEP][VENUE_NAME_FIELD];
  const venueLink = unpublishedLottery[DETAILS_STEP][VENUE_LINK_FIELD];

  const eventType = EVENT_TYPE_OPTIONS.find(
    (typeOption) =>
      typeOption.value === unpublishedLottery[DETAILS_STEP][EVENT_TYPE_FIELD],
  );

  const startTimeFrame = new Date(
    `${unpublishedLottery[DETAILS_STEP][START_DATE_FIELD]} ${unpublishedLottery[DETAILS_STEP][START_TIME_FIELD]}`,
  ).toLocaleString('en-US', options);

  const endTimeFrame = new Date(
    `${unpublishedLottery[DETAILS_STEP][END_DATE_FIELD]} ${unpublishedLottery[DETAILS_STEP][END_TIME_FIELD]}`,
  ).toLocaleString('en-US', options);

  const timeFrame = `${startTimeFrame} PST to ${endTimeFrame}`;

  // Lottery Tickets
  const totalTickets =
    unpublishedLottery[DETAILS_STEP][EVENT_TICKETS]?.length || 0;
  const [paginatedTickets, setPaginatedTickets] = useState([]);
  const [currentTicketsPage, setCurrentTicketsPage] = useState(1);
  const ticketsPageTotal = Math.ceil(totalTickets / ITEMS_PER_PAGE);

  // Lottery Schedule Data
  const totalSchedules =
    unpublishedLottery[SCHEDULE_STEP]?.eventSchedule?.length || 0;
  const [paginatedTicketSchedules, setPaginatedTicketSchedules] = useState([]);
  const [currentSchedulesPage, setCurrentSchedulesPage] = useState(1);
  const schedulesPageTotal = Math.ceil(totalSchedules / ITEMS_PER_PAGE);

  const unpublishedLotteryTickets =
    unpublishedLottery[DETAILS_STEP][EVENT_TICKETS];

  // Convert dates into north american standard with slashes '/'
  const unpublishedEventSchedule =
    unpublishedLottery[SCHEDULE_STEP]?.eventSchedule;

  // Component State

  const loadTables = useCallback(() => {
    setPaginatedTickets(
      handlePaginateItems(currentTicketsPage, unpublishedLotteryTickets),
    );

    setPaginatedTicketSchedules(
      handlePaginateItems(currentSchedulesPage, unpublishedEventSchedule),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTicketsPage,
    currentSchedulesPage,
    unpublishedLotteryTickets,
    unpublishedEventSchedule,
  ]);

  const handleTicketChange = (newTickets) => {
    const schedule = unpublishedEventSchedule.find(
      (event) => event[EVENT_ID] === eventToEdit[EVENT_ID],
    );

    if (schedule) {
      schedule.Tickets = newTickets;
    }
    // loadTables();
  };

  useEffect(() => {
    loadTables();
  }, [loadTables]);

  useEffect(() => {
    // Initialize all the events with all the tickets
    unpublishedEventSchedule?.forEach((schedule) => {
      if (!schedule.Tickets) {
        // Schedule with no array of Tickets means the schedule was added to the flow and has not
        // yet been assgined to any tickets, so initialize it with all of the tickets
        // eslint-disable-next-line no-param-reassign
        schedule.Tickets = [];
        unpublishedLotteryTickets?.forEach((ticket) => {
          schedule.Tickets.push(ticket[EVENT_TICKET_ID]);
        });
      } else {
        unpublishedLotteryTickets?.forEach((ticket) => {
          // The schedule was already assgined with tickets
          // Only add tickets that have not yet been assigned to a schedule, this is
          // for cases where the user added more tickets by going back to step 1
          // and then coming back here
          if (!ticket[IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE]) {
            schedule.Tickets.push(ticket[EVENT_TICKET_ID]);
          }
        });

        // Make sure all of the schedules' tickets reflect the list of unpublishedLotteryTickets
        // They may be out of sync if the user gone back and removed tickets. The following
        // logic removes schedule.Tickets that no longer exist in the unpublishedLotterTickets
        const updatedSchedTickets = [];
        schedule.Tickets.forEach((ticketId) => {
          if (unpublishedLotteryTickets) {
            // Check the array of unpublishedLotterTickets. If the schedule's ticket does not exist
            // in the unpublishedLotteryTickets, then remove it from the schedule's Ticket array
            const ticketStillExists = unpublishedLotteryTickets.some( unpubTicket => (unpubTicket[EVENT_TICKET_ID] === ticketId));
            if (ticketStillExists) {
              updatedSchedTickets.push(ticketId);
            }
          }
        });
        // eslint-disable-next-line no-param-reassign
        schedule.Tickets = updatedSchedTickets;
      }

    });

    // At this point any tickets that are currently in unpublishedLotteryTickets
    // have been initially assigned. So make sure that flag is updated in unpublishedLotteryTickets
    // The IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE is useful in determining whether or not a ticket was
    // initially assigned to a schedule so that it does not get re-assigned if the user
    // had later unassigned the initially assigned ticket from a schedule.
    unpublishedLotteryTickets?.forEach((ticket) => {
      if (!ticket[IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE]) {
        // eslint-disable-next-line no-param-reassign
        ticket[IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE] = true;
      }
    });

  }, [unpublishedEventSchedule, unpublishedLotteryTickets]);

  const currentPageTicketRows = paginatedTickets?.map((ticket) => ({
    id: ticket[EVENT_TICKET_ID],
    [SECTION]: ticket[SECTION],
    [ROW]: ticket[ROW],
    [SEAT]: ticket[SEAT],
    EventCount: unpublishedEventSchedule?.filter((schedule) =>
      schedule.Tickets?.includes(ticket[EVENT_TICKET_ID]) ?? false,
    ).length,
  }));

  const currentPageEventRows = paginatedTicketSchedules?.map((schedule) => ({
    id: schedule[EVENT_ID],
    [EVENT_NAME]: schedule[EVENT_NAME],
    [EVENT_DATE]: format(parseISO(schedule[EVENT_DATE]), 'MM/dd/yyyy'),
    [TIME]: schedule[TIME],
    [ADDITIONAL_DETAILS]: schedule[ADDITIONAL_DETAILS],
    TicketCount: (
      <div className="d-flex align-items-center">
        <span className="badge bg-primary">
          {schedule.Tickets?.length || 0}
        </span>
        <Button
          variant="link"
          className="text-primary"
          onClick={() => setEventToEdit(schedule)}
        >
          <EditIcon />
        </Button>
      </div>
    ),
  }));

  return (
    <>
      <LotteryEventTicketsSidebar
        onTicketChange={handleTicketChange}
        availableTickets={unpublishedLotteryTickets}
        eventToEdit={eventToEdit}
        onClose={() => setEventToEdit(null)}
      />
      <div
        className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
        data-form_type={REVIEW_TICKETS_FORM_TYPE}
      >
        <section className="d-flex flex-column align-items-start gap-5 w-100">
          <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
            <div className="d-flex flex-column align-items-start gap-1">
              <h2 className="m-0 fs-2 fw-semibold lh-lg">
                Step 3: Review Tickets
              </h2>
              <p className="m-0 fs-4 text-secondary">
                Please confirm your tickets & event schedule here.
              </p>
            </div>
            <ActionButtons
              actionLabel="Next: Set Lottery Rules"
              handleAction={handleReviewTickets}
              handleCancel={() => navigate('/season-lotteries')}
            />
          </div>
          <div className="hr-divider" />
        </section>
        <div className="w-100 d-flex flex-column gap-5">
          <section className="d-flex flex-column gap-5 w-100 mt-4">
            <div className="hr-divider" />

            <FieldRow fieldName={CONFIRM_DETAILS}>
              <FieldRowLeft>
                <p className={EDIT_LABEL_CLASSES}>
                  Lottery Details{' '}
                  <Button
                    variant="text"
                    className="text-primary"
                    onClick={() => handleEditStep(DETAILS_STEP)}
                  >
                    <EditIcon /> Edit
                  </Button>
                </p>
              </FieldRowLeft>
              <FieldRowRight wide>
                <div className="w-100 d-flex flex-column gap-6 fs-4 text-gray">
                  <div>
                    <h2 className="fs-2 fw-semibold">{lotteryName}</h2>
                    <p className="fs-5">{timeFrame}</p>
                    <p>{lotteryDescription}</p>
                    <p className="d-flex gap-4 mb-0">
                      <a
                        href={venueLink}
                        className="text-gray d-flex align-items-center"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Marker className="me-2 text-gray-light" />
                        <span>{venueName}</span>
                        <LinkExternal className="ms-2 text-gray-light" />
                      </a>
                      <span className="text-gray d-flex align-items-center">
                        <Ticket className="me-2 text-gray-light" />{' '}
                        {eventType?.label}
                      </span>
                    </p>
                  </div>
                  <div className="hr-divider" />
                  <div>
                    <p className="fs-4 fw-semibold mb-2">
                      Unclaimed Ticket Allocation
                    </p>
                    <div
                      id={`${unclaimedOption?.value}-card`}
                      className={cn([
                        'd-flex border justify-content-between border-gray-lighter p-4',
                        styles['option-card'],
                      ])}
                    >
                      <div
                        className="bg-gray-lighter d-flex align-items-center rounded-circle p-2 border border-2 border-gray-lightest"
                        style={{ width: 32, height: 32 }}
                      >
                        {UnclaimedOptionIcon && (
                          <UnclaimedOptionIcon className="text-gray-darker" />
                        )}
                      </div>
                      <div className="ps-4 pe-1 flex-grow-1">
                        <p className={cn(['text-gray-dark fw-medium mb-0'])}>
                          {unclaimedOption?.title}
                        </p>
                        <p className="mb-0">{unclaimedOption?.description}</p>
                      </div>
                      <div
                        className={cn([
                          styles['icon-wrapper'],
                          'border d-flex align-items-center rounded-circle',
                          'bg-info border-gray-lighter',
                        ])}
                      >
                        <Check className="text-white w-100" />
                      </div>
                    </div>
                  </div>
                  <div className="hr-divider" />
                  <div>
                    <div className="row">
                      <div className="col-6 pe-3">
                        <p className="fs-4 fw-semibold mb-2">Event Logo</p>
                        <div className="d-flex py-8 align-items-center justify-content-center">
                          <Avatar
                            altText="LT"
                            altBackground="yellow"
                            size="md"
                            style={{
                              width: '4rem',
                              height: '4rem',
                              fontSize: '2rem',
                            }}
                            imgSrc={
                              unpublishedLottery[DETAILS_STEP][
                                EVENT_LOGO_FIELD
                              ] ?? null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 ps-3">
                        <p className="fs-4 fw-semibold mb-2">
                          Event Cover Photo
                        </p>
                        <HeroImage
                          imgSrc={
                            unpublishedLottery[DETAILS_STEP][
                              EVENT_COVER_PHOTO_FIELD
                            ] ?? null
                          }
                          style={{ width: '100%', height: '7.75rem' }}
                          className="rounded-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FieldRowRight>
            </FieldRow>

            <FieldRow fieldName={CONFIRM_EVENT_SCHEDULE}>
              <FieldRowLeft>
                <p className={EDIT_LABEL_CLASSES}>
                  Event Schedule
                  <Button
                    variant="text"
                    className="text-primary"
                    onClick={() => handleEditStep(SCHEDULE_STEP)}
                  >
                    <EditIcon /> Edit
                  </Button>
                </p>
              </FieldRowLeft>
              <FieldRowRight wide>
                <div className="d-flex flex-row gap-5">
                  <TableFrame>
                    <div className={styles.tableHeader}>
                      Event Schedule
                      <span className="badge rounded-pill bg-black ms-4">
                        {totalSchedules}
                      </span>
                    </div>

                    <TableBody
                      columns={{
                        id: 'ID',
                        [EVENT_NAME]: 'Event',
                        [EVENT_DATE]: 'Date',
                        [TIME]: 'Time',
                        [ADDITIONAL_DETAILS]: 'Additional Details',
                        TicketCount: 'Tickets',
                      }}
                      rows={currentPageEventRows}
                    />
                    <TableFooter
                      currentPage={currentSchedulesPage}
                      pageTotal={schedulesPageTotal}
                      setCurrentPage={setCurrentSchedulesPage}
                    />
                  </TableFrame>

                  <TableFrame>
                    <TableHeader
                      title="Tickets"
                      totalItems={totalTickets}
                      description="Assign your tickets to the events."
                    />

                    <TableBody
                      columns={{
                        id: 'ID',
                        [SECTION]: 'Section',
                        [ROW]: 'Row',
                        [SEAT]: 'Seat',
                        EventCount: '# of Events',
                      }}
                      rows={currentPageTicketRows}
                    />
                    <TableFooter
                      currentPage={currentTicketsPage}
                      pageTotal={ticketsPageTotal}
                      setCurrentPage={setCurrentTicketsPage}
                    />
                  </TableFrame>
                </div>
              </FieldRowRight>
            </FieldRow>

            <div className="d-flex justify-content-end">
              <ActionButtons
                actionLabel="Next: Set Lottery Rules"
                handleAction={handleReviewTickets}
                handleCancel={() => navigate('/season-lotteries')}
              />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LotteryReviewTickets;
