import React, { useEffect } from 'react';
import {
  LotteryDetailsFields,
  LotteryScheduleFields,
} from 'constants/addLottery';
import TicketShapeRightIcon from 'assets/icons/ticket-shape-right.svg';
import ticketStyle from 'pages/tickets/Tickets.module.scss';
import cn from 'utils/classNames';
import Check from 'assets/icons/check-small.svg';
import { format, parseISO } from 'date-fns';
import Sidebar from '../../../components/Sidebar';
import styles from './LotterySteps.module.scss';

const { SECTION, ROW, SEAT, EVENT_TICKET_ID } = LotteryDetailsFields;

const { EVENT_NAME, EVENT_DATE, TIME } = LotteryScheduleFields;

function LotteryEventTicketsSidebar({
  onTicketChange,
  availableTickets,
  eventToEdit,
  onClose,
}) {
  const [selectedTicketIds, setSelectedTicketIds] = React.useState([]);

  useEffect(() => {
    if (eventToEdit === null) {
      return;
    }
    setSelectedTicketIds(eventToEdit.Tickets);
  }, [eventToEdit]);

  return (
    <Sidebar
      title="Tickets"
      visible={eventToEdit !== null}
      direction="right"
      style={{ width: '100%', maxWidth: '480px' }}
      onClose={onClose}
    >
      {eventToEdit !== null && (
        <div className="d-flex w-100 flex-column p-4 gap-8">
          <div className="d-flex flex-column gap-1">
            <h1 className="h5 m-0">Edit event tickets</h1>
            <span>
              {eventToEdit[EVENT_NAME]} -{' '}
              {format(parseISO(eventToEdit[EVENT_DATE]), 'MM/dd/yyyy')} at{' '}
              {eventToEdit[TIME]}
            </span>
          </div>
          <span>
            Select the tickets that will be available for this event. You can
            select multiple tickets.
          </span>

          {availableTickets.map((ticket) => {
            const isChecked =
              selectedTicketIds.indexOf(ticket[EVENT_TICKET_ID]) !== -1;

            return (
              <label
                key={ticket[EVENT_TICKET_ID]}
                id="TicketDetails"
                className="d-flex w-100"
                style={{
                  cursor: 'pointer',
                }}
                htmlFor={`ticket-${ticket[EVENT_TICKET_ID]}`}
              >
                <span className="visually-hidden">
                  Ticket with Section: {ticket[SECTION]}, Row: {ticket[ROW]},
                  Seat: {ticket[SEAT]}
                </span>

                <TicketShapeRightIcon className={ticketStyle.flipHorizontal} />
                <div
                  className={`${ticketStyle.ticketContainer} d-flex flex-column px-1 flex-grow-1 justify-content-center align-items-start gap-4 text-center`}
                >
                  <div
                    className={cn([
                      styles['icon-wrapper'],
                      'border d-flex align-items-center rounded-circle align-self-center',
                      isChecked
                        ? 'bg-primary border-primary'
                        : 'bg-white border-gray-lighter',
                    ])}
                  >
                    {isChecked && <Check className="text-white w-100" />}
                  </div>

                  <div
                    className={`${ticketStyle.ticketDetails} d-flex gap-5 w-100`}
                  >
                    <div className="d-flex flex-column">
                      <span className="fs-5 fw-medium text-gray">SECTION</span>
                      <span className="h5 fw-normal mb-0">
                        {ticket[SECTION]}
                      </span>
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="fs-5 fw-medium text-gray">ROW</span>
                      <span className="h5 fw-normal mb-0">{ticket[ROW]}</span>
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="fs-5 fw-medium text-gray">SEAT</span>
                      <span className="h5 fw-normal mb-0">{ticket[SEAT]}</span>
                    </div>
                  </div>
                </div>
                <TicketShapeRightIcon />

                <input
                  type="checkbox"
                  checked={isChecked}
                  value={ticket[EVENT_TICKET_ID]}
                  className="visually-hidden"
                  id={`ticket-${ticket[EVENT_TICKET_ID]}`}
                  onChange={(e) => {
                    setSelectedTicketIds((currentValue) => {
                      let newValue;
                      if (e.target.checked) {
                        newValue = [...currentValue, ticket[EVENT_TICKET_ID]];
                      } else {
                        newValue = currentValue.filter(
                          (t) => t !== ticket[EVENT_TICKET_ID],
                        );
                      }

                      onTicketChange(newValue);
                      return newValue;
                    });
                  }}
                />
              </label>
            );
          })}
        </div>
      )}
    </Sidebar>
  );
}

export default LotteryEventTicketsSidebar;
