import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import classNames from 'utils/classNames';
import useToastMessages from 'hooks/useToastMessages';
import ConsoleLogger from 'utils/logger';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import useUser from 'hooks/useUser';
import { createPortal } from 'react-dom';
import styles from './TicketsStatusDropdown.module.scss';
import { useConfirm } from '../../confirm/ConfirmProvider';

const consoleLogger = ConsoleLogger.getInstance();

const TICKET_ACTION_CLAIM = 'claim';
const TICKET_ACTION_CONFIRM = 'confirm';
const TICKET_ACTION_RELEASE = 'release';
const TICKET_ACTION_DISPERSE = 'disperse';
const TICKET_ACTION_UNCLAIMED = 'unclaimed';
const TICKET_ACTION_ARCHIVE = 'hide';

const ticketActionOptions = {
  [TICKET_ACTION_UNCLAIMED]: {
    Badge: () => (
      <span className="badge rounded-pill text-green-dark bg-gray-lighter border-gray-light">
        Unclaimed
      </span>
    ),
  },
  [TICKET_ACTION_CLAIM]: {
    Badge: ({ isClaimed }) => (
      <span className="badge rounded-pill text-blue-dark bg-blue-lighter border-blue-light">
        {isClaimed ? 'Claimed' : 'Claim'}
      </span>
    ),
  },
  [TICKET_ACTION_CONFIRM]: {
    Badge: ({ isConfirmed }) => (
      <span className="badge rounded-pill text-green-dark bg-green-lighter border-green-light">
        {isConfirmed ? 'Confirmed' : 'Confirm'}
      </span>
    ),
  },
  [TICKET_ACTION_RELEASE]: {
    Badge: ({ isReleased }) => (
      <span className="badge rounded-pill text-purple-dark bg-purple-lighter border-purple-light">
        {isReleased ? 'Released' : 'Release'}
      </span>
    ),
  },
  [TICKET_ACTION_ARCHIVE]: {
    Badge: () => (
      <span className="badge rounded-pill text-green-dark bg-gray-lighter border-gray-light">
        Archived
      </span>
    ),
  },
  [TICKET_ACTION_DISPERSE]: {
    Badge: ({ isDispersed }) => (
      <span
        className={classNames([
          styles.dispersedTicket,
          'badge',
          isDispersed || 'px-3',
        ])}
      >
        {isDispersed ? 'Dispersed' : 'Disperse'}
      </span>
    ),
  },
};

function TicketStatusDropDown({
  isConfirmed,
  isDispersed,
  ticketId,
  isClaimed,
  lotteryDetail,
  isArchived,
  isOnHold,
}) {
  const { Badge } =
    ticketActionOptions[
      lotteryDetail ? TICKET_ACTION_UNCLAIMED : TICKET_ACTION_CLAIM
    ];
  const { organizationUserId, organizationUserRole } = useUser();

  let initialCurrentState = {
    status: lotteryDetail ? TICKET_ACTION_UNCLAIMED : TICKET_ACTION_CLAIM,
    Badge: <Badge />,
  };

  const { requestConfirm } = useConfirm();

  if (isConfirmed) {
    const ConfirmedBadge = ticketActionOptions[TICKET_ACTION_CONFIRM].Badge;
    initialCurrentState = {
      status: TICKET_ACTION_CONFIRM,
      Badge: (
        <ConfirmedBadge isConfirmed={isConfirmed} isDispersed={isDispersed} />
      ),
    };
  }
  if (isDispersed) {
    const DisperedBadge = ticketActionOptions[TICKET_ACTION_DISPERSE].Badge;
    initialCurrentState = {
      status: TICKET_ACTION_DISPERSE,
      Badge: (
        <DisperedBadge isConfirmed={isConfirmed} isDispersed={isDispersed} />
      ),
    };
  }

  if (isClaimed) {
    const ClaimedBadge = ticketActionOptions[TICKET_ACTION_CLAIM].Badge;
    initialCurrentState = {
      status: TICKET_ACTION_CLAIM,
      Badge: (
        <ClaimedBadge
          isConfirmed={isConfirmed}
          isDispersed={isDispersed}
          isClaimed={isClaimed}
        />
      ),
    };
  }

  if (isArchived) {
    const ArchivedBadge = ticketActionOptions[TICKET_ACTION_ARCHIVE].Badge;

    initialCurrentState = {
      status: TICKET_ACTION_ARCHIVE,
      Badge: <ArchivedBadge />,
    };
  }

  const [currentStatus, setCurrentStatus] = useState(initialCurrentState);
  const { handleNewToast } = useToastMessages();
  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.tickets.scopes.access_as_user,
  });

  /**
   * Weird hanlder for the optimistic rendering of the current state
   * @param {Object} params
   * @param {Boolean} params.confirmed
   * @param {Boolean} params.dispersed
   * @param { 'confirm' | 'release' | 'disperse' | 'unclaimed'} params.action
   */
  const handleCurrentState = ({ action }) => {
    // Optimisitcally display the correctly worded message
    if (action) {
      const OptimisiticBadge = ticketActionOptions[action].Badge;
      switch (action) {
        case TICKET_ACTION_CONFIRM:
          setCurrentStatus({
            status: TICKET_ACTION_CONFIRM,
            Badge: <OptimisiticBadge isConfirmed />,
          });
          break;
        case TICKET_ACTION_RELEASE:
          setCurrentStatus({
            status: TICKET_ACTION_RELEASE,
            Badge: <OptimisiticBadge isReleased />,
          });
          break;
        case TICKET_ACTION_CLAIM:
          setCurrentStatus({
            status: TICKET_ACTION_CLAIM,
            Badge: <OptimisiticBadge isClaimed />,
          });
          break;
        case TICKET_ACTION_ARCHIVE:
          setCurrentStatus({
            status: TICKET_ACTION_ARCHIVE,
            Badge: <OptimisiticBadge />,
          });
          break;
        case TICKET_ACTION_DISPERSE:
          setCurrentStatus({
            status: TICKET_ACTION_DISPERSE,
            Badge: <OptimisiticBadge isDispersed />,
          });
          break;
        default:
          consoleLogger.error('Unhandled action!');
      }
    }
  };

  const handleTicketStatusChange = useCallback(
    /**
     * Given a ticket and action, update the ticket's status
     * @param {"claim" | "release" | "disperse"} action
     * @param {string} ticketId
     */

    async (action, updatingTicketId) => {
      try {
        const adminTicketPayload = {
          participantId: organizationUserId.toUpperCase(),
          ticketId: updatingTicketId,
          UserRole: organizationUserRole,
        };
        const ticketPayload = {
          ticketIds: [updatingTicketId],
        };

        const res = await execute(
          'POST',
          `${protectedResources.tickets.endpoint}/${action}`,
          action === TICKET_ACTION_CLAIM ? adminTicketPayload : ticketPayload,
        );

        if (res?.errorSource) {
          handleNewToast({
            title: 'Opps, something went wrong!',
            message:
              "We could't complete your request. If the issue continues please contact support",
          });
        }

        handleCurrentState({ action });
      } catch (err) {
        consoleLogger.error(err);
      }
    },
    [execute, handleNewToast, organizationUserId, organizationUserRole],
  );

  const confirmStatusChange = useCallback(
    /**
     * Given a ticket and action, update the ticket's status
     * @param {"claim" | "release" | "disperse"} action
     * @param {string} updatingTicketId
     */
    async (action, updatingTicketId) => {
      if (action === TICKET_ACTION_DISPERSE) {
        requestConfirm({
          callback: () => handleTicketStatusChange(action, updatingTicketId),
          headerText: 'Are you sure?',
          subHeaderText:
            'Ticket will be marked as dispersed and the user will be notified',
          rememberKeyText: 'Disperse.Tickets.Confirmation',
        });
      } else {
        await handleTicketStatusChange(action, updatingTicketId);
      }
    },
    [handleTicketStatusChange, requestConfirm],
  );

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant="tertiary"
          id="dropdown-basic"
          disabled={isLoading}
        >
          {currentStatus.Badge}
        </Dropdown.Toggle>

        {createPortal(
          <Dropdown.Menu>
            {Object.entries(ticketActionOptions)
              // Remove current status and unclaimed status from actions
              .filter(([action]) => {
                if (currentStatus.status === action) {
                  return false;
                }
                if (
                  action === TICKET_ACTION_CLAIM &&
                  currentStatus.status === TICKET_ACTION_UNCLAIMED
                ) {
                  return true;
                }
                if (action === TICKET_ACTION_CLAIM) {
                  return false;
                }
                if (
                  action === TICKET_ACTION_UNCLAIMED &&
                  (currentStatus.status !== TICKET_ACTION_UNCLAIMED ||
                    !lotteryDetail)
                ) {
                  return false;
                }

                if (action === TICKET_ACTION_ARCHIVE && !isOnHold) {
                  return false;
                }

                return true;
              })
              .map(([action, ticketActionOption]) => (
                <Dropdown.Item
                  key={action}
                  as="button"
                  className="btn btn-text"
                  onClick={() => confirmStatusChange(action, ticketId)}
                >
                  <ticketActionOption.Badge
                    isConfirmed={isConfirmed}
                    isDispersed={isDispersed}
                  />
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>,
          document.body,
        )}
      </Dropdown>
    </div>
  );
}

export default TicketStatusDropDown;
