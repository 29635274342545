import React from 'react';
import Announcement from 'assets/icons/announcement.svg';
import HeartHand from 'assets/icons/heart-hand.svg';
import Watch from 'assets/icons/watch.svg';
import UsersUp from 'assets/icons/users-up-small.svg';

export const DETAILS_STEP = 'step-details';
export const SCHEDULE_STEP = 'step-schedule';
export const RULES_STEP = 'step-rules';
export const REVIEW_TICKETS_STEP = 'step-review-tickets';
export const PUBLISH_STEP = 'step-publish';

export const DETAILS_FORM_TYPE = `add_new_season_lottery_${DETAILS_STEP}`;
export const SCHEDULE_FORM_TYPE = `add_new_season_lottery_${SCHEDULE_STEP}`;
export const REVIEW_TICKETS_FORM_TYPE = `add_new_season_lottery_${REVIEW_TICKETS_STEP}`;
export const RULES_FORM_TYPE = `add_new_season_lottery_${RULES_STEP}`;
export const PUBLISH_FORM_TYPE = `add_new_season_lottery_${PUBLISH_STEP}`;

export const LotteryDetailsFields = {
  LOTTERY_NAME_FIELD: 'lottery-name',
  DESCRIPTION_FIELD: 'description',
  START_DATE_FIELD: 'start-date',
  START_TIME_FIELD: 'start-time',
  END_DATE_FIELD: 'end-date',
  END_TIME_FIELD: 'end-time',
  UNCLAIMED_TICKETS_FIELD: 'unclaimed-tickets',
  EVENT_TYPE_FIELD: 'event-type',
  VENUE_NAME_FIELD: 'venue-name',
  VENUE_LINK_FIELD: 'venue-link',
  EVENT_LOGO_FIELD: 'event-logo',
  EVENT_COVER_PHOTO_FIELD: 'event-cover-photo',
  EVENT_TICKETS: 'event-tickets',
  EVENT_TICKET_ID: 'event-ticket-id',
  SECTION: 'section',
  ROW: 'row',
  SEAT: 'seat',
  IS_TICKET_INIT_ASSIGNED_TO_SCHEDULE: 'is-ticket-init-assigned-to-schedule',
};

export const LotteryRulesFields = {
  LOTTERY_STYLE_FIELD: 'lotteryStyleCode',
  PRIORITY_GROUPS_FIELD: 'priorityGroups',
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  EMAIL: 'Email',
  COMPANY_NAME: 'CompanyName',
  JOB_TITLE: 'JobTitle',
  GROUP_NAME_FIELD: 'groupName',
  TICKET_LIMIT_PER_EVENT_FIELD: 'ticketLimitPerEvent',
  EVENT_LIMIT_FIELD: 'eventLimit',
  MINUTES_PICK_WINDOW_FIELD: 'minutesPickWindow',
  PRIORITY_GROUPS_PARTICIPANT_IDS_FIELD: 'priorityGroupParticipantIds',
};

export const PRIORITY_STYLE_VALUE = 'PRIORITY';
export const FREE_FOR_ALL_STYLE_VALUE = 'FREE_FOR_ALL';

export const LOTTERY_STYLE_OPTIONS = [
  {
    value: PRIORITY_STYLE_VALUE,
    title: 'Priority Lottery',
    priorityFeature: true,
    description:
      'With Priority Lottery, admins can personalize participant groups, allocate tickets, and set flexible claim windows for an equitable and engaging event experience.',
    // eslint-disable-next-line react/jsx-props-no-spreading
    Icon: ({ ...props }) => <UsersUp {...props} />,
  },
  {
    value: FREE_FOR_ALL_STYLE_VALUE,
    title: 'Free For All',
    priorityFeature: false,
    description:
      'Free For All is a seamless ticketing solution that opens the door for everyone to participate, ensuring an equal chance for all to claim tickets.',
    // eslint-disable-next-line react/jsx-props-no-spreading
    Icon: ({ ...props }) => <Announcement {...props} />,
  },
];

export const UNCLAIMED_TICKET_OPTIONS = [
  {
    value: 'HOLD',
    title: 'Hold Unclaimed Tickets',
    description:
      'Unclaimed tickets go on hold, and back into the system. Admins can choose to create a new lottery, donate, or perform other actions at any point during the season.',
    // eslint-disable-next-line react/jsx-props-no-spreading
    Icon: ({ ...props }) => <Watch {...props} />,
  },
  {
    value: 'FREE_FOR_ALL',
    title: 'Start Free For All',
    description:
      'All participants receive an email and can select their unclaimed tickets within the given limitations.',
    // eslint-disable-next-line react/jsx-props-no-spreading
    Icon: ({ ...props }) => <Announcement {...props} />,
  },
  {
    value: 'DONATE',
    title: 'Donate Unclaimed Tickets',
    description:
      'Designated donors receive an email with the option to claim tickets.',
    // eslint-disable-next-line react/jsx-props-no-spreading
    Icon: ({ ...props }) => <HeartHand {...props} />,
  },
];

const GAME_TAXONOMY = 'Game';
const EVENT_TAXONOMY = 'Event';

export const EVENT_TYPE_OPTIONS = [
  {
    value: 'BASEBALL',
    label: 'Baseball',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'BASKETBALL',
    label: 'Basketball',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'BOXING_MMA',
    label: 'Boxing/MMA',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'CONCERT',
    label: 'Concert',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'CONFERENCE',
    label: 'Conference',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'FOOTBALL',
    label: 'Football',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'GOLF',
    label: 'Golf',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'HOCKEY',
    label: 'Hockey',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'LACROSSE',
    label: 'Lacrosse',
    taxonomy: GAME_TAXONOMY,
  },
  {
    value: 'ONLINE_EVENT',
    label: 'Online Event',
    taxonomy: EVENT_TAXONOMY,
  },
  {
    value: 'RACING',
    label: 'Racing',
    taxonomy: EVENT_TAXONOMY,
  },
  {
    value: 'SOCCER',
    label: 'Soccer',
    taxonomy: EVENT_TAXONOMY,
  },
  {
    value: 'TENNIS',
    label: 'Tennis',
    taxonomy: EVENT_TAXONOMY,
  },
  {
    value: 'THEATER',
    label: 'Theater',
    taxonomy: EVENT_TAXONOMY,
  },
  {
    value: 'OTHER',
    label: 'Other',
    taxonomy: EVENT_TAXONOMY,
  },
];

export const LotteryScheduleFields = {
  EVENT_ID: 'event-id',
  EVENT_NAME: 'name',
  EVENT_DATE: 'eventDate',
  TIME: 'time',
  ADDITIONAL_DETAILS: 'additionalDetails',
};

export const defaultTableColumns = {
  id: 'Id',
  name: { label: 'Name', sortBy: 'FirstName' },
  jobTitle: { label: 'Job Title', sortBy: 'JobTitle' },
  company: { label: 'Company', sortBy: 'CompanyName' },
};

export const freeForAllTableColumns = {
  ...defaultTableColumns,
  actions: 'Actions',
};

export const priorityGroupTableColumns = {
  ...defaultTableColumns,
  priorityGroup: { label: 'Priority Group', sortBy: 'PriorityGroup' },
  actions: 'Actions',
};
