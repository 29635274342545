import React from 'react';
import HeroImage from '../../components/HeroImage/HeroImage';

function HeroGuide() {
  return (
    <div className="container-fluid m-0">
      <div className="row my-4">
        <div className="col-md-2">
          <p className="fs-text-lg fw-bold">With Image</p>
        </div>

        <div className="col-md-10 d-flex flex-column gap-8">
          <HeroImage
            imgSrc="https://picsum.photos/1200/300"
            style={{ width: '72.5rem', height: '15rem' }}
          />
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-2">
          <p className="fs-text-lg fw-bold">Ticket Placeholder</p>
        </div>

        <div className="col-md-10 d-flex flex-column gap-8">
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="pattern"
            altBackgroundColor="yellow"
          />
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="pattern"
            altBackgroundColor="blue"
          />
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="pattern"
            altBackgroundColor="green"
          />
          {/* TODO: HeroImage needs to be updated to support purple pattern variant */}
          {/* <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="pattern"
            altBackgroundColor="purple"
          /> */}
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="pattern"
            altBackgroundColor="pink"
          />
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-2">
          <p className="fs-text-lg fw-bold">Gradient Placeholder</p>
        </div>

        <div className="col-md-10 d-flex flex-column gap-8">
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="gradient"
            altBackgroundColor="yellow"
          />
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="gradient"
            altBackgroundColor="blue"
          />
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="gradient"
            altBackgroundColor="green"
          />
          {/* TODO: HeroImage needs to be updated to support purple gradient variant */}
          {/* <HeroImages
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="gradient"
            altBackgroundColor="purple"
          /> */}
          <HeroImage
            style={{ width: '72.5rem', height: '15rem' }}
            altBackground="gradient"
            altBackgroundColor="pink"
          />
        </div>
      </div>
    </div>
  );
}

export default HeroGuide;
