import React from 'react';
import { Button } from 'react-bootstrap';
import styles from '../AccountSettings.module.scss';
import BulletCheck from '../../../assets/images/plan-check.svg';
import useUser from '../../../hooks/useUser';

function PlanCard({ plan, isCurrent, billingPortalLink, onSelectPlan }) {
  const { hasActivePlan } = useUser();

  const planPrice = () => {
    if (!plan.isDisabled) {
      if (plan.price === 0) {
        return 'Free';
      }
      return `$${plan.price}`;
    }
    return 'Contact Us';
  };

  const changePlanButton = () => {
    if (hasActivePlan) {
      return (
        <a
          className="btn btn-tertiary justify-content-center btn-lg"
          href={
            !plan.isDisabled ? billingPortalLink : 'mailto:sales@allseater.com'
          }
        >
          {!plan.isDisabled ? <>Manage my plan</> : <>Contact Us</>}
        </a>
      );
    }

    if (!plan.isDisabled) {
      return (
        <Button
          type="button"
          variant="tertiary"
          className="btn btn-tertiary justify-content-center btn-lg"
          onClick={() => onSelectPlan()}
        >
          Select Plan
        </Button>
      );
    }

    return (
      <a
        className="btn btn-tertiary justify-content-center btn-lg"
        href="mailto:sales@allseater.com"
      >
        Contact Us
      </a>
    );
  };

  return (
    <div
      className={`d-flex flex-column align-items-center border rounded-3 border-1 ${
        styles['plan-card']
      } ${isCurrent ? styles['plan-card-current'] : ''}`}
      style={{ minHeight: '32rem' }}
    >
      {/* Card Header */}
      {isCurrent && (
        <div
          className={`d-flex p-4 align-items-center gap-1 align-self-stretch justify-content-center border border-primary border-1 text-white fs-3 fw-bold ${styles['plan-card-current-header']}`}
        >
          CURRENT PLAN
        </div>
      )}

      {/* Card Body */}
      <div
        className="d-flex p-8 flex-column align-items-center justify-content-between align-self-stretch gap-8"
        style={{ flex: '1 0 0' }}
      >
        {/* Card Content */}
        <div className="d-flex flex-column align-items-start gap-4 align-self-stretch">
          <span className="d-block w-100 fw-medium fs-3 text-primary text-center">
            {plan.name}
          </span>

          <div className="d-flex justify-content-center align-items-end gap-1 align-self-stretch">
            <span className="h5 mb-0 pb-0 fw-medium">{planPrice()}</span>
            {plan.price > 0 && !plan.isDisabled && <span>/year</span>}
          </div>

          <div className="d-flex flex-column align-items-start gap-4 align-self-stretch">
            {plan.planFeatures &&
              plan.planFeatures.map((feature) => (
                <div
                  key={feature}
                  className="d-flex align-items-start gap-3 align-self-stretch"
                >
                  <img src={BulletCheck} alt="check" />
                  <div
                    className="d-flex flex-column align-items-start text-secondary"
                    style={{ flex: '1 0 0' }}
                  >
                    {feature}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Card Actions */}
        {!isCurrent && plan.price > 0 && (
          <div className="d-flex justify-content-center align-items-end gap-3 w-100">
            {changePlanButton()}
          </div>
        )}
      </div>
    </div>
  );
}

export default PlanCard;
