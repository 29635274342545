/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
import {
  USE_CONSOLE_LOGGING,
  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
} from 'constants';
import ConsoleLogger from './logger';
import appInsights from './appInsights';

const logger = ConsoleLogger.getInstance();

class LatencyMetric {
  constructor(operationName) {
    this.startTime = Date.now();
    this.metricName = `Latency_${operationName}`;
  }

  record() {
    const latency = Date.now() - this.startTime;
    if (USE_CONSOLE_LOGGING) {
      logger.log(`${this.metricName} took ${latency}ms`);
    }

    if (APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
      appInsights.trackMetric({ name: this.metricName, average: latency });
    }
  }
}

class SuccessMetric {
  constructor(operationName) {
    this.metricName = `Success_${operationName}`;
  }

  record(isSuccessful) {
    if (USE_CONSOLE_LOGGING) {
      logger.log(
        `${this.metricName} was ${
          isSuccessful ? 'successful' : 'unsuccessful'
        }`,
      );
    }

    if (APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
      appInsights.trackEvent({
        name: this.metricName,
        properties: { isSuccess: isSuccessful },
      });
    }
  }
}

export const startLatencyMetric = (operationName) =>
  new LatencyMetric(operationName);

export const startSuccessMetric = (operationName) =>
  new SuccessMetric(operationName);
