import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ConsoleLogger from '../../utils/logger';

const logger = ConsoleLogger.getInstance();

export default function ProtectedRoute({
  requiredRole,
  userRole,
  redirectPath = '/no-authorization',
  children,
}) {
  if (!requiredRole) {
    logger.error('requiredRole prop is required for ProtectedRoute');
    return <Navigate to={redirectPath} replace />;
  }

  if (requiredRole !== userRole) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}
