const { NOTIFICATION_METHOD_BYTES } = require('../constants');

/* eslint-disable no-bitwise */
/**
 * From a Decimal Bitwise value, get the notification methods as boolean values
 * @param {*} input Bitwise value
 * @returns
 */
const getNotificationMethodsFromBytes = (input) => ({
  EMAIL: (input & NOTIFICATION_METHOD_BYTES.EMAIL) !== 0,
  INAPP: (input & NOTIFICATION_METHOD_BYTES.INAPP) !== 0,
  TEXT: (input & NOTIFICATION_METHOD_BYTES.TEXT) !== 0,
});

/**
 * Performs Bitwise operations to get the decimal value of the notification methods selection
 * @param {*} notificationCategorySettings Object with the notification methods as boolean values
 * @returns
 */
const getNotificationMethodBytes = (notificationCategorySettings) =>
  Object.keys(notificationCategorySettings).reduce(
    (accumulator, currentKey) => {
      if (notificationCategorySettings[currentKey]) {
        return accumulator | NOTIFICATION_METHOD_BYTES[currentKey];
      }
      return accumulator;
    },
    0,
  );

module.exports = {
  getNotificationMethodsFromBytes,
  getNotificationMethodBytes,
};
