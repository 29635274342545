import React from 'react';
import colorMap from 'utils/backgroundColorMapper';

import styles from './Avatar.module.scss';

/**
 * Avatar component displays an image or text avatar with a specified size, background color gradient, and style.
 *
 * @param {object} props - The component props.
 * @param {string} [props.className=''] - Additional CSS class for the component.
 * @param {object} [props.style={}] - Inline styles for the component.
 * @param {string} [props.size='md'] - The size of the avatar (options: 'sm', 'md', 'lg', 'xs', 'xl').
 * @param {string} props.imgSrc - The source URL of the image.
 * @param {string} props.altText - The alternate text for the avatar. Used as the image initials if no imgSrc is provided.
 * @param {string} [props.altBackground='yellow'] - The background color for the avatar when altText is displayed.
 * Available options are 'yellow', 'blue', 'green', and 'pink'.
 * If no altBackground is provided, one will be assigned from the available options using the backgroundColorMapper.
 * @returns {JSX.Element} - The rendered Avatar component.
 */
function Avatar({
  className = '',
  style = {},
  size = 'md',
  imgSrc,
  altText = 'Avatar',
  altBackground = 'yellow',
}) {
  /**
   * Function to generate initials from a given string.
   *
   * @param {string} altText - The input string to generate initials from.
   * @returns {string} The initials generated from the input string.
   */
  const getInitials = () => {
    const isFormatted = altText.length <= 2;
    let initials = altText.toUpperCase();

    if (!isFormatted) {
      // Get the first character in the string
      const firstChar = altText.charAt(0);

      // Get the last word in the string
      const words = altText.split(' ');
      const lastWord = words[words.length - 1];

      // Get the first character of the last word
      const firstCharOfLastWord = lastWord.charAt(0);

      // Return a single initial in cases of only one word
      initials =
        words.length > 1
          ? `${firstChar}${firstCharOfLastWord}`.toUpperCase()
          : firstChar.toUpperCase();
    }
    return initials;
  };

  return (
    <div
      className={`${className} ${styles.avatar} ${styles[`${size}`]} ${
        imgSrc ? styles['avatar-img'] : styles['avatar-text']
      }`}
      style={{
        ...style,
      }}
      data-testid="avatar"
    >
      {imgSrc ? (
        <img src={imgSrc} alt={getInitials()} />
      ) : (
        <div
          className={`${styles['avatar-text-content']} ${
            styles[
              `avatar-text-${altBackground ?? colorMap(getInitials())}-gradient`
            ]
          }`}
        >
          {getInitials()}
        </div>
      )}
    </div>
  );
}

export default Avatar;
