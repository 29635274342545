import React from 'react';

function ColorCard({ colorName, backgroundClass, className }) {
  return (
    <div
      className={`d-flex flex-column align-items-start ${className}`}
      style={{
        borderRadius: '0.75rem',
        backgroundColor: 'white',
        overflow: 'hidden',
        width: '3.5rem',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '5rem',
          padding: '1.624rem 0.5rem',
          justifyContent: 'center',
          alignSelf: 'stretch',
        }}
        className={backgroundClass}
      >
        &nbsp;
      </div>
      <div
        style={{
          display: 'flex',
          padding: '0.75rem',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
        }}
      >
        <span className="fs-text-lg fw-medium">{colorName}</span>
        <span
          className="fs-text-md fw-regular"
          style={{ color: 'var(--neutral-gray-600' }}
        >
          .{backgroundClass}
        </span>
      </div>
    </div>
  );
}

function ColorGuide() {
  const primaryColors = [
    {
      colorName: 'Primary 200',
      backgroundClass: 'bg-primary-lightest',
    },
    {
      colorName: 'Primary 400',
      backgroundClass: 'bg-primary-light',
    },
    {
      colorName: 'Primary 500',
      backgroundClass: 'bg-primary',
    },
    {
      colorName: 'Primary 600',
      backgroundClass: 'bg-primary-dark',
    },
  ];

  const neutralColors = [
    {
      colorName: 'White',
      backgroundClass: 'bg-white',
    },

    {
      colorName: 'Gray 200',
      backgroundClass: 'bg-gray-lighter',
    },

    {
      colorName: 'Gray 400',
      backgroundClass: 'bg-gray-light',
    },

    {
      colorName: 'Gray 600',
      backgroundClass: 'bg-gray',
    },
    {
      colorName: 'Gray 800',
      backgroundClass: 'bg-gray-darker',
    },
  ];

  const accentColors = {
    blue: [
      {
        colorName: 'Blue 300',
        backgroundClass: 'bg-blue-light',
      },
      {
        colorName: 'Blue 400',
        backgroundClass: 'bg-blue',
      },
      {
        colorName: 'Blue 700',
        backgroundClass: 'bg-blue-dark',
      },
    ],
    yellow: [
      {
        colorName: 'Yellow 300',
        backgroundClass: 'bg-yellow-light',
      },
      {
        colorName: 'Yellow 400',
        backgroundClass: 'bg-yellow',
      },
      {
        colorName: 'Yellow 700',
        backgroundClass: 'bg-yellow-dark',
      },
    ],

    green: [
      {
        colorName: 'Green 300',
        backgroundClass: 'bg-green-light',
      },
      {
        colorName: 'Green 400',
        backgroundClass: 'bg-green',
      },
      {
        colorName: 'Green 700',
        backgroundClass: 'bg-green-dark',
      },
    ],

    purple: [
      {
        colorName: 'Purple 300',
        backgroundClass: 'bg-purple-light',
      },
      {
        colorName: 'Purple 400',
        backgroundClass: 'bg-purple',
      },
      {
        colorName: 'Purple 700',
        backgroundClass: 'bg-purple-dark',
      },
    ],

    pink: [
      {
        colorName: 'PiNk 300',
        backgroundClass: 'bg-pink-light',
      },
      {
        colorName: 'PiNk 400',
        backgroundClass: 'bg-pink',
      },
      {
        colorName: 'PiNk 700',
        backgroundClass: 'bg-pink-dark',
      },
    ],
  };

  return (
    <div className="container-fluid m-0">
      <div className="row my-4">
        <div className="col-md-3">
          <p className="fs-text-lg fw-bold">Primary</p>
          <p className="fs-text-md fw-regular">
            The primary color is your &quot;brand&quot; color, and is used
            across all interactive elements such as buttons, links, inputs, etc.
            This color can define the overall feel and can elicit emotion.
          </p>
        </div>

        <div className="col-md-9">
          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {primaryColors.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-3">
          <p className="fs-text-lg fw-bold">Accent</p>
          <p className="fs-text-md fw-regular">
            The accent colors are used for different audience types. ticket
            visual elements, and image treatments.
          </p>
        </div>

        <div className="col-md-9">
          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {accentColors.blue.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>

          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {accentColors.yellow.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>

          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {accentColors.green.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>

          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {accentColors.pink.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-3">
          <p className="fs-text-lg fw-bold">Neutrals</p>
          <p className="fs-text-md fw-regular">
            Gray is a neutral color and is the foundation of the color system.
            Almost everything in UI design — text, form fields, backgrounds,
            dividers — are usually gray.
          </p>
        </div>

        <div className="col-md-9">
          <div
            className="d-flex align-start flex-wrap my-3"
            style={{ gap: '2rem' }}
          >
            {neutralColors.map((color) => (
              <ColorCard
                colorName={color.colorName}
                hexValue={color.hexValue}
                colorCardText={color.colorCardText}
                backgroundClass={color.backgroundClass}
                className="flex-grow-1"
                key={color.backgroundClass}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorGuide;
