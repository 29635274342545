import React from 'react';
import { format } from 'date-fns';
import { useOutletContext, NavLink, Link } from 'react-router-dom';
import { PRIORITY_STYLE_VALUE } from 'constants/addLottery';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';
import localDateTime from 'utils/localTime';
import TicketUsage from 'components/TicketUsage';
import Avatar from 'components/Avatar';
import classNames from 'utils/classNames';
import ConsoleLogger from 'utils/logger';

import Ticket from 'assets/icons/ticket.svg';
import usePageTracking from 'hooks/usePageTracking';

const tableColumns = {
  id: 'Id',
  event: 'Event',
  eventDate: 'Event Date',
  lotteryStatus: 'Lottery Status',
  ticketUsage: 'Ticket Usage',
  actions: '',
};

function makeUpcomingEventRow({
  id,
  eventDateTime,
  lotteryStartDateTime,
  eventName,
  lotteryLogo,
  lotteryStyle,
  tickets,
}) {
  let lotteryStyleBadge = {
    text: 'Free For All',
    classes: 'text-purple-dark bg-purple-lighter border-purple-light',
  };
  let lotteryStatusBadge = {
    text: 'Upcoming',
    classes: 'text-green-dark bg-green-lighter border-green-light',
  };
  const localEventDate = localDateTime(eventDateTime);
  const localLotteryStartDate = localDateTime(lotteryStartDateTime).getTime();
  const now = Date.now();
  if (localLotteryStartDate < now) {
    lotteryStatusBadge = {
      text: 'Active',
      classes: 'text-primary-dark bg-primary-lightest border-primary-light',
    };
  }

  if (lotteryStyle === PRIORITY_STYLE_VALUE) {
    lotteryStyleBadge = {
      text: 'Priority',
      classes: 'text-blue-dark bg-blue-lighter border-blue-light',
    };
  } else {
    ConsoleLogger.log('Unknown lottery style', lotteryStyle);
  }

  return {
    id,
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    eventDate: (
      <span className="text-nowrap">
        {format(localEventDate, 'MMMM do, yyyy')}
      </span>
    ),
    lotteryStatus: () => (
      <div className="d-flex gap-3">
        <span
          className={classNames([
            lotteryStatusBadge.classes,
            'badge rounded-pill',
          ])}
        >
          {lotteryStatusBadge.text}
        </span>
        <span
          className={classNames([
            lotteryStyleBadge.classes,
            'badge rounded-pill',
          ])}
        >
          {lotteryStyleBadge.text}
        </span>
      </div>
    ),
    actions: (
      <Link
        className="btn btn-tertiary me-3 d-inline-block"
        to={`/event-detail/${id}`}
      >
        Manage Tickets
      </Link>
    ),
    ticketUsage: (
      <div style={{ minWidth: 200 }}>
        <TicketUsage tickets={tickets} />
      </div>
    ),
  };
}

function NoDataCTA() {
  return (
    <NavLink className="btn btn-primary btn-lg" to="/add-lottery">
      <Ticket className="me-1" />
      Create A New Lottery!
    </NavLink>
  );
}

function UpcomingEvents() {
  const {
    totalUpcomingEvents,
    upcomingEvents,
    currentUpcomingEventsPage,
    upcomingEventsPageTotal,
    setUpcomingEventsCurrentPage,
    isLoading,
  } = useOutletContext();
  usePageTracking('upcoming-events');

  let upcomingEventRows = [];
  if (upcomingEvents?.length) {
    upcomingEventRows = upcomingEvents.map((upcomingEvent) =>
      makeUpcomingEventRow(upcomingEvent),
    );
  }

  return (
    <TableFrame>
      <TableHeader
        title="Upcoming Events"
        totalItems={totalUpcomingEvents}
        description="These are events happening in the next 14 days."
      />
      <TableBody
        columns={tableColumns}
        rows={upcomingEventRows}
        isLoading={isLoading}
        noRowsMessage={
          isLoading ? 'Loading Upcoming Events...' : 'No Upcoming Events Found'
        }
        NoRowsCTA={NoDataCTA}
      />
      <TableFooter
        currentPage={currentUpcomingEventsPage}
        pageTotal={upcomingEventsPageTotal}
        setCurrentPage={setUpcomingEventsCurrentPage}
      />
    </TableFrame>
  );
}

export default UpcomingEvents;
