import React from 'react';
import { NavLink } from 'react-router-dom';
import SvgIcon from 'components/SvgIcon';
import cn from 'utils/classNames';

import styles from './NavItem.module.scss';

function NavItem({ icon, text, url }) {
  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        cn([styles['nav-item-wrapper'], isActive && styles.active])
      }
    >
      <SvgIcon component={icon} className={cn([styles['nav-link-icon']])} />
      <span>{text}</span>
    </NavLink>
  );
}

export default NavItem;
