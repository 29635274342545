import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import localDateTime from 'utils/localTime';
import TicketUsage from 'components/TicketUsage';
import Avatar from 'components/Avatar';

const tableColumns = {
  id: 'Id',
  event: 'Event',
  eventDate: 'Event Date',
  startTime: 'Start Time',
  ticketUsage: 'Ticket Usage',
};

function makeEventRow({ id, eventDateTime, eventName, lotteryLogo, tickets }) {
  const localEventDate = localDateTime(eventDateTime);

  return {
    id,
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    eventDate: format(localEventDate, 'EEEE, MMMM d, yyyy'),
    startTime: format(localEventDate, 'p'),
    ticketUsage: <TicketUsage tickets={tickets} />,
  };
}

function EventSchedule() {
  const {
    lotteryEvents,
    lotteryEventsCurrentPage,
    setLotteryEventsCurrentPage,
    lotteryEventsTotal,
    lotteryEventsPageTotal,
    isLoading,
  } = useOutletContext();

  let eventRows = [];
  if (lotteryEvents?.length) {
    eventRows = lotteryEvents.map((event) => makeEventRow(event));
  }

  return (
    <TableFrame>
      <TableHeader
        title="Event Schedule"
        totalItems={lotteryEventsTotal}
        description="Events happening in your lottery."
      />
      <TableBody
        columns={tableColumns}
        rows={eventRows}
        isLoading={isLoading}
        noRowsMessage={
          isLoading ? 'Loading Event Schedule...' : 'No Events Found'
        }
      />
      <TableFooter
        currentPage={lotteryEventsCurrentPage}
        pageTotal={lotteryEventsPageTotal}
        setCurrentPage={setLotteryEventsCurrentPage}
      />
    </TableFrame>
  );
}

export default EventSchedule;
