import { ORGANIZATION_ADMIN } from 'constants';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import TabbedMenu from 'components/TabbedMenu';

import Ticket from 'assets/icons/ticket.svg';
import useUser from 'hooks/useUser';
import { UserRoleAdmin } from 'components/UserRole';

function SeasonLotteries() {
  const addLotteryPath = '/add-lottery';
  const { organizationUserRole } = useUser();

  const seasonLotterisMenuItems = [
    { path: 'all-lotteries', label: 'All Lotteries' },
    { path: 'active-lotteries', label: 'Active' },
    { path: 'upcoming-lotteries', label: 'Upcoming' },
    { path: 'complete-lotteries', label: 'Complete' },
    { path: 'archived-lotteries', label: 'Archived' },
  ];

  return (
    <div className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12">
      <section className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100">
        <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Season Lotteries</h1>
            <p className="text-gray mb-0">
              {organizationUserRole === ORGANIZATION_ADMIN
                ? 'Manage lotteries and create new ones with ease.'
                : 'See all of the lotteries you are a part of here!'}
            </p>
            <UserRoleAdmin userType={organizationUserRole}>
              <NavLink
                className="d-lg-none btn btn-primary btn-lg mt-4"
                to={addLotteryPath}
              >
                <Ticket className="me-3" />
                Add New Lottery
              </NavLink>
            </UserRoleAdmin>
            <div className="mt-6">
              <TabbedMenu items={seasonLotterisMenuItems} />
            </div>
          </div>
          <UserRoleAdmin userType={organizationUserRole}>
            <NavLink
              id="NewLotteryDesktop"
              className="d-none d-lg-block btn btn-primary btn-xxl"
              to={addLotteryPath}
            >
              <Ticket className="me-3" />
              Add New Lottery
            </NavLink>
          </UserRoleAdmin>
        </div>
      </section>
      <section className="gap-6 d-flex align-items-start px-lg-8 px-4 flex-column w-100 mt-8">
        <Outlet />
      </section>
    </div>
  );
}

export default SeasonLotteries;
