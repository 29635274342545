import React from 'react';
import NoTickets from 'assets/images/tix.svg';
import styles from './Tickets.module.scss';

function NoTicket({ text }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-8 mb-12 w-100">
      <section className="gap-6 d-flex flex-column align-items-center px-lg-8 px-4">
        <div className="d-flex flex-column align-items-center py-24 w-50">
          <img src={NoTickets} alt="no-tickets" />
          <span className="fs-1 fw-semibold">No tickets found</span>
          <span className={styles.noTicket}>{text}</span>
        </div>
      </section>
    </div>
  );
}

export default NoTicket;
