import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import styles from './TabbedMenu.module.scss';

/**
 * Tabbed menu component that displays a list of items as a button group on desktop and a select on mobile.
 * @param {Object} props
 * @param {Array} props.items
 * @returns {React.ReactElement}
 */
function TabbedMenu({ items }) {
  const [activeItem, setActiveItem] = React.useState(items[0]);
  const { pathname } = useLocation();

  React.useEffect(() => {
    const item = items.find((i) => pathname.includes(i.path));
    setActiveItem(item);

    // Set focus to first item in the tabbed menu
    const firstItem = document.querySelector(`.${styles.desktop} a`);
    if (firstItem) {
      firstItem.focus();
    }
  }, [items, pathname]);

  return (
    <>
      <div
        data-testid="tabbed-menu"
        className={`${styles.mobile} d-lg-none d-flex w-100`}
      >
        <Dropdown className={`w-100 ${styles.dropdown}`}>
          <Dropdown.Toggle
            variant="tertiary-gray"
            className={`w-100 fs-3 ${styles['dropdown-toggle']} shadow-sm`}
          >
            {activeItem?.label}
            {activeItem?.badge && (
              <span className="badge rounded-pill bg-black">
                {activeItem?.badge}
              </span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu className={`w-100 ${styles['dropdown-menu']}`}>
            {items.map((item) => (
              <Dropdown.Item
                className={`p-3 fs-3 ${styles['dropdown-item']}`}
                key={item.path}
                as={NavLink}
                to={item.path}
              >
                {item.label}
                {(item.badge || item.badge === 0) && (
                  <span className="badge rounded-pill bg-black d-inline-block ms-2">
                    {item.badge}
                  </span>
                )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        data-testid="tabbed-menu-desktop"
        className={`${styles.desktop} d-lg-flex d-none`}
      >
        <ButtonGroup>
          {items.map((item) => (
            <Button
              key={item.path}
              as={NavLink}
              tabIndex={0}
              to={item.path}
              variant="tertiary-gray"
            >
              {item.label}
              {(item.badge || item.badge === 0) && (
                <span className="badge rounded-pill bg-black">
                  {item.badge}
                </span>
              )}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
}

export default TabbedMenu;
