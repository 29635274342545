import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import LotteriesFiltered from './LotteriesFiltered';

function CompleteLotteries() {
  const COMPLETE = 'Complete';
  usePageTracking('complete-lotteries');
  return <LotteriesFiltered lotteryStatusFilter={COMPLETE} />;
}

export default CompleteLotteries;
