import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import useUser from 'hooks/useUser';
import CalendarIcon from 'assets/icons/calendar-normal.svg';
import HomeIcon from 'assets/icons/home.svg';
import TicketIcon from 'assets/icons/ticket.svg';
import ReportIcon from 'assets/icons/pie-chart.svg';
import UsersIcon from 'assets/icons/users.svg';
import SupportIcon from 'assets/icons/support.svg';
import AccountSettingsIcon from 'assets/icons/config-cog.svg';
import LogoutIcon from 'assets/icons/logout.svg';
import UserOraganizationPicker from 'components/UserOrganizationPicker';
import AllSeaterAvatar from 'components/Avatar';
import SvgIcon from 'components/SvgIcon';
import { UserRoleAdmin, UserRoleParticipant } from 'components/UserRole';
import NavItem from '../NavItem';
import styles from './MenuNavigation.module.scss';
import ActiveSubscriptionRequired from '../ActiveSubscriptionRequired/ActiveSubscriptionRequired';
import AuthenticationContext from '../../AuthenticationContext';

/**
 * `MenuNavigationTop` component represents the top row content of the Menu Navigation.
 *
 * @component
 *
 * @returns {JSX.Element}
 */
export function MenuNavigationTop() {
  const { organizationUserRole } = useUser();
  return (
    <>
      {/* Content shown for admin */}
      <UserRoleAdmin userType={organizationUserRole}>
        <ActiveSubscriptionRequired>
          <NavItem icon={HomeIcon} url="/admin" text="Admin Dashboard" />
          <NavItem
            icon={TicketIcon}
            url="/season-lotteries"
            text="Season Lotteries"
          />
          <NavItem icon={ReportIcon} url="reports" text="Reports" />
          <NavItem
            icon={UsersIcon}
            url="/manage-participants"
            text="Participants"
          />
        </ActiveSubscriptionRequired>
      </UserRoleAdmin>
      {/* Content shown for participants */}
      <UserRoleParticipant userType={organizationUserRole}>
        <NavItem icon={HomeIcon} url="/participant" text="Dashboard" />
        <NavItem icon={TicketIcon} url="/tickets" text="Tickets" />
        <NavItem
          icon={CalendarIcon}
          url="/season-lotteries"
          text="Season Lotteries"
        />
      </UserRoleParticipant>
    </>
  );
}

/**
 * `MenuNavigationBottom` component represents the bottom row content of the Menu Navigation.
 *
 * @component
 *
 * @returns {JSX.Element}
 */
export function MenuNavigationBottom() {
  const { organizationUserRole, profile, organizationUserRoles } = useUser();
  const { msalInstance } = useContext(AuthenticationContext);

  return (
    <>
      <nav className={styles['menu-top-navigation']}>
        <NavItem url="/support" icon={SupportIcon} text="Support" />
        {/* Content shown for admin */}
        <UserRoleAdmin userType={organizationUserRole}>
          <NavItem
            url="/admin/account-settings"
            icon={AccountSettingsIcon}
            text="Account Settings"
          />
        </UserRoleAdmin>
        {/* Content shown for participants */}
        <UserRoleParticipant userType={organizationUserRole}>
          <NavItem
            url="/account-settings"
            icon={AccountSettingsIcon}
            text="Account Settings"
          />
        </UserRoleParticipant>
      </nav>

      {profile && (
        <div className={styles['menu-account-info']}>
          <div className={styles['menu-account-userinfo']}>
            <AllSeaterAvatar
              size="md"
              imgSrc={profile?.profilePhoto}
              altText={`${profile?.firstName
                ?.charAt(0)
                .toUpperCase()}${profile?.lastName?.charAt(0).toUpperCase()}`}
            />
            <div className={styles['menu-account-info-user-data']}>
              <p className="m-0 fw-semibold fs-4">
                {`${profile?.firstName} ${profile?.lastName}`}
              </p>
              <p className="m-0 fw-regular fs-4 text-secondary">
                {profile?.email}
              </p>
            </div>
          </div>
          <Button variant="text" onClick={() => msalInstance.logoutRedirect()}>
            <SvgIcon altText="Logout" component={LogoutIcon} />
          </Button>
        </div>
      )}
      {organizationUserRoles?.length > 1 ? (
        <div className="w-100">
          <div className="hr-divider" />
          <div className="w-100 pt-5">
            <UserOraganizationPicker />
          </div>
        </div>
      ) : null}
    </>
  );
}
