import React from 'react';

export default function TableHeader({
  title,
  totalItems,
  description,
  children,
}) {
  return (
    <div
      id="TableOuterHeader"
      className="py-5 px-4 px-lg-6 d-flex flex-column flex-lg-row gap-4 w-100 justify-content-between"
    >
      <div>
        <h2 className="fs-2 fw-semibold d-flex align-items-center">
          {title}{' '}
          {(totalItems || totalItems === 0) && (
            <span className="badge rounded-pill bg-black ms-2">
              {totalItems}
            </span>
          )}
        </h2>
        <p className="fs-4 mb-0">{description}</p>
      </div>
      <div className="d-flex">{children}</div>
    </div>
  );
}
