import React, { useEffect, useState, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { protectedResources } from 'authConfig';

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import useUser from 'hooks/useUser';
import HelpCircleIcon from 'assets/icons/help-circle.svg';
import DragAndDrop from 'components/DragAndDrop';
import Avatar from 'components/Avatar';
import HeroImage from 'components/HeroImage/HeroImage';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import usePageTracking from 'hooks/usePageTracking';
import cn from 'utils/classNames';
import styles from './CompayDetails.module.scss';

function CompanyDetails() {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.organizations.scopes.access_as_user,
  });
  const { organizationId } = useUser();

  usePageTracking('company-details');

  const [isFormLoading, setIsFormLoading] = useState(true);

  const formValidation = yup.object({
    name: yup
      .string()
      .required('Company name is required')
      .min(3, "Company name can't be less than 3 characters"),
    tagline: yup.string().max(90, "Tagline can't be more than 90 characters"),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      tagLine: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      logo: '',
      coverPhoto: '',
    },
    onSubmit: (values) => {
      execute(
        'PUT',
        `${protectedResources.organizations.endpoint}`,
        values,
      ).then((updatedCompanyData) => {
        if (updatedCompanyData) {
          formik.setValues({
            ...formik.values,
            ...updatedCompanyData,
          });
        }
      });
    },
    validationSchema: formValidation,
  });

  const { formValue, setFormValue } = {
    formValue: formik.value,
    setFormValue: formik.setValues,
  };

  const getCompanyDetails = useCallback(async () => {
    if (!organizationId) return;
    setIsFormLoading(true);
    const companyId = organizationId;
    const companyDetails = await execute(
      'GET',
      `${protectedResources.organizations.endpoint}/${companyId}`,
    );

    if (companyDetails) {
      setIsFormLoading(false);

      setFormValue({
        ...formValue,
        ...companyDetails,
      });
    }
  }, [execute, organizationId, formValue, setFormValue]);

  useEffect(() => {
    getCompanyDetails();
  }, [getCompanyDetails]);

  const handleSaveClick = () => {
    formik.validateForm().then((errors) => {
      const firstErrorField = Object.keys(errors)[0];
      const errorField = document.getElementById(firstErrorField);
      if (errorField) {
        errorField.focus();
      }
    });
  };

  const actionButtons = () => (
    <>
      <Button variant="tertiary" onClick={getCompanyDetails}>
        Cancel
      </Button>
      <Button type="submit" variant="primary" onClick={handleSaveClick}>
        Save
      </Button>
    </>
  );

  const setImageFormField = (event, fieldName) => {
    // Because of the way the useFetchWithMsal hook works, we cannot send the file as a file upload, so we are sending it as a base64 instead, will have to look if there is a better way to do this
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onloadend = () => {
      formik.setValues({
        ...formik.values,
        [`${fieldName}`]: reader.result,
      });
    };
  };

  return isFormLoading ? (
    <div>Loading...</div>
  ) : (
    <Form
      className="w-100 d-flex flex-column align-items-start gap-6"
      onSubmit={formik.handleSubmit}
    >
      <section className="d-flex flex-column align-items-start gap-5 w-100">
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
          <div className="d-flex flex-column align-items-start gap-1">
            <h2 className="m-0 fs-2 fw-semibold lh-lg">Company Details</h2>
            <p className="m-0 fs-4 text-secondary">
              Update your company photo and details here.
            </p>
          </div>

          <div className="d-flex align-items-center gap-3">
            {actionButtons()}
          </div>
        </div>

        <div className="hr-divider" />
      </section>
      <div className="w-100 d-flex flex-column gap-5">
        <div
          id="public-profile-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Public profile
            </p>
            <p className="fs-4 text-secondary m-0">
              This will be displayed on your profile.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <Form.Group controlId="name" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                Company name*
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                type="text"
              />
              {formik.errors.name && formik.touched.name ? (
                <Form.Text className="text-danger">
                  {formik.errors.name}
                </Form.Text>
              ) : null}
            </Form.Group>
          </div>
        </div>
        <div className="hr-divider" />

        <div
          id="tagline-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Tagline{' '}
              <SvgIcon className="text-gray" component={HelpCircleIcon} />
            </p>
            <p className="fs-4 text-secondary m-0">
              A quick snapshot of your company.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <Form.Group controlId="tagline" className="w-100">
              <Form.Label className="visually-hidden">TagLine</Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tagline}
                as="textarea"
                rows={3}
                maxLength={90}
                style={{ resize: 'none' }}
              />
              <Form.Text className="d-block text-gray">
                {90 - formik.values.tagline.length} characters left
              </Form.Text>

              {formik.errors.tagline && formik.touched.tagline ? (
                <Form.Text className="d-block text-danger">
                  {formik.errors.tagline}
                </Form.Text>
              ) : null}
            </Form.Group>
          </div>
        </div>
        <div className="hr-divider" />

        <div
          id="company-logo-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Company Logo*
            </p>
            <p className="fs-4 text-secondary m-0">
              Enhance your company&apos;s profile with a professional logo.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <div className="d-flex flex-column flex-lg-row gap-8 align-items-start justify-content-start w-100">
              <div
                className={cn([
                  styles['event-image-wrapper'],
                  'd-flex align-items-center justify-content-center',
                ])}
              >
                <Avatar
                  altText="C"
                  altBackground="yellow"
                  size="md"
                  style={{
                    width: '4rem',
                    height: '4rem',
                    fontSize: '2rem',
                  }}
                  imgSrc={formik.values.logo ?? null}
                />
              </div>

              <DragAndDrop
                altText="Company logo upload field"
                multiple={false}
                onFileUpload={(event) => setImageFormField(event, 'logo')}
                accept={{ 'image/*': ['.png', '.svg', '.jpg', '.gif'] }}
                maxSize={5 * 1024 * 1024}
                uploadDescription="SVG, PNG, JPG or GIF (max. 800x800px)"
                controlId="logo-upload"
              />
            </div>
          </div>
        </div>

        <div className="hr-divider" />
        <div
          id="company-cover-photo-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Company cover photo*
            </p>
            <p className="fs-4 text-secondary m-0">
              Showcase your company&apos;s identity with a captivating cover
              photo.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <div className="d-flex flex-column flex-lg-row gap-8 align-items-start justify-content-start w-100">
              <div
                style={{ width: '7.25rem' }}
                className="d-flex align-items-center justify-content-center"
              >
                <HeroImage
                  imgSrc={formik.values.coverPhoto ?? null}
                  style={{ width: '7.25rem', height: '4rem' }}
                  className="rounded-2"
                />
              </div>
              <DragAndDrop
                altText="Company cover photo upload field"
                multiple={false}
                onFileUpload={(event) => setImageFormField(event, 'coverPhoto')}
                accept={{ 'image/*': ['.png', '.svg', '.jpg', '.gif'] }}
                maxSize={5 * 1024 * 1024}
                uploadDescription="SVG, PNG, JPG or GIF (max. 1200x600px)"
                controlId="cover-upload"
              />
            </div>
          </div>
        </div>
        <div className="hr-divider" />

        <div
          id="social-profile-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Social profiles
            </p>
            <p className="fs-4 text-secondary m-0">
              Enhance your company&apos;s online presence by adding your social
              media profiles.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <Form.Group controlId="linkedin" className="w-100">
              <Form.Label className="visually-hidden">LinkedIn URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>linkedin.com/company/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.linkedin}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="twitter" className="w-100">
              <Form.Label className="visually-hidden">Twitter URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>twitter.com/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.twitter}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="instagram" className="w-100">
              <Form.Label className="visually-hidden">Instagram URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>instagram.com/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.instagram}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
        <div className="hr-divider" />
      </div>
      <section className="d-flex align-items-center justify-content-center gap-3 justify-content-lg-end w-100">
        {actionButtons()}
      </section>
    </Form>
  );
}

export default CompanyDetails;
