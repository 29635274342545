import React from 'react';
import { Outlet } from 'react-router-dom';
import useUser from 'hooks/useUser';
import TabbedMenu from 'components/TabbedMenu';
import { UserRoleAdmin, UserRoleParticipant } from 'components/UserRole';
import styles from './AccountSettings.module.scss';

function AccountSettings() {
  const { organizationUserRole } = useUser();
  const adminAccSettingsMenuItems = [
    {
      path: 'company-details',
      label: 'Company Details',
    },
    {
      path: 'my-profile',
      label: 'My Profile',
    },
    {
      path: 'plan-billing',
      label: 'Plan & Billing',
    },
    {
      path: 'notifications',
      label: 'Notifications',
    },
    {
      path: 'integrations',
      label: 'Integrations',
    },
  ];

  const participantAccSettingsMenuItems = [
    {
      path: 'my-profile',
      label: 'My Profile',
    },
    {
      path: 'notifications',
      label: 'Notifications',
    },
  ];

  return (
    <div
      className={`${styles.wrapper} d-flex flex-column align-items-start justify-content-start my-8`}
    >
      <section
        className={`${styles.header} d-flex flex-column align-items-start px-lg-8 px-4 w-100`}
      >
        <h1 className="h5 fw-bold">Settings</h1>
        <UserRoleAdmin userType={organizationUserRole}>
          <TabbedMenu items={adminAccSettingsMenuItems} />
        </UserRoleAdmin>
        <UserRoleParticipant userType={organizationUserRole}>
          <TabbedMenu items={participantAccSettingsMenuItems} />
        </UserRoleParticipant>
      </section>

      <section
        className={`${styles.content} d-flex align-items-start px-lg-8 px-4 flex-column w-100`}
      >
        <Outlet />
      </section>
    </div>
  );
}

export default AccountSettings;
