/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { format, parseISO } from 'date-fns';
import cn from 'utils/classNames';
import handleFormTracking from 'utils/handleFormTracking';
import {
  DETAILS_STEP,
  SCHEDULE_STEP,
  RULES_STEP,
  PUBLISH_STEP,
  LotteryDetailsFields,
  LotteryRulesFields,
  DETAILS_FORM_TYPE,
  SCHEDULE_FORM_TYPE,
  RULES_FORM_TYPE,
  PUBLISH_FORM_TYPE,
  REVIEW_TICKETS_STEP,
  REVIEW_TICKETS_FORM_TYPE,
  LotteryScheduleFields,
} from 'constants/addLottery';
import { protectedResources } from 'authConfig';
import Rocket from 'assets/icons/rocket.svg';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import useUser from 'hooks/useUser';
import usePageTracking from 'hooks/usePageTracking';
import ConsoleLogger from 'utils/logger';
import styles from './AddLottery.module.scss';
import {
  LotteryDetails,
  LotteryRules,
  LotteryReviewAndPublish,
  LotterySchedule,
} from './LotterySteps';
import LotteryCreationSuccessDialog from './LotteryCreationSuccessDialog';
import LotteryCreationErrorDialog from './LotteryCreationErrorDialog';
import { startLatencyMetric, startSuccessMetric } from '../../utils/metrics';
import LotteryReviewTickets from './LotterySteps/LotteryReviewTickets';
// TODO: Validation removed from ALL-565 https://allseater.atlassian.net/browse/ALLS-565
// import LotteryMaxDialogError from './LotteryMaxErrorDialog';

const logger = ConsoleLogger.getInstance();

/**
 * Priority group emails for lottery picks may only be sent during business hours.
 * The following defines the window of time during the day in hours (24 hour clock) the email
 * notifications are sent.
 */
const LOTTERY_PICKWINDOW_HOUROFDAY = {
  START: 7, // 7AM
  END: 17, // 5PM
};

const {
  LOTTERY_NAME_FIELD,
  START_DATE_FIELD,
  START_TIME_FIELD,
  END_DATE_FIELD,
  END_TIME_FIELD,
  DESCRIPTION_FIELD,
  EVENT_TYPE_FIELD,
  VENUE_NAME_FIELD,
  VENUE_LINK_FIELD,
  UNCLAIMED_TICKETS_FIELD,
  EVENT_LOGO_FIELD,
  EVENT_COVER_PHOTO_FIELD,
  EVENT_TICKETS,
  EVENT_TICKET_ID,
  SECTION,
  ROW,
  SEAT,
} = LotteryDetailsFields;
const { LOTTERY_STYLE_FIELD } = LotteryRulesFields;

const { EVENT_DATE, TIME, EVENT_NAME, ADDITIONAL_DETAILS } =
  LotteryScheduleFields;

const LOTTERY_DRAFT = 'lotteryDraft';

const initialLotteryState = {
  [DETAILS_STEP]: false,
  [SCHEDULE_STEP]: false,
  [REVIEW_TICKETS_STEP]: false,
  [RULES_STEP]: false,
  [PUBLISH_STEP]: null,
};

const tabValues = {
  [DETAILS_STEP]: 1,
  [SCHEDULE_STEP]: 2,
  [REVIEW_TICKETS_STEP]: 3,
  [RULES_STEP]: 4,
  [PUBLISH_STEP]: 5,
};

const PublishActionLabel = (
  <>
    <Rocket /> Publish Lottery
  </>
);

/**
 * The Add Lottery Parent component which handles state for the entire Lottery Creation Flow
 *
 * @returns {JSX.Element}
 */
function AddLottery() {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState();
  const [lottery, setLottery] = useState(initialLotteryState);
  // const [lotteryMaxError, setLotteryMaxError] = useState();
  // We need to manuall reset the ag grid's values when we publish the lottery
  const { organizationId } = useUser();
  const navScheduleRef = useRef();
  const navReviewTicketsRef = useRef();
  const navRulesRef = useRef();
  const navPublishRef = useRef();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.lotteries.scopes.access_as_user,
  });
  usePageTracking('add-lottery');

  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [lotteryCreationSucceeded, setLotteryCreationSucceeded] =
    useState(false);
  const [lotteryCreationError, setLotteryCreationError] = useState(undefined);

  const navItemClasses = (isActive, isComplete, isDisabled) =>
    cn([
      !isComplete && styles.incomplete,
      isDisabled && styles.incomplete,
      isActive && styles.active,
      styles['tab-nav-item'],
      'text-info fs-4 fw-semibold',
    ]);

  function handleNextStep(step) {
    const stepRefs = {
      [SCHEDULE_STEP]: navScheduleRef,
      [REVIEW_TICKETS_STEP]: navReviewTicketsRef,
      [RULES_STEP]: navRulesRef,
      [PUBLISH_STEP]: navPublishRef,
    };
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    stepRefs[step].current.focus();
    setActiveKey(step);
  }

  // Function to save the lottery state to local storage
  function saveLotteryState(lotteryState) {
    try {
      logger.log({
        lotterySaved: lotteryState,
      });
      // Stringify the lottery state to be able to store it in local storage
      const serializedState = JSON.stringify(lotteryState);
      // Set the item in local storage
      localStorage.setItem(LOTTERY_DRAFT, serializedState);
    } catch (err) {
      // Handle the error, possibly by logging it or displaying an error message
      logger.error('Error saving lottery state:', err);
    }
  }

  // Function to load the lottery state from local storage
  function loadLotteryState() {
    try {
      // Get the item from local storage
      const serializedState = localStorage.getItem(LOTTERY_DRAFT);
      // Check if there's any stored state
      if (serializedState === null) {
        // No stored state, go to first step
        setActiveKey(DETAILS_STEP);
      } else {
        // Parse the stored JSON string back into an object
        const lotteryState = JSON.parse(serializedState);
        // Set the lottery state using the passed hook
        setLottery(lotteryState);
        // Loop over the keys in order
        // eslint-disable-next-line no-restricted-syntax
        for (const key in lotteryState) {
          if (Object.hasOwnProperty.call(lotteryState, key)) {
            // stop on the last key without a saved state, ignore draft only key
            if (!lotteryState[key] && key !== `${RULES_STEP}Draft`) {
              setActiveKey(key);
              break;
            }
          }
        }
        logger.log({ lotteryLoaded: lotteryState });
      }
    } catch (err) {
      // Handle errors, such as invalid JSON, or no access to localStorage
      setLottery(initialLotteryState);
      logger.error('Error loading lottery state:', err);
    }
  }

  function mapLotteryObjectToFormValues(lotteryState, participants) {
    const startDateTimeObj = parseISO(lotteryState.startDateTime);
    const endDateTimeObj = parseISO(lotteryState.endDateTime);

    // Sort lotteryState.priorityGroups by its groupName alphabetically
    lotteryState.priorityGroups.sort((a, b) =>
      a.groupName.localeCompare(b.groupName),
    );

    const priorityGroups = {};

    lotteryState.priorityGroups.forEach((group, index) => {
      const { groupName } = group;
      priorityGroups[groupName] = {
        groupName,
        priorityGroupIndex: index,
        eventLimit: group.eventLimit,
        minutesPickWIndow: group.minutesPickWIndow,
        ticketLimitPerEvent: group.ticketLimitPerEvent,
        participantIds: participants
          .filter((participant) => participant.PriorityGroup === groupName)
          .map((participant) => participant.Id),
      };
    });

    return {
      [DETAILS_STEP]: {
        [LOTTERY_NAME_FIELD]: lotteryState.title,
        [DESCRIPTION_FIELD]: lotteryState.description,
        // Parse date from lotteryState.startDateTime to YYY-MM-DD
        [START_DATE_FIELD]: format(startDateTimeObj, 'yyyy-MM-dd'),
        // Parse date from lotteryState.startDateTime to HH:MM
        [START_TIME_FIELD]: format(startDateTimeObj, 'HH:mm'),

        // Parse date from lotteryState.endDateTime to YYY-MM-DD
        [END_DATE_FIELD]: format(endDateTimeObj, 'yyyy-MM-dd'),
        // Parse date from lotteryState.endDateTime to HH:MM
        [END_TIME_FIELD]: format(endDateTimeObj, 'HH:mm'),
        [EVENT_COVER_PHOTO_FIELD]: lotteryState.coverPhoto ?? '',
        [EVENT_LOGO_FIELD]: lotteryState.logo ?? '',
        [EVENT_TYPE_FIELD]: lotteryState.eventTypeCode,
        [VENUE_NAME_FIELD]: lotteryState.venueName,
        [VENUE_LINK_FIELD]: lotteryState.venueUrl,
        [UNCLAIMED_TICKETS_FIELD]: lotteryState.unclaimedTicketMode,
      },
      [SCHEDULE_STEP]: {
        eventSchedule: lotteryState.tickets.map((ticket) => {
          const ticketDateTime = parseISO(ticket.eventDateTime);

          return {
            eventDate: format(ticketDateTime, 'yyyy-MM-dd'),
            time: format(ticketDateTime, 'hh:mm a'),
            row: ticket.row,
            seat: ticket.seat,
            section: ticket.section,
            name: ticket.eventName,
            'event-id': window?.crypto?.randomUUID() ?? '',
          };
        }),
      },

      [RULES_STEP]: {
        [LOTTERY_STYLE_FIELD]: lotteryState.lotteryStyleCode,
        priorityGroups: Object.values(priorityGroups).map((priorityGroup) => ({
          groupName: priorityGroup.groupName,
          eventLimit: priorityGroup.eventLimit,
          minutesPickWIndow: priorityGroup.minutesPickWIndow,
          ticketLimitPerEvent: priorityGroup.ticketLimitPerEvent,
          priorityGroupParticipantIds: priorityGroup.participantIds,
        })),
        participants: participants.map((participant) => ({
          CompanyName: participant.Company,
          Email: participant.Email,
          FirstName: participant.FirstName,
          JobTitle: participant.JobTitle,
          LastName: participant.LastName,
          OrganizationUserId: participant.Id,
          ProfileImageUrl: participant.profilePhoto,
          Tags: participant.UserTag,
          priorityGroupIndex:
            priorityGroups[participant.PriorityGroup].priorityGroupIndex,
        })),
        priorityParticipants: {
          lotteryStyleCode: lotteryState.lotteryStyleCode,
          priorityGroups: Object.values(priorityGroups).map(
            (priorityGroup) => ({
              groupIndex: priorityGroup.priorityGroupIndex,
              eventLimit: priorityGroup.eventLimit,
              minutesPickWIndow: priorityGroup.minutesPickWIndow,
              ticketLimitPerEvent: priorityGroup.ticketLimitPerEvent,
            }),
          ),
        },
      },
      [`${RULES_STEP}Draft`]: {
        [LOTTERY_STYLE_FIELD]: lotteryState.lotteryStyleCode,
        priorityGroups: Object.values(priorityGroups).map((priorityGroup) => ({
          groupIndex: priorityGroup.priorityGroupIndex,
          eventLimit: 2,
          minutesPickWIndow: 15,
          ticketLimitPerEvent: 1,
        })),
        participants: participants.map((participant) => ({
          CompanyName: participant.Company,
          Email: participant.Email,
          FirstName: participant.FirstName,
          JobTitle: participant.JobTitle,
          LastName: participant.LastName,
          OrganizationUserId: participant.Id,
          ProfileImageUrl: participant.profilePhoto,
          Tags: participant.UserTag,
          priorityGroupIndex:
            priorityGroups[participant.PriorityGroup].priorityGroupIndex,
        })),
      },
      [PUBLISH_STEP]: null,
    };
  }

  /**
   * Loads an existing lottery by its ID
   * @param {string} lotteryId ID of the Lottery to load as a state
   */
  async function loadExistingLotteryState(lotteryId) {
    setActiveKey(PUBLISH_STEP);
    logger.log('Loading existing lottery state with id: ', lotteryId);

    const getLotteryMetric = startLatencyMetric('getLottery');
    const existingLotteryPromise = execute(
      'GET',
      `${protectedResources.lotteries.endpoint}/getLottery/${lotteryId}`,
    );
    getLotteryMetric.record();

    const latencyMetric = startLatencyMetric('getLotteryParticipants');
    const lotteryParticipantsPromise = execute(
      'GET',
      `${protectedResources.lotteries.endpoint}/${lotteryId}/participants?$top=10000`,
    ).then((response) => response?.value);
    latencyMetric.record();

    const [existingLotteryResponse, lotteryParticipantsResponse] =
      await Promise.all([existingLotteryPromise, lotteryParticipantsPromise]);

    if (existingLotteryResponse) {
      const mappedLotteryState = mapLotteryObjectToFormValues(
        existingLotteryResponse,
        lotteryParticipantsResponse,
      );
      setLottery(mappedLotteryState);

      // Removes the search Params
      searchParams.delete('duplicate');
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    if (searchParams.has('duplicate')) {
      const duplicateId = searchParams.get('duplicate');
      loadExistingLotteryState(duplicateId);
    } else {
      loadLotteryState();
    }
    setActiveKey(DETAILS_STEP);
    // Disabling the eslint rule because we only want to run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLotteryDetails = async (values) => {
    handleFormTracking(DETAILS_FORM_TYPE, 'form_complete');
    setLottery((prevState) => {
      // persist the lottery
      saveLotteryState({
        ...prevState,
        [DETAILS_STEP]: { ...values },
      });

      return {
        ...prevState,
        [DETAILS_STEP]: { ...values },
      };
    });

    // TODO: Validation removed from ALL-565 https://allseater.atlassian.net/browse/ALLS-565
    // const StartDateTime = new Date(
    //   `${values[START_DATE_FIELD]} ${values[START_TIME_FIELD]}`,
    // );

    // const EndDateTime = new Date(
    //   `${values[END_DATE_FIELD]} ${values[END_TIME_FIELD]}`,
    // );
    // try {
    //   const payload = {
    //     OrganizationId: organizationId,
    //     StartDateTime,
    //     EndDateTime,
    //   };
    //   const result = await execute(
    //     'POST',
    //     `${protectedResources.lotteries.endpoint}/validateLottery`,
    //     payload,
    //   );

    //   // TODO: handle error with specific status code in the future
    //   if (result.StatusCode === 500) {
    //     setLotteryMaxError(result.Message);
    //   }
    //   handleNextStep(SCHEDULE_STEP);
    // } catch (error) {
    //   ConsoleLogger.error('Error fetching lotteries', error);
    // }
    handleNextStep(SCHEDULE_STEP);
  };

  function handleLotterySchedule(values) {
    handleFormTracking(SCHEDULE_FORM_TYPE, 'form_complete');
    setLottery((prevState) => {
      // persist the lottery
      saveLotteryState({
        ...prevState,
        [SCHEDULE_STEP]: { ...values },
      });

      return {
        ...prevState,
        [SCHEDULE_STEP]: { ...values },
      };
    });
    handleNextStep(REVIEW_TICKETS_STEP);
  }

  function handleTicketReview() {
    handleFormTracking(REVIEW_TICKETS_FORM_TYPE, 'form_complete');

    setLottery((prevState) => {
      // persist the lottery
      saveLotteryState({
        ...prevState,
        [REVIEW_TICKETS_STEP]: true,
      });

      return {
        ...prevState,
        [REVIEW_TICKETS_STEP]: true,
      };
    });

    handleNextStep(RULES_STEP);
  }

  function handleLotteryRules({ payload, draft }) {
    handleFormTracking(RULES_FORM_TYPE, 'form_complete');
    setLottery((prevState) => {
      // persist the lottery
      saveLotteryState({
        ...prevState,
        [RULES_STEP]: { ...payload },
        [`${RULES_STEP}Draft`]: draft,
      });

      return {
        ...prevState,
        [RULES_STEP]: { ...payload },
        [`${RULES_STEP}Draft`]: draft,
      };
    });
    handleNextStep(PUBLISH_STEP);
  }

  const handleLotteryPublish = async () => {
    const lotteryPublishMetric = startLatencyMetric('lotteryPublish');
    const lotteryPublishSuccessMetric = startSuccessMetric('lotteryPublish');

    setIsSubmitLoading(true);
    setLotteryCreationError(null);
    setLotteryCreationSucceeded(false);

    const startDateTime = new Date(
      `${lottery[DETAILS_STEP][START_DATE_FIELD]} ${lottery[DETAILS_STEP][START_TIME_FIELD]}`,
    );

    const endDateTime = new Date(
      `${lottery[DETAILS_STEP][END_DATE_FIELD]} ${lottery[DETAILS_STEP][END_TIME_FIELD]}`,
    );

    // TODO: Validation removed from ALL-565 https://allseater.atlassian.net/browse/ALLS-565
    // Initialize an empty string for storing validation messages
    // let validateLotteryMessage = '';
    // try {
    //   // Define the payload for the POST request
    //   const payload = {
    //     OrganizationId: organizationId,
    //     startDateTime,
    //     endDateTime,
    //   };

    //   // Execute a POST request to validate the lottery
    //   const result = await execute(
    //     'POST',
    //     `${protectedResources.lotteries.endpoint}/validateLottery`,
    //     payload,
    //   );

    //   // If the server responds with a status code of 500, store the server's message
    //   if (result.StatusCode === 500) {
    //     validateLotteryMessage = result.Message;
    //   }
    // } catch (error) {
    //   ConsoleLogger.error('Error fetching lotteries', error);
    // }

    // If a validation message exists, set it as the lottery max error, stop the loading state, and return from the function
    // if (validateLotteryMessage) {
    //   setLotteryMaxError(validateLotteryMessage);
    //   setIsSubmitLoading(false);
    //   return;
    // }

    const eventSchedule = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const event of lottery[SCHEDULE_STEP].eventSchedule) {
      // eslint-disable-next-line no-restricted-syntax
      for (const ticket of event.Tickets) {
        const ticketObj = lottery[DETAILS_STEP][EVENT_TICKETS].find(
          (ticketElement) => ticketElement[EVENT_TICKET_ID] === ticket,
        );

        eventSchedule.push({
          name: event[EVENT_NAME],
          eventDateTime: new Date(`${event[EVENT_DATE]} ${event[TIME]}`),
          section: ticketObj[SECTION],
          row: ticketObj[ROW],
          seat: ticketObj[SEAT],
          additionalDetails: event[ADDITIONAL_DETAILS],
        });
      }
    }

    try {
      const newLotteryPayload = {
        organizationId,
        title: lottery[DETAILS_STEP][LOTTERY_NAME_FIELD],
        description: lottery[DETAILS_STEP][DESCRIPTION_FIELD],
        startDateTime,
        endDateTime,
        unclaimedTicketMode: lottery[DETAILS_STEP][UNCLAIMED_TICKETS_FIELD],
        eventTypeCode: lottery[DETAILS_STEP][EVENT_TYPE_FIELD],
        venueName: lottery[DETAILS_STEP][VENUE_NAME_FIELD],
        venueUrl: lottery[DETAILS_STEP][VENUE_LINK_FIELD],
        logo: lottery[DETAILS_STEP][EVENT_LOGO_FIELD],
        coverPhoto: lottery[DETAILS_STEP][EVENT_COVER_PHOTO_FIELD],
        eventSchedule,
        lotteryStyleCode: lottery[RULES_STEP][LOTTERY_STYLE_FIELD],
        priorityGroups: lottery[RULES_STEP].priorityGroups || [],
        pickNotificationWindowStartTime: new Date(
          startDateTime.getFullYear(),
          startDateTime.getMonth(),
          startDateTime.getDay(),
          LOTTERY_PICKWINDOW_HOUROFDAY.START,
          0,
        ),
        pickNotificationWindowEndTime: new Date(
          startDateTime.getFullYear(),
          startDateTime.getMonth(),
          startDateTime.getDay(),
          LOTTERY_PICKWINDOW_HOUROFDAY.END,
          0,
        ),
      };
      const result = await execute(
        'POST',
        `${protectedResources.lotteries.endpoint}`,
        newLotteryPayload,
      );

      setIsSubmitLoading(false);

      lotteryPublishMetric.record();

      if (result?.StatusCode >= 400) {
        // This is a handled exception in the Backend, probably a validation error

        lotteryPublishSuccessMetric.record(false);

        if (
          result.Message.includes('One or more validation errors occurred') &&
          result.StatusCode === 400
        ) {
          // Validation error
          const validationErrors = result.Errors.map((validationError) => ({
            index: validationError.PropertyName,
            text: validationError.ErrorMessage,
          }));

          setLotteryCreationError(validationErrors);
        } else {
          setLotteryCreationError(JSON.stringify(result)); // It is an unhandled exception or we don't know the format.
        }
      } else {
        lotteryPublishSuccessMetric.record(true);
        localStorage.removeItem(LOTTERY_DRAFT);
        setLottery(initialLotteryState);
        setLotteryCreationSucceeded(true);
        handleFormTracking(PUBLISH_FORM_TYPE, 'form_complete');
      }

      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    } catch (err) {
      logger.error('Error creating new lottery', err);
      setIsSubmitLoading(false);
      setLotteryCreationError(err);
    }
  };

  function handleCancel() {
    // TODO: Add a dialog here to prompt user that lottery draft will be destroyed?
    navigate('/season-lotteries');
  }

  return (
    <>
      {/*
      // TODO: Validation removed from ALL-565 https://allseater.atlassian.net/browse/ALLS-565
      <LotteryMaxDialogError
        errors={lotteryMaxError}
        onDismiss={() => setLotteryMaxError(null)}
      />
    */}
      <LotteryCreationSuccessDialog
        show={lotteryCreationSucceeded}
        onDismiss={() => {
          setLotteryCreationSucceeded(false);
          navigate('/season-lotteries');
        }}
      />
      <LotteryCreationErrorDialog
        errors={lotteryCreationError}
        onDismiss={() => setLotteryCreationError(null)}
      />

      <div className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12">
        <section className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100">
          <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
            <div>
              <h1 className="h5 fw-bold">Add New Season Lottery</h1>
              <p className="text-gray mb-0">
                Set up and publish new season lotteries and customize lottery
                parameters.
              </p>
            </div>
          </div>
        </section>
        <section className="gap-6 d-flex align-items-start px-lg-8 px-4 flex-column w-100 mt-6">
          {/* Check for the existence of an active key to prevent tab flashes on system navigation */}
          {!activeKey ? (
            ''
          ) : (
            <Tab.Container
              id="AddLotteryFlow"
              activeKey={activeKey}
              defaultActiveKey={DETAILS_STEP}
              onSelect={(k) => setActiveKey(k)}
            >
              <Nav
                className={cn([styles['tab-nav'], 'w-100 d-none d-lg-flex'])}
              >
                <Nav.Item>
                  <Nav.Link
                    className={navItemClasses(
                      DETAILS_STEP === activeKey,
                      lottery[DETAILS_STEP],
                      tabValues[activeKey] < tabValues[DETAILS_STEP],
                    )}
                    eventKey={DETAILS_STEP}
                    disabled={tabValues[activeKey] < tabValues[DETAILS_STEP]}
                  >
                    1. Add Lottery Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={navItemClasses(
                      SCHEDULE_STEP === activeKey,
                      lottery[SCHEDULE_STEP],
                      tabValues[activeKey] < tabValues[SCHEDULE_STEP],
                    )}
                    eventKey={SCHEDULE_STEP}
                    disabled={tabValues[activeKey] < tabValues[SCHEDULE_STEP]}
                    ref={navScheduleRef}
                  >
                    2. Add Event Schedule
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className={navItemClasses(
                      REVIEW_TICKETS_STEP === activeKey,
                      lottery[REVIEW_TICKETS_STEP],
                      tabValues[activeKey] < tabValues[REVIEW_TICKETS_STEP],
                    )}
                    eventKey={REVIEW_TICKETS_STEP}
                    ref={navReviewTicketsRef}
                    disabled={
                      tabValues[activeKey] < tabValues[REVIEW_TICKETS_STEP]
                    }
                  >
                    3. Review Tickets
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className={navItemClasses(
                      RULES_STEP === activeKey,
                      lottery[RULES_STEP],
                      tabValues[activeKey] < tabValues[RULES_STEP],
                    )}
                    eventKey={RULES_STEP}
                    ref={navRulesRef}
                    disabled={tabValues[activeKey] < tabValues[RULES_STEP]}
                  >
                    4. Set Lottery Rules
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={navItemClasses(
                      PUBLISH_STEP === activeKey,
                      lottery[DETAILS_STEP] &&
                        lottery[SCHEDULE_STEP] &&
                        lottery[RULES_STEP],
                      tabValues[activeKey] < tabValues[PUBLISH_STEP],
                    )}
                    eventKey={PUBLISH_STEP}
                    ref={navPublishRef}
                    disabled={tabValues[activeKey] < tabValues[PUBLISH_STEP]}
                  >
                    5. Review & Publish
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="w-100">
                <Tab.Pane eventKey={DETAILS_STEP}>
                  <LotteryDetails
                    actionLabel="Next: Add Event Schedule"
                    handleCancel={() => handleCancel()}
                    handleLotteryDetails={(values, actions) =>
                      handleLotteryDetails(values, actions)
                    }
                    unpublishedLottery={lottery}
                    // TODO: Validation removed from ALL-565 https://allseater.atlassian.net/browse/ALLS-565
                    // lotteryMaxError={lotteryMaxError}
                    // setLotteryMaxError={setLotteryMaxError}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={SCHEDULE_STEP}>
                  <LotterySchedule
                    handleLotterySchedule={(values, actions) =>
                      handleLotterySchedule(values, actions)
                    }
                    handleCancel={() => handleCancel()}
                    unpublishedLottery={lottery}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey={REVIEW_TICKETS_STEP}>
                  <LotteryReviewTickets
                    handleCancel={() => handleCancel()}
                    unpublishedLottery={lottery}
                    handleEditStep={(step) => setActiveKey(step)}
                    handleReviewTickets={(values) => handleTicketReview(values)}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey={RULES_STEP}>
                  <LotteryRules
                    actionLabel="Next: Review & Publish"
                    handleCancel={() => handleCancel()}
                    handleLotteryRules={(values, actions) =>
                      handleLotteryRules(values, actions)
                    }
                    unpublishedLottery={lottery}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={PUBLISH_STEP}>
                  <LotteryReviewAndPublish
                    actionLabel={PublishActionLabel}
                    unpublishedLottery={lottery}
                    handleCancel={() => handleCancel()}
                    handleEditStep={(step) => setActiveKey(step)}
                    handleLotteryPublish={() => handleLotteryPublish()}
                    isSubmitLoading={isSubmitLoading}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </section>
      </div>
    </>
  );
}

export default AddLottery;
