/**
 * Maps an input string to a background color.
 *
 * @param {string} input - The input string to map to a background color.
 * @returns {string} The mapped background color based on the input string.
 * @throws {Error} Throws an error if the input is not a string.
 */
const backgroundColorMapper = (input) => {
  // Check if input is a string with alphabetic characters
  if (typeof input !== 'string') {
    throw new Error('Invalid input. Please provide a string.');
  }

  const isFormatted = input.length <= 2;
  let checkedInput = input;

  // Check if the input is formatted before assigning a color
  if (!isFormatted) {
    // Get the first character in the string
    const firstChar = input.charAt(0);

    // Get the last word in the string
    const words = input.split(' ');
    const lastWord = words[words.length - 1];

    // Get the first character of the last word
    const firstCharOfLastWord = lastWord.charAt(0);

    // Return a single initial in cases of only one word
    checkedInput =
      words.length > 1
        ? `${firstChar}${firstCharOfLastWord}`.toUpperCase()
        : firstChar.toUpperCase();
  }

  let hash = 0;

  // Calculate a hash value based on the characters in the input
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < checkedInput.length; i++) {
    const char = checkedInput.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
  }

  // Ensure the result is positive and map it to a number between 1 and 4
  const result = (Math.abs(hash) % 4) + 1;

  switch (result) {
    case 1:
      return 'yellow';
    case 2:
      return 'green';
    case 3:
      return 'blue';
    default:
      return 'pink';
  }
};

export default backgroundColorMapper;
