/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ConsoleLogger from '../../utils/logger';

/**
 * Wrapper component that gets an SVG Icon and exposes as a React Functional Component that can be customized
 * @param {} param0
 * @returns
 */
function SvgIcon({ component: Icon, className = '', altText = '', ...props }) {
  // TODO: This is very sneaky, setting default class names through webpack is NOT COOL.
  let defaultClassName = '';
  try {
    defaultClassName = Icon({}).props.className || '';
  } catch (e) {
    ConsoleLogger.error(e);
  }
  return (
    <>
      {altText && <span className="visually-hidden">{altText}</span>}
      <Icon className={`${defaultClassName} ${className}`} {...props} />
    </>
  );
}

export default SvgIcon;
