import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { protectedResources } from '../../../authConfig';
import styles from './ParticipantDetails.module.scss';
import ActionDots from '../../../assets/icons/action-dots.svg';
import Trash from '../../../assets/icons/trash.svg';
import Dialog from '../../Dialog';
import Award from '../../../assets/icons/award.svg';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';

function ParticipantDetailsActions({
  id,
  firstName,
  lastName,
  email,
  setShouldDismiss,
}) {
  const [showDeleteParticipantDialog, setShowDeleteParticipantDialog] =
    useState(false);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  async function deleteParticipant() {
    await execute(
      'DELETE',
      `${protectedResources.participants.endpoint}/${id}`,
    );
    setShowDeleteParticipantDialog(false);
    setShouldDismiss(true);
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={styles['dropdown-toggle']}
        id={`action-button-${id}`}
        variant="link"
      >
        <span className="visually-hidden">Additional Actions</span>
        <ActionDots aria-hidden />
      </Dropdown.Toggle>

      <Dropdown.Menu role="menu" aria-label="Actions" as="ul">
        <Dropdown.Item className="" as="li">
          <Button
            id={`delete-participant-${id}`}
            className="me-3"
            variant="text"
            onClick={() => setShowDeleteParticipantDialog(true)}
          >
            <Trash className="me-1" />
            Delete Participant
          </Button>
          <Dialog
            show={showDeleteParticipantDialog}
            Icon={Trash}
            heading={`Are you sure you want to delete ${firstName} ${lastName}?`}
            subHeading="This user will be permanently deleted"
            ctaHandler={() => deleteParticipant()}
            ctaLabel={`Delete User ${email}`}
            handleClose={setShowDeleteParticipantDialog}
          />
        </Dropdown.Item>

        <Dropdown.Item className="" as="li">
          <Button
            id={`make-company-admin-${id}`}
            className="me-3"
            variant="text"
          >
            <Award className="me-1" />
            Make Company Admin
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ParticipantDetailsActions;
