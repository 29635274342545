/**
 * Given a date string in UTC, return local time
 * @param {string} dateTime
 * @returns {Date}
 */
function localDateTime(dateTime) {
  // Critical converstion from UTC to local time
  const utcDate = dateTime ? new Date(dateTime) : new Date();
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000,
  );

  return localDate;
}

export default localDateTime;
