import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import cn from 'utils/classNames';
import ConsoleLogger from 'utils/logger';
import useUser from 'hooks/useUser';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';

import FilterLines from 'assets/icons/filter-lines.svg';
import XClose from 'assets/icons/x-close.svg';
import Plus from 'assets/icons/plus.svg';
import Minus from 'assets/icons/minus.svg';

import styles from './ParticipantsFilter.module.scss';

const MINIMUM_TAG_FACET_OPTIONS = 4;

/**
 * `ParticipantsFilter` is a React component that provides filtering functionality based on user tags.
 * It utilizes a controlled list of tags to filter, and provides UI for expanding and collapsing the tag list,
 * as well as selecting and deselecting individual tags. A fetch operation retrieves available tags based on the current organization.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<string>} props.tagsToFilter - The currently selected tags used for filtering.
 * @param {Function} props.setTagsToFilter - The setter function to update the `tagsToFilter`.
 * @returns {React.ReactElement} - The rendered `ParticipantsFilter` component.
 *
 * @component
 * @example
 * // Usage in a parent component
 * const [selectedTags, setSelectedTags] = useState([]);
 * <ParticipantsFilter tagsToFilter={selectedTags} setTagsToFilter={setSelectedTags} />
 */
function ParticipantsFilter({ tagsToFilter, setTagsToFilter }) {
  const [tags, setTags] = useState([]);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tagsFacetExpanded, setTagsFacetExpanded] = useState(false);
  const { organizationId } = useUser();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  const getOrgUserTags = useCallback(async () => {
    if (!organizationId) {
      return;
    }
    try {
      const res = await execute(
        'GET',
        `${protectedResources.organizations.endpoint}/${organizationId}/tags`,
      );
      if (res?.tags) {
        const optionsWithLabel = res.tags.map((tag) => ({
          value: tag,
          label: tag,
        }));
        setTags(optionsWithLabel);
      }
    } catch (err) {
      ConsoleLogger.error(err);
      setTags([
        {
          value: null,
          label:
            'Something went wrong, please contact support if the issue persists.',
        },
      ]);
    }
  }, [organizationId, execute]);

  useEffect(() => {
    // get the tags on the initial render
    getOrgUserTags();
  }, [getOrgUserTags]);

  function handleTagsToFilter(tag) {
    setTagsToFilter((prevTags) => {
      // If the tag exists remove it
      if (prevTags.includes(tag)) {
        return [...prevTags.filter((tagToFilter) => tagToFilter !== tag)];
      }
      // if the tag doesn't exist, add it
      return [...prevTags, tag];
    });
  }

  function clearAll() {
    setTagsToFilter([]);
  }

  /**
   * User Tag Facet
   */
  const UserTagFacet = (
    <div id="UserTagFacet" className="py-3 fs-4 fw-medium">
      <div className="d-flex justify-content-between align-items-center pb-4">
        <span>User Tag</span>
        {tags.length > MINIMUM_TAG_FACET_OPTIONS && (
          <Button
            onClick={() => setTagsFacetExpanded((prevValue) => !prevValue)}
            className="btn-icon-sm"
            size="sm"
            variant="tertiary"
          >
            {!tagsFacetExpanded ? <Plus /> : <Minus />}
            <span className="visually-hidden">Expand filterable tags</span>
          </Button>
        )}
      </div>
      <ul className="list-group w-100">
        {tags
          .slice(0, tagsFacetExpanded ? tags.length : MINIMUM_TAG_FACET_OPTIONS)
          .map((tag) => (
            <li key={tag.value} className="list-group-item px-0 mx-0 border-0">
              <div
                className={cn([
                  styles['filter-item'],
                  'd-flex align-items-center m-0',
                ])}
              >
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  value={tag.value}
                  checked={tagsToFilter.includes(tag.value)}
                  id={`filter-option-${tag.value}`}
                  onChange={() => handleTagsToFilter(tag.value)}
                />
                <label
                  className="form-check-label ps-2 w-100"
                  htmlFor={`filter-option-${tag.value}`}
                >
                  {tag.label}
                </label>
              </div>
            </li>
          ))}
        {tagsFacetExpanded || (
          <li className="list-group-item px-0 mx-0 border-0">
            <div
              className={cn([
                styles['filter-item'],
                'd-flex align-items-center m-0',
              ])}
            >
              <span className="form-check-label ps-2 w-100">
                +{tags.slice(MINIMUM_TAG_FACET_OPTIONS, tags.length).length}{' '}
                More
              </span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );

  const handleFilterOpened = () => {
    setButtonClicked(true);
    // on click, wait for the animation to complete before toggling the menu
    setTimeout(() => {
      setShowFilterMenu((prev) => !prev);
    }, 200);
  };

  const handleFilterClosed = () => {
    setButtonClicked(false);
    setTagsFacetExpanded(false);
  };

  /**
   * Participant Filter - Outer Component
   */
  return (
    <div className={styles['filter-wrapper']}>
      <Button
        id="FilterParticipants"
        variant="tertiary"
        className={cn([
          styles['filter-button'],
          buttonClicked && styles['filter-button-open'],
          'd-flex justify-content-between align-items-start shadow-sm',
        ])}
        onClick={() => handleFilterOpened()}
      >
        <div className="d-flex">
          <FilterLines className="me-2" />
          Filter by
        </div>
        {showFilterMenu && (
          <div>
            <XClose />
            <span className="visually-hidden">Close filter</span>
          </div>
        )}
      </Button>
      <Collapse
        in={showFilterMenu}
        onEnter={() => {
          getOrgUserTags();
        }}
        onExited={() => handleFilterClosed()}
      >
        <div
          className={cn([
            styles['filter-menu'],
            'bg-white rounded-3 shadow-sm px-4 position-relative',
          ])}
        >
          <div className="hr-divider" />
          {tags.length > 0 && UserTagFacet}
          <div className="hr-divider" />
          <div id="FilterFooter" className="py-3 fs-4 fw-medium">
            <div className="d-flex justify-content-end align-items-center">
              <Button size="sm" variant="tertiary" onClick={() => clearAll()}>
                <XClose className="me-2" />
                Clear Selection
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default ParticipantsFilter;
