import { ORGANIZATION_ADMIN } from 'constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
  EVENT_TYPE_OPTIONS,
  LOTTERY_STYLE_OPTIONS,
  UNCLAIMED_TICKET_OPTIONS,
} from 'constants/addLottery';
import useUser from 'hooks/useUser';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import HeroImage from 'components/HeroImage/HeroImage';
import Avatar from 'components/Avatar';
import LotteryStatusPill from 'components/LotteryStatusPill';
import TabbedMenu from 'components/TabbedMenu';
import { UserRoleAdmin } from 'components/UserRole';
import ProgressBar from 'components/ProgressBar';
import ConsoleLogger from 'utils/logger';
import colorMap from 'utils/backgroundColorMapper';
import localDateTime from 'utils/localTime';
import cn from 'utils/classNames';
import usePageTracking from 'hooks/usePageTracking';

import ArrowLeft from 'assets/icons/arrow-left.svg';
import MarkerIcon from 'assets/icons/marker-pin.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import TicketIcon from 'assets/icons/ticket-tiny.svg';
import TicketGradient from 'assets/icons/ticket-gradient.svg';

import styles from './SeasonLotteryDetail.module.scss';

const consoleLogger = ConsoleLogger.getInstance();

const PAGE_SIZE = 10;

const lotteryDetailMenuItems = [
  {
    path: 'event-schedule',
    label: 'Event Schedule',
  },
  {
    path: 'tickets',
    label: 'Tickets',
  },
  {
    path: 'participants',
    label: 'Participants',
  },
];

function SeasonLotteryDetail() {
  const [lottery, setLottery] = useState({
    title: '',
    description: '',
    logo: '',
    coverPhoto: '',
    venueName: '',
    venueUrl: '',
    startDateTime: '',
    endDateTime: '',
    status: '',
    claimedTickets: [],
    claimedTicketsPercentage: 0,
    tickets: [],
    eventIds: [],
    lotteryStyleCode: '',
    unclaimedTicketMode: '',
    eventTypeCode: '',
  });
  const [lotteryEvents, setLotteryEvents] = useState([]);
  const [lotteryEventsTotal, setLotteryEventsTotal] = useState(0);
  const [lotteryEventsPageTotal, setLotteryEventsPageTotal] = useState(0);
  const [lotteryEventsCurrentPage, setLotteryEventsCurrentPage] = useState(1);
  const [lotteryTickets, setLotteryTickets] = useState({});
  const [lotteryTicketsTotal, setLotteryTicketsTotal] = useState(0);
  const [lotteryTicketsPageTotal, setLotteryTicketsPageTotal] = useState(0);
  const [lotteryTicketsCurrentPage, setLotteryTicketsCurrentPage] = useState(1);
  const [lotteryParticipants, setLotteryParticipants] = useState({});
  const [lotteryParticipantsTotal, setLotteryParticipantsTotal] = useState(0);
  const [lotteryParticipantsPageTotal, setLotteryParticipantsPageTotal] =
    useState(0);
  const [lotteryParticipantsCurrentPage, setLotteryParticipantsCurrentPage] =
    useState(1);
  const { organizationId, organizationUserRole } = useUser();
  usePageTracking('season-lottery-detail');
  const { lotteryId } = useParams();

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.lotteries.scopes.access_as_user,
  });

  const getLottery = useCallback(async () => {
    try {
      const res = await execute(
        'GET',
        `${protectedResources.lotteries.endpoint}/getLottery/${lotteryId}`,
      );
      const {
        id,
        title,
        description,
        logo,
        coverPhoto,
        venueName,
        venueUrl,
        startDateTime,
        endDateTime,
        status,
        tickets,
        eventIds,
        lotteryStyleCode,
        unclaimedTicketMode,
        eventTypeCode,
      } = res;
      const localStartDate = localDateTime(startDateTime);
      const localEndDate = localDateTime(endDateTime);
      const claimedTickets = tickets.filter((ticket) => ticket.isClaimed);
      const claimedTicketsPercentage =
        (claimedTickets.length / tickets.length) * 100;
      setLottery({
        id,
        title,
        description,
        logo,
        coverPhoto,
        venueName,
        venueUrl,
        startDateTime: format(localStartDate, 'MMMM do, yyyy'),
        endDateTime: format(localEndDate, 'MMMM do, yyyy'),
        status,
        claimedTickets,
        claimedTicketsPercentage,
        tickets,
        eventIds,
        lotteryStyleCode,
        unclaimedTicketMode,
        eventTypeCode,
      });
    } catch (err) {
      consoleLogger.error(JSON.stringify(err));
    }
  }, [lotteryId, execute]);

  const getLotteryEvents = useCallback(async () => {
    const payload = {
      OrganizationId: organizationId,
      LotteryId: lotteryId,
      Skip: (lotteryEventsCurrentPage - 1) * PAGE_SIZE,
      Take: PAGE_SIZE,
    };
    try {
      const res = await execute(
        'POST',
        `${protectedResources.events.endpoint}/byLotteryId`,
        payload,
      );
      setLotteryEvents(res?.events);
      setLotteryEventsTotal(res?.count);
      setLotteryEventsPageTotal(
        Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
      );
    } catch (err) {
      consoleLogger.error(JSON.stringify(err));
    }
  }, [lotteryId, execute, organizationId, lotteryEventsCurrentPage]);

  const getLotteryTickets = useCallback(async () => {
    const payload = {
      OrganizationId: organizationId,
      LotteryId: lotteryId,
      Skip: (lotteryTicketsCurrentPage - 1) * PAGE_SIZE,
      Take: PAGE_SIZE,
    };
    try {
      const res = await execute(
        'POST',
        `${protectedResources.tickets.endpoint}/byLotteryId`,
        payload,
      );
      setLotteryTickets(
        res?.tickets.map((ticket) => ({ ...ticket, id: ticket.ticketId })),
      );
      setLotteryTicketsTotal(res?.count);
      setLotteryTicketsPageTotal(
        Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
      );
    } catch (err) {
      consoleLogger.error(JSON.stringify(err));
    }
  }, [lotteryId, execute, organizationId, lotteryTicketsCurrentPage]);

  const getLotteryParticipants = useCallback(async () => {
    // Define the select fields for the OData query
    const select = `FirstName, LastName, Email, ProfilePhoto, UserTag, PriorityGroup`;
    // Set the number of records to retrieve (top), and calculate the number to skip based on pagination
    const top = PAGE_SIZE;
    const skip = (lotteryParticipantsCurrentPage - 1) * top;
    // Join the sorting criteria into a string for the 'orderBy' part of the query
    const orderBy = 'FirstName asc';

    try {
      // TODO: backend integrtation must be fixed, currently we don't have count returned to odata spec
      const res = await execute(
        'GET',
        `${protectedResources.lotteries.endpoint}/${lotteryId}/participants?$select=${select}&$top=${top}&$skip=${skip}&$orderBy=${orderBy}&$count=true`,
      );
      // TODO: get these back in gear!
      setLotteryParticipantsPageTotal(
        Math.ceil((res['@odata.count'] * 100) / (top * 100)),
      );
      setLotteryParticipantsTotal(res['@odata.count']);
      setLotteryParticipants(res?.value);
    } catch (err) {
      consoleLogger.error(JSON.stringify(err));
    }
  }, [lotteryId, execute, lotteryParticipantsCurrentPage]);

  // Separate effects for each fetch to avoid unnecessary multiple fetches
  useEffect(() => {
    getLottery();
  }, [getLottery]);

  useEffect(() => {
    if (organizationUserRole === ORGANIZATION_ADMIN) {
      getLotteryEvents();
    }
  }, [getLotteryEvents, organizationUserRole]);

  useEffect(() => {
    if (organizationUserRole === ORGANIZATION_ADMIN) {
      getLotteryTickets();
    }
  }, [getLotteryTickets, organizationUserRole]);

  useEffect(() => {
    if (organizationUserRole === ORGANIZATION_ADMIN) {
      getLotteryParticipants();
    }
  }, [getLotteryParticipants, organizationUserRole]);

  const unclaimedOption = UNCLAIMED_TICKET_OPTIONS.find(
    (option) => option.value === lottery.unclaimedTicketMode,
  );
  const UnclaimedOptionIcon = unclaimedOption?.Icon;

  // Find the lottery style option that matches the chosen lottery style code
  const lotteryStyleOption = LOTTERY_STYLE_OPTIONS.find(
    (option) => option.value === lottery.lotteryStyleCode,
  );
  // Set the values for lottery style display
  const lotteryStyleTitle = lotteryStyleOption?.title;
  const lotteryStyleDescription = lotteryStyleOption?.description;
  const LotteryStyleOptionIcon = lotteryStyleOption?.Icon;

  const eventOption = EVENT_TYPE_OPTIONS.find(
    (option) => option.value === lottery.eventTypeCode,
  );
  const eventOptionLabel = `${eventOption?.label} ${eventOption?.taxonomy}${
    lottery.eventIds?.length > 1 ? 's' : ''
  }`;

  return (
    <>
      <div id="SeasonLotteryDetailHero" />
      <HeroImage
        altBackgroundColor={colorMap(lottery.title || '. .')}
        imgSrc={lottery.coverPhoto ?? null}
        className={styles['detail-hero']}
      />
      <div
        className={cn([
          styles['detail-content'],
          'd-flex flex-column align-items-start justify-content-start mb-12',
        ])}
      >
        <div
          id="SeasonLotteryDetailHeader"
          className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
        >
          <div className="d-block d-lg-flex w-100 align-items-start justify-content-between mt-8 mt-lg-0">
            <div>
              {!lottery.title ? (
                <Avatar
                  size="xl"
                  imgSrc={null}
                  altText=". ."
                  className={styles['detail-logo']}
                />
              ) : (
                <Avatar
                  size="xl"
                  imgSrc={lottery.logo ?? null}
                  altText={lottery.title}
                  className={styles['detail-logo']}
                />
              )}
            </div>
            <div className="flex-grow-1 ps-lg-6 mt-6">
              <Link
                className="d-flex align-items-center fs-4 fw-semibold"
                to="/season-lotteries"
              >
                <ArrowLeft className="me-3" />
                <span>Back to Season Lotteries</span>
              </Link>
              <LotteryStatusPill status={lottery?.status} className="mt-4" />
              <div id="LotteryDescription">
                <h1 className="h5 fw-bold mt-4">{lottery?.title}</h1>
                <p className="fs-3 fw-medium text-gray mb-1 mt-2">
                  {lottery?.startDateTime} to {lottery?.endDateTime}
                </p>
                <p className="text-gray-light mb-0">{lottery?.description}</p>
              </div>
              <div
                id="LotteryInfo"
                className="d-flex gap-8 align-items-center justify-content-start"
              >
                <div>
                  <MarkerIcon className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {lottery.venueName}
                  </span>
                </div>
                <div>
                  <CalendarIcon className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {lottery.eventIds?.length} {eventOptionLabel}
                  </span>
                </div>
                <div>
                  <TicketIcon className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {lottery.tickets?.length}{' '}
                    {lottery.tickets?.length !== 1 ? 'Tickets' : 'Ticket'}
                  </span>
                </div>
                <div
                  className={cn([styles['progress-wrapper'], 'flex-grow-1'])}
                >
                  <div className="bg-gray-lightest rounded-3 p-4 d-flex align-items-center shadow-sm">
                    <div
                      className={cn([
                        styles['ticket-icon-wrapper'],
                        'd-flex align-items-center justify-content-center bg-white rounded-circle me-3',
                      ])}
                    >
                      <TicketGradient />
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between">
                        <span className="fs-4 fw-medium text-gray">
                          Tickets Claimed
                        </span>
                        <span>
                          {lottery?.claimedTickets?.length}/
                          {lottery?.tickets?.length}
                        </span>
                      </div>
                      <ProgressBar
                        progress={lottery.claimedTicketsPercentage}
                        contentClassNames={styles['custom-progress-bar']}
                        wrapperClassNames="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="LotteryRules" className="d-flex gap-6 mt-6">
                <div id="LotteryStyle">
                  <p className="mb-2 fs-4 fw-medium text-gray">Lottery Style</p>
                  <div
                    id="LotteryStyleCard"
                    className="d-flex border justify-content-between border-gray-lighter p-4 rounded-3"
                  >
                    <div
                      className="bg-gray-lighter d-flex align-items-center rounded-circle p-2 border border-2 border-gray-lightest"
                      style={{ width: 32, height: 32 }}
                    >
                      {LotteryStyleOptionIcon && (
                        <LotteryStyleOptionIcon className="text-gray-darker" />
                      )}
                    </div>
                    <div className="ps-4 pe-1 flex-grow-1">
                      <p className={cn(['text-gray-dark fw-medium mb-0'])}>
                        {lotteryStyleTitle}
                      </p>
                      <p className="mb-0">{lotteryStyleDescription}</p>
                    </div>
                  </div>
                </div>
                <div id="UnclaimedOptionCard">
                  <p className="mb-2 fs-4 fw-medium text-gray mb-2">
                    Unclaimed Ticket Allocation
                  </p>
                  <div
                    id={`${unclaimedOption?.value}-card`}
                    className="d-flex border justify-content-between border-gray-lighter p-4 rounded-3"
                  >
                    <div
                      className="bg-gray-lighter d-flex align-items-center rounded-circle p-2 border border-2 border-gray-lightest"
                      style={{ width: 32, height: 32 }}
                    >
                      {UnclaimedOptionIcon && (
                        <UnclaimedOptionIcon className="text-gray-darker" />
                      )}
                    </div>
                    <div className="ps-4 pe-1 flex-grow-1">
                      <p className={cn(['text-gray-dark fw-medium mb-0'])}>
                        {unclaimedOption?.title}
                      </p>
                      <p className="mb-0">{unclaimedOption?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserRoleAdmin userType={organizationUserRole}>
          <div className="py-8 px-lg-8 px-4 w-100">
            <div className="hr-divider" />
          </div>
          <div
            id="SeasonLotteryDetailBody"
            className="gap-6 d-flex align-items-start px-lg-8 px-4 flex-column w-100"
          >
            <TabbedMenu items={lotteryDetailMenuItems} />

            <Outlet
              context={{
                lotteryId,
                // Lottery events state
                lotteryEvents,
                lotteryEventsCurrentPage,
                setLotteryEventsCurrentPage,
                lotteryEventsTotal,
                lotteryEventsPageTotal,
                // Lottery tickets state
                lotteryTickets,
                lotteryTicketsCurrentPage,
                setLotteryTicketsCurrentPage,
                lotteryTicketsTotal,
                lotteryTicketsPageTotal,
                // Lottery tickets state
                lotteryParticipants,
                lotteryParticipantsCurrentPage,
                setLotteryParticipantsCurrentPage,
                lotteryParticipantsTotal,
                lotteryParticipantsPageTotal,
                isLoading,
              }}
            />
          </div>
        </UserRoleAdmin>
      </div>
    </>
  );
}

export default SeasonLotteryDetail;
