import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Outlet } from 'react-router-dom';
import TabbedMenu from 'components/TabbedMenu';
import ParticipantActions from 'components/ParticipantActions';
import Avatar from 'components/Avatar';
import ParticipantsFilter from 'components/ParticipantsFilter';
import ConsoleLogger from 'utils/logger';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import useUser from 'hooks/useUser';
import { protectedResources } from 'authConfig';

import UserPlus from 'assets/icons/user-plus.svg';

import InviteParticipantsModal from './InviteParticipantsModal';

const FIRST_NAME = 'FirstName';
const TAGS = 'Tags';
const EMAIL = 'Email';
const ADDITIONAL_DETAILS = 'AdditionalDetails';

const tableColumns = {
  id: 'Id',
  name: { label: 'Name', sortBy: FIRST_NAME },
  userTag: { label: 'User Tag', sortBy: TAGS },
  details: { label: 'Additional Details', sortBy: ADDITIONAL_DETAILS },
  lotteries: 'Lottery',
  actions: 'Actions',
};

const invitedParticipantsTableColumns = {
  id: 'Id',
  name: { label: 'Email', sortBy: EMAIL },
  userTag: { label: 'User Tag', sortBy: TAGS },
  details: { label: 'Additional Details', sortBy: ADDITIONAL_DETAILS },
  lotteries: 'Lottery',
  actions: 'Actions',
};

function makeParticipantRow({
  id,
  firstName,
  lastName,
  avatar,
  email,
  tag,
  details,
  lotteries,
  setShouldFetch,
}) {
  return {
    id,
    name: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={avatar}
          altText={`${firstName.charAt(0)}${lastName.charAt(0)}`}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">
            {firstName} {lastName}
          </span>{' '}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    userTag: () => <span className="badge user-tag rounded-pill">{tag}</span>,
    details,
    lotteries,
    actions: (
      <ParticipantActions
        id={id}
        setShouldFetch={setShouldFetch}
        firstName={firstName}
        lastName={lastName}
        email={email}
        activeParticipant
      />
    ),
  };
}

function makeInvitedParticipantRow({
  id,
  email,
  tag,
  details,
  lotteries,
  companyName,
  setShouldFetch,
  firstName,
  lastName,
}) {
  return {
    id,
    name: () => (
      <div className="d-flex align-items-center">
        <div>
          <span className="fw-medium">{companyName}</span> <br />
          <a className="fw-medium" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
    ),
    userTag: () => <span className="badge user-tag rounded-pill">{tag}</span>,
    details,
    lotteries,
    actions: (
      <ParticipantActions
        id={id}
        setShouldFetch={setShouldFetch}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
    ),
  };
}

function ManageParticipants() {
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isParticipantsLoading, setIsParticipantsLoading] = useState();
  // This allows us to force the a fetch when a record is updated
  const [shouldFetch, setShouldFetch] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);

  const [invitedParticipants, setInvitedParticipants] = useState([]);
  const [invitedParticipantsTotal, setInvitedParticipantsTotal] = useState(0);
  const [pageInviteParticipantsTotal, setPageInviteParticipantsTotal] =
    useState(0);

  const [tagsToFilter, setTagsToFilter] = useState([]);
  const [sortBy, setSortBy] = useState([FIRST_NAME, 'asc']);
  const { organizationId } = useUser();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  // TODO: remove or use for invited participants sorting init fix
  // const location = useLocation();
  // const currentPath = location.pathname;

  const participantMenuItems = [
    {
      path: 'all-participants',
      label: 'Active Participants',
      badge: totalParticipants,
    },
    {
      path: 'invited-participants',
      label: 'Invited Participants',
      badge: invitedParticipantsTotal,
    },
  ];

  const getAllInvitedParticipants = useCallback(async () => {
    const filter = [`isaccountcreated eq false`];
    if (tagsToFilter.length) {
      const tagsFilterExpression = tagsToFilter
        .map((tag) => `contains(Tags, '${tag.trim()}')`)
        .join(' or ');
      filter.push(tagsFilterExpression);
    }

    const top = 10;
    const skip = (currentPage - 1) * top;
    const orderBy = sortBy.join(' ');

    try {
      setIsParticipantsLoading(true);
      const res = await execute(
        'GET',
        `${
          protectedResources.participants.endpoint
        }/${organizationId}?$filter=${filter.join(
          ' and ',
        )}&$top=${top}&$skip=${skip}&$orderBy=${orderBy}&$count=true`,
      );
      setPageInviteParticipantsTotal(
        Math.ceil((res['@odata.count'] * 100) / (top * 100)),
      );
      setInvitedParticipantsTotal(res['@odata.count']);
      setInvitedParticipants(
        res?.value?.map((participant) => ({
          ...makeInvitedParticipantRow({
            id: participant?.OrganizationUserId,
            companyName: participant?.CompanyName,
            email: participant?.Email,
            tag: participant?.Tags,
            details: participant?.AdditionalDetails,
            lotteries: '',
            setShouldFetch,
          }),
        })),
      );
    } catch (err) {
      ConsoleLogger.error(err);
      setInvitedParticipants([]);
    } finally {
      setIsParticipantsLoading(false);
      setShouldFetch(false);
    }
  }, [currentPage, execute, organizationId, sortBy, tagsToFilter]);

  const getAllParticipants = useCallback(async () => {
    const filter = [`isaccountcreated eq true`];
    if (tagsToFilter.length) {
      // Create a filter expression for each tag using the 'contains' function
      const tagsFilterExpression = tagsToFilter
        .map((tag) => `contains(Tags, '${tag.trim()}')`)
        .join(' or ');
      // Add the tags filter expression to the overall filter array
      filter.push(tagsFilterExpression);
    }
    // Define the select fields for the OData query
    const select = `OrganizationUserId, ${FIRST_NAME}, LastName, Email, ${TAGS}, ProfileImageUrl, ${ADDITIONAL_DETAILS}`;
    // Set the number of records to retrieve (top), and calculate the number to skip based on pagination
    const top = 10;
    const skip = (currentPage - 1) * top;
    // Join the sorting criteria into a string for the 'orderBy' part of the query
    const orderBy = sortBy.join(' ');

    try {
      setIsParticipantsLoading(true);
      const res = await execute(
        'GET',
        `${
          protectedResources.participants.endpoint
        }/${organizationId}?$filter=${filter.join(
          ' and ',
        )}&$select=${select}&$top=${top}&$skip=${skip}&$orderBy=${orderBy}&$count=true`,
      );
      setPageTotal(Math.ceil((res['@odata.count'] * 100) / (top * 100)));
      setTotalParticipants(res['@odata.count']);
      setParticipants(
        res?.value?.map((participant) => ({
          ...makeParticipantRow({
            id: participant?.OrganizationUserId,
            avatar: participant?.ProfileImageUrl,
            firstName: participant?.FirstName,
            lastName: participant?.LastName,
            email: participant?.Email,
            tag: participant?.Tags,
            details: participant?.AdditionalDetails,
            lotteries: '',
            setShouldFetch,
          }),
        })),
      );
    } catch (err) {
      ConsoleLogger.error(err);
      setParticipants([]);
    } finally {
      setIsParticipantsLoading(false);
      setShouldFetch(false);
    }
  }, [execute, organizationId, currentPage, tagsToFilter, sortBy]);

  const handleCloseInvitationModal = () => {
    setShowInvitationModal(false);
    // Trigger the fetch to display newly invited participants on modal close
    setShouldFetch(true);
  };
  const handleShowInvitationModal = () => setShowInvitationModal(true);
  const handleSortBy = (columnToSort) => {
    const [sortedColumn, sortedDirection] = sortBy;
    let directionToSort = 'asc';
    if (columnToSort === sortedColumn) {
      directionToSort = sortedDirection === 'asc' ? 'desc' : 'asc';
    }
    setSortBy([columnToSort, directionToSort]);
  };

  useEffect(() => {
    // TODO FIX SORTING. CAUSING SIDE EFFECT INFINITE LOOP RE-RENDER:
    // if (currentPath.includes(participantMenuItems[1].path)) {
    //   handleSortBy(EMAIL);
    // }
    getAllParticipants();
    getAllInvitedParticipants();
    // We're watching should fetch to get all participants and unapproved participants
  }, [shouldFetch, getAllParticipants, getAllInvitedParticipants]);

  useEffect(() => {
    setCurrentPage(1);
    // If participants get filtered we need to set the current page back to 1
  }, [tagsToFilter]);

  return (
    <div className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12">
      <section className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100">
        <div className="d-block d-lg-flex gap-3 w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Manage Participants</h1>
            <p className="text-gray mb-0">
              Effectively oversee and manage user profiles, prioritize access,
              and control lottery participation.
            </p>
            <div className="mt-6">
              <TabbedMenu items={participantMenuItems} />
            </div>
          </div>
          <Button
            className="d-none d-lg-block text-nowrap"
            variant="primary"
            size="xxl"
            onClick={handleShowInvitationModal}
          >
            <UserPlus className="me-3" />
            Add Participants
          </Button>
        </div>
      </section>

      <section className="mt-8 px-lg-8 px-4 w-100">
        <ParticipantsFilter
          tagsToFilter={tagsToFilter}
          setTagsToFilter={setTagsToFilter}
        />
      </section>

      <section className="gap-6 d-flex align-items-start px-lg-8 px-0 flex-column w-100 mt-8">
        <Outlet
          context={{
            handleShowInvitationModal,
            totalParticipants,
            isParticipantsLoading,
            participants,
            tableColumns,
            invitedParticipantsTableColumns,
            currentPage,
            invitedParticipants,
            setCurrentPage,
            pageTotal,
            pageInviteParticipantsTotal,
            invitedParticipantsTotal,
            sortBy,
            handleSortBy,
          }}
        />
      </section>
      <InviteParticipantsModal
        show={showInvitationModal}
        handleClose={handleCloseInvitationModal}
      />
    </div>
  );
}

export default ManageParticipants;
