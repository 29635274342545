import React from 'react';
import { Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';

import UploadCloud from 'assets/icons/upload-cloud.svg';
import UserPlus from 'assets/icons/user-plus.svg';
import usePageTracking from 'hooks/usePageTracking';

function AllParticipants() {
  const {
    handleShowInvitationModal,
    totalParticipants,
    isParticipantsLoading,
    setCurrentPage,
    participants,
    tableColumns,
    currentPage,
    pageTotal,
    sortBy,
    handleSortBy,
  } = useOutletContext();
  usePageTracking('all-participants');

  return (
    <TableFrame>
      <TableHeader
        title="Active Participants"
        totalItems={totalParticipants}
        description="View and manage profiles for all participants in the system."
      >
        <Button
          id="UploadParticipantsDesktop"
          className="d-none d-lg-block"
          variant="outline-primary"
          onClick={handleShowInvitationModal}
        >
          <UploadCloud className="me-2" />
          Upload New Participants
        </Button>
        <Button
          id="AddParticipantsMobile"
          className="d-block d-lg-none"
          variant="primary"
          onClick={handleShowInvitationModal}
        >
          <UserPlus className="me-2" />
          Add Participants
        </Button>
      </TableHeader>
      <TableBody
        columns={tableColumns}
        rows={participants}
        sortBy={sortBy}
        handleSortBy={handleSortBy}
        isLoading={isParticipantsLoading}
        noRowsMessage={
          isParticipantsLoading
            ? 'Loading Participants...'
            : 'No Participants Found'
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        NoRowsCTA={() =>
          !isParticipantsLoading && (
            <Button
              id="UploadParticipantsDesktop"
              variant="outline-primary"
              onClick={handleShowInvitationModal}
            >
              <UploadCloud className="me-2" />
              Upload New Participants
            </Button>
          )
        }
      />
      <TableFooter
        currentPage={currentPage}
        pageTotal={pageTotal}
        setCurrentPage={setCurrentPage}
      />
    </TableFrame>
  );
}

export default AllParticipants;
