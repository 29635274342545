import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import {
  NO_SUBSCRIPTION_PLAN_NAME,
  NO_SUBSCRIPTION_REDIRECT_PATH,
} from '../../constants';

function ActiveSubscriptionRequired({ children }) {
  const { companyPlan } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [hasActivePlan, setHasActivePlan] = useState(true);

  useEffect(() => {
    if (companyPlan.planName === NO_SUBSCRIPTION_PLAN_NAME) {
      setHasActivePlan(false);

      navigate(NO_SUBSCRIPTION_REDIRECT_PATH, {
        state: { from: location.pathname },
      });
    }
  }, [companyPlan.planName, navigate, location.pathname]);

  return hasActivePlan ? children : null;
}

export default ActiveSubscriptionRequired;
