import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import useUser from './useUser';

const hashCode = (string) => {
  let hash = 0;
  let i;
  let chr;
  if (string.length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Handles a generic gtm event
 * @param {string} pageType
 * @returns {void}
 */
export default function usePageTracking(pageType) {
  const { organizationUserId, profile } = useUser();
  useEffect(() => {
    if (organizationUserId && profile?.email && pageType) {
      TagManager.dataLayer({
        dataLayer: {
          page_type: pageType,
          user_email: hashCode(profile?.email),
          user_id: organizationUserId,
        },
      });
    }
  }, [organizationUserId, profile?.email, pageType]);
}
