/* eslint-disable react/self-closing-comp */
import React from 'react';
// import PlaceholderCircle from 'assets/icons/placeholder-circle.svg';

function FormGuide() {
  return (
    <div className="form-group">
      <label htmlFor="some-text" className="form-label">
        Your Text
      </label>
      <div className="d-flex align-items-center">
        <input
          type="text"
          id="some-text"
          name="some-text"
          className="form-control"
        />
      </div>
      <div className="form-helper text-danger">
        This is a hint text to help user
      </div>
    </div>
  );
}
export default FormGuide;
