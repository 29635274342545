import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Dropdown, Form, InputGroup, Modal } from 'react-bootstrap';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import ActionDots from '../../assets/icons/action-dots.svg';
import styles from './Tickets.module.scss';
import AddCalendarIcon from '../../assets/icons/calendar-tiny-v2.svg';
import SendEmailIcon from '../../assets/icons/email.svg';

function ClaimedTicketAction({ EventId, location, name, startDate }) {
  const [showModal, setShowModal] = useState(false);
  const [Email, setEmail] = useState('');

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.tickets.scopes.access_as_user,
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleAddCalendar = () => {
    const formatNumericDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    };

    const endDate = new Date(startDate);
    endDate.setHours(endDate.getHours() + 4);

    const icsContent = `
BEGIN:VCALENDAR
CALSCALE:GREGORIAN
METHOD:PUBLISH
PRODID:-//Test Cal//EN
VERSION:2.0
BEGIN:VEVENT
UID:test-1
DTSTART;VALUE=DATE:${formatNumericDate(new Date(startDate))}
DTEND;VALUE=DATE:${endDate}
SUMMARY:${name}
DESCRIPTION:${location}
END:VEVENT
END:VCALENDAR`;

    const icsFile = new File([icsContent], {
      type: 'text/plain',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(icsFile);
    link.setAttribute('download', 'event.ics');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShareDetails = async () => {
    const payload = {
      EventId,
      Email,
    };
    await execute(
      'POST',
      `${protectedResources.events.endpoint}/share-details`,
      payload,
    );
    handleCloseModal();
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className={styles['dropdown-toggle']}
          id={`action-button-${EventId}`}
          variant="link"
        >
          <span className="visually-hidden">Additional Actions</span>
          <ActionDots aria-hidden />
        </Dropdown.Toggle>
        <Dropdown.Menu role="menu" aria-label="Actions" as="ul">
          <Dropdown.Item className="" as="li">
            <Button
              id={`view-lottery-${EventId}`}
              className="me-3 w-100"
              variant="text"
              onClick={() => handleAddCalendar()}
            >
              <AddCalendarIcon />
              Add to calendar
            </Button>
          </Dropdown.Item>
          <Dropdown.Item className="" as="li">
            <Button
              id={`view-lottery-${EventId}`}
              className="me-3 w-100"
              variant="text"
              onClick={() => {
                handleOpenModal();
              }}
            >
              <SendEmailIcon />
              Share ticket details
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share your ticket details!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="email" className="w-100">
            <Form.Label className="fs-4 text-secondary-emphasis">
              Email
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <SendEmailIcon />
              </InputGroup.Text>
              <Form.Control onChange={(e) => setEmail(e.target.value)} />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="primary" onClick={handleShareDetails}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClaimedTicketAction;
