import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import FaqIcon from '../../assets/icons/faq-icon.svg';

const corporateAccount =
  "To sign up a corporate account, please join our interest list first. When our web application is live you'll be the first to know and we'll provide a sign up link then!";
const ticketsForCompany =
  'Adding season tickets is a breeze. Our web app allows you to directly input season ticket information when creating a lottery, or copy & pasting ticketing information from another document.';
const seasonTicketLottery =
  'The season ticket lottery works in different ways depending on the plan you have. However, overall the lottery when running will email groups of participants at randomized times when their pick window is open. Once pick windows are open, users have the ability to go to their AllSeater dashboard to view, claim, & confirm all tickets still available in a lottery.';
const priorityGroups =
  'Priority groups come with the VIP or Box Suite plan and they are an extra feature to lotteries. With priority groups, Admins can bucket sets of users into ranked tiers to give them additional preference when a lottery runs. The higher the priority group, the sooner you will receive your "pick window" email.';
const sendTicketsToEmployees =
  'Tickets are still sent through your ticket partner platform. However, with AllSeater Admins & participants are both notified when tickets are ready to send to users leading up to the event date. Once tickets are sent, you can update the ticket status in AllSeater & an additional notification will be sent.';
const unused =
  'AllSeater provides a plethora of options for tickets that go unclaimed during a lottery. Tickets can go into a "Free-For-All" state so anybody can claim, go into "Hold" state to be used at another time, or re-distributed to our vast network of donation partners.';

function AdminFaq({ title, text }) {
  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div
        className="d-flex align-items-center justify-content-center bg-yellow-light rounded-circle mb-4"
        style={{ width: 56, height: 56 }}
      >
        <div className="mb-1" style={{ width: 24, height: 24 }}>
          <FaqIcon className="text-yellow-dark" />
        </div>
      </div>
      <h3 className="fs-1 fw-semibold lh-base mb-2 text-center">{title}</h3>
      <p className="rich-text text-center">{text}</p>
    </div>
  );
}

function AdminSupport() {
  usePageTracking('admin-support');
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
      <div className="d-flex flex-column flex-lg-row justify-content-center gap-16 text-center">
        <AdminFaq
          title="How do I set up a corporate ticket management account?"
          text={corporateAccount}
        />
        <AdminFaq
          title="How do I add season tickets for my company?"
          text={ticketsForCompany}
        />
        <AdminFaq
          title="How does the season ticket lottery work?"
          text={seasonTicketLottery}
        />
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-center gap-16 text-center mt-lg-4">
        <AdminFaq title="What are priority groups?" text={priorityGroups} />
        <AdminFaq
          title="How do I send tickets to employees?"
          text={sendTicketsToEmployees}
        />
        <AdminFaq
          title="What happens to unused season tickets?"
          text={unused}
        />
      </div>

      <p className="text-gray mt-12 px-8 p-4 border rounded">
        For further questions or concerns please contact{' '}
        <a href="mailto:gary@allseater.com">gary@allseater.com</a>
      </p>
    </div>
  );
}

export default AdminSupport;
