import { ORGANIZATION_ADMIN } from 'constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { protectedResources } from 'authConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import localDateTime from 'utils/localTime';
import ConsoleLogger from 'utils/logger';
import cn from 'utils/classNames';
import useUser from 'hooks/useUser';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import { PRIORITY_STYLE_VALUE } from 'constants/addLottery';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import Avatar from 'components/Avatar';
import TicketStatusDropDown from 'pages/admin-dashboard/TicketStatusDropdown';
import HeroImage from 'components/HeroImage/HeroImage';
import LotteryStylePill from 'components/LotteryStylePill';
import LotteryStatusPill from 'components/LotteryStatusPill';
import { UserRoleAdmin, UserRoleParticipant } from 'components/UserRole';
import backgroundColorMapper from 'utils/backgroundColorMapper';

import ArrowLeft from 'assets/icons/arrow-left.svg';
import MarkerIcon from 'assets/icons/marker-pin.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import Clock from 'assets/icons/clock.svg';

import styles from './EventDetails.module.scss';

const consoleLogger = ConsoleLogger.getInstance();
const PAGE_SIZE = 10;

function makeUpcomingTicketRow({
  id: ticketId,
  profilePhoto,
  firstName,
  lastName,
  email,
  priorityGroupIndex,
  section,
  row,
  seat,
  isConfirmed,
  isDispersed,
}) {
  const claimed = !isConfirmed && !isDispersed;
  return {
    id: ticketId,
    ticketHolder: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={profilePhoto}
          altText={`${firstName.charAt(0)}${lastName.charAt(0)}`}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">
            {firstName} {lastName}
          </span>{' '}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    priorityGroup: (
      <PriorityGroupBadge priorityGroup={{ groupIndex: priorityGroupIndex }} />
    ),
    section,
    row,
    seat,
    ticketStatus: (
      <TicketStatusDropDown
        isConfirmed={isConfirmed}
        isDispersed={isDispersed}
        isClaimed={claimed}
        ticketId={ticketId}
      />
    ),
  };
}

function EventDetail() {
  const { organizationId, organizationUserRole } = useUser();
  const { eventId } = useParams();
  const [event, setEvent] = useState({
    id: '',
    eventName: '',
    eventDateTime: '',
    additionalDetails: '',
    lotteryId: '',
    lotteryLogo: '',
    lotteryCoverPhoto: '',
    lotteryEndDateTime: '',
    lotteryStartDateTime: '',
    lotteryVenueName: '',
    lotteryStyle: '',
    lotteryStatus: '',
  });
  const [claimedTickets, setClaimedTickets] = useState({});
  const [claimedTicketsTotal, setClaimedTicketsTotal] = useState(0);
  const [claimedTicketsPageTotal, setClaimedTicketsPageTotal] = useState(0);
  const [claimedTicketsCurrentPage, setClaimedTicketsCurrentPage] = useState(1);

  const { execute, isLoading, error } = useFetchWithMsal({
    scopes: protectedResources.lotteries.scopes.access_as_user,
  });

  const getEvent = useCallback(async () => {
    try {
      const res = await execute(
        'GET',
        `${protectedResources.events.endpoint}/organization/${organizationId}/event/${eventId}`,
      );
      const {
        id,
        eventName,
        eventDateTime,
        lotteryId,
        lotteryLogo,
        lotteryCoverPhoto,
        lotteryStartDateTime,
        lotteryEndDateTime,
        lotteryVenueName,
        lotteryStyle,
        lotteryStatus,
      } = res;
      const localLotteryStartDateTime = localDateTime(lotteryStartDateTime);
      const localLotteryEndDateTime = localDateTime(lotteryEndDateTime);
      const localEventDateTime = localDateTime(eventDateTime);

      setEvent({
        id,
        eventName,
        eventDateTime: localEventDateTime,
        lotteryId,
        lotteryLogo,
        lotteryCoverPhoto,
        lotteryStartDateTime: localLotteryStartDateTime,
        lotteryEndDateTime: localLotteryEndDateTime,
        lotteryVenueName,
        lotteryStyle,
        lotteryStatus,
      });
    } catch (err) {
      consoleLogger.error(JSON.stringify(err));
    }
  }, [eventId, organizationId, execute]);

  const fetchClaimedTickets = useCallback(async () => {
    const payload = {
      organizationId,
      skip: (claimedTicketsCurrentPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
      eventId,
    };

    const res = await execute(
      'POST',
      `${protectedResources.tickets.endpoint}/upcoming`,
      payload,
    );

    setClaimedTicketsTotal(res.count);
    setClaimedTicketsPageTotal(
      Math.ceil((res.count * 100) / (PAGE_SIZE * 100)),
    );
    const tickets = res?.tickets || [];
    const ticketsLocalized = tickets.map((ticket) => ({
      ...ticket,
      eventDate: localDateTime(ticket.eventDate),
    }));
    setClaimedTickets(ticketsLocalized);
  }, [execute, eventId, organizationId, claimedTicketsCurrentPage]);

  const claimedTicketColumns = useMemo(() => {
    let tableColumns = {
      id: 'Id',
      section: 'Section',
      row: 'Row',
      seat: 'Seat',
      ticketStatus: 'Ticket Status',
      ticketHolder: 'Ticket Holder',
    };

    // If a priority lottery, show the priority group column
    if (event.lotteryStyle === PRIORITY_STYLE_VALUE) {
      tableColumns = {
        ...tableColumns,
        priorityGroup: 'Priority Group',
      };
    }

    return tableColumns;
  }, [event]);

  // Get page data on load
  useEffect(() => {
    getEvent();
    // Fetch tickets only for admin
    if (organizationUserRole === ORGANIZATION_ADMIN) {
      fetchClaimedTickets();
    }
  }, [getEvent, fetchClaimedTickets, organizationUserRole]);

  if (isLoading) {
    return <>...loading</>;
  }

  if (error) {
    return <>sorry, something went wrong.</>;
  }

  // Handle tickets from parent context
  let tickets = [];
  if (claimedTickets?.length) {
    tickets = claimedTickets.map((upcomingTicket) =>
      makeUpcomingTicketRow(upcomingTicket),
    );
  }

  return (
    <>
      <HeroImage
        id="EventDetailHero"
        altBackgroundColor={backgroundColorMapper(event?.eventName || '. .')}
        imgSrc={event?.lotteryCoverPhoto ?? null}
        className={styles['detail-hero']}
      />
      <div
        className={cn([
          styles['detail-content'],
          'd-flex flex-column align-items-start justify-content-start mb-12',
        ])}
      >
        <div
          id="EventDetailHeader"
          className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
        >
          <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
            <div>
              {!event?.eventName ? (
                <Avatar
                  size="xl"
                  imgSrc={null}
                  altText=". ."
                  className={styles['detail-logo']}
                />
              ) : (
                <Avatar
                  size="xl"
                  imgSrc={event?.lotteryLogo}
                  altText={event?.eventName}
                  className={styles['detail-logo']}
                />
              )}
            </div>
            <div className="flex-grow-1 ps-lg-6 mt-6">
              <UserRoleAdmin userType={organizationUserRole}>
                <Link
                  className="d-flex align-items-center fs-4 fw-semibold"
                  to="/admin/upcoming-events"
                >
                  <ArrowLeft className="me-3" />
                  <span>Back to Dashboard</span>
                </Link>
              </UserRoleAdmin>
              <UserRoleParticipant userType={organizationUserRole}>
                <Link
                  className="d-flex align-items-center fs-4 fw-semibold"
                  to="/participant"
                >
                  <ArrowLeft className="me-3" />
                  <span>Back to Dashboard</span>
                </Link>
              </UserRoleParticipant>
              <div id="EventDetailName">
                <h1 className="h5 fw-bold mt-4">{event?.eventName}</h1>
              </div>
              <div
                id="EventDetailInfo"
                className="d-flex gap-8 align-items-center justify-content-start pt-3 flex-wrap"
              >
                <div id="EventLotteryStatus" className="d-flex gap-3">
                  <LotteryStatusPill status={event?.lotteryStatus} />
                  <LotteryStylePill lotteryStyle={event?.lotteryStyle} />
                </div>
                <div>
                  <MarkerIcon className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {event?.lotteryVenueName}
                  </span>
                </div>
                <div>
                  <CalendarIcon className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {format(
                      event?.eventDateTime || new Date().toDateString(),
                      'EEEE, MMMM d, yyyy',
                    )}
                  </span>
                </div>
                <div>
                  <Clock className="text-gray-light me-2" />
                  <span className="fs-4 fw-medium text-gray text-truncate">
                    {format(
                      event?.eventDateTime || new Date().toDateString(),
                      'h:mm a',
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserRoleAdmin userType={organizationUserRole}>
          <div className="py-8 px-lg-8 px-4 w-100">
            <div className="hr-divider" />
          </div>
          <div
            id="EventDetailBody"
            className="gap-6 d-flex align-items-start px-lg-8 px-4 flex-column w-100"
          >
            <TableFrame>
              <TableHeader
                title="Claimed Tickets"
                totalItems={claimedTicketsTotal}
              />
              <TableBody
                columns={claimedTicketColumns}
                rows={tickets}
                isLoading={isLoading}
                noRowsMessage={
                  isLoading
                    ? 'Loading Claimed Tickets...'
                    : 'No Claimed Tickets Found'
                }
              />
              <TableFooter
                currentPage={claimedTicketsCurrentPage}
                setCurrentPage={setClaimedTicketsCurrentPage}
                pageTotal={claimedTicketsPageTotal}
              />
            </TableFrame>
          </div>
        </UserRoleAdmin>
      </div>
    </>
  );
}

export default EventDetail;
