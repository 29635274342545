import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';

function SidebarGuide() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [sidebarDirection, setSidebarDirection] = useState('right');

  const showRightSidebar = () => {
    setSidebarDirection('right');
    setIsSidebarVisible(true);
  };

  const showLeftSidebar = () => {
    setSidebarDirection('left');
    setIsSidebarVisible(true);
  };

  const showTopSidebar = () => {
    setSidebarDirection('top');
    setIsSidebarVisible(true);
  };

  const showBottomSidebar = () => {
    setSidebarDirection('bottom');
    setIsSidebarVisible(true);
  };

  const showFullscreenSidebar = () => {
    setSidebarDirection('fullscreen');
    setIsSidebarVisible(true);
  };

  return (
    <>
      <div className="d-flex flex-row gap-4">
        <Button variant="primary" onClick={showRightSidebar}>
          Right
        </Button>

        <Button variant="primary" onClick={showLeftSidebar}>
          Left
        </Button>

        <Button variant="primary" onClick={showTopSidebar}>
          Top
        </Button>

        <Button variant="primary" onClick={showBottomSidebar}>
          Bottom
        </Button>

        <Button variant="primary" onClick={showFullscreenSidebar}>
          Full Screen
        </Button>
      </div>

      <Sidebar
        visible={isSidebarVisible}
        direction={sidebarDirection}
        onClose={() => setIsSidebarVisible(false)}
      >
        <div className="d-flex flex-column gap-4">
          <Button variant="primary" onClick={() => setIsSidebarVisible(false)}>
            Close
          </Button>
        </div>
      </Sidebar>
    </>
  );
}

export default SidebarGuide;
