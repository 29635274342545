import React from 'react';
import cn from 'utils/classNames';

import styles from './FieldRow.module.scss';

/**
 * `FieldRow` component is a layout helper that groups form elements into a row with a dividing line underneath.
 * It allows for additional styling via `className` and accepts any form elements as `children`.
 *
 * @component
 * @param {Object} props - The properties object for this component.
 * @param {string} props.fieldName - The unique identifier for the field, used to construct the id attribute.
 * @param {string} [props.className] - Additional CSS class names to apply to the container.
 * @param {React.ReactNode} props.children - Child components or elements to be rendered within the row.
 *
 * @example
 * <FieldRow fieldName="name" className="custom-row-class">
 *   <FieldRowLeft>/* Left column content *\/</FieldRowLeft>
 *   <FieldRowRight>/* Right column content *\/</FieldRowRight>
 * </FieldRow>
 *
 * @returns {JSX.Element} The component structure consisting of a styled row and a divider.
 */
export default function FieldRow({ fieldName, className, children }) {
  return (
    <>
      <div
        id={`${fieldName}-row`}
        className={cn([
          className,
          'd-flex flex-column flex-lg-row gap-4 gap-lg-8 align-items-start w-100',
        ])}
      >
        {children}
      </div>
      <div className="hr-divider" />
    </>
  );
}

/**
 * `FieldRowLeft` component represents the left column of a `FieldRow`.
 * It typically holds labels or other auxiliary elements related to the inputs on the right.
 * Accepts additional styling through `className`.
 *
 * @component
 * @param {Object} props - The properties object for this component.
 * @param {React.ReactNode} props.children - Child components or elements to render inside the left column.
 * @param {string} [props.className] - Additional CSS class names to apply to the left column.
 *
 * @example
 * <FieldRowLeft className="custom-left-class">
 *   /* Content such as field labels goes here *\/
 * </FieldRowLeft>
 *
 * @returns {JSX.Element} A `<div>` element representing the left part of a field row.
 */
export function FieldRowLeft({ children, className }) {
  return (
    <div
      className={cn([
        'd-flex flex-column align-items-start',
        className,
        styles['row-left'],
      ])}
    >
      {children}
    </div>
  );
}

/**
 * `FieldRowRight` component represents the right column of a `FieldRow`.
 * This is where interactive form elements like inputs are typically placed.
 * Additionally, it accepts a `className` for extra styling needs.
 *
 * @component
 * @param {Object} props - The properties object for this component.
 * @param {React.ReactNode} props.children - Child components or elements to render inside the right column.
 * @param {boolean} props.wide - If the right side of the row should take up full space
 * @param {string} [props.className] - Additional CSS class names to apply to the right column.
 *
 * @example
 * <FieldRowRight className="custom-right-class">
 *   /* Input elements or other interactive components go here *\/
 * </FieldRowRight>
 *
 * @returns {JSX.Element} A `<div>` element wrapped around the provided children, designed to be the right part of a field row.
 */
export function FieldRowRight({ children, className, wide }) {
  return (
    <div
      className={cn([className, 'd-flex flex-column align-items-start'])}
      style={{ width: wide ? '100%' : '32rem', maxWidth: '100%' }}
    >
      <div className="w-100">{children}</div>
    </div>
  );
}
