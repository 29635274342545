import { useContext } from 'react';
import UserContext from 'UserContext';

/**
 * Custom hook to extract organization details from the UserContext.
 *
 * This hook retrieves the current user's context and extracts specific information related
 * to their organization, such as organization ID, maximum lottery participants, and the total number
 * of invited participants. If the UserContext is not available, it returns an empty object.
 *
 */
export default () => {
  const userContext = useContext(UserContext);
  return userContext;
};
