import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import colorMap from 'utils/backgroundColorMapper';
import Sidebar from '../Sidebar';
import HeroImage from '../HeroImage/HeroImage';
import Avatar from '../Avatar';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import cn from '../../utils/classNames';
import ParticipantDetails from './ParticipantDetails/ParticipantDetails';
import ParticipantLotteries from './ParticipantLotteries';
import ParticipantTickets from './ParticipantTickets';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedResources } from '../../authConfig';
import useUser from '../../hooks/useUser';

function ParticipantViewEditSidebar({
  participantId,
  show,
  initialMode,
  onClose,
  setShouldFetch,
}) {
  const [currentTab, setCurrentTab] = useState('DETAILS');
  const [currentParticipant, setCurrentParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldDismiss, setShouldDismiss] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const { organizationId } = useUser();

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  const getParticipant = useCallback(async () => {
    setIsLoading(true);
    const response = await execute(
      'GET',
      `${protectedResources.participants.endpoint}/${organizationId}/participant/${participantId}`,
    );
    setCurrentParticipant(response);
    setIsLoading(false);
  }, [execute, organizationId, participantId]);

  function getTabComponent() {
    switch (currentTab) {
      case 'DETAILS':
        return (
          <ParticipantDetails
            participant={currentParticipant}
            initialMode={mode}
            participantUpdated={() => {
              getParticipant();
              setShouldFetch(true);
              setMode('VIEW');
            }}
            setShouldDismiss={setShouldDismiss}
          />
        );
      case 'LOTTERIES':
        return <ParticipantLotteries participant={currentParticipant} />;
      case 'TICKETS':
        return <ParticipantTickets participant={currentParticipant} />;
      default:
        return <div>Invalid Tab</div>;
    }
  }

  function getParticipantInitials() {
    return `${currentParticipant.firstName
      ?.charAt(0)
      .toUpperCase()}${currentParticipant.lastName?.charAt(0).toUpperCase()}`;
  }

  useEffect(() => {
    if (mode === 'EDIT') {
      setCurrentTab('DETAILS');
    }
  }, [mode]);

  useEffect(() => {
    if (initialMode) {
      setMode(initialMode);
    }
  }, [initialMode]);

  useEffect(() => {
    if (participantId && show) {
      getParticipant();
    }
  }, [participantId, show, getParticipant]);

  useEffect(() => {
    if (shouldDismiss) {
      setShouldFetch(true);
      onClose();
    }
  }, [shouldDismiss, onClose, setShouldFetch]);

  return (
    <Sidebar
      visible={show}
      direction="right"
      onClose={onClose}
      className={cn(['pb-12'])}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div
            className="d-flex w-100 flex-column"
            style={{ gap: '-2.25rem', height: '19rem' }}
          >
            <HeroImage
              style={{
                height: '7.4375rem',
                width: '100%',
                marginBottom: '-2.3rem',
              }}
              altBackgroundColor={colorMap(getParticipantInitials())}
              imgSrc={currentParticipant.coverPhotoUrl}
            />

            <div className="px-6 d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-6">
                <div className="d-flex flex-row justify-content-between align-items-end">
                  <Avatar
                    altText={getParticipantInitials()}
                    size="lg"
                    imgSrc={currentParticipant.profileImageUrl}
                  />
                  <Button
                    onClick={onClose}
                    variant="tertiary"
                    className="d-flex gap-2"
                  >
                    <ArrowLeft />
                    <span>Back to all</span>
                  </Button>
                </div>

                <div className="d-flex flex-column gap-2 align-items-start">
                  <div className="h6 fw-medium text-black m-0">
                    {currentParticipant.firstName} {currentParticipant.lastName}
                  </div>
                  <span className="fs-3 m-0">
                    {currentParticipant.jobTitle?.length === 0
                      ? 'Lottery Participant'
                      : currentParticipant.jobTitle}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex px-6 flex-column gap-6 align-self-stretch">
            <div className="d-flex gap-5 flex-column align-self-stretch">
              <ButtonGroup className="align-self-stretch text-center">
                <Button
                  variant="tertiary-gray"
                  className={cn([
                    'justify-content-center',
                    currentTab === 'DETAILS' && 'active',
                  ])}
                  onClick={() => setCurrentTab('DETAILS')}
                >
                  Details
                </Button>
                <Button
                  variant="tertiary-gray"
                  className={cn([
                    'justify-content-center',
                    currentTab === 'LOTTERIES' && 'active',
                  ])}
                  onClick={() => setCurrentTab('LOTTERIES')}
                >
                  Lotteries
                </Button>
                <Button
                  variant="tertiary-gray"
                  className={cn([
                    'justify-content-center',
                    currentTab === 'TICKETS' && 'active',
                  ])}
                  onClick={() => setCurrentTab('TICKETS')}
                >
                  Tickets
                </Button>
              </ButtonGroup>
            </div>

            {getTabComponent()}
          </div>
        </>
      )}
    </Sidebar>
  );
}

export default ParticipantViewEditSidebar;
