import React, { useEffect, useState } from 'react';
import BuildingAlt from '../../../assets/icons/building-alt.svg';
import Suitcase from '../../../assets/icons/suitcase.svg';
import InfoSquare from '../../../assets/icons/info-square.svg';
import LinkedIn from '../../../assets/icons/linkedin.svg';
import AtSymbol from '../../../assets/icons/at.svg';
import Phone from '../../../assets/icons/phone.svg';
import UserSquare from '../../../assets/icons/user-square.svg';

import ParticipantDetailsView from './ParticipantDetailsView';
import ParticipantDetailsEdit from './ParticipantDetailsEdit';

function ParticipantDetails({
  initialMode,
  participant,
  setShouldDismiss,
  participantUpdated,
}) {
  const [mode, setMode] = useState('VIEW');

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  const participantFields = {
    tags: {
      label: 'User Tags',
      icon: <UserSquare style={{ width: '1.5rem' }} />,
      editable: true,
      type: 'tags',
    },
    jobTitle: {
      label: 'Role',
      icon: <Suitcase style={{ width: '1.5rem' }} />,
      editable: true,
      type: 'text',
    },
    companyName: {
      label: 'Company',
      icon: <BuildingAlt style={{ width: '1.5rem' }} />,
      editable: true,
      type: 'text',
    },
    additionalDetails: {
      label: 'Additional Details',
      icon: <InfoSquare style={{ width: '1.5rem' }} />,
      editable: true,
      type: 'text',
    },
    linkedInProfileUrl: {
      label: 'LinkedIn',
      icon: <LinkedIn style={{ width: '1.5rem' }} />,
      editable: false,
      type: 'link',
    },
    email: {
      label: 'Email Address',
      icon: <AtSymbol style={{ width: '1.5rem' }} />,
      editable: false,
      type: 'email',
    },
    phoneNumber: {
      label: 'Phone Number',
      icon: <Phone style={{ width: '1.5rem' }} />,
      editable: false,
      type: 'text',
    },
  };

  return (
    <div className="d-flex flex-column gap-6 align-items-start align-self-stretch">
      {mode === 'VIEW' ? (
        <ParticipantDetailsView
          participant={participant}
          fields={participantFields}
          onEditClick={() => setMode('EDIT')}
          setShouldDismiss={setShouldDismiss}
        />
      ) : (
        <ParticipantDetailsEdit
          participant={participant}
          fields={participantFields}
          setShouldDismiss={setShouldDismiss}
          onParticipantUpdated={() => {
            participantUpdated();
            setMode('VIEW');
          }}
        />
      )}
    </div>
  );
}

export default ParticipantDetails;
