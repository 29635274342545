import React, { Fragment } from 'react';
import TogglerField from './TogglerField';
import styles from '../AccountSettings.module.scss';

function NotificationMethods({
  loading,
  handleNotificationToggle,
  notificationMethods,
  notificationSettings,
}) {
  return (
    <div
      style={{ width: '32rem', maxWidth: '100%' }}
      className="d-flex d-lg-flex flex-column align-items-center align-items-lg-end align-self-stretch pb-0 gap-4"
    >
      <div
        className="d-none d-lg-flex align-items-start fs-4 fw-medium text-secondary-emphasis"
        style={{ gap: '5rem', width: '17.375rem' }}
      >
        <span>In-App</span>
        <span>Email</span>
        <span>Text</span>
      </div>

      {notificationMethods.map((method) => (
        <Fragment key={`${method.category}`}>
          <div className="d-flex flex-column flex-lg-row align-items-center gap-4 align-self-stretch">
            <span
              className="d-flex fw-medium fs-4 text-secondary-emphasis"
              style={{ flex: '1 0 0' }}
            >
              {method.category}
            </span>

            <div
              className={`d-flex py-0 px-3 px-lg-0 justify-content-between align-items-center align-self-stretch ${styles['toggler-container']}`}
            >
              <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                <span className="d-lg-none fw-medium fs-4 text-secondary-emphasis">
                  InApp
                </span>
                <TogglerField
                  field={method.INAPP}
                  loading={loading}
                  handleSwitch={(event, bitName) =>
                    handleNotificationToggle(
                      event,
                      method.userNotificationMethodsField,
                      bitName,
                    )
                  }
                  checked={
                    notificationSettings[method.userNotificationMethodsField]
                      .INAPP
                  }
                />
              </div>

              <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                <span className="d-lg-none fw-medium fs-4 text-secondary-emphasis">
                  Email
                </span>
                <TogglerField
                  field={method.EMAIL}
                  loading={loading}
                  handleSwitch={(event, bitName) =>
                    handleNotificationToggle(
                      event,
                      method.userNotificationMethodsField,
                      bitName,
                    )
                  }
                  checked={
                    notificationSettings[method.userNotificationMethodsField]
                      .EMAIL
                  }
                />
              </div>

              <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                <span className="d-lg-none fw-medium fs-4 text-secondary-emphasis">
                  Text
                </span>
                <TogglerField
                  field={method.TEXT}
                  loading={loading}
                  handleSwitch={(event, bitName) =>
                    handleNotificationToggle(
                      event,
                      method.userNotificationMethodsField,
                      bitName,
                    )
                  }
                  checked={
                    notificationSettings[method.userNotificationMethodsField]
                      .TEXT
                  }
                />
              </div>
            </div>
          </div>
          <div className="hr-divider" />
        </Fragment>
      ))}
    </div>
  );
}

export default NotificationMethods;
