import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { format } from 'date-fns';
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import { protectedResources } from 'authConfig';
import cn from 'utils/classNames';
import colorMap from 'utils/backgroundColorMapper';
import ConsoleLogger from 'utils/logger';
import localDateTime from 'utils/localTime';
import useUser from 'hooks/useUser';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import usePageTracking from 'hooks/usePageTracking';
import HeroImage from 'components/HeroImage/HeroImage';
import Avatar from 'components/Avatar';
import TicketShapeRightIcon from 'assets/icons/ticket-shape-right.svg';
import MarkerIcon from 'assets/icons/marker-pin.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import ClockIcon from 'assets/icons/clock.svg';

import styles from './Tickets.module.scss';
import NoTicket from './NoTicket';

const POSSIBLE_CLAIM_ERRORS = {
  TicketNotFound: 'Sorry, the ticket is no longer available.',
  TicketAlreadyClaimed: 'Sorry! This ticket was already claimed.',
  UserNotInLottery: "Sorry, you'r no longer a participant in this lottery.",
  TicketLimitPerEventReached:
    'You are trying to claim more tickets than you are allowed. Please refer to the claimed ticket limits for this event.',
  EventLimitReached:
    'You are trying to claim tickets from more events than you are allowed. Please refer to the claimed ticket limits for this event.',
  UnkownError:
    'Something went wrong, please contact support if the issue persists.',
};

// TODO: handle character overages with "..."
function TicketContainer({
  ticket: {
    LotteryId,
    LotteryCover,
    LotteryLogo,
    Game,
    Location,
    Date,
    Time,
    Section,
    Row,
    Seat,
  },
  handleTicketSubmit,
  isClaimed,
  isLoading,
}) {
  const navigate = useNavigate();
  const localDate = localDateTime(`${Date}T${Time}`);

  return (
    <div
      className={`${styles.ticket} d-flex flex-column p-4 justify-content-between gap-5 shadow-sm border border-gray-lighter rounded-3 bg-white`}
    >
      <div className="position-relative rounded-2 overflow-hidden">
        <HeroImage
          altBackgroundColor={colorMap(Game)}
          imgSrc={LotteryCover ?? null}
          style={{ width: '107px' }}
          className={styles.lotteryCover}
        />
        <Avatar
          altText={Game}
          size="md"
          style={{
            width: '128px',
            height: '128px',
            fontSize: '4rem',
          }}
          imgSrc={LotteryLogo ?? null}
          className={`${styles.lotteryLogo} ${
            LotteryLogo === null ? 'shadow' : ''
          }`}
        />
      </div>
      <span className="fs-1 fw-semibold">{Game}</span>
      <div
        id="TicketInfo"
        className="d-flex flex-column gap-4 align-items-center justify-content-center"
      >
        <div className="d-flex flex-start w-100 gap-6">
          <div className="d-flex gap-2">
            <MarkerIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">{Location}</span>
          </div>
          <div className="d-flex gap-2">
            <CalendarIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">
              {format(localDate, 'EEEE, MMMM d, yyyy')}
            </span>
          </div>
        </div>
        <div className="d-flex flex-start w-100 gap-6">
          <div className="d-flex gap-2">
            <ClockIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">
              {format(localDate, 'h:mm a')}
            </span>
          </div>
        </div>
      </div>
      <div id="TicketDetails" className="d-flex w-100">
        <TicketShapeRightIcon className={styles.flipHorizontal} />

        <div
          className={`${styles.ticketContainer} d-flex flex-column px-1 flex-grow-1 justify-content-center align-items-start gap-4`}
        >
          <div className={`${styles.ticketDetails} d-flex w-100`}>
            <div className="d-flex flex-column ">
              <span className="fs-5 fw-medium text-gray">SECTION</span>
              <span className="h5 fw-normal mb-0">{Section}</span>
            </div>
            <div className="d-flex flex-column ">
              <span className="fs-5 fw-medium text-gray">ROW</span>
              <span className="h5 fw-normal mb-0">{Row}</span>
            </div>
            <div className="d-flex flex-column ">
              <span className="fs-5 fw-medium text-gray">SEAT</span>
              <span className="h5 fw-normal mb-0">{Seat}</span>
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-between">
            {isClaimed ? (
              <Button
                variant="primary"
                size="sm"
                className="w-50 text-center justify-content-center"
                onClick
                disabled
              >
                Claimed!
              </Button>
            ) : (
              <Button
                variant="primary"
                size="sm"
                className="w-50 text-center justify-content-center"
                onClick={() => handleTicketSubmit()}
                disabled={isLoading}
              >
                Claim
              </Button>
            )}
            <Button
              variant="tertiary"
              size="sm"
              className="w-50 text-center justify-content-center text-nowrap"
              onClick={() => navigate(`/season-lottery-detail/${LotteryId}`)}
            >
              Lottery Details
            </Button>
          </div>
        </div>
        <TicketShapeRightIcon />
      </div>
    </div>
  );
}

function UnclaimedTickets({ dashboard }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [tickets, setTickets] = useState([]);
  const [pageTotal, setPageTotal] = useState(0);
  const [claimedTickets, setClaimedTickets] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });
  const { organizationId, organizationUserId } = useUser();
  usePageTracking(!dashboard ? 'unclaimed-tickets' : false);
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const navigate = useNavigate();

  const handlePrevPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };

  const getAllTickets = useCallback(async () => {
    const top = dashboard ? 3 : 9;
    const skip = (currentPage - 1) * top;
    const orderBy = 'datetime asc, game asc, claimed asc';

    try {
      const res = await execute(
        'GET',
        `${protectedResources.tickets.endpoint}/${organizationUserId}?$top=${top}&$skip=${skip}&$orderBy=${orderBy}&$count=true`,
      );
      setPageTotal(Math.ceil((res['@odata.count'] * 100) / (top * 100)));
      setTotalCount(res['@odata.count']);
      setTickets(res.value);
    } catch (err) {
      ConsoleLogger.error(err);
      setTickets([]);
    }
  }, [dashboard, currentPage, execute, organizationUserId]);

  const handleTicketSubmit = async (ticketId) => {
    try {
      const ticketPayload = {
        participantId: organizationUserId.toUpperCase(),
        ticketId,
      };
      const res = await execute(
        'POST',
        `${protectedResources.tickets.endpoint}/claim`,
        ticketPayload,
      );

      if (res.errorSource) {
        if (POSSIBLE_CLAIM_ERRORS[res.errorSource]) {
          setErrorMessages((prevMessages) => [
            ...prevMessages,
            {
              timestamp: Date.now(),
              message: POSSIBLE_CLAIM_ERRORS[res.errorSource],
            },
          ]);
        }
      }
    } catch (err) {
      ConsoleLogger.error(err);
    }
  };

  const handleErrorDismiss = (dissmissedErrorTimestamp) => {
    const unDissmissedErrors = errorMessages.filter(
      (errMsg) => errMsg.timestamp !== dissmissedErrorTimestamp,
    );
    setErrorMessages(unDissmissedErrors);
  };

  useEffect(() => {
    getAllTickets();
  }, [getAllTickets]);

  useEffect(() => {
    // If the current page has changed scroll to window top
    if (currentPage) {
      if (typeof window !== 'undefined') {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    // Pusher.logToConsole = true;
    const pusher = new Pusher('7be6fc8079e495a1b769', { cluster: 'us3' });

    const channel = pusher.subscribe(`tickets_${organizationId.toUpperCase()}`);

    channel.bind('ticket-claimed', ({ ticketIds }) => {
      setClaimedTickets((prevClaimedTickets) => [
        ...(prevClaimedTickets || []),
        ...ticketIds,
      ]);
    });

    return () => {
      pusher.disconnect();
    };
  }, [organizationId]);

  return (
    <div
      id="TicketsWrapper"
      className={cn([styles.ticketsWrapper, 'd-flex flex-wrap gap-6'])}
      aria-live="polite"
      aria-atomic="true"
    >
      {dashboard && (
        <div className="d-flex w-100 justify-content-between">
          <h2 className="fs-2 fw-semibold d-flex align-items-center">
            Unclaimed Tickets
          </h2>
          <Button
            variant="tertiary-gray"
            onClick={() => navigate('/tickets/unclaimed')}
          >
            View All Unclaimed Tickets
            <span className="badge rounded-pill bg-black">{totalCount}</span>
          </Button>
        </div>
      )}
      {tickets?.length > 0 ? (
        tickets.map((t) => (
          <TicketContainer
            key={t.TicketId}
            ticket={t}
            handleTicketSubmit={() => handleTicketSubmit(t.TicketId)}
            isClaimed={claimedTickets.includes(t.TicketId) || t.Claimed}
            isLoading={isLoading}
          />
        ))
      ) : (
        <NoTicket text="There are currently no unclaimed tickets available. Please check back later for upcoming lotteries and events." />
      )}
      <ToastContainer
        className="p-3 position-fixed"
        position="bottom-end"
        style={{ zIndex: 1 }}
      >
        {!errorMessages?.length ||
          errorMessages.map((err) => (
            <Toast
              key={err.timestamp}
              onClose={() => handleErrorDismiss(err.timestamp)}
              delay={12000}
              autohide
            >
              <Toast.Header className="text-primary">
                <strong className="me-auto">
                  We couldn&apos;t claim your ticket!
                </strong>
              </Toast.Header>
              <Toast.Body className="bg-white">{err.message}</Toast.Body>
            </Toast>
          ))}
      </ToastContainer>
      {!dashboard && (
        <div
          id="TableOuterFooter"
          className="pt-3 pb-4 px-4 px-lg-6 d-flex gap-4 w-100 justify-content-between align-items-center"
        >
          <div className={styles.paginationText}>
            Page {currentPage} of {pageTotal || 1}
          </div>

          <div className="d-flex">
            <Button
              variant="tertiary"
              size="sm"
              disabled={currentPage === 1}
              className="me-3"
              onClick={() => handlePrevPage()}
            >
              Previous
            </Button>
            <Button
              variant="tertiary"
              disabled={currentPage === pageTotal}
              size="sm"
              onClick={() => handleNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UnclaimedTickets;
