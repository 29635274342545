import { ORGANIZATION_ADMIN } from 'constants';
import React from 'react';
import useUser from 'hooks/useUser';
import ParticipantSupport from './ParticipantSupport';
import AdminSupport from './AdminSupport';

function Support() {
  const { organizationUserRole } = useUser();
  return (
    <div
      id="Support"
      className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
    >
      <div
        id="SupportHeader"
        className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
      >
        <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Support Information</h1>
          </div>
        </div>
      </div>

      <div
        id="SupportContentWrapper"
        className="gap-6 d-flex align-items-start px-lg-8 px-0 flex-column w-100 mt-8"
      >
        <div className="d-flex flex-column align-items-center justify-content-center gap-6">
          <p className="col-8 fs-1 text-gray mb-4 mb-lg-10 text-center">
            See below for more on the different plan features, how the lottery
            process works, and other frequently asked questions to help you get
            started.
            <br /> <br />
            <a
              className="text-primary"
              href="https://www.loom.com/share/folder/6769b6d72f754a7b80c2265c9ded664b"
              target="_blank"
              rel="noreferrer"
            >
              Watch How AllSeater Works
            </a>
          </p>

          <div className="mt-0 mx-16 mx-xl-20">
            {organizationUserRole === ORGANIZATION_ADMIN ? (
              <AdminSupport />
            ) : (
              <ParticipantSupport />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
