import React from 'react';
import NoTickets from 'assets/images/tix.svg';

function NoLotteries({ title, desc, children }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-8 mb-12 w-100">
      <section className="gap-6 d-flex flex-column align-items-center px-lg-8 px-4">
        <div className="d-flex flex-column align-items-center py-24 text-center">
          <img src={NoTickets} alt="no-tickets" />
          {!title || <span className="fs-1 fw-semibold">{title}</span>}
          {!desc || <span className="h6 fw-semibold">{desc}</span>}
          {children}
        </div>
      </section>
    </div>
  );
}

export default NoLotteries;
