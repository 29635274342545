import { React } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from 'layouts/Default';

import 'styles/App.scss';
import Router from 'Router';
import { ConfirmProvider } from './confirm/ConfirmProvider';

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfirmProvider>
        <Layout>
          <Router />
        </Layout>
      </ConfirmProvider>
    </QueryClientProvider>
  );
}

export default App;
