/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import SvgIcon from 'components/SvgIcon';
import UploadIcon from 'assets/icons/upload.svg';

import styles from './DragAndDrop.module.scss';

function DragAndDrop({
  style,
  accept,
  onFileUpload,
  multiple,
  maxSize,
  uploadDescription,
  controlId,
  altText,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileUpload,
    accept,
    multiple,
    maxSize,
  });

  return (
    <div
      className={`d-flex py-4 px-6 flex-column align-items-center w-100 gap-1 align-self-stretch ${
        styles['dropzone-wrapper']
      } ${isDragActive ? styles.dragging : ''}`}
      {...getRootProps()}
      style={style}
    >
      <div
        className={`d-flex flex-column align-items-center gap-3 align-self-stretch ${styles.content}`}
      >
        <label htmlFor={controlId} className="visually-hidden">
          {altText}
        </label>
        <input id={controlId} name={controlId} {...getInputProps()} />

        <div
          className={`d-flex justify-content-center align-items-center p-4 ${styles['upload-icon-wrapper']}`}
        >
          <SvgIcon component={UploadIcon} className={styles['upload-icon']} />
        </div>

        <div className="text-center align-items-center">
          <p className="fs-4 m-0">
            <span className={`${styles['upload-text']} text-primary fw-bold`}>
              Click to upload
            </span>{' '}
            or drag and drop
          </p>
          <p className="fs-5 m-0">{uploadDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default DragAndDrop;
