import React from 'react';

function GridGuide() {
  return (
    <div className="container">
      <p className="fw-bold">Equal-width</p>
      <div className="row">
        <div className="col border">1 of 2</div>
        <div className="col border"> 2 of 2</div>
      </div>
      <div className="row">
        <div className="col border">1 of 3</div>
        <div className="col border"> 2 of 3</div>
        <div className="col border"> 3 of 3</div>
      </div>

      <p className="fw-bold mt-4">One column width</p>
      <div className="row">
        <div className="col border">1 of 3</div>
        <div className="col-6 border">2 of 3 (wider)</div>
        <div className="col border">3 of 3</div>
      </div>
      <div className="row">
        <div className="col border">1 of 3</div>
        <div className="col-5 border">2 of 3 (wider)</div>
        <div className="col border">3 of 3</div>
      </div>

      <p className="fw-bold mt-4">Variable Width Content</p>
      <div className="row justify-content-md-center">
        <div className="col col-lg-2 border">1 of 3</div>
        <div className="col-md-auto border">Variable width content</div>
        <div className="col col-lg-2 border">3 of 3</div>
      </div>
      <div className="row">
        <div className="col border">1 of 3</div>
        <div className="col-md-auto border">Variable width content</div>
        <div className="col col-lg-2 border">3 of 3</div>
      </div>

      <p className="fw-bold mt-4">Responsive</p>
      <div className="row">
        <div className="col border">col</div>
        <div className="col border">col</div>
        <div className="col border">col</div>
        <div className="col border">col</div>
      </div>
      <div className="row">
        <div className="col-8 border">col-8</div>
        <div className="col-4 border">col-4</div>
      </div>

      <p className="fw-bold mt-4">Mix and match</p>
      <div className="row">
        <div className="col-md-8 border">.col-md-8</div>
        <div className="col-6 col-md-4 border">.col-6 .col-md-4</div>
      </div>

      {/*  Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop */}
      <div className="row">
        <div className="col-6 col-md-4 border">.col-6 .col-md-4</div>
        <div className="col-6 col-md-4 border">.col-6 .col-md-4</div>
        <div className="col-6 col-md-4 border">.col-6 .col-md-4</div>
      </div>

      {/* Columns are always 50% wide, on mobile and desktop */}
      <div className="row">
        <div className="col-6 border">.col-6</div>
        <div className="col-6 border">.col-6</div>
      </div>

      <p className="fw-bold mt-4">Row columns</p>
      <div className="row row-cols-3">
        <div className="col border">Column</div>
        <div className="col border">Column</div>
        <div className="col border">Column</div>
        <div className="col border">Column</div>
      </div>
      <p className="fw-bold mt-4">Row columns auto</p>

      <div className="row row-cols-auto">
        <div className="col border">Column</div>
        <div className="col border">Column</div>
        <div className="col border">Column</div>
        <div className="col border">Column</div>
      </div>

      <p className="fw-bold mt-4">Nesting </p>
      <div className="row">
        <div className="col-sm-3 border">Level 1: .col-sm-3</div>
        <div className="col-sm-9 border">
          <div className="row p-2">
            <div className="col-8 col-sm-6 border">
              Level 2: .col-8 .col-sm-6
            </div>
            <div className="col-4 col-sm-6 border">
              Level 2: .col-4 .col-sm-6
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridGuide;
