import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { protectedResources } from 'authConfig';

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import BellRingingIcon from 'assets/icons/bell-ringing.svg';
import {
  getNotificationMethodBytes,
  getNotificationMethodsFromBytes,
} from 'utils/notifications';
import usePageTracking from 'hooks/usePageTracking';
import NotificationMethods from './NotificationMethods';

import styles from './NotificationMethods.module.scss';
import useUser from '../../../hooks/useUser';
import { ORGANIZATION_ADMIN } from '../../../constants';

function Notifications() {
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [userNotificationMethods, setUserNotificationMethods] = useState({
    userId: '',
    organizationId: '',
    lotteryNotificationMethod: null,
    postEventNotificationMethod: null,
    ticketNotificationMethod: null,
    marketingNotificationMethod: null,
    systemUpdatesNotificationMethod: null,
  });

  const { execute, isLoading: isHttpLoading } = useFetchWithMsal({
    scopes: protectedResources.userprofiles.scopes.access_as_user,
  });
  const { organizationUserRole, organizationId } = useUser();
  usePageTracking('notifications');

  const getUserNotificationMethods = useCallback(async () => {
    if (!organizationId) return;
    setIsFormLoading(true);

    const companyId = organizationId;

    const notificationsSettingUrl =
      organizationUserRole === ORGANIZATION_ADMIN
        ? `${protectedResources.userprofiles.endpoint}/orgadmin/notificationsettings/${companyId}`
        : `${protectedResources.userprofiles.endpoint}/notificationsettings/current`;

    const companyNotifications = await execute('GET', notificationsSettingUrl);

    if (companyNotifications) {
      const parsedNotificationSettings = {
        lotteryNotificationMethod: getNotificationMethodsFromBytes(
          companyNotifications.lotteryNotificationMethod,
        ),
        postEventNotificationMethod: getNotificationMethodsFromBytes(
          companyNotifications.postEventNotificationMethod,
        ),
        ticketNotificationMethod: getNotificationMethodsFromBytes(
          companyNotifications.ticketNotificationMethod,
        ),
        marketingNotificationMethod: getNotificationMethodsFromBytes(
          companyNotifications.marketingNotificationMethod,
        ),
        systemUpdatesNotificationMethod: getNotificationMethodsFromBytes(
          companyNotifications.systemUpdatesNotificationMethod,
        ),
      };

      setUserNotificationMethods({
        ...companyNotifications,
        ...parsedNotificationSettings,
      });
      setIsFormLoading(false);
    }
  }, [execute, organizationId, organizationUserRole]);

  const handleNotificationToggle = useCallback(
    async (event, field, bit) => {
      if (!organizationId) return;

      const newNotificationSettings = { ...userNotificationMethods };
      newNotificationSettings[field][bit] = event.target.checked;

      const notificationSettingsUpdatePayload = {
        ...newNotificationSettings,
        lotteryNotificationMethod: getNotificationMethodBytes(
          newNotificationSettings.lotteryNotificationMethod,
        ),
        postEventNotificationMethod: getNotificationMethodBytes(
          newNotificationSettings.postEventNotificationMethod,
        ),
        ticketNotificationMethod: getNotificationMethodBytes(
          newNotificationSettings.ticketNotificationMethod,
        ),
        marketingNotificationMethod: getNotificationMethodBytes(
          newNotificationSettings.marketingNotificationMethod,
        ),
        systemUpdatesNotificationMethod: getNotificationMethodBytes(
          newNotificationSettings.systemUpdatesNotificationMethod,
        ),
      };

      const updatedNotificationSettingsUrl =
        organizationUserRole === ORGANIZATION_ADMIN
          ? `${protectedResources.userprofiles.endpoint}/orgadmin/notificationsettings`
          : `${protectedResources.userprofiles.endpoint}/notificationsettings/current`;

      await execute(
        'PUT',
        updatedNotificationSettingsUrl,
        notificationSettingsUpdatePayload,
      );
      if (!isHttpLoading) {
        setUserNotificationMethods(newNotificationSettings);
      }
    },
    [
      execute,
      organizationId,
      isHttpLoading,
      userNotificationMethods,
      organizationUserRole,
    ],
  );

  useEffect(() => {
    getUserNotificationMethods();
  }, [getUserNotificationMethods]);

  const notificationMethods = [
    {
      category: 'Lottery notifications',
      userNotificationMethodsField: 'lotteryNotificationMethod',
      INAPP: {
        mandatory: true,
        altText: 'Toggle lottery notifications in app on/off',
        bit: 'INAPP',
      },
      EMAIL: {
        mandatory: true,
        altText: 'Toggle lottery notifications via email on/off',
        bit: 'EMAIL',
      },
      TEXT: {
        mandatory: true,
        altText: 'Toggle lottery notifications via text on/off',
        bit: 'TEXT',
      },
    },
    {
      category: 'Ticketing notifications',
      userNotificationMethodsField: 'ticketNotificationMethod',
      INAPP: {
        mandatory: true,
        altText: 'Toggle ticketing notifications in app on/off',
        bit: 'INAPP',
      },
      EMAIL: {
        mandatory: true,
        altText: 'Toggle ticketing notifications via email on/off',
        bit: 'EMAIL',
      },
      TEXT: {
        mandatory: true,
        altText: 'Toggle ticketing notifications via text on/off',
        bit: 'TEXT',
      },
    },
    {
      category: 'Post-event notifications',
      userNotificationMethodsField: 'postEventNotificationMethod',
      INAPP: {
        mandatory: true,
        altText: 'Toggle post-event notifications in app on/off',
        bit: 'INAPP',
      },
      EMAIL: {
        mandatory: true,
        altText: 'Toggle post-event notifications via email on/off',
        bit: 'EMAIL',
      },
      TEXT: {
        mandatory: true,
        altText: 'Toggle post-event notifications via text on/off',
        bit: 'TEXT',
      },
    },
    {
      category: 'System update notifications',
      userNotificationMethodsField: 'systemUpdatesNotificationMethod',
      INAPP: {
        mandatory: true,
        altText: 'Toggle system update notifications in app on/off',
        bit: 'INAPP',
      },
      EMAIL: {
        mandatory: false,
        altText: 'Toggle system update notifications via email on/off',
        bit: 'EMAIL',
      },
      TEXT: {
        mandatory: true,
        altText: 'Toggle system update notifications via text on/off',
        bit: 'TEXT',
      },
    },
    {
      category: 'Marketing notifications',
      userNotificationMethodsField: 'marketingNotificationMethod',
      INAPP: {
        mandatory: true,
        altText: 'Toggle marketing notifications in app on/off',
        bit: 'INAPP',
      },
      EMAIL: {
        mandatory: false,
        altText: 'Toggle marketing notifications via email on/off',
        bit: 'EMAIL',
      },
      TEXT: {
        mandatory: true,
        altText: 'Toggle marketing notifications via text on/off',
        bit: 'TEXT',
      },
    },
  ];

  return isFormLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="w-100 d-flex flex-column align-items-start gap-6">
      <section className="d-flex flex-column align-items-start gap-5 w-100">
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
          <div className="d-flex flex-column align-items-start gap-1">
            <h2 className="m-0 fs-2 fw-semibold lh-lg">My Notifications</h2>
            <p className="m-0 fs-4 text-secondary">
              Select when and how you&apos;ll be notified.
            </p>
          </div>
        </div>

        <div className="hr-divider" />
      </section>

      <div className="w-100 d-flex flex-column gap-5">
        <Alert variant="light" className="w-100 d-flex gap-4">
          <SvgIcon
            className={styles['bell-icon']}
            component={BellRingingIcon}
          />
          <span className="fw-semibold fs-4">
            Some emails notifications are a requirement for all users upon sign
            up. In App & Text notifications coming soon.
          </span>
        </Alert>

        <div
          id="general-notifications-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              General notifications
            </p>
            <p className="fs-4 text-secondary my-2">
              Select when you&apos;ll be notified when the following changes
              occur.
            </p>
          </div>

          <NotificationMethods
            loading={isHttpLoading}
            notificationMethods={notificationMethods}
            handleNotificationToggle={handleNotificationToggle}
            notificationSettings={userNotificationMethods}
          />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
