import React, { useCallback, useState, useEffect } from 'react';
import { Card, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedResources } from '../../authConfig';
import localDateTime from '../../utils/localTime';

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
};

function ImportEventSchedulesDialog({
  show,
  handleClose,
  handleImport,
  eventTypeCode,
}) {
  const [eventSchedules, setEventSchedules] = useState([]);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.events.scopes.access_as_user,
  });

  const handleDismiss = () => {
    handleClose();
  };

  const fetchEventSchedules = useCallback(async () => {
    if (eventTypeCode) {
      const response = await execute(
        'GET',
        `${protectedResources.events.endpoint}/schedule-templates${eventTypeCode ? `?eventTypeCode=${eventTypeCode}` : ''}`,
      );
      if (response) {
        setEventSchedules(response);
      }
    }
  }, [execute, eventTypeCode]);

  useEffect(() => {
    fetchEventSchedules();
  }, [fetchEventSchedules]);

  return (
    <Modal
      show={show}
      onHide={handleDismiss}
      backdrop
      keyboard
      centered
      size="lg"
    >
      <Modal.Header className="align-items-start pb-5" closeButton>
        <div className="d-flex flex-column align-items-start">
          <h2 className="fs-2 fw-semibold w-100">Import Event Schedules</h2>
          <p className="text-gray mb-0 fs-4">
            Here are some pre defined event schedules that you can import into
            your lottery.
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        {eventSchedules.map((eventSchedule) => {
          const localDateStart = localDateTime(eventSchedule.dates[0].dateTime);
          const localDateEnd = localDateTime(
            eventSchedule.dates.at(-1).dateTime,
          );

          const dateStartFormatted = localDateStart.toLocaleString(
            'en-US',
            options,
          );
          const dateEndFormatted = localDateEnd.toLocaleString(
            'en-US',
            options,
          );

          return (
            <Card key={eventSchedule.id} className="mb-4 p-0">
              <Card.Body className="m-0 p-0">
                <Card.Title className="text-primary h6 px-3 pt-3">
                  {eventSchedule.title}
                </Card.Title>
                <Card.Text className="text-secondary-emphasis px-3">
                  {eventSchedule.description}
                </Card.Text>

                <ListGroup>
                  <ListGroupItem>
                    <strong>Start Date:</strong> {dateStartFormatted}
                  </ListGroupItem>

                  <ListGroupItem>
                    <strong>End Date:</strong> {dateEndFormatted}
                  </ListGroupItem>

                  <ListGroupItem>
                    <strong>Number of dates:</strong>{' '}
                    {eventSchedule.dates.length}
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>

              <Card.Footer className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleImport(eventSchedule)}
                >
                  Import
                </button>
              </Card.Footer>
            </Card>
          );
        })}
      </Modal.Body>
    </Modal>
  );
}

export default ImportEventSchedulesDialog;
