import React, { useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import classNames from 'utils/classNames';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import ConsoleLogger from 'utils/logger';
import useUser from 'hooks/useUser';

import styles from './TagBox.module.scss';

// This TagBox is based on https://react-select.com/home

const {
  Control: RsControl,
  ValueContainer: RsValueContainer,
  IndicatorsContainer: RsIndicatorsContainer,
  SingleValue: RsSingleValue,
  Option: RsOption,
  MenuList: RsMenuList,
} = components;

// See https://react-select.com/components#replaceable-components
function Control({ children, isFocused, ...props }) {
  return (
    <RsControl
      className={classNames([
        'btn btn-tertiary-gray w-100 text-start fw-normal form-control',
        isFocused && 'focused',
      ])}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </RsControl>
  );
}

// See https://react-select.com/components#replaceable-components
function IndicatorsContainer({ children, ...props }) {
  return (
    <RsIndicatorsContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames([
        'p-0 m-0',
        styles['remove-decendant-spacing'],
        styles.chevron,
      ])}
    >
      {children}
    </RsIndicatorsContainer>
  );
}

// See https://react-select.com/components#replaceable-components
function MenuList({ children, ...props }) {
  return (
    <RsMenuList
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      aria-label="Tag select list"
    >
      {children}
    </RsMenuList>
  );
}

// See https://react-select.com/components#replaceable-components
function Option({ children, ...props }) {
  // remove getClassNames
  const { getClassNames, isSelected, ...culledProps } = props;

  return (
    <RsOption
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...culledProps}
      getClassNames={() => {}}
      className={classNames([
        'dropdown-item',
        styles.option,
        isSelected && styles.selected,
      ])}
    >
      <div className={styles.rowBackground}>{children}</div>
    </RsOption>
  );
}

// See https://react-select.com/props#select-props
function OptionLabel(option) {
  if (!option?.label) {
    return <span />;
  }

  return (
    <span className="badge rounded-pill user-tag-blue d-inline-block">
      {option?.label}
    </span>
  );
}

// See https://react-select.com/props#select-props
function CreateLabel(value) {
  // eslint-disable-next-line react/no-unescaped-entities
  return <>Create new tag "{value}"</>;
}

// See https://react-select.com/components#replaceable-components
function SingleValue({ children, ...props }) {
  return (
    <RsSingleValue
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className="overflow-visible d-flex"
    >
      {children}
    </RsSingleValue>
  );
}

// See https://react-select.com/components#replaceable-components
function ValueContainer({ children, ...props }) {
  return (
    <RsValueContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames(['mx-0 px-0', styles['remove-decendant-spacing']])}
    >
      {children}
    </RsValueContainer>
  );
}

function noOptionsMessage(isLoading) {
  return (
    <span className="d-block width-100 fs-4 text-secondary">
      {isLoading
        ? 'Loading tags...'
        : 'No tags yet, start typing to create one.'}
    </span>
  );
}

/**
 * Renders a User TagBox component using react-select internally.
 *
 * @param {Object} props
 * @param {string} props.id - The id of the select element.
 * @param {Array} props.overrideOptions - The array of options to display in the select dropdown. { value: string, label: string }
 * @param {function} props.handleChange - The function to handle the change event when an option is selected.
 * @param {string} props.className - The CSS class name to apply to the select element.
 * @param {boolean} props.disabled - Determines whether the select element is disabled or not.
 * @returns {JSX.Element} - The rendered TagBox component.
 */
export default function TagBox({
  id,
  overrideOptions,
  handleChange,
  className,
  disabled,
  isInvalid,
  value,
}) {
  const { organizationId } = useUser();
  const [tagOptions, setTagOptions] = useState();
  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.organizations.scopes.access_as_user,
  });

  const getOrgUserTags = useCallback(async () => {
    if (!organizationId) {
      return;
    }
    try {
      const res = await execute(
        'GET',
        `${protectedResources.organizations.endpoint}/${organizationId}/tags`,
      );
      if (res?.tags) {
        const optionsWithLabel = res.tags.map((tag) => ({
          value: tag,
          label: tag,
        }));
        setTagOptions(optionsWithLabel);
      }
    } catch (err) {
      ConsoleLogger.error(err);
      setTagOptions([
        {
          value: null,
          label:
            'Something went wrong, please contact support if the issue persists.',
        },
      ]);
    }
  }, [organizationId, execute]);

  useEffect(() => {
    if (!overrideOptions) {
      getOrgUserTags();
    } else {
      setTagOptions(overrideOptions);
    }
  }, [overrideOptions, getOrgUserTags]);

  return (
    <CreatableSelect
      id={id}
      isClearable
      className={className}
      aria-invalid={isInvalid}
      options={tagOptions}
      disabled={isLoading || disabled}
      placeholder={isLoading ? 'Loading tags...' : 'Select or create a tag'}
      isDisabled={isLoading || disabled}
      formatOptionLabel={OptionLabel}
      formatCreateLabel={CreateLabel}
      noOptionsMessage={() => noOptionsMessage(isLoading)}
      value={value}
      // onCreateOption TODO: this will be helpful (potentially) with creation request on API
      styles={{
        option: (baseStyles, state) => {
          // remove backgroundColor & color
          const { backgroundColor, color, ...culledStyles } = baseStyles;
          return {
            ...culledStyles,
            cursor: !state.isDisabled ? 'pointer' : 'default',
          };
        },
        control: (baseStyles, state) => {
          // remove backgroundColor & color
          const { backgroundColor, color, borderRadius, ...culledStyles } =
            baseStyles;
          return {
            ...culledStyles,
            cursor: !state.isDisabled ? 'pointer' : 'default',
          };
        },
      }}
      onChange={(option) => {
        handleChange(option?.value);
      }}
      components={{
        Control,
        IndicatorsContainer,
        IndicatorSeparator: null,
        MenuList,
        Option,
        SingleValue,
        ValueContainer,
      }}
    />
  );
}
