import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import Avatar from 'components/Avatar';
import LotteryRulesParticipantsActions from './LotteryRulesParticipantsActions';
import styles from './LotterySteps.module.scss';

function makeParticipantRow({
  id,
  firstName,
  lastName,
  avatar,
  email,
  jobTitle,
  company,
  tags,
  priorityGroup,
  priorityGroups,
  handlePriorityGroupSelect,
  handleParticipantDelete,
}) {
  return {
    id,
    name: () => (
      <div className="d-flex align-items-center">
        {firstName && lastName ? (
          <Avatar
            imgSrc={avatar}
            altText={`${firstName.charAt(0)}${lastName.charAt(0)}`}
            className="me-3"
            size="md"
          />
        ) : null}
        <div>
          <span className="fw-medium">
            {firstName} {lastName}
          </span>{' '}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    jobTitle,
    priorityGroup: () => (
      <Dropdown>
        <Dropdown.Toggle className={styles['dropdown-toggle']} variant="link">
          <PriorityGroupBadge priorityGroup={priorityGroup} showTooltip />
        </Dropdown.Toggle>

        <Dropdown.Menu role="menu" aria-label="Actions" as="ul">
          {priorityGroups?.map((p, index) => (
            <Dropdown.Item key={p.groupIndex} className="" as="li">
              <Button
                id={`set-priority-group-participant-${id}`}
                className="me-3 w-100"
                variant="text"
                onClick={() => handlePriorityGroupSelect(index, id)}
                disabled={priorityGroup?.groupIndex === index}
              >
                <PriorityGroupBadge priorityGroup={p} />
              </Button>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ),
    tags: () => <span className="badge user-tag rounded-pill">{tags}</span>,
    company,
    actions: (
      <LotteryRulesParticipantsActions
        priorityGroups={priorityGroups}
        currentPriorityGroup={priorityGroup}
        onPriorityGroupSelect={handlePriorityGroupSelect}
        onParticipantDelete={handleParticipantDelete}
        id={id}
      />
    ),
  };
}

export default makeParticipantRow;
