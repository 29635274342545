/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from 'react-bootstrap';
import ActionDots from '../../../assets/icons/action-dots.svg';
import Edit from '../../../assets/icons/edit.svg';
import ParticipantDetailsActions from './ParticipantDetailsActions';

function ParticipantDetailsView({
  participant,
  fields,
  onEditClick,
  setShouldDismiss,
}) {
  function getParticipantAttributeValue({ type }, value) {
    if (!value || value === '') {
      return <span className="fs-4 fw-normal text-light">None</span>;
    }

    switch (type) {
      case 'link':
        return (
          <a
            href={`${value}`}
            target="_blank"
            rel="noreferrer"
            className="fs-4 fw-normal text-primary"
          >
            {value}
          </a>
        );
      case 'email':
        return (
          <a
            href={`mailto:${value}`}
            target="_blank"
            rel="noreferrer"
            className="fs-4 fw-normal text-primary"
          >
            {value}
          </a>
        );
      case 'tags':
        return value.length === 0 ? (
          <span className="fs-4 fw-normal text-light">None</span>
        ) : (
          <div className="d-flex flex-row gap-2">
            <span className="badge user-tag rounded-pill" key={value}>
              {value}
            </span>
          </div>
        );
      default:
        return <span className="fs-4 fw-normal text-secondary">{value}</span>;
    }
  }

  function getParticipantAttribute(fieldName) {
    const field = fields[fieldName];
    const fieldValue = participant[fieldName];

    return (
      <div
        key={fieldName}
        className="d-flex align-items-start gap-4 align-self-stretch"
      >
        {field.icon}
        <div
          className="d-flex flex-column align-items-start gap-1"
          style={{ flex: '1 0 0' }}
        >
          <span className="fs-4 fw-medium text-secondary-emphasis">
            {field.label}
          </span>

          {getParticipantAttributeValue(field, fieldValue)}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column align-items-start align-self-stretch gap-5">
        <div className="gap-4 d-flex flex-row align-self-stretch align-items-center">
          <div className="fs-2 fw-medium text-black" style={{ flex: 1 }}>
            Participant Details
          </div>

          <Button
            variant="text"
            size="sm"
            className="d-flex gap-2"
            onClick={() => onEditClick()}
          >
            <Edit aria-hidden />
            <span>Edit info</span>
          </Button>

          <ParticipantDetailsActions
            email={participant.email}
            firstName={participant.firstName}
            id={participant.organizationUserId}
            lastName={participant.lastName}
            setShouldDismiss={setShouldDismiss}
          />
        </div>

        <div className="hr-divider" />
      </div>
      {Object.keys(fields).map((field) => getParticipantAttribute(field))}
    </>
  );
}

export default ParticipantDetailsView;
