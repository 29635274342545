import { parse, isValid } from 'date-fns';

const dateFormats = [
  'MMMM d, yyyy', // "January 1, 2024"
  'MMM d, yyyy', // "Jan 1, 2024"
  'M/d/yy', // "1/1/24"
  'MM/dd/yy', // "01/01/24"
  'MM-dd-yy', // "01-01-24"
  'MMMM do, yyyy', // "January 1st, 2024"
  'MMM do, yyyy', // "Jan 1st, 2024"
  'd-MMM-yy', // "1-Jan-24"
  'dd-MMM-yy', // "01-Jan-24"
  'd MMMM yyyy', // "1 January 2024"
  'dd MMMM yyyy', // "01 January 2024"
  'MMMM d', // "January 1"
  'MMM d', // "Jan 1"
  'M/d/yyyy', // "1/1/2024"
  'MM/dd/yyyy', // "01/01/2024"
  'MM-dd-yyyy', // "01-01-2024"
  'd-M-yyyy', // "1-1-24"
  'd-M-yyyy', // "1-1-2024"
  'MM-d-yyyy', // "01-1-24"
  'MM-d-yyyy', // "01-1-2024"
  'd.M.yy', // "1.1.24"
  'd.M.yyyy', // "1.1.2024"
  'dd.MM.yy', // "01.01.24"
  'dd.MM.yyyy', // "01.01.2024"
  'yyyy-MM-dd', // "2024-01-01"
  'yyyy/MM/dd', // "2024/01/01"
  "do 'of' MMMM, yyyy", // "1st of January, 2024"
  "'the' do 'of' MMMM, yyyy", // "the 1st of January, 2024"
  "'the' do 'of' MMMM, yyyy", // "the first of January, 2024"
  'd/MMMM/yyyy', // "1/January/2024"
  'dd/MMMM/yyyy', // "01/January/2024"
  'd-MMMM-yyyy', // "1-January-2024"
  'dd-MMMM-yyyy', // "01-January-2024"
  'yyyy MMMM d', // "2024 January 1"
  'yyyy MMM d', // "2024 Jan 1"
  'yyyy do MMM', // "2024 1st Jan"
  'yyyy d MMM', // "2024 1 Jan"
  'MMMM yyyy do', // "January 2024 1st"
  'MMMM yyyy d', // "January 2024 1"
  'MMM yyyy d', // "Jan 2024 1"
  'do MMM, yyyy', // "1st Jan, 2024"
  'd MMM, yyyy', // "1 Jan, 2024"
  'do MMMM, yyyy', // "1st January, 2024"
  'd MMMM, yyyy', // "1 January, 2024"
];

/**
 * A function which handles a dateString in any possible format as defined in dateFormats and returns a JavaScript Valid date. If valid date cannont be determined an error is returned.
 * @param {string} dateString
 * @param {Function} onFailedCallback
 * @returns {Date|null}
 */
function parseDateString(dateString, onFailedCallback) {
  if (!onFailedCallback) {
    throw new Error(
      'You must provide an onFailedCallback function as a second argument to parsedDateString',
    );
  }

  // implementation with comments
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dateFormats.length; i++) {
    const format = dateFormats[i];
    const parsedDate = parse(dateString, format, new Date());

    // Use date-fns' isValid function to check if parsedDate is a valid date
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  onFailedCallback();
  return null;
}

export default parseDateString;
