import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Ticket from 'assets/icons/ticket.svg';

function LotteryCreationSuccessDialog({ show, onDismiss }) {
  if (!show) {
    return null;
  }

  return (
    <Modal show={show} onHide={onDismiss} backdrop keyboard centered>
      <Modal.Header className="align-items-start pb-5" closeButton>
        <div className="d-flex flex-column align-items-start">
          <div className="bg-primary-lighter p-3 rounded-circle mb-4">
            <Ticket className="text-primary-darker" />
          </div>
          <h2 className="fs-2 fw-semibold w-100">Congratulations!</h2>
        </div>
      </Modal.Header>
      <Modal.Body className="py-0 pb-2 text-gray mb-0 fs-4">
        <p className="text-gray mb-0 fs-4">
          Your lottery is now Active. Participants will receive notifications
          letting them know the lottery is active and when their pick window
          opens!
        </p>
      </Modal.Body>
      <Modal.Footer className="m-0">
        <Button
          variant="primary"
          size="lg"
          className="w-100 justify-content-center"
          onClick={onDismiss}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LotteryCreationSuccessDialog;
