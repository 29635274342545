import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dialog from 'components/Dialog';
import AlertTriangle from 'assets/icons/alert-triangle.svg';
import usePageTracking from 'hooks/usePageTracking';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { protectedResources } from '../../../authConfig';
import PlanCard from './PlanCard';
import ActiveSubscriptionRequired from '../../../components/ActiveSubscriptionRequired/ActiveSubscriptionRequired';
import useUser from '../../../hooks/useUser';

function PlanAndBilling() {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.organizations.scopes.access_as_user,
  });

  const { hasActivePlan, organizationId, companyPlan } = useUser();
  usePageTracking('plan-and-billing');

  const [isFormLoading, setIsFormLoading] = useState(true);
  const [availablePlans, setAvailablePlans] = useState([]);

  const [showNoActivePlanDialog, setShowNoActivePlanDialog] = useState(false);

  const handleCloseNoActivePlanDialog = () => setShowNoActivePlanDialog(false);

  const [billingPortalLink, setBillingPortalLink] = useState('');

  const usdFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const [companyData, setCompanyData] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState({});

  const handlePlanSelected = (selectedPlan) => {
    execute(
      'POST',
      `${protectedResources.subscriptions.endpoint}/create-checkout-session`,
      {
        priceId: selectedPlan.stripePriceId,
        organizationId,
        planId: selectedPlan.id,
      },
    ).then((response) => {
      window.location.href = response.sessionUrl;
    });
  };

  useEffect(() => {
    const initPlanAndBilling = async () => {
      if (!organizationId) return;
      setIsFormLoading(true);
      const companyId = organizationId;
      const companyDetailsRequest = execute(
        'GET',
        `${protectedResources.organizations.endpoint}/${companyId}`,
      );

      const availablePlansRequest = await execute(
        'GET',
        `${protectedResources.subscriptions.endpoint}/plans`,
      );

      const [details, allSeaterPlans] = await Promise.all([
        companyDetailsRequest,
        availablePlansRequest,
      ]);

      setCompanyData(details);
      setCurrentSubscription(companyPlan);
      setAvailablePlans(allSeaterPlans);

      if (!hasActivePlan) {
        setShowNoActivePlanDialog(true);
      }

      // Get billing portal link. This link lasts for 24hrs.
      const sessionUrl = await execute(
        'GET',
        `${protectedResources.subscriptions.endpoint}/create-billing-portal-session/${companyId}`,
      );

      setBillingPortalLink(sessionUrl.sessionUrl);

      setIsFormLoading(false);
    };

    initPlanAndBilling();
  }, [execute, organizationId, companyPlan, hasActivePlan]);

  return isFormLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <Dialog
        show={showNoActivePlanDialog}
        handleClose={handleCloseNoActivePlanDialog}
        heading="No Active Plan"
        subHeading="Please select a plan to start utilizing AllSeater Features."
        Icon={AlertTriangle}
        ctaHandler={handleCloseNoActivePlanDialog}
        ctaLabel="Close"
      />

      <div className="w-100 d-flex flex-column align-items-start gap-6 py-0">
        <section className="d-flex flex-column align-items-start gap-5 w-100">
          <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
            <div className="d-flex flex-column align-items-start gap-1">
              <h2 className="m-0 fs-2 fw-semibold lh-lg">
                {companyData.name} AllSeater Plan Details
              </h2>
              <p className="m-0 fs-4 text-secondary">
                Update your organization&apos;s AllSeater plan here.
              </p>
            </div>
          </div>

          <section className="d-flex flex-column align-items-start gap-6 align-self-stretch">
            <ActiveSubscriptionRequired>
              <div className="hr-divider" />
              <div className="d-flex flex-column align-items-start gap-1 align-self-stretch">
                <h3 className="m-0 fs-2 fw-semibold lh-lg">
                  Current plan Details
                </h3>

                <ul className="fs-4 text-secondary">
                  <li>
                    You are on the <b>{currentSubscription.planName}</b>.
                  </li>
                  <li>
                    You have{' '}
                    <b>
                      {currentSubscription.lotteryParticipantMax} seats ({' '}
                      {currentSubscription.totalParticipantsInvited} allocated )
                    </b>
                    .
                  </li>
                  <li>
                    Your plan will renew on{' '}
                    <b>
                      {new Date(
                        currentSubscription.renewalDate,
                      ).toLocaleDateString()}
                    </b>
                    , charging your credit card{' '}
                    <b>
                      {usdFormatter.format(
                        currentSubscription.nextChargeAmount,
                      )}
                    </b>
                    .
                  </li>
                </ul>
              </div>
            </ActiveSubscriptionRequired>

            <div className="hr-divider" />

            <div className="gap-1 d-flex flex-column align-items-start align-self-stretch">
              <h3 className="m-0 fs-2 fw-semibold lh-lg">Change your plan</h3>
              <p className="m-0 fs-4 text-secondary">
                Flexible pricing that grows with you.
              </p>
            </div>

            <div className="d-flex flex-column flex-lg-row gap-5 align-items-start align-self-stretch">
              {availablePlans.length > 0 &&
                availablePlans.map((plan) => (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    isCurrent={
                      plan.id?.toLocaleLowerCase() ===
                      currentSubscription.id?.toLocaleLowerCase()
                    }
                    billingPortalLink={billingPortalLink}
                    onSelectPlan={() => handlePlanSelected(plan)}
                  />
                ))}
            </div>
          </section>
          <div className="hr-divider" />

          <div className="d-flex flex-column flex-lg-row align-items-start gap-8 align-self-stretch">
            <div
              className="d-flex flex-column align-items-start fw-medium fs-4"
              style={{ width: '17.5rem' }}
            >
              Manage my subscription
            </div>

            <div
              className="d-flex flex-column align-items-start gap-4"
              style={{ width: '32rem' }}
            >
              <span className="fs-4 text-secondary">
                To manage your subscription, upgrade, change your payment
                methods, update you billing information, or view your invoices,
                please visit the billing portal. If you have issues or
                questions, please contact our sales team.
              </span>

              <div className="d-flex gap-4 align-items-center">
                <Button href={billingPortalLink} as="a" variant="secondary">
                  Go to my billing portal
                </Button>

                <Button
                  variant="text"
                  className="text-primary"
                  as="a"
                  target="_blank"
                  href="mailto:sales@allseater.com"
                >
                  sales@allseater.com
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PlanAndBilling;
