import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { format, parseISO } from 'date-fns';
import ActionButtons from 'components/ActionButtons';
import FieldRow, { FieldRowLeft, FieldRowRight } from 'components/FieldRow';
import {
  DETAILS_STEP,
  EVENT_TYPE_OPTIONS,
  SCHEDULE_STEP,
  LotteryDetailsFields,
  LotteryScheduleFields,
  UNCLAIMED_TICKET_OPTIONS,
  RULES_STEP,
  LOTTERY_STYLE_OPTIONS,
  defaultTableColumns,
  PUBLISH_FORM_TYPE,
} from 'constants/addLottery';
import Avatar from 'components/Avatar';
import HeroImage from 'components/HeroImage/HeroImage';
import cn from 'utils/classNames';
import EditIcon from 'assets/icons/edit.svg';
import Marker from 'assets/icons/marker-tiny.svg';
import LinkExternal from 'assets/icons/link-external-tiny.svg';
import Check from 'assets/icons/check-small.svg';
import Ticket from 'assets/icons/ticket-tiny.svg';
import { TableBody, TableFooter, TableFrame } from 'components/Table';

import styles from './LotterySteps.module.scss';
import makeParticipantRow from './ParticipantRowFormatter';

const {
  LOTTERY_NAME_FIELD,
  START_DATE_FIELD,
  START_TIME_FIELD,
  END_DATE_FIELD,
  END_TIME_FIELD,
  DESCRIPTION_FIELD,
  EVENT_TYPE_FIELD,
  VENUE_NAME_FIELD,
  VENUE_LINK_FIELD,
  UNCLAIMED_TICKETS_FIELD,
  EVENT_LOGO_FIELD,
  EVENT_COVER_PHOTO_FIELD,
  EVENT_TICKETS,
  EVENT_TICKET_ID,
  ROW,
  SECTION,
  SEAT
} = LotteryDetailsFields;
const { EVENT_NAME, EVENT_DATE, TIME, ADDITIONAL_DETAILS } =
  LotteryScheduleFields;
const CONFIRM_DETAILS = 'confirm-details';
const CONFIRM_SCHEDULE_AND_TICKETS = 'confirm-schedule-and-tickets';
const CONFIRM_PARTICIPANTS = 'confirm-participants';
const EDIT_LABEL_CLASSES =
  'fs-4 d-flex align-items-center justify-content-between w-100 text-secondary-emphasis fw-semibold m-0';

const ITEMS_PER_PAGE = 10;
const DECREMENT_VALUE = 1;

// Handles the pagination of lottery participants and lottery schedules
const handlePaginateItems = (currentPage, paginateTarget = []) => {
  // Return an empty array if no current participant or current schedules page
  if (!currentPage) {
    return [];
  }
  const startIndex = (currentPage - DECREMENT_VALUE) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  return paginateTarget?.slice(startIndex, endIndex) || [];
};

function LotteryReviewAndPublish({
  unpublishedLottery,
  handleLotteryPublish,
  handleCancel,
  actionLabel,
  handleEditStep,
  isSubmitLoading,
}) {
  const unclaimedOption = UNCLAIMED_TICKET_OPTIONS.find(
    (option) =>
      option.value ===
      unpublishedLottery[DETAILS_STEP][UNCLAIMED_TICKETS_FIELD],
  );
  const UnclaimedOptionIcon = unclaimedOption?.Icon;
  const lotteryName = unpublishedLottery[DETAILS_STEP][LOTTERY_NAME_FIELD];
  const [currentSchedulesPage, setCurrentSchedulesPage] = useState(1);
  const [paginatedTicketSchedules, setPaginatedTicketSchedules] = useState([]);
  const [currentParticipantsPage, setCurrentParticipantsPage] = useState(1);
  const [paginatedParticipants, setPaginatedParticipants] = useState([]);
  const [sortBy, setSortBy] = useState(['jobTitle', 'asc']);
  // Set page totals for lottery participants & schedules
  const totalParticipants =
    unpublishedLottery[RULES_STEP]?.participants?.length || 0;
  const participantsPageTotal = Math.ceil(totalParticipants / ITEMS_PER_PAGE);
  // Convert dates into north american standard with slashes '/'
  let unpublishedEventSchedule =
    unpublishedLottery[SCHEDULE_STEP]?.eventSchedule;
  if (unpublishedEventSchedule) {
    unpublishedEventSchedule = unpublishedEventSchedule.map(
      (scheduledEvent) => {
        // parse ISO formatted date from AG Grid
        const date = parseISO(scheduledEvent[EVENT_DATE]);
        return {
          // Spread the existing data into the new object
          ...scheduledEvent,
          // Build the custom format date string 'm/d/YYYY'
          [EVENT_DATE]: format(date, 'EEEE, MMMM d, yyyy'),
        };
      },
    );
  }

  // Build an array of all assigned tickets to schedules. Data is used
  // for Lottery Schedule and Tickets table
  const unpublishedTickets =
    unpublishedLottery[DETAILS_STEP][EVENT_TICKETS];
  const schedulesWithTickets = [];
  unpublishedEventSchedule.forEach( evtSched => {
    const tickets = evtSched?.Tickets ?? [];
    tickets.forEach( ticketId => {
      const foundTicket = unpublishedTickets.find( t => t[EVENT_TICKET_ID] === ticketId );
      schedulesWithTickets.push(
        {
          [EVENT_NAME]: evtSched[EVENT_NAME],
          [EVENT_DATE]: evtSched[EVENT_DATE],
          [TIME]: evtSched[TIME],
          [ROW]: foundTicket[ROW],
          [SECTION]: foundTicket[SECTION],
          [SEAT]: foundTicket[SEAT],
          [ADDITIONAL_DETAILS]: evtSched[ADDITIONAL_DETAILS],
        }
      );
    });
  });

  const totalSchedulesWithTickets = schedulesWithTickets.length || 0;
  const schedulesPageTotal = Math.ceil(totalSchedulesWithTickets / ITEMS_PER_PAGE);
  
  // using the following date fields contruct the following string "Monday March 1, 2023 9:00AM PST to Friday March 5, 2023"
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  };

  useEffect(() => {
    setPaginatedParticipants(
      handlePaginateItems(
        currentParticipantsPage,
        unpublishedLottery[RULES_STEP]?.participants,
      ),
    );
    setPaginatedTicketSchedules(
      handlePaginateItems(currentSchedulesPage, schedulesWithTickets),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParticipantsPage, currentSchedulesPage, unpublishedLottery]);

  const startTimeFrame = new Date(
    `${unpublishedLottery[DETAILS_STEP][START_DATE_FIELD]} ${unpublishedLottery[DETAILS_STEP][START_TIME_FIELD]}`,
  ).toLocaleString('en-US', options);

  const endTimeFrame = new Date(
    `${unpublishedLottery[DETAILS_STEP][END_DATE_FIELD]} ${unpublishedLottery[DETAILS_STEP][END_TIME_FIELD]}`,
  ).toLocaleString('en-US', options);

  const timeFrame = `${startTimeFrame} PST to ${endTimeFrame}`;

  const lotteryDescription =
    unpublishedLottery[DETAILS_STEP][DESCRIPTION_FIELD];
  const venueName = unpublishedLottery[DETAILS_STEP][VENUE_NAME_FIELD];
  const venueLink = unpublishedLottery[DETAILS_STEP][VENUE_LINK_FIELD];
  const eventType = EVENT_TYPE_OPTIONS.find(
    (typeOption) =>
      typeOption.value === unpublishedLottery[DETAILS_STEP][EVENT_TYPE_FIELD],
  );
  // The lottery object from the RULES_STEP
  const lotteryStyleValue = unpublishedLottery[RULES_STEP];
  const lotteryStyleCode = lotteryStyleValue?.lotteryStyleCode;
  // Find the lottery style option that matches the chosen lottery style code
  const lotteryStyleOption = LOTTERY_STYLE_OPTIONS.find(
    (option) => option.value === lotteryStyleCode,
  );
  // Set the values for lottery style display
  const lotteryStyleTitle = lotteryStyleOption?.title;
  const lotteryStyleDescription = lotteryStyleOption?.description;
  const LotteryStyleOptionIcon = lotteryStyleOption?.Icon;

  const showPriorityGroups = () => {
    const lotteryStyle = LOTTERY_STYLE_OPTIONS.find(
      (option) => option.value === lotteryStyleValue,
    );
    return lotteryStyle?.priorityFeature;
  };

  const currentPageParticipantRows = paginatedParticipants?.map((participant) =>
    makeParticipantRow({
      avatar: participant?.ProfileImageUrl,
      company: participant?.CompanyName,
      email: participant?.Email,
      firstName: participant?.FirstName,
      id: participant?.OrganizationUserId,
      jobTitle: participant?.JobTitle,
      lastName: participant?.LastName,
      priorityGroup: unpublishedLottery[
        RULES_STEP
      ].priorityParticipants?.priorityGroups?.find(
        (group) => group.groupIndex === participant?.priorityGroupIndex,
      ),
    }),
  );

  const freeForAllTableColumn = {
    ...defaultTableColumns,
  };

  const priorityGroupTableColumns = {
    ...defaultTableColumns,
    priorityGroup: { label: 'Priority Group', sortBy: 'PriorityGroup' },
  };

  return (
    <div
      className="w-100 d-flex flex-column align-items-start gap-6"
      data-form_type={PUBLISH_FORM_TYPE}
    >
      <section className="d-flex flex-column align-items-start gap-5 w-100">
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
          <div className="d-flex flex-column align-items-start gap-1">
            <h2 className="m-0 fs-2 fw-semibold lh-lg">
              Step 4: Review & Publish
            </h2>
            <p className="m-0 fs-4 text-secondary">
              Review and finalize your lottery details before publishing.
            </p>
          </div>

          <ActionButtons
            actionLabel={actionLabel}
            handleAction={() => handleLotteryPublish()}
            handleCancel={handleCancel}
            isLoading={isSubmitLoading}
          />
        </div>

        <div className="hr-divider" />
      </section>
      <div id="LotteryReviewAndPublish" className="w-100">
        <div className="w-100 d-flex flex-column gap-5">
          <FieldRow fieldName={CONFIRM_DETAILS}>
            <FieldRowLeft>
              <p className={EDIT_LABEL_CLASSES}>
                Lottery Details{' '}
                <Button
                  variant="text"
                  className="text-primary"
                  onClick={() => handleEditStep(DETAILS_STEP)}
                >
                  <EditIcon /> Edit
                </Button>
              </p>
            </FieldRowLeft>
            <FieldRowRight wide>
              <div className="w-100 d-flex flex-column gap-6 fs-4 text-gray">
                <div>
                  <h2 className="fs-2 fw-semibold">{lotteryName}</h2>
                  <p className="fs-5">{timeFrame}</p>
                  <p>{lotteryDescription}</p>
                  <p className="d-flex gap-4 mb-0">
                    <a
                      href={venueLink}
                      className="text-gray d-flex align-items-center"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Marker className="me-2 text-gray-light" />
                      <span>{venueName}</span>
                      <LinkExternal className="ms-2 text-gray-light" />
                    </a>
                    <span className="text-gray d-flex align-items-center">
                      <Ticket className="me-2 text-gray-light" />{' '}
                      {eventType?.label}
                    </span>
                  </p>
                </div>
                <div className="hr-divider" />
                <div>
                  <p className="fs-4 fw-semibold mb-2">
                    Unclaimed Ticket Allocation
                  </p>
                  <div
                    id={`${unclaimedOption?.value}-card`}
                    className={cn([
                      'd-flex border justify-content-between border-gray-lighter p-4',
                      styles['option-card'],
                    ])}
                  >
                    <div
                      className="bg-gray-lighter d-flex align-items-center rounded-circle p-2 border border-2 border-gray-lightest"
                      style={{ width: 32, height: 32 }}
                    >
                      {UnclaimedOptionIcon && (
                        <UnclaimedOptionIcon className="text-gray-darker" />
                      )}
                    </div>
                    <div className="ps-4 pe-1 flex-grow-1">
                      <p className={cn(['text-gray-dark fw-medium mb-0'])}>
                        {unclaimedOption?.title}
                      </p>
                      <p className="mb-0">{unclaimedOption?.description}</p>
                    </div>
                    <div
                      className={cn([
                        styles['icon-wrapper'],
                        'border d-flex align-items-center rounded-circle',
                        'bg-info border-gray-lighter',
                      ])}
                    >
                      <Check className="text-white w-100" />
                    </div>
                  </div>
                </div>
                <div className="hr-divider" />
                <div>
                  <div className="row">
                    <div className="col-6 pe-3">
                      <p className="fs-4 fw-semibold mb-2">Event Logo</p>
                      <div className="d-flex py-8 align-items-center justify-content-center">
                        <Avatar
                          altText="LT"
                          altBackground="yellow"
                          size="md"
                          style={{
                            width: '4rem',
                            height: '4rem',
                            fontSize: '2rem',
                          }}
                          imgSrc={
                            unpublishedLottery[DETAILS_STEP][
                              EVENT_LOGO_FIELD
                            ] ?? null
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6 ps-3">
                      <p className="fs-4 fw-semibold mb-2">Event Cover Photo</p>
                      <HeroImage
                        imgSrc={
                          unpublishedLottery[DETAILS_STEP][
                            EVENT_COVER_PHOTO_FIELD
                          ] ?? null
                        }
                        style={{ width: '100%', height: '7.75rem' }}
                        className="rounded-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FieldRowRight>
          </FieldRow>
          <FieldRow fieldName={CONFIRM_SCHEDULE_AND_TICKETS}>
            <FieldRowLeft>
              <p className={EDIT_LABEL_CLASSES}>
                Lottery Schedule and Tickets{' '}
                <Button
                  variant="text"
                  className="text-primary"
                  onClick={() => handleEditStep(SCHEDULE_STEP)}
                >
                  <EditIcon /> Edit
                </Button>
              </p>
            </FieldRowLeft>
            <FieldRowRight wide>
              <TableFrame>
                <div className={styles.tableHeader}>
                  Lottery Schedule and Tickets
                  <span className="badge rounded-pill bg-black ms-4">
                    {totalSchedulesWithTickets}
                  </span>
                </div>

                <TableBody
                  columns={{
                    [EVENT_NAME]: 'Event',
                    [EVENT_DATE]: 'Date',
                    [TIME]: 'Time',
                    [ROW]: 'Row',
                    [SECTION]: 'Section',
                    [SEAT]: 'Seat',
                    [ADDITIONAL_DETAILS]: 'Additional Details',
                  }}
                  rows={paginatedTicketSchedules}
                />
                <TableFooter
                  currentPage={currentSchedulesPage}
                  pageTotal={schedulesPageTotal}
                  setCurrentPage={setCurrentSchedulesPage}
                />
              </TableFrame>
            </FieldRowRight>
          </FieldRow>
          <FieldRow fieldName={CONFIRM_PARTICIPANTS}>
            <FieldRowLeft>
              <p className={EDIT_LABEL_CLASSES}>
                Lottery Participants{' '}
                <Button
                  variant="text"
                  className="text-primary"
                  onClick={() => handleEditStep(RULES_STEP)}
                >
                  <EditIcon /> Edit
                </Button>
              </p>
            </FieldRowLeft>
            <FieldRowRight wide>
              <div className="mb-5">
                <p className="fs-4 fw-semibold mb-2">Lottery Style</p>
                <div
                  className={cn([
                    'd-flex border justify-content-between border-gray-lighter p-4',
                    styles['lottery-style-card'],
                  ])}
                >
                  <div
                    className="bg-gray-lighter d-flex align-items-center rounded-circle p-2 border border-2 border-gray-lightest"
                    style={{ width: 32, height: 32 }}
                  >
                    {LotteryStyleOptionIcon && (
                      <LotteryStyleOptionIcon className="text-gray-darker" />
                    )}
                  </div>
                  <div className="ps-4 pe-1 flex-grow-1">
                    <p className={cn(['text-gray-dark fw-medium mb-0'])}>
                      {lotteryStyleTitle}
                    </p>
                    <p className="mb-0">{lotteryStyleDescription}</p>
                  </div>
                  <div
                    className={cn([
                      styles['icon-wrapper'],
                      'border d-flex align-items-center rounded-circle',
                      'bg-info border-gray-lighter',
                    ])}
                  >
                    <Check className="text-white w-100" />
                  </div>
                </div>
              </div>
              <div className="hr-divider mb-5" />
              <TableFrame>
                <div className={styles.tableHeader}>
                  Lottery Participants
                  <span className="badge rounded-pill bg-black ms-4">
                    {totalParticipants}
                  </span>
                </div>
                <TableBody
                  columns={
                    showPriorityGroups
                      ? priorityGroupTableColumns
                      : freeForAllTableColumn
                  }
                  rows={currentPageParticipantRows}
                  sortBy={sortBy}
                  handleSortBy={setSortBy}
                />
                <TableFooter
                  currentPage={currentParticipantsPage}
                  pageTotal={participantsPageTotal}
                  setCurrentPage={setCurrentParticipantsPage}
                />
              </TableFrame>
            </FieldRowRight>
          </FieldRow>
        </div>
      </div>
      <div className="d-flex justify-content-lg-end w-100">
        <ActionButtons
          actionLabel={actionLabel}
          handleAction={() => handleLotteryPublish()}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
}

export default LotteryReviewAndPublish;
