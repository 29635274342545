import { ORGANIZATION_ADMIN, LOTTERY_PARTICIPANT } from 'constants';

/**
 * UserRoleAdmin component renders its children only if the user's role matches ORGANIZATION_ADMIN.
 * @param {object} props - Component props.
 * @param {string} props.userType - The type of user role.
 * @param {React.ReactNode} props.children - The content to be rendered if the user is an admin.
 * @returns {React.ReactNode|null} - Rendered component or null if the user is not an admin.
 */
export function UserRoleAdmin({ children, userType }) {
  const isAdmin = userType === ORGANIZATION_ADMIN;
  return isAdmin ? children : null;
}

/**
 * UserRoleParticipant component renders its children only if the user's role matches LOTTERY_PARTICIPANT.
 * @param {object} props - Component props.
 * @param {string} props.userType - The type of user role.
 * @param {React.ReactNode} props.children - The content to be rendered if the user is a participant.
 * @returns {React.ReactNode|null} - Rendered component or null if the user is not a participant.
 */
export function UserRoleParticipant({ children, userType }) {
  const isAdmin = userType === LOTTERY_PARTICIPANT;
  return isAdmin ? children : null;
}
