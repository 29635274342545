import React from 'react';
import {
  PRIORITY_STYLE_VALUE,
  FREE_FOR_ALL_STYLE_VALUE,
} from 'constants/addLottery';
import ConsoleLogger from 'utils/logger';
import cn from 'utils/classNames';

const consoleLogger = ConsoleLogger.getInstance();

/**
 * Renders a badge with the text and styles corresponding to a lottery system style.
 *
 * @param {Object} props - The style of the lottery, influencing the badge's appearance and text.
 * @param {'PRIORITY' | 'FREE_FOR_ALL'} [props.lotteryStyle] - Optional style of the lottery, influencing the badge's appearance and text. Defaults to FREE_FOR_ALL
 * @returns {ReactElement} A span element with the appropriate styling and text for the lottery badge.
 */
function LotteryStylePill({ lotteryStyle = FREE_FOR_ALL_STYLE_VALUE }) {
  let lotteryStyleBadge = {
    text: 'Unknown',
    classes: 'text-gray-dark bg-gray-lighter border-gray-light',
  };
  // Sets default value to free for all
  switch (lotteryStyle) {
    case FREE_FOR_ALL_STYLE_VALUE:
      lotteryStyleBadge = {
        text: 'Free For All',
        classes: 'text-purple-dark bg-purple-lighter border-purple-light',
      };
      break;
    case PRIORITY_STYLE_VALUE:
      lotteryStyleBadge = {
        text: 'Priority',
        classes: 'text-blue-dark bg-blue-lighter border-blue-light',
      };
      break;
    default:
      consoleLogger.log('Unknown lottery style', lotteryStyle);
      break;
  }
  return (
    <span className={cn([lotteryStyleBadge.classes, 'badge rounded-pill'])}>
      {lotteryStyleBadge.text}
    </span>
  );
}

export default LotteryStylePill;
