import TagManager from 'react-gtm-module';

/**
 * Handles a form event tracking
 * @param {string} formType
 * @param {string} event
 * @returns {void}
 */
export default function handleFormTracking(formType, event) {
  if (formType || event) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        form_type: formType,
      },
    });
  }
}
