import {
  USE_CONSOLE_LOGGING,
  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
} from 'constants';
import appInsights from './appInsights';

/**
 * A logger singleton for greater environmental control. Usage:
 * ```js
 * // Init the logger
 * const logger = ConsoleLogger.getInstance();
 * // log messages
 * logger.log('This is a log message');
 * logger.warn('This is a warning message');
 * logger.error('This is an error message');
 * ```
 */
const ConsoleLogger = (() => {
  let instance;

  // Private methods and properties
  const logMessage = (type, message) => {
    if (USE_CONSOLE_LOGGING === true) {
      // eslint-disable-next-line no-console
      console[type](message);
    }

    if (APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
      appInsights.trackTrace({ message, severityLevel: type });
    }
  };

  return {
    // Public methods
    log: (message) => {
      logMessage('log', message);
    },
    info: (message) => {
      logMessage('info', message);
    },
    table: (message) => {
      logMessage('table', message);
    },
    warn: (message) => {
      logMessage('warn', message);
    },
    error: (message) => {
      logMessage('error', message);
    },
    getInstance: () => {
      if (!instance) {
        instance = { ...ConsoleLogger };
      }
      return instance;
    },
  };
})();

export default ConsoleLogger;
