import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import LotteriesFiltered from './LotteriesFiltered';

function ActiveLotteries({ dashboard }) {
  usePageTracking(!dashboard ? 'all-lotteries' : false);
  return <LotteriesFiltered dashboard={dashboard} />;
}

export default ActiveLotteries;
