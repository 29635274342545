import { CURRENT_ORG_STORE } from 'constants';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import useUser from 'hooks/useUser';

import Building from 'assets/icons/building.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import styles from './UserOrganizationPicker.module.scss';

function UserOraganizationPicker() {
  const navigate = useNavigate();
  const {
    organizationUserRoles,
    changeOrganization,
    organizationName,
    organizationId,
  } = useUser();
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [selectedOrgName, setSelectedName] = useState('');
  const [showChangeModal, setShowChangeModal] = useState(false);

  const handleOrganizationPicker = (orgId) => {
    localStorage.setItem(CURRENT_ORG_STORE, orgId);
    changeOrganization(orgId);
    setSelectedOrgId(orgId);
  };

  // Set the transition state
  useEffect(() => {
    // If a selection was made, show dialog
    if (selectedOrgId && selectedOrgId !== organizationId) {
      const selectedOrg = organizationUserRoles.find(
        (org) => org.organizationId === selectedOrgId,
      );
      setSelectedName(selectedOrg.organizationName);
      setShowChangeModal(true);
    } else if (selectedOrgId === organizationId) {
      // Hide dialog if organization has been switched
      setShowChangeModal(false);
    }
  }, [selectedOrgId, organizationId, organizationUserRoles]);

  if (organizationUserRoles?.length === 1) {
    return null;
  }

  return (
    <>
      <p id="your-org" className="mb-0 text-gray-dark fw-semibold">
        Current Organization
      </p>
      <Dropdown
        className="w-100"
        onSelect={handleOrganizationPicker}
        aria-describedby="your-org"
      >
        <Dropdown.Toggle
          variant="tertiary-gray"
          className={`w-100 fs-3 shadow-sm ${styles['dropdown-toggle']}`}
        >
          <div className={`${styles['dropdown-toggle-inner']}`}>
            {organizationName}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {organizationUserRoles?.map((orgRole) => (
            <Dropdown.Item
              className={`p-3 fs-3 ${styles['dropdown-item']}`}
              eventKey={orgRole.organizationId}
              key={orgRole.organizationId}
              active={orgRole.organizationId === organizationId}
            >
              {orgRole.organizationName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={showChangeModal}
        backdrop
        keyboard={false}
        centered
        onHide={() => navigate('/')}
      >
        <Modal.Header className="align-items-start pb-5">
          <div className="w-100 d-flex justify-content-center">
            <h2 className="fs-2 fw-semibold w-100 text-center">
              Changing to {selectedOrgName}
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="py-0 pb-2 text-gray mb-0 fs-4">
          <div className="d-flex align-items-center justify-content-center">
            <Building className="text-primary" />
            <div style={{ width: 48 }}>
              <ArrowRight
                className={`text-black ${styles['animated-arrow']}`}
              />
            </div>
            <Building className="text-primary" />
          </div>
        </Modal.Body>
        <Modal.Footer className="m-0" />
      </Modal>
    </>
  );
}

export default UserOraganizationPicker;
