import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AllSeaterLogoSmall from 'assets/images/allseater-logo-small.svg';
import MenuIcon from 'assets/icons/menu.svg';
import TimesIcon from 'assets/icons/times.svg';
import cn from 'utils/classNames';
import {
  MenuNavigationTop,
  MenuNavigationBottom,
} from 'components/MenuNavigation';

import styles from './MobileMenu.module.scss';

function MobileMenu() {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  const toggleMenuOpen = () => {
    setIsOpened((prevState) => !prevState);
  };

  useEffect(() => {
    setIsOpened(false);
  }, [location]);

  return (
    <>
      <div
        className={cn([
          'd-flex d-lg-none flex-column bg-white position-fixed',
          styles['mobile-menu-wrapper'],
          isOpened && styles['menu-open'],
        ])}
      >
        <div className={styles['mobile-menu-header']}>
          <img
            src={AllSeaterLogoSmall}
            alt="Graphical logo of all seater brand."
          />
          <button
            type="button"
            className={cn([styles['menu-toggler'], 'px-2'])}
            onClick={toggleMenuOpen}
          >
            <span className="visually-hidden">Menu Toggle</span>
            {isOpened ? (
              <TimesIcon className={styles.icons} />
            ) : (
              <MenuIcon className={styles.icons} />
            )}
          </button>
        </div>

        <nav className={styles['mobile-menu-navigation']}>
          <MenuNavigationTop />
        </nav>
        <div className={styles['mobile-menu-footer-div']} />
        <div className={styles['mobile-menu-footer']}>
          <MenuNavigationBottom />
        </div>
      </div>
      <div
        className={cn([
          styles.backdrop,
          isOpened ? styles['backdrop-visible'] : styles['backdrop-hidden'],
        ])}
        role="button"
        aria-label="Close Menu"
        onClick={() => setIsOpened(false)}
        tabIndex={0}
        onKeyDown={(event) => event.key === 27 && setIsOpened(false)}
      />
    </>
  );
}

export default MobileMenu;
