import React from 'react';
import usePageTracking from 'hooks/usePageTracking';

export default function NoMatch() {
  usePageTracking('not-found');
  return (
    <div>
      <h1>No Page</h1>
      Sorry! We could not find what you&apos;re looking for...
    </div>
  );
}
