/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Button } from 'react-bootstrap';
import PlaceholderCircle from 'assets/icons/placeholder-circle.svg';

function ButtonGuide() {
  return (
    <div className="d-flex flex-column" style={{ gap: '2rem' }}>
      <div>
        <h5>Primary Button</h5>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          {/* TODO: all buttons should be refactored with custom component, icon buttons */}
          <Button variant="primary" size="sm">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="primary" size="md">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="primary" size="lg">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="primary" size="xl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="primary" size="xxl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          {/* Icon Button */}

          <Button className="btn-icon-sm" variant="primary" size="sm">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-md" variant="primary" size="md">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-lg" variant="primary" size="lg">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xl" variant="primary" size="xl">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xxl" variant="primary" size="xxl">
            <PlaceholderCircle />
          </Button>
        </div>
      </div>

      <div>
        <h5>Secondary Button</h5>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          <Button variant="secondary" size="sm">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="secondary" size="md">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="secondary" size="lg">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="secondary" size="xl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="secondary" size="xxl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          {/* Icon Button */}

          <Button className="btn-icon-sm" variant="secondary" size="sm">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-md" variant="secondary" size="md">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-lg" variant="secondary" size="lg">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xl" variant="secondary" size="xl">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xxl" variant="secondary" size="xxl">
            <PlaceholderCircle />
          </Button>
        </div>
      </div>

      <div>
        <h5>Secondary Gray Button</h5>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          <Button variant="tertiary" size="sm">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="tertiary" size="md">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="tertiary" size="lg">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="tertiary" size="xl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="tertiary" size="xxl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          {/* Icon Button */}

          <Button className="btn-icon-sm" variant="tertiary" size="sm">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-md" variant="tertiary" size="md">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-lg" variant="tertiary" size="lg">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xl" variant="tertiary" size="xl">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xxl" variant="tertiary" size="xxl">
            <PlaceholderCircle />
          </Button>
        </div>
      </div>

      <div>
        <h5>Text</h5>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: '2rem',
          }}
        >
          <Button variant="text" size="sm">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="text" size="md">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="text" size="lg">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="text" size="xl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          <Button variant="text" size="xxl">
            <PlaceholderCircle />
            Button CTA
            <PlaceholderCircle />
          </Button>

          {/* Icon Button */}

          <Button className="btn-icon-sm" variant="text" size="sm">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-md" variant="text" size="md">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-lg" variant="text" size="lg">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xl" variant="text" size="xl">
            <PlaceholderCircle />
          </Button>

          <Button className="btn-icon-xxl" variant="text" size="xxl">
            <PlaceholderCircle />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ButtonGuide;
