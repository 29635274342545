import React from 'react';
import { Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';

import UploadCloud from 'assets/icons/upload-cloud.svg';
import UserPlus from 'assets/icons/user-plus.svg';
import usePageTracking from 'hooks/usePageTracking';

function InvitedParticipants() {
  const {
    handleShowInvitationModal,
    invitedParticipantsTotal,
    isParticipantsLoading,
    setCurrentPage,
    invitedParticipants,
    invitedParticipantsTableColumns,
    currentPage,
    pageInviteParticipantsTotal,
    sortBy,
    handleSortBy,
  } = useOutletContext();
  usePageTracking('invited-participants');

  return (
    <TableFrame>
      <TableHeader
        title="Invited Participants"
        totalItems={invitedParticipantsTotal}
        description="These are participants that have been invited to AllSeater, but have not yet finished account creation."
      >
        <Button
          id="AddParticipantsMobile"
          className="d-block d-lg-none"
          variant="primary"
          onClick={handleShowInvitationModal}
        >
          <UserPlus className="me-2" />
          Add Participants
        </Button>
      </TableHeader>
      <TableBody
        columns={invitedParticipantsTableColumns}
        rows={invitedParticipants}
        sortBy={sortBy}
        handleSortBy={handleSortBy}
        isLoading={isParticipantsLoading}
        noRowsMessage={
          isParticipantsLoading
            ? 'Loading Invited Participants...'
            : 'No Participants Found'
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        NoRowsCTA={() =>
          !isParticipantsLoading && (
            <Button
              id="UploadParticipantsDesktop"
              variant="outline-primary"
              onClick={handleShowInvitationModal}
            >
              <UploadCloud className="me-2" />
              Upload New Participants
            </Button>
          )
        }
      />
      <TableFooter
        currentPage={currentPage}
        pageTotal={pageInviteParticipantsTotal}
        setCurrentPage={setCurrentPage}
      />
    </TableFrame>
  );
}

export default InvitedParticipants;
