import React, { useEffect, useState } from 'react';
import cn from 'utils/classNames';

import styles from './ProgressBar.module.scss';
import ConsoleLogger from '../../utils/logger';

const logger = ConsoleLogger.getInstance();

/**
 * A progress bar component.
 *
 * @param {Object} props
 * @param {number} props.progress - The value of the progress bar (between 0 and 100).
 * @param {string} props.wrapperClassNames - Additional class name for the progress bar wrapper.
 * @param {string} props.contentClassNames - Additional class name for the progress bar content.
 * @returns {JSX.Element} The rendered progress bar component.
 */
function ProgressBar({
  progress = 0,
  wrapperClassNames = '',
  contentClassNames = '',
}) {
  // Value must be a number and be between 0 and 100
  if (typeof progress !== 'number' || Number.isNaN(progress)) {
    logger.error('Progress value must be a number');

    // eslint-disable-next-line no-param-reassign
    progress = 0;
  }

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    // If the progress value is greater than 100, set it to 100. If its less than 0, set it to 0
    if (progress > 100) {
      setProgressValue(100);
    } else if (progress < 0) {
      setProgressValue(0);
    } else {
      setProgressValue(progress);
    }
  }, [progress]);

  return (
    <div
      data-testid="progress-bar"
      className={cn([styles['progress-bar-wrapper'], wrapperClassNames])}
    >
      <div
        style={{ width: `${progressValue}%` }}
        className={cn([styles['progress-bar-content'], contentClassNames])}
      />
    </div>
  );
}

export default ProgressBar;
