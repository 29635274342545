import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Badge, Button } from 'react-bootstrap';
import { formatISO } from 'date-fns';
import { NavLink } from 'react-router-dom';
import ActionButtons from 'components/ActionButtons';
import FieldRow, { FieldRowLeft, FieldRowRight } from 'components/FieldRow';
import useUser from 'hooks/useUser';
import cn from 'utils/classNames';
import Lock from 'assets/icons/lock-tiny.svg';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import ConsoleLogger from 'utils/logger';
import InviteParticipantsModal from 'pages/manage-participants/InviteParticipantsModal';

import Check from 'assets/icons/check-small.svg';
import Plus from 'assets/icons/plus.svg';
import Trash from 'assets/icons/trash.svg';

import {
  LOTTERY_STYLE_OPTIONS,
  RULES_FORM_TYPE,
  RULES_STEP,
} from 'constants/addLottery';
import styles from './LotterySteps.module.scss';
import LotteryRulesParticipants from './LotteryRulesParticipants';

const LOTTERY_STYLE_FIELD = 'lotteryStyleCode';
const PRIORITY_GROUPS_FIELD = 'priorityGroups';
const GROUP_NAME_FIELD = 'groupName';
const TICKET_LIMIT_PER_EVENT_FIELD = 'ticketLimitPerEvent';
const EVENT_LIMIT_FIELD = 'eventLimit';
const MINUTES_PICK_WINDOW_FIELD = 'minutesPickWindow';
const PRIORITY_GROUPS_PARTICIPANT_IDS_FIELD = 'priorityGroupParticipantIds';
const PARTICIPANTS = 'participants';

const PRIORITY_STYLE_VALUE = 'PRIORITY';
const FREE_FOR_ALL_STYLE_VALUE = 'FREE_FOR_ALL';

const PICK_WINDOW_TIME_OPTIONS = [
  { value: 5, label: '5 Minutes' },
  { value: 15, label: '15 Minutes' },
  { value: 30, label: '30 Minutes' },
  { value: 60, label: '1 Hour' },
  { value: 90, label: '1.5 Hours' },
  { value: 120, label: '2 Hours' },
  { value: 150, label: '2.5 Hours' },
  { value: 180, label: '3 Hours' },
  { value: 210, label: '3.5 Hours' },
  { value: 240, label: '4 Hours' },
];

const EVENT_ALLOWED_COUNT_OPTIONS = [...Array(50).keys()].map((x) => x + 1);
const TICKET_PER_ALLOWED_COUNT_OPTIONS = [...Array(10).keys()].map(
  (x) => x + 1,
);

const validationSchema = Yup.object().shape({
  [LOTTERY_STYLE_FIELD]: Yup.string().required(
    'Please select a lottery style option.',
  ),
});

const getDefaultPriorityGroups = (usePriorityGroups) => {
  const defaultProps = {
    ticketLimitPerEvent: 3,
    eventLimit: 2,
    minutesPickWindow: 15,
  };

  if (usePriorityGroups) {
    return [
      { groupIndex: 0, ...defaultProps },
      { groupIndex: 1, ...defaultProps },
      { groupIndex: 2, ...defaultProps },
    ];
  }
  return [
    {
      groupIndex: 0, // This field is only used for the UI, it's not sent to the backend
      ...defaultProps,
    },
  ];
};

/**
 * Lottery Detail Step, handles the steps state and when submitted invokes the handleLotteryDetails function.
 * @param {Object} props
 * @param {Function} props.handleLotteryRules formik onSubmit handler
 * @returns {JSX.Element}
 */
function LotteryRules({
  handleLotteryRules,
  handleCancel,
  actionLabel,
  unpublishedLottery,
}) {
  // eslint-disable-next-line no-unused-vars
  const { companyPlan, organizationId } = useUser();
  const [initialValues, setInitialValues] = useState({
    [LOTTERY_STYLE_FIELD]: '',
  });
  const [participants, setParticipants] = useState([]);
  const [isParticipantsLoading, setIsParticipantsLoading] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [newParticipantDateTime, setNewParticipantDateTime] = useState(
    formatISO(new Date()),
  );
  const handleShowInvitationModal = () => {
    setShowInvitationModal(true);
  };
  const [sortBy, setSortBy] = useState(['FirstName', 'asc']);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  const handleLotteryDraft = useCallback((unpublishedRules) => {
    // Do we have drafted rules?
    if (unpublishedRules) {
      // Pick out drafted participants and initial values
      const { participants: draftedParticipants, ...otherRules } =
        unpublishedRules;
      if (otherRules) {
        setInitialValues({
          ...otherRules,
        });
        setParticipants(draftedParticipants);
      }
    }
  }, []);

  const getAllParticipants = useCallback(async () => {
    setIsParticipantsLoading(true);
    const select = `OrganizationUserId, FirstName, LastName, CompanyName, Email, Tags, ProfileImageUrl, JobTitle`;
    const top = 2000;

    try {
      const res = await execute(
        'GET',
        `${protectedResources.participants.endpoint}/${organizationId}?$select=${select}&$top=${top}&$count=true`,
      );

      setParticipants(
        res?.value?.map((participant) => ({
          ...participant,
          priorityGroupIndex: 0,
        })),
      );

      handleLotteryDraft(unpublishedLottery[`${RULES_STEP}Draft`]);
    } catch (err) {
      ConsoleLogger.error(err);
      setParticipants([]);
    } finally {
      setIsParticipantsLoading(false);
    }
  }, [execute, organizationId, handleLotteryDraft, unpublishedLottery]);

  const getNewParticipants = useCallback(
    async (newSince) => {
      if (!newSince) {
        ConsoleLogger.error('No valid date provided.');
      }
      setIsParticipantsLoading(true);
      const select = `OrganizationUserId, FirstName, LastName, CompanyName, Email, Tags, ProfileImageUrl, JobTitle`;
      const top = 2000;

      try {
        const res = await execute(
          'GET',
          `${protectedResources.participants.endpoint}/${organizationId}?$select=${select}&$top=${top}&$count=true&$filter=Created gt ${newSince}`,
        );

        setParticipants((prevParticipants) => [
          ...(res?.value?.map((participant) => ({
            ...participant,
            priorityGroupIndex: 0,
          })) || []),
          ...prevParticipants,
        ]);

        handleLotteryDraft(unpublishedLottery[`${RULES_STEP}Draft`]);
      } catch (err) {
        ConsoleLogger.error(err);
        setParticipants([]);
      } finally {
        setIsParticipantsLoading(false);
      }
    },
    [execute, organizationId, handleLotteryDraft, unpublishedLottery],
  );

  const handleCloseInvitationModal = () => {
    setShowInvitationModal(false);
    // Trigger the fetch to display newly invited participants on modal close
    getNewParticipants(newParticipantDateTime);
    setNewParticipantDateTime(formatISO(new Date()));
  };

  // Set the initial value of Lottery Style based on company plan
  useEffect(() => {
    // Check if the plan has priority feature
    const isFeaturesPriority = companyPlan?.isFeaturesPriority
      ? PRIORITY_STYLE_VALUE
      : FREE_FOR_ALL_STYLE_VALUE;

    setInitialValues((prevInitValues) => ({
      ...prevInitValues,
      [LOTTERY_STYLE_FIELD]: isFeaturesPriority,
      priorityGroups: getDefaultPriorityGroups(companyPlan?.isFeaturesPriority),
    }));
  }, [companyPlan?.isFeaturesPriority]);

  // Set the initial value of Lottery Rules if we have a drafted lottery
  useEffect(() => {}, [unpublishedLottery]);

  // Gets the participants
  useEffect(() => {
    getAllParticipants();
  }, [getAllParticipants]);

  const resetParticipants = () => {
    setParticipants([]);
    getAllParticipants();
  };

  const onPriorityGroupSelect = (priorityGroupIndex, ...participantIds) => {
    const currentParticipants = participants.map((participant) => {
      if (participantIds.includes(participant.OrganizationUserId)) {
        // eslint-disable-next-line no-param-reassign
        participant.priorityGroupIndex = priorityGroupIndex;
      }
      return participant;
    });

    setParticipants(currentParticipants);
  };

  const onParticipantDelete = (...participantIds) => {
    setParticipants(
      participants.filter(
        (participant) =>
          !participantIds.includes(participant.OrganizationUserId),
      ),
    );
  };

  const mapFormValueToPayload = (values) => {
    if (values[LOTTERY_STYLE_FIELD] === FREE_FOR_ALL_STYLE_VALUE) {
      return {
        [LOTTERY_STYLE_FIELD]: values[LOTTERY_STYLE_FIELD],
        [PARTICIPANTS]: participants || [],
        priorityParticipants: initialValues,
        [PRIORITY_GROUPS_FIELD]: [values.priorityGroups[0]].map(
          (priorityGroup) => ({
            [GROUP_NAME_FIELD]: ``,
            [TICKET_LIMIT_PER_EVENT_FIELD]:
              priorityGroup.ticketLimitPerEvent || 0,
            [EVENT_LIMIT_FIELD]: priorityGroup.eventLimit || 0,
            [MINUTES_PICK_WINDOW_FIELD]: priorityGroup.minutesPickWindow || 0,
            [PRIORITY_GROUPS_PARTICIPANT_IDS_FIELD]: participants.map(
              (participant) => participant.OrganizationUserId,
            ),
          }),
        ),
      };
    }

    return {
      [LOTTERY_STYLE_FIELD]: values[LOTTERY_STYLE_FIELD],
      [PARTICIPANTS]: participants || [],
      priorityParticipants: initialValues,
      [PRIORITY_GROUPS_FIELD]: values.priorityGroups
        .map((priorityGroup) => ({
          [GROUP_NAME_FIELD]: `Priority Group ${priorityGroup.groupIndex + 1}`,
          [TICKET_LIMIT_PER_EVENT_FIELD]:
            priorityGroup.ticketLimitPerEvent || 0,
          [EVENT_LIMIT_FIELD]: priorityGroup.eventLimit || 0,
          [MINUTES_PICK_WINDOW_FIELD]: priorityGroup.minutesPickWindow || 0,
          [PRIORITY_GROUPS_PARTICIPANT_IDS_FIELD]: participants
            .filter(
              (participant) =>
                participant.priorityGroupIndex === priorityGroup.groupIndex,
            )
            .map((participant) => participant.OrganizationUserId),
        }))
        .filter(
          (priorityGroup) =>
            priorityGroup[PRIORITY_GROUPS_PARTICIPANT_IDS_FIELD].length > 0,
        ),
    };
  };

  return (
    <div className="w-100 d-flex flex-column align-items-start gap-6">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const payload = mapFormValueToPayload(values);
          handleLotteryRules({ payload, draft: { ...values, participants } });
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => {
          function isInvalid(field) {
            return !!(formik.touched[field] && formik.errors[field]);
          }

          function showPriorityGroups() {
            const lotteryStyleValue = formik.values[LOTTERY_STYLE_FIELD];
            const lotteryStyle = LOTTERY_STYLE_OPTIONS.find(
              (option) => option.value === lotteryStyleValue,
            );
            return lotteryStyle?.priorityFeature;
          }

          function handlePriorityGroupAdd() {
            let priorityGroups = formik.values.priorityGroups || [];
            if (priorityGroups.length >= 10) return;
            priorityGroups.push({
              // GroupIndex will be the the value of the latest item in the array + 1
              groupIndex:
                priorityGroups[priorityGroups.length - 1].groupIndex + 1,
              ticketLimitPerEvent: 0,
              eventLimit: 0,
              minutesPickWindow: 0,
            });

            priorityGroups = [...priorityGroups]; // Create a new array to trigger re-render
            formik.setFieldValue(PRIORITY_GROUPS_FIELD, priorityGroups);
          }

          function getRenderablePriorityGroups() {
            if (!formik.values.priorityGroups) return [];
            // If there's no priority groups rule selected, return the first element of the array
            if (!showPriorityGroups()) {
              return [formik.values.priorityGroups[0]];
            }
            return formik.values.priorityGroups;
          }

          function handlePriorityGroupRemove(priorityGroup) {
            const currentParticipants = [...participants];

            currentParticipants.forEach((participant) => {
              const participantPriorityGroupIndex =
                participant.priorityGroupIndex;

              if (participantPriorityGroupIndex === priorityGroup.groupIndex) {
                // eslint-disable-next-line no-param-reassign
                participant.priorityGroupIndex = 0;
              }

              if (participantPriorityGroupIndex > priorityGroup.groupIndex) {
                // eslint-disable-next-line no-param-reassign
                participant.priorityGroupIndex -= 1;
              }
            });

            let priorityGroups = formik.values.priorityGroups || [];
            priorityGroups = priorityGroups.filter(
              (group) => group.groupIndex !== priorityGroup.groupIndex,
            );

            // Re index priority groups
            priorityGroups = priorityGroups.map((group, index) => ({
              ...group,
              groupIndex: index,
            }));

            setParticipants(() => {
              formik.setFieldValue(PRIORITY_GROUPS_FIELD, priorityGroups);
              return currentParticipants;
            });
          }

          function getPriorityGroupMembersCount(priorityGroupIndex) {
            return participants?.filter(
              (participant) =>
                participant.priorityGroupIndex === priorityGroupIndex,
            ).length;
          }

          return (
            <>
              <section className="d-flex flex-column align-items-start gap-5 w-100">
                <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
                  <div className="d-flex flex-column align-items-start gap-1">
                    <h2 className="m-0 fs-2 fw-semibold lh-lg">
                      Step 3: Set Lottery Rules
                    </h2>
                    <p className="m-0 fs-4 text-secondary">
                      Tailor participant eligibility, ticket allocation, and
                      claim windows.
                    </p>
                  </div>

                  <ActionButtons
                    actionLabel={actionLabel}
                    handleAction={() => formik.handleSubmit()}
                    handleCancel={handleCancel}
                  />
                  <InviteParticipantsModal
                    show={showInvitationModal}
                    handleClose={handleCloseInvitationModal}
                  />
                </div>

                <div className="hr-divider" />
              </section>
              <Form
                id="LotteryDetails"
                className="w-100"
                data-form_type={RULES_FORM_TYPE}
              >
                <div className="w-100 d-flex flex-column gap-5">
                  <FieldRow fieldName={LOTTERY_STYLE_FIELD}>
                    <FieldRowLeft>
                      <label
                        htmlFor={LOTTERY_STYLE_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Select Lottery Style*
                      </label>
                      <p className="fs-4 text-secondary m-0">
                        Customize your lottery experience by selecting a
                        preferred style.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight wide>
                      <div className="w-100 d-flex flex-column gap-3 fs-4 text-gray">
                        {LOTTERY_STYLE_OPTIONS.map((lotteryStyleOption) => {
                          const isChecked =
                            formik.values[LOTTERY_STYLE_FIELD] ===
                            lotteryStyleOption.value;
                          const isFeatureLocked =
                            lotteryStyleOption.priorityFeature &&
                            !companyPlan?.isFeaturesPriority;
                          return (
                            <label
                              htmlFor={`${lotteryStyleOption.value}-mode-option`}
                              id={`${lotteryStyleOption.value}-card`}
                              key={lotteryStyleOption.value}
                              className={cn([
                                'd-flex justify-content-between border border-gray-lighter p-4',
                                isChecked && styles['active-option-card'],
                                styles['option-card'],
                                isFeatureLocked &&
                                  styles['disabled-option-card'],
                              ])}
                            >
                              <div
                                className={cn([
                                  isFeatureLocked
                                    ? 'bg-secondary-subtle'
                                    : 'bg-primary-lighter',
                                  'd-flex',
                                  'align-items-center',
                                  'rounded-circle',
                                  'p-2',
                                ])}
                                style={{ width: 32, height: 32 }}
                              >
                                <lotteryStyleOption.Icon
                                  className={cn([
                                    isFeatureLocked
                                      ? 'text-secondary-emphasis'
                                      : 'text-primary-darker',
                                  ])}
                                />
                              </div>
                              <div className="ps-4 pe-1 flex-grow-1">
                                <p
                                  className={cn([
                                    'text-gray-dark fw-medium mb-0',
                                    isChecked &&
                                      styles['active-option-card-header'],
                                  ])}
                                >
                                  {lotteryStyleOption.title}
                                </p>
                                <p className="mb-0 flex-grow-1 d-flex align-items-start gap-1">
                                  {lotteryStyleOption.description}
                                  {isFeatureLocked && (
                                    <Badge
                                      as={NavLink}
                                      to="/admin/account-settings/plan-billing"
                                      pill
                                      className="text-success bg-green-lighter border-1 border-green-light d-flex align-items-center gap-1"
                                    >
                                      <Lock /> Upgrade to unlock
                                    </Badge>
                                  )}
                                </p>
                                <Field
                                  type="radio"
                                  key={lotteryStyleOption.value}
                                  id={`${lotteryStyleOption.value}-mode-option`}
                                  className="visually-hidden"
                                  checked={isChecked}
                                  value={lotteryStyleOption.value}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  name={LOTTERY_STYLE_FIELD}
                                  aria-invalid={isInvalid(LOTTERY_STYLE_FIELD)}
                                  aria-describedby="name-errors"
                                  disabled={isFeatureLocked}
                                  required
                                />
                              </div>
                              <div
                                className={cn([
                                  styles['icon-wrapper'],
                                  'border d-flex align-items-center rounded-circle',
                                  isChecked
                                    ? 'bg-primary border-primary'
                                    : 'bg-white border-gray-lighter',
                                  isFeatureLocked &&
                                    styles['feature-locked-input'],
                                ])}
                              >
                                {isChecked && (
                                  <Check className="text-white w-100" />
                                )}
                              </div>
                            </label>
                          );
                        })}
                        {isInvalid(LOTTERY_STYLE_FIELD) && (
                          <div
                            id={`${LOTTERY_STYLE_FIELD}-errors`}
                            className="text-danger mt-1 fs-4"
                          >
                            {formik.errors[LOTTERY_STYLE_FIELD]}
                          </div>
                        )}
                      </div>
                    </FieldRowRight>
                  </FieldRow>
                  <FieldRow fieldName={LOTTERY_STYLE_FIELD}>
                    <FieldRowLeft>
                      <label
                        htmlFor={LOTTERY_STYLE_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Set Ticket Allocation*
                      </label>
                      <p className="fs-4 text-secondary m-0">
                        Configure and add custom ticket allocation and claim
                        window settings.
                      </p>
                      {showPriorityGroups() && (
                        <Button
                          variant="text"
                          className="px-0 mt-4 text-primary"
                          disabled={formik.values.priorityGroups.length >= 10}
                          onClick={() => handlePriorityGroupAdd(formik.values)}
                        >
                          <Plus className="me-2" />
                          Add Priority Group
                        </Button>
                      )}
                    </FieldRowLeft>
                    <FieldRowRight wide>
                      <div className="d-flex align-items-start flex-column gap-8 align-self-stretch">
                        {getRenderablePriorityGroups().map(
                          (priorityGroup, index) => (
                            <div
                              key={priorityGroup.groupIndex}
                              className="d-flex flex-column align-items-start gap-8 align-self-stretch"
                            >
                              {showPriorityGroups() && (
                                <div className="d-flex align-items-center justify-content-between align-self-stretch">
                                  <div className="d-flex align-items-center gap-2 align-self-stretch">
                                    <PriorityGroupBadge
                                      priorityGroup={priorityGroup}
                                      showTooltip={false}
                                    />
                                    <Badge
                                      pill
                                      className="bg-gray-lighter text-secondary border-gray-light"
                                    >
                                      {getPriorityGroupMembersCount(
                                        priorityGroup.groupIndex,
                                      )}{' '}
                                      Participants
                                    </Badge>
                                  </div>

                                  {formik.values.priorityGroups.length > 1 && (
                                    <Button
                                      variant="text"
                                      onClick={() =>
                                        handlePriorityGroupRemove(priorityGroup)
                                      }
                                    >
                                      <Trash />
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              )}

                              <div className="d-flex align-items-start flex-column flex-lg-row gap-4 align-self-stretch">
                                <div className="d-flex flex-column gap-1 align-items-start form-group align-self-stretch w-100">
                                  <label
                                    htmlFor={`priority-group-${priorityGroup.groupIndex}-event-limit`}
                                    className="fs-4 fw-medium text-secondary-emphasis mb-1"
                                  >
                                    # of Events Allowed*
                                  </label>
                                  <Field
                                    id={`priority-group-${priorityGroup.groupIndex}-event-limit`}
                                    name={`priorityGroups[${index}].eventLimit`}
                                    as="select"
                                    required
                                    className="form-control form-select"
                                    placeholder="# of Events Allowed"
                                    aria-invalid={isInvalid(
                                      `priorityGroups[${index}].eventLimit`,
                                    )}
                                    aria-describedby="name-errors"
                                  >
                                    {EVENT_ALLOWED_COUNT_OPTIONS.map(
                                      (value) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ),
                                    )}
                                  </Field>
                                </div>

                                <div className="d-flex flex-column gap-1 align-items-start form-group align-self-stretch w-100">
                                  <label
                                    htmlFor={`priority-group-${priorityGroup.groupIndex}-event-limit`}
                                    className="fs-4 fw-medium text-secondary-emphasis mb-1"
                                  >
                                    # of Tickets per Event*
                                  </label>
                                  <Field
                                    id={`priority-group-${priorityGroup.groupIndex}-ticket-limit`}
                                    name={`priorityGroups[${index}].ticketLimitPerEvent`}
                                    required
                                    as="select"
                                    className="form-select"
                                    placeholder="# of Tickets per Event"
                                    aria-invalid={isInvalid(
                                      `priorityGroups[${index}].ticketLimitPerEvent`,
                                    )}
                                    aria-describedby="name-errors"
                                  >
                                    {TICKET_PER_ALLOWED_COUNT_OPTIONS.map(
                                      (value) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ),
                                    )}
                                  </Field>
                                </div>

                                <div className="d-flex flex-column gap-1 align-items-start w-100">
                                  <label
                                    htmlFor={`priority-group-${priorityGroup.groupIndex}-minutes-pick-window`}
                                    className="fs-4 fw-medium text-secondary-emphasis mb-1"
                                  >
                                    Time Until Next Priority Group Starts
                                  </label>
                                  <Field
                                    id={`priority-group-${priorityGroup.groupIndex}-minutes-pick-window`}
                                    name={`priorityGroups[${index}].minutesPickWindow`}
                                    required
                                    className="form-select"
                                    placeholder="Time Until Next Priority Group Starts"
                                    as="select"
                                    aria-invalid={isInvalid(
                                      `priorityGroups[${index}].minutesPickWindow`,
                                    )}
                                    aria-describedby="name-errors"
                                  >
                                    {PICK_WINDOW_TIME_OPTIONS.map(
                                      ({ value, label }) => (
                                        <option key={value} value={value}>
                                          {`${label}`}
                                        </option>
                                      ),
                                    )}
                                  </Field>
                                </div>
                              </div>

                              {index !==
                                getRenderablePriorityGroups().length - 1 && (
                                <div className="hr-divider" />
                              )}
                            </div>
                          ),
                        )}
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <LotteryRulesParticipants
                    usePriorityGroups={showPriorityGroups()}
                    priorityGroups={getRenderablePriorityGroups()}
                    handleShowInvitationModal={handleShowInvitationModal}
                    participants={participants}
                    isParticipantsLoading={isParticipantsLoading}
                    sortBy={sortBy}
                    onPriorityGroupSelect={onPriorityGroupSelect}
                    onParticipantDelete={onParticipantDelete}
                    setSortBy={setSortBy}
                    handleReset={resetParticipants}
                  />
                </div>
              </Form>
              <div className="d-flex justify-content-lg-end w-100">
                <ActionButtons
                  actionLabel={actionLabel}
                  handleAction={() => formik.handleSubmit()}
                  handleCancel={handleCancel}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default LotteryRules;
