import React from 'react';
import AllSeaterLogo from 'assets/images/allseater-logo-small.svg';
import styles from './Loading.module.scss';

function Loading() {
  return (
    <div
      className={`align-items-center flex-column justify-content-center gap-8 d-flex ${styles['loading-wrapper']}`}
    >
      <img
        className={styles['loading-logo']}
        src={AllSeaterLogo}
        alt="allseater-logo"
      />
      <div className="text-center">
        <h3 className="fw-bold text-primary">Application is loading</h3>
        <p className="text-secondary">
          Application is being loaded and will be ready soon.
        </p>
      </div>

      <div className={styles['loading-bars']}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loading;
