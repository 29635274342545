import React from 'react';
import usePageTracking from 'hooks/usePageTracking';
import FaqIcon from '../../assets/icons/faq-icon.svg';

const accountCreation =
  'To create an account you must be invited to the AllSeater platform. Upon receiving an invitation email, there will be a link to create your account that pairs you with the organization that invited you.';
const lotteryParticipate =
  "To participate in a lottery, you must be part of an organization who has season tickets. Once a lottery is created and started, you will receive notice that you are a part of the lottery. If you've found that you were not included in a certain lottery, please reach out to your organization Admin.";
const ticketsWon =
  "To access tickets that you've won, look out for email updates from your Admin. They will disburse your tickets the week leading up to an event. Don't forget to 'Confirm' your tickets in the AllSeater platform, so the Admin will get notified to send your tickets.";
const friendsToLotteries =
  'To add friends to lotteries, please reach out to your Admin. They are the only users that can invite new participants to AllSeater.';
const ticketsReceived =
  'Admins should send your tickets about 3 days leading up to the event start date.';
const unableToAttend =
  "If you can't attend an event, don’t worry. Simply go to your AllSeater dashboard and reject tickets that were once claimed. Try to do this as soon as possible, so your Admin has the utmost time to redistribute tickets properly.";

function ParticipantFaq({ title, text }) {
  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div
        className="d-flex align-items-center justify-content-center bg-pink-light rounded-circle mb-4"
        style={{ width: 56, height: 56 }}
      >
        <div className="mb-1" style={{ width: 24, height: 24 }}>
          <FaqIcon className="text-pink-dark" />
        </div>
      </div>
      <h3 className="fs-1 fw-semibold lh-base mb-2 text-center">{title}</h3>
      <p className="rich-text text-center">{text}</p>
    </div>
  );
}

function ParticipantSupport() {
  usePageTracking('participant-support');
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
      <div className="d-flex flex-column flex-lg-row justify-content-center gap-16">
        <ParticipantFaq
          title="How do I create an account?"
          text={accountCreation}
        />
        <ParticipantFaq
          title="How do I participate in a lottery?"
          text={lotteryParticipate}
        />
        <ParticipantFaq
          title="How do I access tickets I've won?"
          text={ticketsWon}
        />
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-center gap-16 mt-lg-4">
        <ParticipantFaq
          title="Can I add my friends to lotteries?"
          text={friendsToLotteries}
        />
        <ParticipantFaq
          title="When do I receive tickets?"
          text={ticketsReceived}
        />
        <ParticipantFaq
          title="What if I can't attend an event?"
          text={unableToAttend}
        />
      </div>
    </div>
  );
}

export default ParticipantSupport;
