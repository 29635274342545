import React, { useEffect, useState } from 'react';
import ProgressBar from 'components/ProgressBar';
import TicketIconNormal from 'assets/icons/ticket.svg';

import styles from './TicketUsage.module.scss';

function TicketUsage({ tickets }) {
  const [ticketClaimingPercentage, setTicketClaimingPercentage] = useState(0);

  useEffect(() => {
    const claimedTickets = tickets.filter((ticket) => ticket.isClaimed);
    const percentage = (claimedTickets.length / tickets.length) * 100;
    setTicketClaimingPercentage(percentage);
  }, [tickets]);

  return (
    <div className="d-flex gap-3 align-items-center">
      <div className="d-flex flex-fill">
        <ProgressBar
          progress={ticketClaimingPercentage}
          contentClassNames={styles['custom-progress-bar']}
          wrapperClassNames="flex-grow-1 flex-shrink-1"
          label={`${ticketClaimingPercentage}% of tickets claimed`}
          visuallyHidden
        />
      </div>

      <span className="fw-medium text-gray">
        {Math.ceil(ticketClaimingPercentage)}%
      </span>
      <TicketIconNormal className="text-gray" />
    </div>
  );
}

export default TicketUsage;
