import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import TagBox from 'components/TagBox';
import UsersPlus from 'assets/icons/users-plus.svg';
import UserSquare from 'assets/icons/user-square.svg';
import InfoSquare from 'assets/icons/info-square.svg';
import useUser from 'hooks/useUser';
// import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import ConsoleLogger from 'utils/logger';
import handleFormTracking from 'utils/handleFormTracking';

const INVITE_PARTICIPANTS_FORM_TYPE = 'invite_participants';

const initialValues = {
  emails: '',
  userTag: '',
  details: '',
};

export default function InviteParticipantsModal({ show, handleClose, lotteryId }) {
  const [inviteResults, setInviteResults] = useState();
  const {
    organizationId,
    companyPlan: { lotteryParticipantMax, totalParticipantsInvited },
    expireContext,
  } = useUser();

  const { execute, isLoading } = useFetchWithMsal({
    scopes: protectedResources.invitation.scopes.access_as_user,
  });

  const remainingInvitations = lotteryParticipantMax - totalParticipantsInvited;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        emails: Yup.string()
          .required('Please provide at least one email address.')
          .test(
            'is-email-list',
            'Please provide a comma or breakline separated list of valid emails.',
            (value) => {
              if (!value) return true;

              // Split the value into individual email addresses
              let emails = value.split(/[,|\n]+/);
              emails = emails.filter((email) => !!email);

              // Validate each email using Yup's email() method
              return emails.every((email) =>
                Yup.string().email().required().isValidSync(email.trim()),
              );
            },
          )
          .test({
            name: 'lottery-max-participant',
            params: { lotteryParticipantMax },
            message: ({ value }) => {
              const emailsCount = value?.split(/[,|\n]+/).length;
              return `You have reached your limit of users for this account, please upgrade to add more participants. You are adding ${
                emailsCount - remainingInvitations
              } Participants over your current limit ${lotteryParticipantMax}.`;
            },
            test: (value) => {
              const emailsCount = value?.split(/[,|\n]+/).length;
              return emailsCount <= remainingInvitations;
            },
          }),
        userTag: Yup.string()
          .nonNullable('Please provide a valid user tag.')
          .max(16, 'Character limit exceeded, maximum sixteen.'),
        details: Yup.string().max(
          32,
          'Please enter no more than 32 characters.',
        ),
      }),
    [lotteryParticipantMax, remainingInvitations],
  );

  const handleDismiss = () => {
    handleClose();
  };

  const handleInviteParticipants = async (values) => {
    // transform emails into array of trimmed emails
    let emails = [];
    if (values?.emails) {
      // break up emails submitted
      emails = values?.emails.split(/[,|\n]+/).map((email) => email.trim());
      // filter out any empties
      emails = emails.filter((email) => !!email);
    }

    try {
      const res = await execute(
        'POST',
        `${protectedResources.invitation.endpoint}/participantinvite`,
        {
          emails,
          tags: values?.userTag ? [values?.userTag] : [],
          organizationId,
          additionalDetails: values?.details || '',
          lotteryId,
        },
      );

      const successes = res.filter(
        (invitation) => invitation.status === 'Success',
      );
      const exceptions = res.filter((invitation) => invitation.error);
      setInviteResults({ successCount: successes.length, exceptions });
      // Cause state to be refetched from the backend
      expireContext();
      handleFormTracking(INVITE_PARTICIPANTS_FORM_TYPE, 'form_complete');
    } catch (err) {
      ConsoleLogger.error(err);
      setInviteResults({
        successCount: 0,
        exceptions: [
          {
            email: 'failed',
            error:
              'Something went wrong, if the issue continues please contact support.',
          },
        ],
      });
    }
  };

  if (inviteResults) {
    return (
      <Modal
        show={show}
        onHide={handleDismiss}
        backdrop
        keyboard
        centered
        onExited={() => setInviteResults()}
      >
        <Modal.Header className="align-items-start pb-5" closeButton>
          <div className="d-flex flex-column align-items-start">
            <div className="bg-primary-lighter p-3 rounded-circle mb-4">
              <UsersPlus className="text-primary-darker" />
            </div>
            <h2 className="fs-2 fw-semibold w-100">
              {inviteResults.successCount} Invitation(s) Sent!
            </h2>
            <p className="text-gray mb-0 fs-4">
              You should see newly invited participants in the list after you
              dismiss this message box.
            </p>
          </div>
        </Modal.Header>
        {inviteResults.exceptions?.length > 0 && (
          <Modal.Body className="py-0 pb-2 text-gray mb-0 fs-4">
            <p className="text-gray fs-4 text-danger">
              Unfortunately we were not able to process all of the emails you
              provided:
            </p>
            <ul className="list-group">
              {inviteResults.exceptions?.map((exception) => (
                <li className="list-group-item" key={exception.email}>
                  {exception.error}
                </li>
              ))}
            </ul>
          </Modal.Body>
        )}
        <Modal.Footer className="m-0">
          <Button
            variant="primary"
            size="lg"
            className="w-100 justify-content-center"
            onClick={handleDismiss}
            disabled={isLoading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleDismiss} backdrop keyboard centered>
      <Modal.Header className="align-items-start pb-2" closeButton>
        <div className="d-flex flex-column align-items-start">
          <div className="bg-primary-lighter p-3 rounded-circle mb-4">
            <UsersPlus className="text-primary-darker" />
          </div>
          <h2 className="fs-2 fw-semibold w-100">Invite participants</h2>
          <p className="text-gray mb-0 fs-4">
            Quickly add new participants to AllSeater.
          </p>
        </div>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleInviteParticipants}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form
            id="InviteParticipants"
            data-form_type={INVITE_PARTICIPANTS_FORM_TYPE}
          >
            <Modal.Body className="py-0 pb-2">
              <div className="form-group">
                <label
                  htmlFor="emails"
                  className="form-label dark-text-emphasis"
                >
                  Enter email addresses
                </label>
                <Field
                  as="textarea"
                  id="emails"
                  name="emails"
                  className="form-control pb-20"
                  placeholder="You can copy and paste a list of emails..."
                  aria-invalid={formik.errors.emails && formik.touched.emails}
                  aria-describedby="emails-errors"
                />
                {formik.touched.emails && formik.errors.emails && (
                  <div id="emails-errors" className="text-danger">
                    {formik.errors.emails}
                  </div>
                )}
              </div>
              <div className="hr-divider my-6" />
              <h2 className="fs-2 fw-semibold w-100">
                Add additional information
              </h2>
              <p className="text-gray mb-6 fs-4">
                As an admin, you can provide additional information on behalf of
                users to enhance their profile and experience on AllSeater.
              </p>
              <div className="form-group mb-6 d-flex">
                <div className="pe-4">
                  <UserSquare className="text-info" />
                </div>
                <div className="w-90">
                  <label htmlFor="userTag" className="form-label">
                    User Tag
                  </label>
                  <TagBox
                    id="userTag"
                    isInvalid={formik.touched.userTag && formik.errors.userTag}
                    handleChange={(tag) => {
                      formik.setFieldValue('userTag', tag);
                    }}
                    value={{
                      label: formik.values.userTag,
                      value: formik.values.userTag,
                    }}
                    aria-describedby="tag-box-errors"
                  />
                  {formik.touched.userTag && formik.errors.userTag && (
                    <div id="tag-box-errors" className="text-danger">
                      {formik.errors.userTag}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group d-flex">
                <div className="pe-4">
                  <InfoSquare className="text-info" />
                </div>
                <div className="w-100">
                  <label
                    htmlFor="details"
                    className="form-label dark-text-emphasis"
                  >
                    Additional Details
                  </label>
                  <Field
                    id="details"
                    name="details"
                    className="form-control"
                    placeholder="Briefly explain why user is invited to join..."
                    aria-invalid={
                      formik.touched.details && formik.errors.details
                    }
                    aria-describedby="details-errors"
                  />
                  {formik.touched.details && formik.errors.details && (
                    <div id="details-errors" className="text-danger">
                      {formik.errors.details}
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="row gap-3 m-0">
              <div className="col p-0">
                <Button
                  variant="tertiary"
                  size="lg"
                  className="w-100 justify-content-center"
                  onClick={() => {
                    formik.resetForm();
                    handleDismiss();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
              <div className="col p-0">
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 justify-content-center"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? '...Sending Invites' : 'Send Invites'}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
