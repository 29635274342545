import { ORGANIZATION_ADMIN } from 'constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import useUser from 'hooks/useUser';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import Avatar from 'components/Avatar';
import DragAndDrop from 'components/DragAndDrop';
import HeroImage from 'components/HeroImage/HeroImage';
import EmailIcon from 'assets/icons/email.svg';
import SvgIcon from 'components/SvgIcon';
import InfoIcon from 'assets/icons/info-circle-green.svg';
import TimesIcon from 'assets/icons/times.svg';
import CheckIcon from 'assets/icons/check.svg';
import { UserRoleAdmin, UserRoleParticipant } from 'components/UserRole';
import usePageTracking from 'hooks/usePageTracking';
import ConsoleLogger from 'utils/logger';
import cn from 'utils/classNames';
import styles from '../AccountSettings.module.scss';

function UserProfile() {
  const { organizationUserRole } = useUser();
  const isAdmin = organizationUserRole === ORGANIZATION_ADMIN;
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const disabledCompany = !isAdmin;
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.userprofiles.scopes.access_as_user,
  });
  usePageTracking('user-profile');

  let formValidationObj = {
    firstName: yup
      .string()
      .required('First name is required.')
      .min(3, "First name can't be less than 3 characters."),
    lastName: yup
      .string()
      .required('Last name is required.')
      .min(3, "Last name can't be less than 3 characters."),
  };

  // Participants do not have a profile name so remove from validation
  if (!isAdmin) {
    formValidationObj = {
      firstName: yup
        .string()
        .required('First name is required.')
        .min(3, "First name can't be less than 3 characters."),
      lastName: yup
        .string()
        .required('Last name is required.')
        .min(3, "Last name can't be less than 3 characters."),
    };
  }

  const formValidation = yup.object(formValidationObj);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactPhoneNumber: '',
      jobTitle: '',
      instagramProfile: '',
      linkedInProfile: '',
      profileCoverPhoto: '',
      profilePhoto: '',
      twitterProfile: '',
      company: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      profileName: '',
    },
    onSubmit: (values) => {
      execute(
        'PUT',
        `${protectedResources.userprofiles.endpoint}/current`,
        values,
      )
        .then((updatedProfile) => {
          if (updatedProfile) {
            formik.setValues({
              ...formik.values,
              ...updatedProfile,
            });
            setAlerts([
              {
                severity: 'success',
                text: 'Profile updated successfully.',
              },
            ]);
          }
          // Scroll user to the top of the page where the success message is displayed
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
        .catch((error) => {
          setAlerts([
            {
              severity: 'danger',
              text: error.message,
            },
          ]);
          ConsoleLogger.error(error);
        });
    },
    validationSchema: formValidation,
  });

  const { formValue, setFormValue } = {
    formValue: formik.value,
    setFormValue: formik.setValues,
  };

  const getUserProfile = useCallback(async () => {
    if (!organizationUserRole) return;
    setIsFormLoading(true);

    const currentUserProfile = await execute(
      'GET',
      `${protectedResources.userprofiles.endpoint}/current`,
    );

    if (currentUserProfile) {
      setFormValue({
        ...formValue,
        ...currentUserProfile,
      });

      setIsFormLoading(false);
    }
  }, [execute, organizationUserRole, formValue, setFormValue]);

  // TODO: This is a temporary solution, we need to work in an in app notification system to display alerts
  const displayAlerts = () =>
    alerts.map((alert) => (
      <Alert
        transition
        key={alert}
        variant={alert.severity}
        className="w-100"
        onClose={() =>
          setAlerts((currentAlerts) => {
            const newAlerts = [...currentAlerts];
            newAlerts.splice(newAlerts.indexOf(alert), 1);
            return newAlerts;
          })
        }
        dismissible
      >
        {alert.text}
      </Alert>
    ));

  const getPasswordValidation = () => {
    const validations = [];

    // Only run the validation if the user has touched the newPassword field and it has a value
    if (formik.touched.newPassword && formik.values.newPassword) {
      if (!formik.values.currentPassword) {
        validations.push({
          message: 'Current password must be provided.',
          valid: false,
        });
      }

      validations.push({
        message: 'New password must be more than 8 characters.',
        valid: formik.values.newPassword.length > 8,
      });

      // Password must contain at least 1 uppercase character
      validations.push({
        message: 'New password must contain at least 1 uppercase character.',
        valid: /[A-Z]/.test(formik.values.newPassword),
      });

      // Password must contain at least 1 special character. (e.g. !&@#$%)
      validations.push({
        message:
          'Password must contain at least 1 special character. (e.g. !&@#$%)',
        valid: /[^A-Za-z0-9]/.test(formik.values.newPassword),
      });

      if (
        formik.touched.confirmPassword &&
        formik.values.newPassword !== formik.values.confirmPassword
      ) {
        validations.push({
          message: 'New password and confirm password must match.',
          valid: false,
        });
      }
    }
    return validations;
  };

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const handleSaveClick = () => {
    formik.validateForm().then((errors) => {
      const firstErrorField = Object.keys(errors)[0];
      const errorField = document.getElementById(firstErrorField);
      if (errorField) {
        errorField.focus();
      }
    });
  };

  const actionButtons = () => (
    <>
      <Button variant="tertiary" onClick={getUserProfile}>
        Cancel
      </Button>
      <Button type="submit" variant="primary" onClick={handleSaveClick}>
        Save
      </Button>
    </>
  );

  // eslint-disable-next-line no-unused-vars
  const setImageFormField = (event, fieldName) => {
    // Because of the way the useFetchWithMsal hook works, we cannot send the file as a file upload, so we are sending it as a base64 instead, will have to look if there is a better way to do this
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onloadend = () => {
      formik.setValues({
        ...formik.values,
        [`${fieldName}`]: reader.result,
      });
    };
  };

  return isFormLoading ? (
    <div>Loading...</div>
  ) : (
    <Form
      className="w-100 d-flex flex-column align-items-start gap-6"
      onSubmit={formik.handleSubmit}
    >
      {displayAlerts()}

      <section className="d-flex flex-column align-items-start gap-5 w-100">
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
          <div className="d-flex flex-column align-items-start gap-1">
            <h2 className="m-0 fs-2 fw-semibold lh-lg">My Profile</h2>
            <p className="m-0 fs-4 text-secondary">
              Update your photo and personal details here.
            </p>
          </div>

          <div className="d-flex align-items-center gap-3">
            {actionButtons()}
          </div>
        </div>

        <div className="hr-divider" />
      </section>

      <div className="w-100 d-flex flex-column gap-5">
        <div
          id="public-profile-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Public profile
            </p>
            <p className="fs-4 text-secondary m-0">
              This will be displayed on your profile.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <Form.Group controlId="firstName" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                First Name*
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                type="text"
                aria-invalid={
                  formik.errors.firstName && formik.touched.firstName
                }
                aria-describedby="first-name-errors"
              />

              <Form.Text id="first-name-errors" className="text-danger">
                {formik.errors.firstName && formik.touched.firstName
                  ? formik.errors.firstName
                  : null}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="lastName" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                Last Name*
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                type="text"
                aria-invalid={formik.errors.lastName && formik.touched.lastName}
                aria-describedby="last-name-errors"
              />

              <Form.Text
                id="last-name-errors"
                className="text-danger"
                aria-live="polite"
              >
                {formik.errors.lastName && formik.touched.lastName
                  ? formik.errors.lastName
                  : null}
              </Form.Text>
            </Form.Group>
            {/* Content shown for admin */}
          </div>
        </div>
        <div className="hr-divider" />

        <div
          id="additional-information-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Additional Information
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            {/* Content shown for admin */}
            <UserRoleAdmin userType={organizationUserRole}>
              <Form.Group controlId="email" className="w-100">
                <Form.Label className="fs-4 text-secondary-emphasis">
                  Email Sign In
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <SvgIcon component={EmailIcon} />
                  </InputGroup.Text>
                  <Form.Control
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="jobTitle" className="w-100">
                <Form.Label className="fs-4 text-secondary-emphasis">
                  Job title
                </Form.Label>
                <Form.Control
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.jobTitle}
                  type="text"
                />
              </Form.Group>
            </UserRoleAdmin>
            <Form.Group controlId="company" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                Company
              </Form.Label>
              <Form.Control
                disabled={disabledCompany}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company}
                type="text"
              />
            </Form.Group>
          </div>
        </div>

        <div className="hr-divider" />

        <div
          id="profile-photo-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Profile photo
            </p>
            <p className="fs-4 text-secondary m-0">
              Enhance your profile with a professional profile photo.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <div className="d-flex flex-column flex-lg-row gap-8 align-items-start justify-content-start w-100">
              <div
                className={cn([
                  styles['event-image-wrapper'],
                  'd-flex align-items-center justify-content-center',
                ])}
              >
                <Avatar
                  altText="C"
                  altBackground="yellow"
                  size="md"
                  style={{
                    width: '4rem',
                    height: '4rem',
                    fontSize: '2rem',
                  }}
                  imgSrc={formik.values.profilePhoto ?? null}
                />
              </div>

              <DragAndDrop
                multiple={false}
                onFileUpload={(event) =>
                  setImageFormField(event, 'profilePhoto')
                }
                accept={{ 'image/*': ['.png', '.svg', '.jpg', '.gif'] }}
                maxSize={5 * 1024 * 1024}
                uploadDescription="SVG, PNG, JPG or GIF (max. 800x800px)"
                controlId="profilePhoto"
                altText="Profile photo upload field"
              />
            </div>
          </div>
        </div>

        <div className="hr-divider" />

        <div
          id="cover-photo-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Profile cover photo
            </p>
            <p className="fs-4 text-secondary m-0">
              Express yourself with a captivating cover photo.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <div className="d-flex flex-column flex-lg-row gap-8 align-items-start justify-content-start w-100">
              <div
                style={{ width: '7.25rem' }}
                className="d-flex align-items-center justify-content-center"
              >
                <HeroImage
                  imgSrc={formik.values.profileCoverPhoto ?? null}
                  style={{ width: '7.25rem', height: '4rem' }}
                  className="rounded-2"
                />
              </div>

              <DragAndDrop
                multiple={false}
                onFileUpload={(event) =>
                  setImageFormField(event, 'profileCoverPhoto')
                }
                accept={{ 'image/*': ['.png', '.svg', '.jpg', '.gif'] }}
                maxSize={5 * 1024 * 1024}
                uploadDescription="SVG, PNG, JPG or GIF (max. 800x800px)"
                controlId="coverPhoto"
                altText="Cover photo upload field"
              />
            </div>
          </div>
        </div>

        <div className="hr-divider" />

        <div
          id="password-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Password
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            {/* Content shown for participants */}
            <UserRoleParticipant userType={organizationUserRole}>
              <div
                className={cn([
                  'd-flex bg-white border p-6',
                  styles['password-linkedin-container'],
                ])}
              >
                <div className="pe-4">
                  <InfoIcon />
                </div>
                <div>
                  <p className="fs-2 fw-semibold mb-1">
                    Did you sign in with LinkedIn?
                  </p>
                  <p className="fs-4 fw-normal mb-6">
                    If so, please visit LinkedIn to change your password
                    accordingly.
                  </p>
                  <a
                    href="https://linkedin.com/"
                    rel="noreferrer"
                    target="_blank"
                    className="btn btn-primary d-inline-flex"
                  >
                    Visit LinkedIn
                  </a>
                </div>
              </div>
            </UserRoleParticipant>

            <Form.Group controlId="currentPassword" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                Current password
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
                type="password"
              />
            </Form.Group>

            <Form.Group controlId="newPassword" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                New password
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                type="password"
                aria-describedby="password-validation"
                aria-invalid={getPasswordValidation().some(
                  (validation) => !validation.valid,
                )}
              />
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="w-100">
              <Form.Label className="fs-4 text-secondary-emphasis">
                Confirm new password
              </Form.Label>
              <Form.Control
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                type="password"
                aria-describedby="password-validation"
                aria-invalid={getPasswordValidation().some(
                  (validation) => !validation.valid,
                )}
              />
              <div
                id="password-validation"
                className="gap-1 d-flex flex-column mt-2"
              >
                {getPasswordValidation().map((validation) => (
                  <div
                    key={validation.message}
                    className={`d-flex align-items-center gap-2 fs-4 ${
                      validation.valid ? 'text-success' : 'text-danger'
                    }`}
                  >
                    <SvgIcon
                      className={styles['password-validation-icon']}
                      component={validation.valid ? CheckIcon : TimesIcon}
                    />
                    {validation.message}
                  </div>
                ))}
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="hr-divider" />
        <div
          id="social-profiles-row"
          className="d-flex flex-column flex-lg-row gap-8 align-items-start w-100"
        >
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: '17.5rem' }}
          >
            <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
              Social profiles
            </p>
            <p className="fs-4 text-secondary m-0">
              Enhance your online presence by adding your social media profiles.
            </p>
          </div>

          <div
            className="d-flex flex-column align-items-start gap-4"
            style={{ width: '32rem', maxWidth: '100%' }}
          >
            <Form.Group controlId="linkedInProfile" className="w-100">
              <Form.Label className="visually-hidden">LinkedIn URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>linkedin.com/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.linkedInProfile}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="twitterProfile" className="w-100">
              <Form.Label className="visually-hidden">Twitter URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>twitter.com/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.twitterProfile}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="instagramProfile" className="w-100">
              <Form.Label className="visually-hidden">Instagram URL</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>instagram.com/</InputGroup.Text>
                <Form.Control
                  onChange={formik.handleChange}
                  value={formik.values.instagramProfile}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
        <div className="hr-divider" />
      </div>

      <section className="d-flex align-items-center justify-content-center gap-3 justify-content-lg-end w-100">
        {actionButtons()}
      </section>
    </Form>
  );
}

export default UserProfile;
