import { ORGANIZATION_ADMIN, LOTTERY_PARTICIPANT } from 'constants';
import { React, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NoMatch from 'pages/NoMatch';
import AdminDashboard, {
  UpcomingEvents,
  UpcomingTickets,
  OnHoldTickets,
  ArchivedTickets,
} from 'pages/admin-dashboard';
import ParticipantDashboard from 'pages/participant-dashboard';
import StyleGuide from 'pages/style-guide/StyleGuide';
import AccountSettings from 'pages/account-settings/AccountSettings';
import CompanyDetails from 'pages/account-settings/company-details/CompanyDetails';
import UserProfile from 'pages/account-settings/user-profile/UserProfile';
import Notifications from 'pages/account-settings/notifications/Notifications';
import Integrations from 'pages/account-settings/integrations/Integrations';
import SeasonLotteries, {
  AllLotteries,
  ActiveLotteries,
  UpcomingLotteries,
  CompleteLotteries,
  ArchivedLotteries,
} from 'pages/season-lotteries';
import SeasonLotteryDetail, {
  EventSchedule,
  SeasonLotteryDetailTickets,
} from 'pages/season-lottery-detail';
import EventDetail from 'pages/event-detail';
import ManageParticipants from 'pages/manage-participants';
import AllParticipants from 'pages/manage-participants/AllParticipants';
import AddLottery from 'pages/add-lottery/AddLottery';
import PlanAndBilling from 'pages/account-settings/plan-billing/PlanAndBilling';
import UnclaimedTickets from 'pages/tickets/UnclaimedTickets';
import Tickets from 'pages/tickets/Tickets';
import ClaimedTickets from 'pages/tickets/ClaimedTickets';
import useUser from 'hooks/useUser';
import ProtectedRoute from 'components/ProtectedRoute';
import SeasonLotteryDetailParticipants from 'pages/season-lottery-detail/SeasonLotteryDetailParticipants';
import Reports from 'pages/reports/Reports';
import Support from 'pages/support/Support';
import Privacy from 'pages/privacy/Privacy';
import InvitedParticipants from 'pages/manage-participants/InvitedParticipants';

function Router() {
  const { organizationUserRole } = useUser();
  const memoizedUserRole = useMemo(
    () => organizationUserRole,
    [organizationUserRole],
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            requiredRole={ORGANIZATION_ADMIN}
            userRole={memoizedUserRole}
            redirectPath="/participant"
          >
            <Navigate to="/admin" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute
            requiredRole={ORGANIZATION_ADMIN}
            userRole={memoizedUserRole}
            redirectPath="/participant"
          >
            <AdminDashboard />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="upcoming-events" />} />
        <Route path="upcoming-events" element={<UpcomingEvents />} />
        <Route path="upcoming-tickets" element={<UpcomingTickets />} />
        <Route path="on-hold-tickets" element={<OnHoldTickets />} />
        <Route path="archived-tickets" element={<ArchivedTickets />} />
      </Route>
      <Route path="support" element={<Support />} />
      <Route path="/reports" element={<Reports />} />
      <Route
        path="/participant"
        element={
          <ProtectedRoute
            requiredRole={LOTTERY_PARTICIPANT}
            userRole={memoizedUserRole}
            redirectPath="/admin"
          >
            <ParticipantDashboard />
          </ProtectedRoute>
        }
      />
      <Route index path="/style-guide" element={<StyleGuide />} />
      <Route path="/tickets" element={<Tickets />}>
        <Route path="" element={<Navigate to="claimed" />} />
        <Route path="unclaimed" element={<UnclaimedTickets />} />
        <Route path="claimed" element={<ClaimedTickets />} />
      </Route>
      <Route
        path="/manage-participants"
        element={
          <ProtectedRoute
            requiredRole={ORGANIZATION_ADMIN}
            userRole={memoizedUserRole}
            redirectPath="/participant"
          >
            <ManageParticipants />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="all-participants" />} />
        <Route path="all-participants" element={<AllParticipants />} />
        <Route path="invited-participants" element={<InvitedParticipants />} />
      </Route>
      {/* Account settings route for admin */}
      <Route
        path="/admin/account-settings"
        element={
          <ProtectedRoute
            requiredRole={ORGANIZATION_ADMIN}
            userRole={memoizedUserRole}
            redirectPath="/participant"
          >
            <AccountSettings />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="company-details" />} />
        <Route path="company-details" element={<CompanyDetails />} />
        <Route path="my-profile" element={<UserProfile />} />
        <Route path="plan-billing" element={<PlanAndBilling />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="integrations" element={<Integrations />} />
      </Route>
      <Route path="/reports" element={<Reports />} />
      <Route
        path="/participant"
        element={
          <ProtectedRoute
            requiredRole={LOTTERY_PARTICIPANT}
            userRole={memoizedUserRole}
            redirectPath="/admin"
          >
            <ParticipantDashboard />
          </ProtectedRoute>
        }
      />
      <Route index path="/style-guide" element={<StyleGuide />} />
      <Route path="/tickets" element={<Tickets />}>
        <Route path="" element={<Navigate to="claimed" />} />
        <Route path="unclaimed" element={<UnclaimedTickets />} />
        <Route path="claimed" element={<ClaimedTickets />} />
      </Route>
      {/* Account settings route for participants */}
      <Route
        path="/account-settings"
        element={
          <ProtectedRoute
            requiredRole={LOTTERY_PARTICIPANT}
            userRole={memoizedUserRole}
            redirectPath="/admin"
          >
            <AccountSettings />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="my-profile" />} />
        <Route path="my-profile" element={<UserProfile />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>
      <Route path="/season-lotteries" element={<SeasonLotteries />}>
        <Route path="" element={<Navigate to="all-lotteries" />} />
        <Route path="all-lotteries" element={<AllLotteries />} />
        <Route path="active-lotteries" element={<ActiveLotteries />} />
        <Route path="upcoming-lotteries" element={<UpcomingLotteries />} />
        <Route path="complete-lotteries" element={<CompleteLotteries />} />
        <Route path="archived-lotteries" element={<ArchivedLotteries />} />
      </Route>
      <Route
        path="/season-lottery-detail/:lotteryId"
        element={<SeasonLotteryDetail />}
      >
        <Route path="" element={<Navigate to="event-schedule" />} />
        <Route path="event-schedule" element={<EventSchedule />} />
        <Route path="tickets" element={<SeasonLotteryDetailTickets />} />
        <Route
          path="participants"
          element={<SeasonLotteryDetailParticipants />}
        />
      </Route>
      <Route path="/event-detail/:eventId" element={<EventDetail />} />
      <Route path="/add-lottery" element={<AddLottery />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
