import { useFormik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import TagBox from '../../TagBox';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { protectedResources } from '../../../authConfig';
import ParticipantDetailsActions from './ParticipantDetailsActions';

function ParticipantDetailsEdit({
  participant,
  fields,
  onParticipantUpdated,
  setShouldDismiss,
}) {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });

  const formik = useFormik({
    initialValues: {
      ...participant,
    },
    onSubmit: (values) => {
      execute(
        'PUT',
        `${protectedResources.participants.endpoint}/${participant.organizationUserId}`,
        { ...values },
      ).then((updatedParticipant) => onParticipantUpdated(updatedParticipant));
    },
  });

  const { formValue } = {
    formValue: formik.values,
  };

  const getTagBoxValue = (value) => {
    if (!value || value === '') {
      return null;
    }
    return {
      label: value,
      value,
    };
  };

  function getParticipantAttributeInput({ type }, fieldName) {
    switch (type) {
      case 'tags':
        return (
          <TagBox
            id={fieldName}
            isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
            handleChange={(tag) => {
              formik.setFieldValue(fieldName, tag ? [tag] : []);
            }}
            value={
              formValue[fieldName] ? getTagBoxValue(formValue[fieldName]) : null
            }
            aria-describedby="tag-box-errors"
            className="align-self-stretch"
          />
        );
      default:
        return (
          <Form.Control
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formValue[fieldName]}
            type="text"
          />
        );
    }
  }

  function getParticipantAttributeValue({ type }, value) {
    if (!value || value === '') {
      return <span className="fs-4 fw-normal text-light">None</span>;
    }

    switch (type) {
      case 'link':
        return (
          <a
            href={`${value}`}
            target="_blank"
            rel="noreferrer"
            className="fs-4 fw-normal text-primary"
          >
            {value}
          </a>
        );
      case 'email':
        return (
          <a
            href={`mailto:${value}`}
            target="_blank"
            rel="noreferrer"
            className="fs-4 fw-normal text-primary"
          >
            {value}
          </a>
        );
      case 'tags':
        return value.length === 0 ? (
          <span className="fs-4 fw-normal text-light">None</span>
        ) : (
          <div className="d-flex flex-row gap-2">
            <span className="badge user-tag rounded-pill" key={value}>
              {value}
            </span>
          </div>
        );
      default:
        return <span className="fs-4 fw-normal text-secondary">{value}</span>;
    }
  }

  function getParticipantAttribute(fieldName) {
    const field = fields[fieldName];
    const fieldValue = participant[fieldName];

    return (
      <div
        key={fieldName}
        className="d-flex align-items-start gap-4 align-self-stretch"
      >
        {field.icon}
        <Form.Group
          className="d-flex flex-column align-items-start gap-1"
          style={{ flex: '1 0 0' }}
          controlId={fieldName}
        >
          <Form.Label className="fs-4 fw-medium text-secondary-emphasis m-0">
            {field.label}
          </Form.Label>

          {field.editable
            ? getParticipantAttributeInput(field, fieldName)
            : getParticipantAttributeValue(field, fieldValue)}
        </Form.Group>
      </div>
    );
  }

  return (
    <Form
      className="d-flex flex-column gap-4 align-self-stretch"
      onSubmit={formik.handleSubmit}
    >
      <div className="d-flex flex-column align-items-start align-self-stretch gap-5">
        <div className="gap-4 d-flex flex-row align-self-stretch align-items-center">
          <div className="fs-2 fw-medium text-black" style={{ flex: 1 }}>
            Participant Details
          </div>

          <Button
            variant="primary"
            size="sm"
            type="submit"
            className="d-flex gap-2"
          >
            Save
          </Button>

          <ParticipantDetailsActions
            email={participant.email}
            firstName={participant.firstName}
            id={participant.organizationUserId}
            lastName={participant.lastName}
            setShouldDismiss={setShouldDismiss}
          />
        </div>

        <div className="hr-divider" />
      </div>

      {Object.keys(fields).map((field) => getParticipantAttribute(field))}
    </Form>
  );
}

export default ParticipantDetailsEdit;
