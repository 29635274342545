import React from 'react';
import AllSeaterLogo from 'assets/images/allseater-logo.svg';
import cn from 'utils/classNames';
import {
  MenuNavigationTop,
  MenuNavigationBottom,
} from 'components/MenuNavigation';

import styles from './SidebarMenu.module.scss';

function SidebarMenu({ className }) {
  return (
    <nav
      aria-label="Primary"
      className={cn([className, styles['sidebar-content']])}
    >
      <div className={styles['sidebar-nav']}>
        <div className={styles['sidebar-header']}>
          <img
            className={styles.logo}
            src={AllSeaterLogo}
            alt="AllSeater Logo"
          />
        </div>

        <div className={styles['sidebar-navigation']}>
          <MenuNavigationTop />
        </div>
      </div>

      <div className={styles['sidebar-footer']}>
        <MenuNavigationBottom />
      </div>
    </nav>
  );
}

export default SidebarMenu;
