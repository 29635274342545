import { UPCOMING, ACTIVE, COMPLETE, PASSED, DRAFT } from 'constants';
import React from 'react';
import ClockIcon from 'assets/icons/clock.svg';
import DotIcon from 'assets/icons/dot.svg';
import CheckIcon from 'assets/icons/check.svg';
import EditIcon from 'assets/icons/edit.svg';
import ArchiveIcon from 'assets/icons/archive.svg';

function LotteryStatusPill({ status, className }) {
  const defaultClass = `${className} badge rounded-pill d-flex align-items-center gap-1 justify-content-start px-2 py-1`;
  switch (status) {
    case DRAFT:
      return (
        <span
          className={`${defaultClass} bg-gray-lighter border-gray text-gray`}
        >
          <EditIcon />
          {status}
        </span>
      );
    case UPCOMING:
      return (
        <span
          className={`${defaultClass} bg-yellow-lighter border-yellow text-yellow-dark`}
        >
          <ClockIcon />
          {status}
        </span>
      );
    case ACTIVE:
      return (
        <span
          className={`${defaultClass} bg-primary-lightest border-primary text-primary`}
        >
          <DotIcon />
          {status}
        </span>
      );
    case COMPLETE:
      return (
        <span
          className={`${defaultClass} bg-green-lighter border-green text-green-dark`}
        >
          <CheckIcon />
          {status}
        </span>
      );
    case PASSED:
      return (
        <span
          className={`${defaultClass} bg-gray-lighter border-gray text-gray-dark`}
        >
          <ArchiveIcon />
          {status}
        </span>
      );
    default:
      return null;
  }
}

export default LotteryStatusPill;
