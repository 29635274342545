import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';
import {
  TableFooter,
  TableFrame,
  TableHeader,
  TableBody,
} from 'components/Table';
import Avatar from 'components/Avatar';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import usePageTracking from 'hooks/usePageTracking';

import Ticket from 'assets/icons/ticket.svg';

import TicketStatusDropDown from './TicketStatusDropdown';

const tableColumns = {
  id: 'Id',
  ticketHolder: 'Ticket Holder',
  priorityGroup: 'Priority Group',
  event: 'Event',
  eventDate: 'Event Date',
  seatDetails: 'Seat Details',
  ticketStatus: 'Ticket Status',
};

function makeUpcomingTicketRow({
  id: ticketId,
  profilePhoto,
  firstName,
  lastName,
  email,
  eventName,
  eventDate,
  lotteryLogo,
  priorityGroupIndex,
  section,
  row,
  seat,
  isConfirmed,
  isDispersed,
}) {
  const sectionDetail = section && `Section ${section}`;
  const rowDetail = row && `Row ${row}`;
  const seatDetail = seat && `Seat ${seat}`;
  const claimed = !isConfirmed && !isDispersed;
  // Construct a string with only valid details
  const seatDetails = [sectionDetail, rowDetail, seatDetail]
    .filter((s) => s)
    .join(', ');

  return {
    id: ticketId,
    ticketHolder: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={profilePhoto}
          altText={`${firstName.charAt(0)}${lastName.charAt(0)}`}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">
            {firstName} {lastName}
          </span>{' '}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    priorityGroup: (
      <PriorityGroupBadge priorityGroup={{ groupIndex: priorityGroupIndex }} />
    ),
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    eventDate: () => format(eventDate, 'EEEE, MMMM d, yyyy'),
    seatDetails: () => seatDetails,
    ticketStatus: (
      <TicketStatusDropDown
        isConfirmed={isConfirmed}
        isDispersed={isDispersed}
        isClaimed={claimed}
        ticketId={ticketId}
      />
    ),
  };
}

function NoDataCTA() {
  return (
    <NavLink className="btn btn-primary btn-lg" to="/add-lottery">
      <Ticket className="me-1" />
      Create A New Lottery!
    </NavLink>
  );
}

function UpcomingTickets() {
  const {
    setUpcomingTicketsCurrentPage,
    upcomingTicketsPageTotal,
    upcomingTickets,
    totalUpcomingTickets,
    currentUpcomingTicketsPage,
    isLoading,
  } = useOutletContext();
  usePageTracking('upcoming-tickets');

  // Handle tickets from parent context
  let tickets = [];
  if (upcomingTickets?.length) {
    tickets = upcomingTickets.map((upcomingTicket) =>
      makeUpcomingTicketRow(upcomingTicket),
    );
  }

  return (
    <TableFrame>
      <TableHeader
        title="Upcoming Tickets"
        totalItems={totalUpcomingTickets}
        description="These are the tickets ready to be dispersed."
      />
      <TableBody
        columns={tableColumns}
        rows={tickets}
        isLoading={isLoading}
        noRowsMessage={
          isLoading
            ? 'Loading Upcoming Tickets...'
            : 'No Upcoming Tickets Found'
        }
        NoRowsCTA={NoDataCTA}
      />
      <TableFooter
        currentPage={currentUpcomingTicketsPage}
        pageTotal={upcomingTicketsPageTotal}
        setCurrentPage={setUpcomingTicketsCurrentPage}
      />
    </TableFrame>
  );
}

export default UpcomingTickets;
