import React, { useCallback, useEffect, useState } from 'react';
import usePageTracking from 'hooks/usePageTracking';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import TicketIcon from 'assets/icons/rounded-green-ticket.svg';
import SupportIcon from 'assets/icons/rounder-pink-support.svg';
import PersonIcon from 'assets/icons/rounded-orange-person.svg';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { protectedResources } from 'authConfig';
import ConsoleLogger from 'utils/logger';
import useUser from 'hooks/useUser';
import { Button } from 'react-bootstrap';
import DownloadIcon from 'assets/icons/download.svg';
import ReportCard from './ReportCard';

const tableColumns = {
  id: 'Id',
  reportName: 'Report Name',
  dateGenerated: 'Date Generated',
  action: 'Action',
};

function Reports() {
  usePageTracking('reports');
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.participants.scopes.access_as_user,
  });
  const { organizationId } = useUser();

  const [totalActions, setTotalActions] = useState();

  const getTotalLotteryActions = useCallback(async () => {
    try {
      const res = await execute(
        'GET',
        `${protectedResources.reports.endpoint}/total-lottery-actions/${organizationId}`,
      );

      setTotalActions(res);
    } catch (err) {
      ConsoleLogger.error(err);
      setTotalActions({
        successCount: 0,
        exceptions: [
          {
            email: 'failed',
            error:
              'Something went wrong, if the issue continues please contact support.',
          },
        ],
      });
    }
  }, [execute, organizationId]);

  useEffect(() => {
    getTotalLotteryActions();
  }, [getTotalLotteryActions]);

  return (
    <div
      id="Reports"
      className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
    >
      <div
        id="ReportsHeader"
        className="gap-6 d-flex flex-column align-items-start px-lg-8 px-4 w-100"
      >
        <div className="d-block d-lg-flex w-100 align-items-start justify-content-between">
          <div>
            <h1 className="h5 fw-bold">Reports</h1>
            <p className="text-gray mb-0">
              Access valuable insights and usage reports for informed
              decision-making.
            </p>
          </div>
        </div>
      </div>
      <div
        id="ReportsContentWrapper"
        className="gap-6 d-flex align-items-start px-lg-8 px-0 flex-column w-100 mt-8"
      >
        <div className="d-flex gap-16 w-100">
          <ReportCard
            title="Total Tickets Dispersed"
            icon={<TicketIcon />}
            value={totalActions?.totalTicketsDispersed}
          />
          <ReportCard
            title="Total Tickets Donated"
            icon={<SupportIcon />}
            value={totalActions?.totalTicketsDonated}
          />
          <ReportCard
            title="Total Participants"
            icon={<PersonIcon />}
            value={totalActions?.totalParticipants}
          />
        </div>
        <TableFrame>
          <TableHeader
            title="Download Insight Reports"
            // totalItems={totalReports}
            description="Access reports to gain a deeper understanding of user engagement, ticket distribution, event popularity, and more."
          >
            <div className="d-flex align-items-center gap-2">
              <Button variant="tertiary" size="xs" disabled>
                <DownloadIcon className="me-2" />
                Download all reports
              </Button>
            </div>
          </TableHeader>
          <TableBody
            columns={tableColumns}
            // rows={reportsRow}
            // isLoading={isLoading}
            noRowsMessage="Downloadable Reports Coming Soon!"
            // NoRowsCTA={NoDataCTA}
          />
          <TableFooter
          // currentPage={currentReportsPage}
          // pageTotal={reportsPageTotal}
          // setCurrentPage={setCurrentReportsPage}
          />
        </TableFrame>
      </div>
    </div>
  );
}

export default Reports;
