import React from 'react';
import Avatar from 'components/Avatar';

function AvatarGuide() {
  return (
    <div className="container-fluid m-0">
      <div className="row my-4">
        <div className="col-md-2">
          <p className="fs-text-lg fw-bold">With Image</p>
        </div>

        <div className="col-md-2">
          <Avatar altText="xs" imgSrc="https://i.pravatar.cc/300" size="xs" />
        </div>

        <div className="col-md-2">
          <Avatar altText="sm" imgSrc="https://i.pravatar.cc/300" size="sm" />
        </div>

        <div className="col-md-2">
          <Avatar altText="md" imgSrc="https://i.pravatar.cc/300" size="md" />
        </div>

        <div className="col-md-2">
          <Avatar altText="lg" imgSrc="https://i.pravatar.cc/300" size="lg" />
        </div>

        <div className="col-md-2">
          <Avatar altText="xl" imgSrc="https://i.pravatar.cc/300" size="xl" />
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-2">
          <p className="fs-text-lg fw-bold">No Image</p>
        </div>

        {/* TODO: Avatar needs to be updated to support purple variant */}
        <div className="col-md-2 d-flex flex-column" style={{ gap: '0.8rem' }}>
          <Avatar altBackground="yellow" altText="xs" size="xs" />
          <Avatar altBackground="green" altText="xs" size="xs" />
          <Avatar altBackground="blue" altText="xs" size="xs" />
          {/* <Avatar altBackground="purple" altText="xs" size="xs" /> */}
          <Avatar altBackground="pink" altText="xs" size="xs" />
        </div>

        <div className="col-md-2 d-flex flex-column" style={{ gap: '0.8rem' }}>
          <Avatar altBackground="yellow" altText="sm" size="sm" />
          <Avatar altBackground="green" altText="sm" size="sm" />
          <Avatar altBackground="blue" altText="sm" size="sm" />
          {/* <Avatar altBackground="purple" altText="sm" size="sm" /> */}
          <Avatar altBackground="pink" altText="sm" size="sm" />
        </div>

        <div className="col-md-2 d-flex flex-column" style={{ gap: '0.8rem' }}>
          <Avatar altBackground="yellow" altText="md" size="md" />
          <Avatar altBackground="green" altText="md" size="md" />
          <Avatar altBackground="blue" altText="md" size="md" />
          {/* <Avatar altBackground="purple" altText="md" size="md" /> */}
          <Avatar altBackground="pink" altText="md" size="md" />
        </div>

        <div className="col-md-2 d-flex flex-column" style={{ gap: '0.8rem' }}>
          <Avatar altBackground="yellow" altText="lg" size="lg" />
          <Avatar altBackground="green" altText="lg" size="lg" />
          <Avatar altBackground="blue" altText="lg" size="lg" />
          {/* <Avatar altBackground="purple" altText="lg" size="lg" /> */}
          <Avatar altBackground="pink" altText="lg" size="lg" />
        </div>

        <div className="col-md-2 d-flex flex-column" style={{ gap: '0.8rem' }}>
          <Avatar altBackground="yellow" altText="xl" size="xl" />
          <Avatar altBackground="green" altText="xl" size="xl" />
          <Avatar altBackground="blue" altText="xl" size="xl" />
          {/* <Avatar altBackground="purple" altText="xl" size="xl" /> */}
          <Avatar altBackground="pink" altText="xl" size="xl" />
        </div>
      </div>
    </div>
  );
}

export default AvatarGuide;
