import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ConsoleLogger from 'utils/logger';

import Tix from 'assets/icons/tix.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import styles from './TableBody.module.scss';

/**
 * TableBody is a React component that renders a table body with rows and columns.
 * It allows for multi-selection of rows and displays a custom message or CTA when no data is available.
 *
 * If we do not pass selected or setSelected, we are hiding the row "id"
 *
 * @component
 * @example
 * const columns = { id: 'ID', name: 'Name' };
 * const rows = [{ id: 1, name: 'John Doe' }];
 * const noRowsMessage = 'No users found';
 * return (
 *   <TableBody
 *     columns={columns}
 *     rows={rows}
 *     noRowsMessage={noRowsMessage}
 *     NoRowsCTA={() => <button>Refresh</button>}
 *     selected={selectedArray}
 *     setSelected={setSelectedArray}
 *   />
 * )
 *
 * @param {Object} props - The component props.
 * @param {Object.<string, string>} props.columns - An object representing column headers where the key is the column field and the value is the column label.
 * @param {Array.<Object>} [props.rows=[]] - An array of objects representing rows of data.
 * @param {string} [props.noRowsMessage] - A message displayed when there are no rows to display.
 * @param {Function} [props.NoRowsCTA] - A React component or function called to display a call-to-action when no rows are present.
 * @param {Array.<string|number>} props.selected - An array of ids representing selected rows.
 * @param {Function} props.setSelected - A function that updates the state of selected row ids.
 * @returns {React.ReactElement} - Returns a table element wrapped in a div with overflow-auto styling.
 */
export default function TableBody({
  columns,
  rows = [],
  noRowsMessage,
  NoRowsCTA,
  selected,
  setSelected,
  sortBy,
  handleSortBy,
  isLoading,
  showSelectAll = true,
}) {
  const [tableSelected, setTableSelected] = useState(false);
  const tableCheckBoxRef = useRef();

  if (!Array.isArray(rows)) {
    ConsoleLogger.error(
      `Prop "rows" must be type Array, instead got type: ${typeof rows}`,
    );
  }

  const [sortedColumn, sortedDirection] = sortBy || [];

  function handleMultiSelect(rowId) {
    // add the id if it doesn't exist
    setSelected((prevSelected) => {
      if (!prevSelected.includes(rowId)) {
        return [...prevSelected, rowId];
      }
      // remove if row id is present
      return [...prevSelected.filter((id) => id !== rowId)];
    });
  }

  function handleTableSelect() {
    if (tableSelected === true) {
      setSelected([]);
      setTableSelected(false);
    } else {
      const allRowIds = rows?.map((row) => `${row.id}`);
      setSelected(allRowIds);
      setTableSelected(true);
    }
  }

  useEffect(() => {
    if (!selected || !showSelectAll) {
      return;
    }

    if (selected.length === 0) {
      setTableSelected(false);
      tableCheckBoxRef.current.indeterminate = false;
    } else if (selected.length < rows.length) {
      tableCheckBoxRef.current.indeterminate = true;
    } else {
      setTableSelected(true);
    }
  }, [selected, tableCheckBoxRef, rows, showSelectAll]);

  return (
    <div className="overflow-auto">
      <table className="table flex-grow-1 mb-0 border-top border-gray-lighter text-gray">
        <thead>
          <tr>
            {columns &&
              Object.entries(columns).map(([colKey, col]) => {
                if (colKey === 'id' && setSelected) {
                  return (
                    <th key={colKey} scope="row">
                      {showSelectAll && (
                        <label className="fs-3">
                          <span className="visually-hidden">
                            Toggle selected data rows
                          </span>
                          <input
                            ref={tableCheckBoxRef}
                            id={`data-column-${colKey}`}
                            type="checkbox"
                            className="form-check-input"
                            value={tableSelected}
                            onChange={handleTableSelect}
                          />
                        </label>
                      )}
                    </th>
                  );
                }

                return (
                  <th
                    key={colKey}
                    scope="col"
                    // Hide the id row if we aren't supporting selecting
                    className={colKey === 'id' ? 'd-none' : ''}
                  >
                    {col.sortBy ? (
                      <Button
                        variant="text"
                        className={`py-0 border-0 ${styles.sortable}`}
                        onClick={() => handleSortBy(col.sortBy)}
                        disabled={isLoading}
                      >
                        {col.label}{' '}
                        {sortedColumn === col.sortBy &&
                          sortedDirection === 'asc' && <ArrowUp />}
                        {sortedColumn === col.sortBy &&
                          sortedDirection === 'desc' && <ArrowDown />}
                      </Button>
                    ) : (
                      col.label || col
                    )}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows?.map((row) => (
              <tr key={row.id}>
                {columns &&
                  Object.entries(columns).map(([colKey]) => {
                    if (colKey === 'id' && setSelected && selected) {
                      return (
                        <th key={`toggle-${row.id}`} scope="row">
                          <label>
                            <span className="visually-hidden">
                              Click to select this record
                            </span>
                            <input
                              id={`data-row-${row.id}`}
                              type="checkbox"
                              className="form-check-input"
                              checked={selected.includes(row.id)}
                              value={row.id}
                              onChange={() => handleMultiSelect(row.id)}
                            />
                          </label>
                        </th>
                      );
                    }

                    // assumes if we are passing functions they must be components.
                    if (typeof row[colKey] === 'function') {
                      const RowComponent = row[colKey];
                      return (
                        <td key={`${colKey}-${row.id}`}>
                          <RowComponent id={row.id} />
                        </td>
                      );
                    }

                    return (
                      <td
                        key={`${colKey}-${row.id}`}
                        // Hide the id row if we aren't supporting selecting
                        className={colKey === 'id' ? 'd-none' : ''}
                      >
                        {row[colKey]}
                      </td>
                    );
                  })}
              </tr>
            ))}
        </tbody>
      </table>
      {!rows?.length && (
        <div
          className="d-flex flex-column justify-content-center"
          style={{ minHeight: 416 }}
        >
          <div className="d-flex flex-column align-items-center">
            <Tix className="text-gray-lighter mb-6" />
            <p className="pb-6 m-0 fw-semibold">
              {noRowsMessage || 'No Data Found'}
            </p>
            {NoRowsCTA && <NoRowsCTA />}
          </div>
        </div>
      )}
    </div>
  );
}
