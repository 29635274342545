import { createContext, useContext } from 'react';

const ToastMessagesContext = createContext({
  handleNewToast: () => {},
  toastMessages: [],
});

const useToastMessages = () => {
  const toastMessageContext = useContext(ToastMessagesContext);
  return {
    handleNewToast: toastMessageContext.handleNewToast,
    toastMessages: toastMessageContext.toastMessages,
  };
};

export { ToastMessagesContext };
export default useToastMessages;
