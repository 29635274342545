import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Ticket from 'assets/icons/ticket.svg';

function LotteryCreationErrorDialog({ errors, onDismiss }) {
  const [show, setShow] = useState(false);
  const handleDismiss = () => {
    setShow(false);
    onDismiss();
  };

  useEffect(() => {
    if (errors !== null && errors !== undefined && errors.length > 0) {
      setShow(true);
    }
  }, [errors]);

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleDismiss} backdrop keyboard centered>
      <Modal.Header className="align-items-start pb-5" closeButton>
        <div className="d-flex flex-column align-items-start">
          <div className="bg-primary-lighter p-3 rounded-circle mb-4">
            <Ticket className="text-primary-darker" />
          </div>
          <h2 className="fs-2 fw-semibold w-100">
            Oh no ! An error just occurred
          </h2>
          <p className="text-gray mb-0 fs-4">
            There was an error while creating your lottery. Please find details
            below:
          </p>
        </div>
      </Modal.Header>
      <Modal.Body className="py-0 pb-2 text-gray mb-0 fs-4">
        {Array.isArray(errors) ? (
          errors.map((error) => (
            <p key={error.index} className="text-danger mb-0 fs-4">
              {error.text}
            </p>
          ))
        ) : (
          <p className="text-danger mb-0 fs-4">{errors}</p>
        )}
      </Modal.Body>
      <Modal.Footer className="m-0">
        <Button
          variant="primary"
          size="lg"
          className="w-100 justify-content-center"
          onClick={handleDismiss}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LotteryCreationErrorDialog;
