import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
} from '@azure/msal-react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './layouts/Default/Loading/Loading';
import NavigationClient from './utils/navigationClient';
import AuthenticationContext from './AuthenticationContext';

function AuthenticationProvider({ pca, children }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new NavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // Memoize the context value to prevent unnecessary re-renders
  const providerValue = useMemo(() => ({ msalInstance: pca }), [pca]);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          loadingComponent={Loading}
          authenticationRequest={{
            scopes: [
              'https://allseaterportal.onmicrosoft.com/allseater-api/access_as_user', // This is the default Scope that we use for all the API Endpoints, so we can get that from advance in the first login request
            ],
          }}
        >
          <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;
