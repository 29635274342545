import React, { useEffect } from 'react';
import cn from 'utils/classNames';
import styles from './Sidebar.module.scss';

function Sidebar({
  visible,
  children,
  onClose,
  direction = 'right',
  style,
  className,
}) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]); // Dependency array ensures effect runs only if onClose changes

  // When sidebar becomes visible set body overflow to hidden and dont allow scrolling
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [visible]);

  return (
    <div
      className={cn([
        styles['sidebar-wrapper'],
        visible ? styles['sidebar-visible'] : styles['sidebar-hidden'],
      ])}
    >
      <div
        role="button"
        tabIndex={0}
        aria-label="Close sidebar"
        className={`${styles.backdrop}`}
        onClick={() => onClose()}
        onKeyDown={(event) => event.key === 27 && onClose()}
      />
      <div
        style={style}
        className={cn([styles.sidebar, styles[direction], className])}
      >
        {children}
      </div>
    </div>
  );
}

export default Sidebar;
