import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AllSeaterLogo from 'assets/images/allseater-logo-small.svg';
import ConsoleLogger from 'utils/logger';
import styles from './ErrorComponent.module.scss';

const logger = ConsoleLogger.getInstance();

function ErrorComponent({ error, resetErrorBoundary }) {
  const navigate = useNavigate();

  useEffect(() => {
    logger.error(error);
  }, [error]);

  const handleGoBack = () => {
    resetErrorBoundary();
    navigate('');
  };

  return (
    <div
      className={`align-items-center flex-column justify-content-center gap-8 d-flex ${styles['error-wrapper']}`}
    >
      <img
        className={styles['error-logo']}
        src={AllSeaterLogo}
        alt="allseater-logo"
      />
      <div className="text-center d-flex flex-column gap-4">
        <h3 className="fw-bold text-primary">Oh No! An error just ocurred</h3>
        <p className="text-secondary">
          Something went wrong, please try again later. If the problem persists,
          please contact support.
        </p>

        <Button variant="primary" onClick={handleGoBack}>
          Go back to home
        </Button>
      </div>
    </div>
  );
}

export default ErrorComponent;
