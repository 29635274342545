import { NO_SUBSCRIPTION_PLAN_NAME } from 'constants';
import { createContext } from 'react';

export const userContextContract = (userContext) => {
  if (!userContext) {
    return { expireContext: () => {}, changeOrganization: () => {} };
  }

  const companyPlan = {
    isFeaturesPriority: false,
    totalParticipantsInvited: 0,
    lotteryParticipantMax: 0,
    planName: '',
    canPurchaseAdditionalSeasons: false,
    id: '',
    isDedicatedManagement: false,
    lotteryGroupMax: 0,
    nextChargeAmount: 0,
    renewalDate: '',
    seasonsMax: 0,
  };

  const profile = {
    firstName: '',
    lastName: '',
    profilePhoto: '',
    email: '',
  };

  // Default org is always the first
  const {
    organizationId,
    organizationUserRole,
    organizationUserId,
    organizationName,
  } = userContext;

  const { expireContext } = userContext;
  if (userContext.companyPlan?.lotteryParticipantMax) {
    companyPlan.lotteryParticipantMax =
      userContext.companyPlan.lotteryParticipantMax;
  }

  if (userContext.companyPlan?.allocatedCount) {
    companyPlan.totalParticipantsInvited =
      userContext.companyPlan.allocatedCount;
  }
  if (userContext?.companyPlan?.isFeaturesPriority) {
    companyPlan.isFeaturesPriority = userContext.companyPlan.isFeaturesPriority;
  }

  if (userContext?.companyPlan?.name) {
    companyPlan.planName = userContext.companyPlan.name;
  }

  if (userContext.companyPlan?.canPurchaseAdditionalSeasons) {
    companyPlan.canPurchaseAdditionalSeasons =
      userContext.companyPlan?.canPurchaseAdditionalSeasons;
  }
  if (userContext.companyPlan?.id) {
    companyPlan.id = userContext.companyPlan?.id;
  }
  if (userContext.companyPlan?.isDedicatedManagement) {
    companyPlan.isDedicatedManagement =
      userContext.companyPlan?.isDedicatedManagement;
  }
  if (userContext.companyPlan?.lotteryGroupMax) {
    companyPlan.lotteryGroupMax = userContext.companyPlan?.lotteryGroupMax;
  }
  if (userContext.companyPlan?.ticketsPerEventMax) {
    companyPlan.ticketsPerEventMax =
      userContext.companyPlan?.ticketsPerEventMax;
  }
  if (userContext.companyPlan?.nextChargeAmount) {
    companyPlan.nextChargeAmount = userContext.companyPlan?.nextChargeAmount;
  }
  if (userContext.companyPlan?.renewalDate) {
    companyPlan.renewalDate = userContext.companyPlan?.renewalDate;
  }
  if (userContext.companyPlan?.seasonsMax) {
    companyPlan.seasonsMax = userContext.companyPlan?.seasonsMax;
  }

  if (userContext?.firstName) {
    profile.firstName = userContext.firstName;
  }

  if (userContext?.lastName) {
    profile.lastName = userContext.lastName;
  }

  if (userContext?.profilePhoto) {
    profile.profilePhoto = userContext.profilePhoto;
  }

  if (userContext?.email) {
    profile.email = userContext.email;
  }

  const hasActivePlan = companyPlan.planName !== NO_SUBSCRIPTION_PLAN_NAME;

  return {
    organizationId,
    expireContext,
    companyPlan,
    organizationUserRole,
    organizationUserId,
    organizationName,
    profile,
    hasActivePlan,
    organizationUserRoles: userContext.organizationUserRoles || [],
    changeOrganization: userContext.changeOrganization,
  };
};

// TODO: define context object for documentation purposes, see useUser hook
const UserContext = createContext(userContextContract());

export default UserContext;
