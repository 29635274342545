import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import Avatar from 'components/Avatar';
import PriorityGroupBadge from 'components/PriorityGroupBadge';
import UserPlus from 'assets/icons/user-plus.svg';
import InviteParticipantsModal from '../manage-participants/InviteParticipantsModal';

const tableColumns = {
  id: 'Id',
  name: 'Name',
  userTag: 'User Tag',
  priorityGroup: 'Priority Group', // TODO: must be passed from BE and handled with a badge/pillZ
  details: 'Additional Details',
};

function makeParticipantRow({
  id,
  firstName,
  lastName,
  avatar,
  email,
  tag,
  details,
  priorityGroup,
}) {
  const groupIndex = priorityGroup ? priorityGroup.split(' ')[2] - 1 : 0;
  return {
    id,
    name: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={avatar}
          altText={firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : 'N/A'}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">
            {firstName} {lastName}
          </span>{' '}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    userTag: () => <span className="badge user-tag rounded-pill">{tag}</span>,
    priorityGroup: <PriorityGroupBadge priorityGroup={{ groupIndex }} />,
    details,
  };
}

function SeasonLotteryDetailParticipants() {
  const {
    lotteryId,
    lotteryParticipants,
    lotteryParticipantsCurrentPage,
    setLotteryParticipantsCurrentPage,
    lotteryParticipantsTotal,
    lotteryParticipantsPageTotal,
    isLoading,
  } = useOutletContext();

  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const handleCloseInvitationModal = () => setShowInvitationModal(false);
  const handleShowInvitationModal = () => setShowInvitationModal(true);

  // Handle participants from parent context
  let participants = [];
  if (lotteryParticipants?.length) {
    participants = lotteryParticipants.map((participant) =>
      makeParticipantRow({
        id: participant?.OrganizationUserId,
        avatar: participant?.ProfileImageUrl,
        firstName: participant?.FirstName,
        lastName: participant?.LastName,
        email: participant?.Email,
        tag: participant?.UserTag,
        details: participant?.AdditionalDetails,
        priorityGroup: participant?.PriorityGroup,
      }),
    );
  }

  return (
    <TableFrame>
      <TableHeader
        title="Lottery Participants"
        totalItems={lotteryParticipantsTotal}
        description="View and manage profiles for all participants in the system."
      >
        <Button
          id="UploadParticipantsDesktop"
          className="d-none d-lg-block"
          variant="outline-primary"
          onClick={handleShowInvitationModal}
        >
          <UserPlus className="me-2" />
          Add Participants
        </Button>
        <Button
          id="AddParticipantsMobile"
          className="d-block d-lg-none"
          variant="primary"
          onClick={handleShowInvitationModal}
        >
          <UserPlus className="me-2" />
          Add Participants
        </Button>
      </TableHeader>
      <TableBody
        columns={tableColumns}
        rows={participants}
        isLoading={isLoading}
        noRowsMessage={
          isLoading ? 'Loading Participants...' : 'No Participants Found'
        }
      />
      <TableFooter
        currentPage={lotteryParticipantsCurrentPage}
        pageTotal={lotteryParticipantsPageTotal}
        setCurrentPage={setLotteryParticipantsCurrentPage}
      />
      <InviteParticipantsModal
        show={showInvitationModal}
        handleClose={handleCloseInvitationModal}
        lotteryId={lotteryId}
      />
    </TableFrame>
  );
}

export default SeasonLotteryDetailParticipants;
