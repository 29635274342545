import { UPCOMING, PASSED } from 'constants';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';
import localDateTime from 'utils/localTime';
import LotteryStatusPill from 'components/LotteryStatusPill';
import colorMap from 'utils/backgroundColorMapper';
import styles from './Tickets.module.scss';
import TicketShapeRightIcon from '../../assets/icons/ticket-shape-right.svg';
import MarkerIcon from '../../assets/icons/marker-pin.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import Confirm from '../../assets/icons/confirm-attending.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ClaimedTicketAction from './ClaimedTicketAction';
import HeroImage from '../../components/HeroImage/HeroImage';
import Avatar from '../../components/Avatar';

function ClaimedTicketDetails({
  tickets: { section, row, seat, isConfirmed, availabilityStatus },
  ticketNumber,
  totalTickets,
  handleConfirm,
  handleRelease,
  isLoading,
}) {
  return (
    <div id="TicketDetails" className="d-flex w-100">
      <TicketShapeRightIcon className={styles.flipHorizontal} />
      <div
        className={`${styles.ticketContainer} d-flex flex-column px-1 flex-grow-1 justify-content-center align-items-start gap-4`}
      >
        <div className={`${styles.ticketDetails} d-flex gap-5`}>
          <div className="d-flex flex-column ">
            <span className="fs-5 fw-medium text-gray">SECTION</span>
            <span className="h5 fw-normal mb-0">{section}</span>
          </div>
          <div className="d-flex flex-column ">
            <span className="fs-5 fw-medium text-gray">ROW</span>
            <span className="h5 fw-normal mb-0">{row}</span>
          </div>
          <div className="d-flex flex-column ">
            <span className="fs-5 fw-medium text-gray">SEAT</span>
            <span className="h5 fw-normal mb-0">{seat}</span>
          </div>
          <div className={styles.verticalLine} />
          <div className="d-flex">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-medium text-gray d-flex">TICKET</span>
              <div className="d-flex align-items-baseline">
                <span className="h5 fw-normal mb-0">{ticketNumber}</span>
                <span className={`${styles.ticketCounter} text-nowrap`}>
                  of {totalTickets}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 gap-2 justify-content-between">
          {isConfirmed || availabilityStatus === 'DISPERSED' ? (
            <div className="w-100 ">
              <div className={styles.horizontalLine} />
              <div className="d-flex mt-2">
                <Confirm />
                <span className={styles.attending}>Attending</span>
              </div>
            </div>
          ) : (
            <div className="d-flex w-100 gap-2 justify-content-between">
              <Button
                variant="primary"
                size="sm"
                className="w-50 text-center justify-content-center"
                disabled={isLoading}
                onClick={() => handleConfirm()}
              >
                Confirm
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                className="w-50 text-center justify-content-center"
                disabled={isLoading}
                onClick={() => handleRelease()}
              >
                Release
              </Button>
            </div>
          )}
        </div>
      </div>
      <TicketShapeRightIcon />
    </div>
  );
}

function ClaimedTicketRoll({
  events: {
    eventId,
    lotteryCover,
    lotteryLogo,
    eventName,
    eventLocation,
    tickets,
    eventDateTime,
  },
  handleRelease,
  handleConfirm,
  isLoading,
}) {
  const [currentTicket, setCurrentTicket] = useState(1);
  const ticketsPerPage = 1;
  const totalTickets = tickets.length;
  const totalPages = Math.ceil(totalTickets / ticketsPerPage);
  const startIdx = (currentTicket - 1) * ticketsPerPage;
  const endIdx = startIdx + ticketsPerPage;
  const visibleTickets = tickets.slice(startIdx, endIdx);

  const handleNextPage = useCallback(() => {
    setCurrentTicket((prevPage) => Math.min(prevPage + 1, totalPages));
  }, [totalPages]);

  const handlePrevPage = () => {
    setCurrentTicket((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    handleNextPage();
  }, [totalTickets, totalPages, handleNextPage]);

  let status = UPCOMING;
  const now = Date.now();
  const localDate = localDateTime(eventDateTime);
  if (localDate < now) {
    status = PASSED;
  }

  return (
    <div
      className={`${styles.ticket} d-flex flex-column p-4 justify-content-between gap-5 shadow-sm border border-gray-lighter rounded-3 bg-white`}
    >
      <div className={styles.lotteryImagesContainer}>
        <div className="position-relative rounded-2 overflow-hidden">
          <HeroImage
            altBackgroundColor={colorMap(eventName)}
            imgSrc={lotteryCover ?? null}
            style={{ width: '107px' }}
            className={styles.lotteryCover}
          />
          <Avatar
            altText={eventName}
            size="md"
            style={{
              width: '107px',
              height: '107px',
              fontSize: '3rem',
            }}
            imgSrc={lotteryLogo ?? null}
            className={`${styles.lotteryLogo} ${
              lotteryLogo === null ? 'shadow' : ''
            }`}
          />
          <LotteryStatusPill className={styles.lotteryStatus} status={status} />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="fs-1 fw-semibold">{eventName}</span>
        <ClaimedTicketAction
          EventId={eventId}
          location={eventLocation}
          name={eventName}
          startDate={eventDateTime}
        />
      </div>
      <div
        id="TicketInfo"
        className="d-flex flex-column gap-4 align-items-center justify-content-center"
      >
        <div className="d-flex flex-start w-100 gap-6">
          <div className="d-flex gap-2">
            <MarkerIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">{eventLocation}</span>
          </div>
          <div className="d-flex gap-2">
            <CalendarIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">
              {format(localDate, 'EEEE, MMMM d, yyyy')}
            </span>
          </div>
        </div>
        <div className="d-flex flex-start w-100 gap-6">
          <div className="d-flex gap-2">
            <ClockIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray">
              {format(localDate, 'h:mm a')}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.ticketPagionationWrapper}>
        {visibleTickets.map((t, index) => (
          <ClaimedTicketDetails
            key={t.ticketId}
            tickets={t}
            ticketNumber={index + startIdx + 1}
            totalTickets={totalTickets}
            handleConfirm={() => handleConfirm(t.ticketId)}
            handleRelease={() => handleRelease(t.ticketId)}
            isLoading={isLoading}
          />
        ))}
        <div
          className={`${styles.paginationButtons} d-flex justify-content-between align-items-center`}
        >
          <Button
            variant="gray"
            disabled={currentTicket === 1}
            className={styles.prevButton}
            onClick={handlePrevPage}
          >
            <ArrowLeft />
          </Button>
          <Button
            variant="gray"
            disabled={currentTicket === totalPages}
            className={styles.nextButton}
            onClick={handleNextPage}
          >
            <ArrowLeft className={styles.flipHorizontal} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ClaimedTicketRoll;
