import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';
import LotteryStatusPill from 'components/LotteryStatusPill';

// Lottery Card Icons
import MarkerIcon from 'assets/icons/marker-pin.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import TicketIcon from 'assets/icons/ticket-tiny.svg';
import TicketIconNormal from 'assets/icons/ticket.svg';

import EditIcon from 'assets/icons/edit.svg';

import { UserRoleAdmin, UserRoleParticipant } from 'components/UserRole';
import colorMap from 'utils/backgroundColorMapper';
import LotteryActions from './LotteryActions';

import styles from './SeasonLotteries.module.scss';
import HeroImage from '../../components/HeroImage/HeroImage';
import Avatar from '../../components/Avatar';

function LotteryCard({
  lottery,
  isDraft = false,
  userRole,
  onLotteryDeletion,
  onLotteryArchive,
}) {
  const [ticketClaimingPercentage, setTicketClaimingPercentage] = useState(0);

  useEffect(() => {
    const claimedTickets = lottery.tickets.filter((ticket) => ticket.isClaimed);
    const percentage = (claimedTickets.length / lottery.tickets.length) * 100;
    setTicketClaimingPercentage(percentage);
  }, [lottery, isDraft]);

  return (
    <div
      className={`${styles.lotteryCard} d-flex flex-column p-4 justify-content-start gap-5 shadow-sm border border-gray-lighter rounded-3 bg-white`}
    >
      <div className="position-relative rounded-2 overflow-hidden">
        <HeroImage
          altBackgroundColor={colorMap(lottery.title)}
          imgSrc={lottery.coverPhoto ?? null}
          style={{ width: '107px' }}
          className={styles.lotteryCover}
        />
        <Avatar
          altText={lottery.title}
          size="md"
          style={{
            width: '128px',
            height: '128px',
            fontSize: '4rem',
          }}
          imgSrc={lottery.logo ?? null}
          className={`${styles.lotteryLogo} ${
            lottery.logo === null ? 'shadow' : ''
          }`}
        />
        <LotteryStatusPill
          className={styles.lotteryStatus}
          status={lottery.status}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="fs-1 fw-semibold">{lottery.title}</span>
        <UserRoleAdmin userType={userRole}>
          {!isDraft ? (
            <LotteryActions
              id={lottery.id}
              lotteryStatus={lottery.status}
              onLotteryDeletion={onLotteryDeletion}
              onLotteryArchive={onLotteryArchive}
            />
          ) : (
            <Button as={NavLink} to="/add-lottery" variant="link">
              <EditIcon className="text-gray-light" />
            </Button>
          )}
        </UserRoleAdmin>
        <UserRoleParticipant userType={userRole}>
          <LotteryActions
            id={lottery.id}
            lotteryStatus={lottery.status}
            onLotteryDeletion={onLotteryDeletion}
          />
        </UserRoleParticipant>
      </div>
      <div
        id="TicketInfo"
        className="d-flex flex-column gap-4 align-items-center justify-content-start"
      >
        <div className={`${styles['lottery-data-pill-container']} w-100 gap-4`}>
          <div
            className={`${styles['lottery-data-pill']} d-flex align-items-center gap-1`}
          >
            <MarkerIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray text-truncate">
              {lottery.venue}
            </span>
          </div>
          <div
            className={`${styles['lottery-data-pill']} d-flex align-items-center gap-1`}
          >
            <CalendarIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray text-truncate">
              {lottery.eventIds.length}{' '}
              {lottery.eventIds.length !== 1 ? 'Games' : 'Game'}
            </span>
          </div>
          <div
            className={`${styles['lottery-data-pill']} d-flex align-items-center gap-1`}
          >
            <TicketIcon className="text-gray-light" />
            <span className="fs-5 fw-medium text-gray text-truncate">
              {lottery.tickets.length}{' '}
              {lottery.tickets.length !== 1 ? 'Tickets' : 'Ticket'}
            </span>
          </div>
        </div>
      </div>
      {!isDraft && (
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex flex-fill">
            <ProgressBar
              progress={ticketClaimingPercentage}
              contentClassNames={styles['custom-progress-bar']}
              wrapperClassNames="flex-grow-1 flex-shrink-1"
            />
          </div>

          <span className="fw-medium text-gray">
            {Math.ceil(ticketClaimingPercentage)}%
          </span>
          <TicketIconNormal className="text-gray" />
        </div>
      )}

      {isDraft && (
        <div className="d-flex gap-3 align-items-center justify-content-between">
          <Button
            as={NavLink}
            to="/add-lottery"
            variant="primary"
            size="sm"
            className="w-100 justify-content-center"
          >
            Continue Lottery Creation
          </Button>
        </div>
      )}
    </div>
  );
}

export default LotteryCard;
