import React from 'react';
import { Form } from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon';
import LockIcon from 'assets/icons/lock.svg';

import styles from './NotificationMethods.module.scss';

function TogglerField({ field, loading, handleSwitch, checked }) {
  return !field.mandatory ? (
    <div>
      <Form.Switch
        onChange={(event) => handleSwitch(event, field.bit)}
        disabled={loading}
        aria-label={field.altText}
        checked={checked}
      />
    </div>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center gap-1"
      style={{ width: '6.5rem', flexShrink: 0 }}
    >
      <Form.Switch
        aria-label={field.altText}
        name={field.id}
        readOnly
        checked={checked}
      />
      <SvgIcon
        altText="This option is required"
        component={LockIcon}
        className={styles['locked-icon']}
      />
    </div>
  );
}

export default TogglerField;
