import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'utils/classNames';
import Calendar from 'assets/icons/calendar-small.svg';
import Ticket from 'assets/icons/ticket-small.svg';
import StopWatch from 'assets/icons/stopwatch-small.svg';

function getPriorityGroupColor(index) {
  const colorMap = ['pink', 'blue', 'yellow', 'green'];
  return colorMap[index % colorMap.length];
}

function PriorityGroupBadge({ priorityGroup, showTooltip = false }) {
  if (!priorityGroup) {
    return false;
  }

  const BadgeContent = (
    <Badge
      pill
      className={cn([
        `bg-${getPriorityGroupColor(priorityGroup.groupIndex)}-lighter`,
        `border-${getPriorityGroupColor(priorityGroup.groupIndex)}-light`,
        `text-${getPriorityGroupColor(priorityGroup.groupIndex)}-dark`,
      ])}
    >
      Priority Group {priorityGroup.groupIndex + 1}
    </Badge>
  );

  return showTooltip ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <div className="d-flex gap-2 flex-column px-3 py-2">
            <span className="fw-semibold">
              Priority Group {priorityGroup.groupIndex + 1}
            </span>
            <div className="d-flex gap-4 align-items-start justify-content-center">
              <div className="d-flex gap-2 align-items-center m-0 p-0">
                <Calendar className="text-light" />
                {priorityGroup.eventLimit}
              </div>

              <div className="d-flex gap-2 align-items-center m-0 p-0">
                <Ticket className="text-light" />
                {priorityGroup.ticketLimitPerEvent}
              </div>

              <div className="d-flex gap-2 align-items-center m-0 p-0">
                <StopWatch className="text-light" />
                {priorityGroup.minutesPickWindow === 0
                  ? 'Unlimited'
                  : `${priorityGroup.minutesPickWindow} Minutes`}
              </div>
            </div>
          </div>
        </Tooltip>
      }
    >
      {BadgeContent}
    </OverlayTrigger>
  ) : (
    BadgeContent
  );
}

export default PriorityGroupBadge;
